import React, { useMemo, useState } from 'react';
import Table from '../../../../atoms/table/Table';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import { DropdownTypes, SelectOptions } from '../../../../commons/types';
import LabelDropdown from '../../../../atoms/dropdown/LabelDropdown';
import { Box } from '@mui/material';
import { IGetRolesData } from '../../../../store/api/types/leadership';

interface IProps {
  data: IGetRolesData[] | [];
  IGDropdownData: Array<SelectOptions>;
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  functionList: SelectOptions[];
  levelList: SelectOptions[];
  setChangeImpactGroup: (value: {
    roleId: string;
    impactGroupId: string;
  }) => void;
  recordSelection: (records: Array<IGetRolesData | null>) => void;
}

const ImpactGroupRoleTable = ({
  data,
  isLoading,
  tableInstanceRef,
  functionList,
  levelList,
  IGDropdownData,
  setChangeImpactGroup,
}: IProps) => {
  const [rowSelection, setRowSelection] = useState({});

  const [impactGroupDown, setImpactGroupDown] = useState<{
    multiDropdown: Array<SelectOptions>;
    dropDown: Array<DropdownTypes>;
  }>({ multiDropdown: [], dropDown: [] });

  React.useEffect(() => {
    if (data && IGDropdownData)
      setImpactGroupDown({
        multiDropdown: IGDropdownData.filter(
          (item) =>
            !!data.find((role) => role.impactGroupAssigned === item.text)
        ),
        dropDown: IGDropdownData.map((item) => ({
          keyName: item.value,
          keyValue: item.text,
        })),
      });
  }, [data, IGDropdownData]);

  React.useEffect(() => {
    const selectedRecords: Array<IGetRolesData | null> = [];
    Object.keys(rowSelection).forEach((value: string) => {
      const idx = Number(value) as number;
      if (idx >= 0) {
        const record = data[idx];
        if (record) selectedRecords.push(record);
      }
    });
  }, [data, rowSelection]);

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IGetRolesData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    const selectedItems = newValue.map((value: { text: string }) => value.text);
    column.setFilterValue(selectedItems || undefined);
  };

  const columns = useMemo<MRT_ColumnDef<IGetRolesData>[]>(
    () => [
      {
        header: 'Roles',
        filterFn: 'includesString',
        accessorKey: 'name',
        size: 100,
      },
      {
        header: 'Role ID',
        accessorKey: 'roleId',
        filterFn: 'includesString',
        size: 50,
      },
      {
        header: 'Level',
        accessorKey: 'levelName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={levelList}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        size: 200,
        minSize: 200,
        maxSize: 560,
      },
      {
        header: 'Function',
        accessorKey: 'functionName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={functionList}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        size: 200,
        minSize: 200,
        maxSize: 560,
      },
      // For Later API enhancement ticket
      // {
      //   header: '# of Impact Groups',
      //   accessorFn: () => '1',
      //   size: 50,
      // },
      // {
      //   header: '# of Assigned Leaders',
      //   accessorFn: () => '3/4',
      //   filterFn: 'includesString',
      //   size: 50,
      // },
      {
        header: 'Primary Impact Group',
        accessorKey: 'impactGroupAssigned',
        filterVariant: 'multi-select',
        Filter: ({ column }) => {
          return (
            <Box sx={{ paddingRight: '16px' }}>
              <MultiSelectDropdown
                width='100% !important'
                dropDownItem={impactGroupDown.multiDropdown}
                onChange={(e, newValue, reason) =>
                  multiSelectOnChangeHandler(e, newValue, reason, column)
                }
                column={column}
              />
            </Box>
          );
        },
        Cell: ({ cell, row }) =>
          impactGroupDown.dropDown.length && cell.getValue() ? (
            <LabelDropdown
              label={''}
              dropDownItem={impactGroupDown.dropDown}
              onChange={(value) =>
                setChangeImpactGroup({
                  impactGroupId: (value.target.value as string) || '',
                  roleId: data[row.index]?.id || '',
                })
              }
              defaultValue={cell.getValue()}
            />
          ) : (
            ''
          ),
      },
    ],
    [data, functionList, impactGroupDown, levelList, setChangeImpactGroup]
  );
  return (
    <Table
      enableRowSelection
      onRowSelectionChange={setRowSelection}
      enableSelectAll={true}
      tableInstanceRef={tableInstanceRef}
      data={data || []}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ rowSelection, showSkeletons: isLoading }}
    />
  );
};

export default ImpactGroupRoleTable;
