import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { routes } from '../../../routes/routes';

import { useActions } from '../../../hooks/actions';
import { IPagination, SelectOptions } from '../../../commons/types';
import { IGetClientStatementData } from '../../../store/api/types/leadership';
import {
  useDeleteClientStatementMutation,
  useLazyValidateStatementByIdQuery,
} from '../../../store/api/leadership';
import { useParams } from 'react-router';

interface IProps {
  data?: IGetClientStatementData[];
  isLoading: boolean;
  columns: MRT_ColumnDef<IGetClientStatementData>[];
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  functionList?: SelectOptions[];
  subFunctionList?: SelectOptions[];
}

const ClientStatementLibraryTable = ({
  data,
  isLoading,
  tableInstanceRef,
  columns,
}: IProps) => {
  const navigate = useNavigate();

  const {
    setStatementDetailsInfo,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    openEditOrganizationalImpactSLModal,
    openAddToRoleModal,
    openStatementLibraryWarningModal,
  } = useActions();

  const params = useParams();

  const [deleteStatement] = useDeleteClientStatementMutation();
  const [validateStatement] = useLazyValidateStatementByIdQuery();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (statementId: string) => {
    try {
      const payload = {
        clientId: params.id ? params.id : '',
        statementId: statementId,
      };
      const { data: validateData } = await validateStatement(payload).unwrap();

      if (validateData && validateData.length) {
        openStatementLibraryWarningModal(validateData);
      } else {
        deleteStatement(statementId)
          .unwrap()
          .then(() =>
            toggleSuccessSnackbar({ message: 'Statement Deleted Successfully' })
          )
          .catch(() => toggleErrorSnackbar({}));
        setAnchorEl(null);
      }
    } catch (e) {
      toggleErrorSnackbar({});
    }
  };

  const handleEdit = (rowData: IGetClientStatementData) => {
    openEditOrganizationalImpactSLModal({
      name: rowData.name,
      id: rowData.id,
      leadership_acumen: rowData.expertiseCategoryCode,
      impact_area: rowData.impactModuleCode || '',
      accelerator: '',
      accelerator_name: '',
      custom_response_options: '',
      description: rowData.description,
      function: '',
      function_name: '',
      statement_id: '',
      leadership_acumen_name: '',
      module_number: '',
      scale: '',
      skill_category_id: '',
      skill_category_name: '',
      skill_group: '',
      skill_group_name: '',
      statement_in_module_number: '',
      sub_function: '',
      subgroupID: '',
      sub_function_name: '',
      status: '',
    });
    setAnchorEl(null);
  };

  const handleApplyToRole = (skill: IGetClientStatementData) => {
    openAddToRoleModal({
      skill: skill.id || '',
      clientId: params.id,
      statementUUID: skill.statementLibraryUUID || '',
      skillGroup: skill.skillGroup || '',
    });
    setAnchorEl(null);
  };

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => {
        const rowData = row.original as IGetClientStatementData;
        return (
          <>
            <IconButton
              id={cell.id}
              onClick={handleOpen}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl?.id === cell.id}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEdit(rowData)}>Edit</MenuItem>
              {rowData.skillGroup !== 'Purpose' && (
                <MenuItem onClick={() => handleApplyToRole(rowData)}>
                  Apply To Role
                </MenuItem>
              )}
              <MenuItem onClick={() => handleDelete(rowData.id || '')}>
                Delete
              </MenuItem>
            </Menu>
          </>
        );
      }}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          setStatementDetailsInfo(row.original as IGetClientStatementData);
          const selection = window.getSelection()!.toString();
          if (!cell.id.includes('mrt-row-actions') && !selection) {
            navigate(
              `${routes.company.url}${params.id as string}${
                routes.company.statements.url
              }/${(row.original as IGetClientStatementData).id}`
            );
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default ClientStatementLibraryTable;
