import Card, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material';

const StyledCard = styled(Card)(() => ({
  maxWidth: 560,
  backgroundColor: '#ffffff',
}));

const HpCard = ({ children, ...extraProps }: CardProps) => {
  return <StyledCard {...extraProps}>{children}</StyledCard>;
};

export default HpCard;
