import React, { useState } from 'react';
import { styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { useDownloadAttachmentsReportsMutation } from '../../../../store/api/attachments';

const StyledText = styled('p')(() => ({
  fontFamily: 'Avenir',
  fontSize: '16px',
  fontWeight: '600',
}));

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background: 'white',
  },
};

type IProps = {
  refreshSelections: () => void;
};

const DownloadReportsModal = ({ refreshSelections }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const isOpen = useAppSelector((state) => state.downloadReportsModal.isOpen);
  const reportData = useAppSelector(
    (state) => state.downloadReportsModal.reportData
  );

  const { closeDownloadReportsModal } = useActions();

  const generatedReportRecords = reportData;

  const handleClose = () => {
    closeDownloadReportsModal();
    setIsLoading(false);
    refreshSelections();
  };

  const [downloadReport] = useDownloadAttachmentsReportsMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const onSubmit = async () => {
    setIsLoading(true);

    await Promise.all(
      reportData.map(async (report: any) => {
        try {
          const payload = {
            id: report?._id,
            name: report?.report,
          };

          const response = await downloadReport(payload).unwrap();
          if (response.status === 'success') {
            toggleSuccessSnackbar({
              message: 'The report has been downloaded successfully',
            });
          } else {
            if (
              response.errors &&
              response.errors[Object.keys(response.errors)[0]].length
            ) {
              toggleErrorSnackbar({
                message: response.errors[Object.keys(response.errors)[0]][0],
              });
            }
          }
        } catch (e) {
          toggleErrorSnackbar({
            message: 'The report could not be downloaded',
          });
        }
      })
    ).finally(() => {
      handleClose();
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle
        onClose={handleClose}
      >{`Download ${generatedReportRecords.length} Reports`}</ModalTitle>
      <ModalBody sx={{ backgroundColor: 'white' }}>
        <StyledText
          sx={{ fontWeight: '500', color: '#002136', fontSize: '17px' }}
        >
          Note that reports must be generated before you can download them.
        </StyledText>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={`${
            isLoading
              ? 'Downloading...'
              : `Download ${generatedReportRecords.length} Reports`
          }`}
          disabled={isLoading}
          onClick={onSubmit}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default DownloadReportsModal;
