import * as React from "react";
import {
  Breadcrumbs,
  Box,
  Link,
  styled,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {useNavigate} from "react-router-dom";

interface BreadCrumbType {
  text: string;
  link?: string;
}

export interface HDBreadCrumbTypes {
  underline?: "none" | "hover" | "always" | undefined;
  routes: Array<BreadCrumbType>;
}

const HDBreadCrumb = ({ underline = "always", routes }: HDBreadCrumbTypes): JSX.Element | null=> {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const StyledBreadCrumbBox = styled(Box)(() => ({
    display: 'block',
  }));

  const StyledLink = styled('button')(() => ({
    display: 'block',
    border: '1px solid transparent!important',
    background: 'none',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    '&:hover': {
      color: '#18768C !important',
      textDecoration: 'underline',
    },
    '&:focus': {
      color: '#18768C !important',
      border: '1px solid #18768C!important',
    }
  }));

  const StyledBackBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
  }));

  const StyledBackText = styled(Typography)(() => ({
    fontFamily: "Avenir",
    fontWeight: 300,
    fontSize: 16,
    lineHeight: "24px",
    color: isDark ? "#ccc" : "#334255",
    marginTop: "1.5px",
    cursor: "pointer",
    '& hover': {
      color: '#18768C',
      textDecoration: 'underline',
    },
    '& focus': {
      color: '#18768C',
      border: '1px solid #18768C'
    }

  }));

  const StyledArrowLeftIcon = styled(KeyboardArrowLeftIcon)(() => ({
    width: 28,
    height: 28,
    color: isDark ? "#fff" : "#002136",
    marginRight: "-1px",
    cursor: "pointer",
  }));

  if (isMobile) {
    return (
      <StyledBackBox>
        <StyledArrowLeftIcon />
        <Link underline="none">
          <StyledBackText onClick={() => history.back()}>Back</StyledBackText>
        </Link>
      </StyledBackBox>
    )
  }

  return (
    <StyledBreadCrumbBox>
      <Breadcrumbs aria-label="breadcrumb">
        {routes?.map((el, index) => {
          if (index === (routes.length - 1)) {
            return (
              <Link
                key={`bread_crumb_${index}`}
                underline="none"
                color="inherit"
              >
                <StyledLink>
                  {el.text}
                </StyledLink>
              </Link>
            );
          }
          return (
            <Link
              key={`bread_crumb_${index}`}
              underline={underline}
              color="inherit"
              sx={{cursor: 'pointer'}}
              onClick={() => {
                  if(el.link){
                    navigate(el.link);
                  } else {
                    navigate(-1);
                  }
                  
                }
              }
            >
              <StyledLink>
                  {el.text}
              </StyledLink>
            </Link>
          );
        })}
      </Breadcrumbs>
    </StyledBreadCrumbBox>
  );
};

export default HDBreadCrumb;