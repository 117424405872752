import { Box, styled } from '@mui/material';
import HDSpinner from '../hd-ui-kit/HDSpinner';
import React from 'react';

function Loader() {
  const LoaderWrap = styled(Box)(() => ({
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: '#fff',
    opacity: '0.9',
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }));

  return (
    <LoaderWrap>
      <HDSpinner
        size={'la-2x'}
        buttonColor={false}
      />
    </LoaderWrap>
  );
}

export default Loader;
