import HDFooter from '../hd-ui-kit/HDFooter';
import React from 'react';
import HpContainer from '../atoms/container/HpContainer';

function Footer() {
  return (
    <div style={{ backgroundColor: '#334255' }}>
      <HpContainer>
        <HDFooter
          logoClick={() =>
            window.open(
              'https://www.heidrick.com/en/',
              '_blank',
              'noopener,noreferrer'
            )
          }
          onNavigationClick={(url) =>
            window.open(url, '_blank', 'noopener,noreferrer')
          }
          contactClick={() =>
            window.open(
              'https://heidricksupport.zendesk.com/hc/en-us',
              '_blank',
              'noopener,noreferrer'
            )
          }
        />
      </HpContainer>
    </div>
  );
}

export default Footer;
