import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import { IImpactModuleFormData } from '../../../store/api/types/leadership';
import {
  useEditImpactModuleMutation,
  useGetImpactModuleQuery,
  useSetImpactModuleMutation,
} from '../../../store/api/leadership';

export const ImpactModuleVLModal = () => {
  const { data: { data: impactModuleList } = {} } =
    useGetImpactModuleQuery('Master');

  const [addImpactModule] = useSetImpactModuleMutation();
  const [editImpactModule] = useEditImpactModuleMutation();

  const initialValues = useAppSelector(
    (state) => state.impactModuleVLModal.initialValues
  );
  const isOpen = useAppSelector((state) => state.impactModuleVLModal.isOpen);
  const modalType = useAppSelector(
    (state) => state.impactModuleVLModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeImpactModuleVLModal,
  } = useActions();

  const methods = useForm<IImpactModuleFormData>();
  const {
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty },
  } = methods;

  const handleClose = () => {
    closeImpactModuleVLModal();
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [isOpen, reset, initialValues]);

  const onSubmit = async (formData: IImpactModuleFormData) => {
    const modalTypeMap = {
      add: {
        fn: addImpactModule,
        messageSuccess: 'Impact Module Added Successfully',
        errorMessage: 'Error Adding Impact Module',
      },
      edit: {
        fn: editImpactModule,
        messageSuccess: 'Impact Module Updated Successfully',
        errorMessage: 'Error Updating Impact Module',
      },
    };

    const payload: any = {
      fieldValue: formData.fieldValue,
      abbreviation: formData.abbreviation,
      ...(modalType === 'edit' && { id: formData.id }),
      ...(modalType === 'add' && { clientId: 'Master' }),
    };

    if (
      modalType === 'add' &&
      impactModuleList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'Impact Module name should be unique',
      });

    if (
      modalType === 'edit' &&
      formData.fieldValue.toLowerCase() !==
        initialValues.fieldValue.toLowerCase() &&
      impactModuleList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'Impact Module name should be unique',
      });

    modalTypeMap[modalType]
      .fn(payload)
      .unwrap()
      .then((response) => {
        if (response.status === 'success') {
          toggleSuccessSnackbar({
            message: modalTypeMap[modalType].messageSuccess,
          });
          handleClose();
        }
      })
      .catch((error) => {
        if (error.data && error.data.detail) {
          toggleErrorSnackbar({ message: error.data.detail });
        } else {
          toggleErrorSnackbar({
            message: modalTypeMap[modalType].errorMessage,
          });
        }
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add new impact module' : 'Edit impact module'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='impactmodule-modal'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='fieldValue'
            rules={{ required: 'Please provide impact module name' }}
            label='Impact Module Name'
          />
          <FormLabelInput
            name='abbreviation'
            label='Impact Module Abbreviation (optional)'
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='impactmodule-modal'
          disabled={!isDirty || !!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};
