import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import PageHeader from '../../atoms/header/PageHeader';
import { routes } from '../../routes/routes';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

export default function CompanyHeader() {
  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.company.settings.name} />
      </StyledTitlesWrapper>
      <StyledButtonWrapper>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </StyledButtonWrapper>
    </PageHeader>
  );
}
