import * as React from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { ReactNode, useEffect } from 'react';
import HpTooltip from '../tooltip/HpTooltip';

type IProps = {
  value: number;
  disabled?: boolean;
  label?: string;
  tooltip?: string | ReactNode;
  extraSliderProps?: SliderProps;
  extraInputProps?: TextFieldProps;
  formControl?: (v: number) => void;
  callback?: (arg0: string) => void; // added callback to get slider value in table component
};

const StyledWrapper = styled(Box)(() => ({
  padding: '10px 0',
}));

const StyledLabelWrapper = styled('span')(() => ({
  fontFamily: 'Avenir',
  fontWeight: 300,
  fontSize: 16,
  display: 'flex',
  alignItems: 'flex-start',
}));

const StyledSliderWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

const StyledTextFieldLabel = styled('span')(() => ({
  fontFamily: 'Aventa',
  fontSize: '14px',
  fontWeight: 400,
}));

const StyledSlider = styled(Slider)(({ disabled }) => ({
  height: 2,
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: '#18768c',
    '&:hover': {
      boxShadow: 'none',
      height: 18,
      width: 18,
    },
    '&.Mui-focusVisible, &.Mui-active': {
      boxShadow: '#ffffff 0px 0px 0px 2px inset',
      border: '2px solid #18768c',
      height: 18,
      width: 18,
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
    color: disabled ? '#646A7C' : '#18768c',
  },
  '& .MuiSlider-rail': {
    opacity: 0.3,
    height: 4,
    backgroundColor: '#646A7C',
  },
  '& .MuiSlider-mark': {
    backgroundColor: disabled ? '#646A7C' : '#18768c',
    height: 14,
    width: 2,
    '&.MuiSlider-markActive': {
      backgroundColor: disabled ? '#646A7C' : '#18768c',
      height: 14,
      width: 2,
      opacity: 1,
    },
  },
  '& .Mui-disabled': {
    backgroundColor: '#646A7C',
  },
}));

const StyledTextField = styled(TextField)(() => ({
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  width: '70px',
  '& .MuiInputBase-root': {
    color: '#002136',
    background: 'rgb(255, 255, 255)',
    borderRadius: '12px',
  },
  '& .MuiInputBase-input': {
    padding: '11.5px 13px',
  },
  '& .Mui-disabled': {
    backgroundColor: '#F1F1F1',
    borderRadius: '12px',
  },
}));

export const InputSlider = ({
  value = 1,
  disabled,
  label,
  formControl,
  callback,
  tooltip,
  extraSliderProps,
  extraInputProps,
}: IProps) => {
  const marks = [{ value: 2 }, { value: 3 }, { value: 4 }];

  const min = 1;
  const max = 5;

  const [slideValue, setSlideValue] = React.useState<number>(value);

  useEffect(() => {
    if (formControl) formControl(slideValue);
  }, [slideValue, formControl]);

  useEffect(() => {
    setSlideValue(value);
  }, [value]);

  const handleChange = (_: Event, newValue: number | number[]) => {
    setSlideValue(newValue as number);
    callback?.(newValue as unknown as string); // Using the optional chaining (?.) operator to solve the undefined error
  };

  const handleInputChange = (value: number) => {
    if (value > max) {
      setSlideValue(max);
      callback?.(max as unknown as string); // Using the optional chaining (?.) operator to solve the undefined error
    } else if (value < min || value === 0) {
      setSlideValue(min);
      callback?.(min as unknown as string); // Using the optional chaining (?.) operator to solve the undefined error
    } else {
      setSlideValue(Number(value.toFixed(1)));
      callback?.(Number(value.toFixed(1)) as unknown as string); // Using the optional chaining (?.) operator to solve the undefined error
    }
  };

  return (
    <StyledWrapper>
      {label && (
        <StyledLabelWrapper>
          {label}
          {tooltip && <HpTooltip title={tooltip} />}
        </StyledLabelWrapper>
      )}
      <StyledSliderWrapper>
        <StyledTextFieldLabel>{min}</StyledTextFieldLabel>
        <StyledSlider
          marks={marks}
          value={slideValue}
          size={'small'}
          aria-label={'Small'}
          step={0.1}
          min={min}
          max={max}
          onChange={handleChange}
          disabled={disabled}
          {...extraSliderProps}
        />
        <StyledTextFieldLabel>{max}</StyledTextFieldLabel>
        <StyledTextField
          type={'number'}
          value={slideValue}
          name={'benchmark'}
          onFocus={(e) => e.target.select()}
          onChange={(e) => handleInputChange(Number(e.target.value))}
          disabled={disabled}
          {...extraInputProps}
        ></StyledTextField>
      </StyledSliderWrapper>
    </StyledWrapper>
  );
};

export default InputSlider;
