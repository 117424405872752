import React, { useMemo } from 'react';
import Table, { checkboxProps } from '../../../../../atoms/table/Table';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';
import { IProjectAssessmentAddTableType } from '../../../../../store/api/types/assessmentStructure';

interface IProps {
  data: Array<IProjectAssessmentAddTableType>;
  isLoading?: boolean;
  tableInstanceRef?: React.RefObject<MRT_TableInstance>;
  checkSave: React.Dispatch<
    React.SetStateAction<Array<IProjectAssessmentAddTableType>>
  >;
}

const AddAssessmentsTable = ({
  data,
  isLoading,
  tableInstanceRef,
  checkSave,
}: IProps) => {
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    const selection = tableInstanceRef?.current
      ?.getSelectedRowModel()
      .flatRows.map(
        (item) => item.original
      ) as Array<IProjectAssessmentAddTableType>;
    if (selection) {
      checkSave(selection);
    }
  }, [checkSave, rowSelection, tableInstanceRef]);

  const columns = useMemo<MRT_ColumnDef<IProjectAssessmentAddTableType>[]>(
    () => [
      {
        header: 'Assessment Name',
        accessorKey: 'assessment',
        filterFn: 'includesString',
      },
      {
        header: 'Skill Category',
        accessorKey: 'skillCategory',
        filterFn: 'includesString',
      },
    ],
    [data]
  );

  return (
    <Table
      data={data}
      enableRowSelection={(row: MRT_Row) => {
        const rowData = row.original as IProjectAssessmentAddTableType;
        return rowData.addedToProject;
      }} // enable row selection for false flag only
      muiSelectCheckboxProps={({ row }) => {
        if (!(row.original as IProjectAssessmentAddTableType).addedToProject) {
          return {
            checked: true,
          };
        } else {
          return checkboxProps;
        }
      }}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      onRowSelectionChange={setRowSelection}
      state={{ showSkeletons: isLoading, rowSelection }}
    />
  );
};

export default AddAssessmentsTable;
