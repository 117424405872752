import { Box, styled } from '@mui/material';
import React, { useRef, useMemo } from 'react';
import TabContent from '../../atoms/tab/TabContent';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { IPagination, ITabContentProps } from '../../commons/types';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';
import LeadershipPotentialSLModal from '../../organisms/modals/Statement/LeadershipPotentialSLModal';
import ImportIcon from '../../assets/icons/Import.svg';
import { useGetClientStatementsQuery } from '../../store/api/leadership';
import StatementLibraryTable from '../../organisms/tables/StatementLibraryTable';
import { IGetClientStatementData } from '../../store/api/types/leadership';

interface IProps {
  skillType: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

export default function LeadershipPotential({
  index,
  value,
  skillType,
}: ITabContentProps & IProps) {
  const { data: { data, metadata } = {}, isLoading } =
    useGetClientStatementsQuery({
      skillGroup: skillType,
      clientId: 'Master',
    });

  const { openImportStatementModal } = useActions();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  const columns = useMemo<MRT_ColumnDef<IGetClientStatementData>[]>(
    () => [
      {
        header: 'Statement Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
      {
        header: 'Statement ID',
        accessorKey: 'statementCode',
        filterFn: 'includesString',
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Leadership Potential'}
              sx={{
                fontSize: '28px',
              }}
            />
            <Box>
              <HpTooltip
                title={
                  'Personal traits and characteristics that enable a leader to adapt and grow in larger and more diverse roles'
                }
              />
            </Box>
          </StyledHeaderWrapper>
          <StyledHeaderActionWrapper>
            <Button
              btnType='SECONDARY'
              text={'Import data'}
              onClick={() => openImportStatementModal()}
              startIcon={
                <ButtonIcon
                  src={ImportIcon}
                  alt='Import CSV'
                />
              }
            />
          </StyledHeaderActionWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${
              metadata ? metadata.pagination.totalCount : '0'
            } total statements`}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <StatementLibraryTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
      />
      <LeadershipPotentialSLModal />
    </TabContent>
  );
}
