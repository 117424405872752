import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../../atoms/button/Button';
import HpCheckbox from '../../../atoms/checkbox/HpCheckbox';
import Modal from '../../../atoms/modal/Modal';
import ModalActions from '../../../atoms/modal/ModalActions';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import { useLazyDownloadCompanyRolesQuery } from '../../../store/api/leadership';

const CompanyRolesModal = () => {
  const { id = '' } = useParams();
  const [downloadCompanyRoles] = useLazyDownloadCompanyRolesQuery();
  const [navigatorRolesCheckbox, setNavigatorRolesCheckbox] =
    useState<boolean>(false);

  const isOpen = useAppSelector((state) => state.companyRolesModal.isOpen);
  const modalType = useAppSelector(
    (state) => state.companyRolesModal.modalType
  );

  const { toggleErrorSnackbar, toggleSuccessSnackbar, closeCompanyRolesModal } =
    useActions();

  const handleClose = () => {
    setNavigatorRolesCheckbox(false);
    closeCompanyRolesModal();
  };

  const onSubmit = async () => {
    const modalTypeMap = {
      download: {
        fn: downloadCompanyRoles,
        messageSuccess: 'The file has been downloaded successfully',
        errorMessage: 'The file could not be downloaded',
      },
    };

    try {
      const response = await modalTypeMap[modalType]
        .fn({ id: id, name: 'ClientRoles.zip' })
        .unwrap();
      if (response.status === 'success') {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
      } else {
        if (
          response.errors &&
          response.errors[Object.keys(response.errors)[0]].length
        ) {
          toggleErrorSnackbar({
            message: response.errors[Object.keys(response.errors)[0]][0],
          });
        }
      }
    } catch (e) {
      toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        Select file types to download
      </ModalTitle>
      <ModalBody>
        <Box sx={{ padding: '5px' }}>
          <HpCheckbox
            label='Navigator Role Export'
            onChange={(e) => setNavigatorRolesCheckbox(e.target.checked)}
          />
        </Box>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Download'}
          disabled={!navigatorRolesCheckbox}
          onClick={onSubmit}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default CompanyRolesModal;
