import React from 'react';
import { Box, Divider, Skeleton, styled } from '@mui/material';
import HyperLinkButton from '../button/HyperLinkButton';

export type SummaryMetricsData = {
  itemName: string;
  itemQty: number;
  btnName?: string;
  threshold?: string;
};

type IProps = {
  data: SummaryMetricsData[];
  isLoading?: boolean;
  onThresholdButtonClick?: (type: string) => void | undefined;
};

const StyledMetricsItemName = styled('span')(() => ({
  fontSize: '16px',
  display: 'block',
  minHeight: '44px',
  clear: 'both',
  '@media (max-width: 1200px)': {
    minHeight: '60px',
    fontSize: '14px',
  },
  '@media (max-width: 950px)': {
    minHeight: '70px',
    fontSize: '14px',
  },
}));

const StyledMetricsItemQty = styled('span')(() => ({
  fontSize: '60px',
  lineHeight: 1,
}));

const SummaryMetrics = ({
  data,
  onThresholdButtonClick,
  isLoading,
}: IProps) => {
  const extrawidth = data[0]?.itemName === 'Total invited' ? true : false;
  const cardlength = data.length;
  // added inside to handle scrollbar visibility of Metrics card on Assessment page and on View Raters modal
  const StyledMetricsWrapper = styled(Box)(() => ({
    display: 'flex',
    height: extrawidth ? '170px' : '150px',
    minHeight: '145px',
    backgroundColor: '#F3F8F9',
    borderRadius: '6px',
    border: '1px solid #00213633',
    flexDirection: 'row',
    overflowX: extrawidth ? 'scroll' : 'auto',
    '&::-webkit-scrollbar': {
      height: cardlength > 3 ? '14px' : '0px',
    },
    '&::-webkit-scrollbar-track': {
      WebkitBorderRadius: '0px 0px 2px 2px',
      background: '#F3F8F9',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: '#002136',
    },
    '&::-webkit-scrollbar-button': {
      width: '7px',
      backgroundColor: '#F8F8F8',
    },
  }));

  // added inside to handle width of Metrics card on Assessment page and on View Raters modal
  const StyledMetricsItem = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: extrawidth ? 'auto' : '100%',
    minWidth: extrawidth ? '300px' : 'auto',
    gap: '8px',
    padding: '16px 22px',
    overflowX: 'auto',
  }));

  return (
    <StyledMetricsWrapper>
      {data.map((el, i) => {
        return (
          <React.Fragment key={i}>
            <StyledMetricsItem>
              <StyledMetricsItemName>{el.itemName}</StyledMetricsItemName>
              {isLoading ? (
                <Skeleton
                  variant='rectangular'
                  width={130}
                  height={60}
                />
              ) : (
                <StyledMetricsItemQty>{el.itemQty}</StyledMetricsItemQty>
              )}
              {el.btnName && (
                <HyperLinkButton
                  onClick={() => onThresholdButtonClick?.(el.itemName)}
                  text={`${el.btnName}${el?.threshold}`}
                  sx={{ marginTop: '-10px' }}
                />
              )}
            </StyledMetricsItem>
            {data.length - 1 !== i ? <Divider orientation='vertical' /> : null}
          </React.Fragment>
        );
      })}
    </StyledMetricsWrapper>
  );
};

export default SummaryMetrics;
