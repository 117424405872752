import HDButton from '../../hd-ui-kit/HDButton';
import { HDButtonTypes } from '../../hd-ui-kit/HDButton/HDButton';
import { styled } from '@mui/material/styles';

const StyledResetButton = styled(HDButton)({
  textDecoration: 'underline',
  border: '1px solid transparent',
  borderRadius: 0,
  margin: '11px 15px',
  padding: 0,
  '&.Mui-selected': {},
  '&.Mui-focusVisible': {
    borderColor: 'transparent',
  },
  ':focus': {
    borderRadius: 'none !important',
    border: '1px solid',
    textDecoration: 'none',
  },
});

const ResetButton = ({ text, ...extraProps }: HDButtonTypes) => {
  return (
    <StyledResetButton
      size='small'
      variant='text'
      disableRipple={true}
      text={<a>{text}</a>}
      {...extraProps}
    />
  );
};

export default ResetButton;
