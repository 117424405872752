import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  setImportCompanyUserUploadProgress,
  setImportRoleUploadProgress,
  setImportStatementUploadProgress,
  setImportSubFunctionUploadProgress,
} from '../../hooks/actions';
import {
  ImportCompanyUserFileType,
  ImportCompanyUsersRequestProps,
  ImportFileResponse,
  ImportStatementRequestPayload,
  ImportStatementRequestPayloadV2,
  ISignedUrlRequest,
  ISignedUrlResponse,
  IUploadLogoRequest,
} from './types/uploadFile';
import { coreApiInvalidateTags } from './core';
import { leaderProfileApiInvalidateTags } from './v2/leaderProfile';
import { leadershipApiInvalidateTags } from './leadership';

const fileUploadProgressApi = async (
  endPoint: string,
  data: FormData,
  progressDispatch: (arg: number) => void
) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
      .accessToken.accessToken;
    request.open('POST', `${process.env.REACT_APP_API_KEY}core/${endPoint}`);
    if (token) request.setRequestHeader('Authorization', 'Basic ' + token);
    request.upload.addEventListener('progress', (e) => {
      const percent_completed = Math.round((e.loaded / e.total) * 100);
      progressDispatch(percent_completed);
    });
    request.addEventListener('load', () => {
      if (request.status === 200) {
        resolve(request.response);
      }
      if (request.status === 500) {
        const error = JSON.parse(request.response);
        reject(error.detail ? error.detail : error);
      }
    });
    request.addEventListener('error', () => {
      reject('upload failed');
    });
    request.send(data);
  });

const fileUploadProgressApiV2 = async (
  endPoint: string,
  data: FormData,
  progressDispatch: (arg: number) => void
) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
      .accessToken.accessToken;
    request.open('POST', `${process.env.REACT_APP_V2_API_KEY}${endPoint}`);
    if (token) request.setRequestHeader('Authorization', 'Basic ' + token);
    request.upload.addEventListener('progress', (e) => {
      const percent_completed = Math.round((e.loaded / e.total) * 100);
      progressDispatch(percent_completed);
    });
    request.addEventListener('load', () => {
      if (request.status === 200) {
        resolve(request.response);
      }
      if (request.status === 400) {
        const error = JSON.parse(request.response);
        reject(error.detail ? error.detail : error);
      }
      if (request.status === 500) {
        const error = JSON.parse(request.response);
        reject(error.detail ? error.detail : error);
      }
    });
    request.addEventListener('error', () => {
      reject('upload failed');
    });
    request.send(data);
  });

export const uploadFileApi = createApi({
  reducerPath: 'uploadFile/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_KEY}core/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const contentType = headers.get('Content-Type');

      if (token && contentType === null) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    importRoleProfiles: builder.mutation<
      ImportFileResponse,
      { formData: FormData; clientId: string }
    >({
      queryFn: async (data, { dispatch }) => {
        const setFileProgress = (progress: number) => {
          dispatch(setImportRoleUploadProgress(progress));
          return;
        };
        const response = (await fileUploadProgressApiV2(
          `leadership/api/v2/role-profiles-import?clientId=${data.clientId}`,
          data.formData,
          setFileProgress
        )) as string;
        const jsonResponse = JSON.parse(response);
        if (jsonResponse && typeof jsonResponse === 'string') {
          dispatch(leadershipApiInvalidateTags(['roleProfiles']));
        }
        return { data: jsonResponse };
      },
    }),
    importStatements: builder.mutation<
      ImportFileResponse,
      ImportStatementRequestPayloadV2
    >({
      queryFn: async (data, { dispatch }) => {
        const setFileProgress = (progress: number) => {
          dispatch(setImportStatementUploadProgress(progress));
          return;
        };
        const response = (await fileUploadProgressApiV2(
          `leadership/api/v2/statement/import?clientId=${data.clientId}`,
          data.formData,
          setFileProgress
        )) as string;
        const jsonResponse = JSON.parse(response);
        if (jsonResponse && typeof jsonResponse === 'string') {
          dispatch(leadershipApiInvalidateTags(['clientStatements']));
        }
        return { data: jsonResponse };
      },
    }),
    importCompanyUsers: builder.mutation<
      ImportFileResponse,
      ImportCompanyUsersRequestProps
    >({
      queryFn: async (data, { dispatch }) => {
        const setFileProgress = (progress: number) => {
          dispatch(setImportCompanyUserUploadProgress(progress));
          return;
        };

        const endpointType = {
          employee: 'EmployeeExcelFile/',
          education: 'EmployeeEducationExcelFile/',
          experience: 'EmployeeExperienceExcelFile/',
          performanceRating: 'EmployeePerformanceRatingExcelFile/',
          capabilitiesData: 'EmployeeCapabilitiesDataExcelFileView/',
        };

        const fileType = data.fileType as ImportCompanyUserFileType;

        const response = (await fileUploadProgressApi(
          endpointType[fileType],
          data.formData,
          setFileProgress
        )) as string;

        const jsonResponse = JSON.parse(response);

        if (jsonResponse && typeof jsonResponse === 'string') {
          dispatch(coreApiInvalidateTags(['Employee']));
        }
        return { data: jsonResponse };
      },
    }),
    clientUploadSignedUrl: builder.mutation<
      ISignedUrlResponse,
      ISignedUrlRequest
    >({
      query: (payload) => ({
        url: 'ClientLogoUploadSignedUrl/',
        method: 'POST',
        body: payload,
      }),
    }),
    uploadLogo: builder.mutation<void, IUploadLogoRequest>({
      query: ({ payload, url }) => ({
        url: url,
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': payload.type,
        },
      }),
    }),

    importEmployee: builder.mutation<
      ImportFileResponse,
      ImportCompanyUsersRequestProps
    >({
      queryFn: async (data, { dispatch }) => {
        const setFileProgress = (progress: number) => {
          dispatch(setImportCompanyUserUploadProgress(progress));
          return;
        };

        const endpointType = {
          employee: 'profile/api/v2/import-leader-profile',
          education: 'profile/api/v2/import-employee-data',
          experience: 'profile/api/v2/import-employee-data',
          performanceRating: 'profile/api/v2/import-employee-data',
          capabilitiesData: '',
        };

        const fileType = data.fileType as ImportCompanyUserFileType;

        const response = (await fileUploadProgressApiV2(
          endpointType[fileType],
          data.formData,
          setFileProgress
        )) as string;

        const jsonResponse = JSON.parse(response);
        dispatch(leaderProfileApiInvalidateTags(['leaderProfiles']));
        return { data: jsonResponse };
      },
    }),
    importSubFunctions: builder.mutation<
      ImportFileResponse,
      { formData: FormData; clientId: string }
    >({
      queryFn: async (data, { dispatch }) => {
        const setFileProgress = (progress: number) => {
          dispatch(setImportSubFunctionUploadProgress(progress));
          return;
        };
        const response = (await fileUploadProgressApiV2(
          `leadership/api/v2/sub-functions-import?clientId=${data.clientId}`,
          data.formData,
          setFileProgress
        )) as string;
        const jsonResponse = JSON.parse(response);
        if (jsonResponse && typeof jsonResponse === 'string') {
          dispatch(leadershipApiInvalidateTags(['subFunctions']));
        }
        return { data: jsonResponse };
      },
    }),
  }),
});

export const {
  useImportRoleProfilesMutation,
  useImportStatementsMutation,
  useImportCompanyUsersMutation,
  useClientUploadSignedUrlMutation,
  useUploadLogoMutation,
  useImportEmployeeMutation,
  useImportSubFunctionsMutation,
} = uploadFileApi;
