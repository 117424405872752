import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IClientResponse, IClientByIdResponse } from './types/client';
import {
  IClientRequest,
  IGetAccountIdPayload,
  IGetAccountIdResponse,
  IUpdateClient,
} from './types';
import { IGenericResponse } from '../../commons/types';
import { downloadFile } from './helpers';
import { IClientBranchDoceboInfoResponse } from './types/branch';
import { authApiInvalidateTags } from './v2/auth';

export const clientsApi = createApi({
  reducerPath: 'clients/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}clients/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Client', 'Single Client', 'Branch'],
  endpoints: (builder) => ({
    getClient: builder.query<IClientResponse, void>({
      query: () => 'clients',
      transformResponse: (response: IClientResponse) => {
        return {
          code: response.code,
          status: response.status,
          data: response.data,
          clientDropdown: response.data.map((el) => ({
            keyName: el.name || '',
            keyValue: el.id || '',
          })),
        };
      },
      providesTags: ['Client'],
    }),
    // Branches endpoints:
    getClientDoceboBranchInfo: builder.query<
      IClientBranchDoceboInfoResponse,
      string
    >({
      query: (id) => 'branches/',
      providesTags: ['Branch'],
    }),
    getAccountId: builder.mutation<IGetAccountIdResponse, IGetAccountIdPayload>(
      {
        query: (payload) => ({
          url: 'get-account-id',
          method: 'POST',
          body: payload,
        }),
        transformResponse: (response: IGetAccountIdResponse) => ({
          status: response.status,
          data: response.data,
          accountIdDropdown: response.data?.map((el) => ({
            keyName: `${el.accountID} - ${el.title}` || '',
            keyValue: `${el.accountID}` || '',
          })),
        }),
      }
    ),
    // Get Client User by id
    getClientById: builder.query<IClientByIdResponse, string>({
      query: (id) => ({
        url: `clients/${id}`,
        method: 'GET',
      }),
      providesTags: ['Single Client'],
    }),
    setClient: builder.mutation<void, IClientRequest>({
      query: (payload) => ({
        url: 'clients',
        method: 'POST',
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ['Client'],
    }),
    editClient: builder.mutation<IGenericResponse, IUpdateClient>({
      query: (payload) => ({
        url: `clients/${payload.clientId}`,
        method: 'PUT',
        body: payload.payload,
      }),
      onCacheEntryAdded: (args, { dispatch }) => {
        dispatch(authApiInvalidateTags(['GetMe']));
      },
      invalidatesTags: ['Client', 'Single Client'],
    }),

    // Tazio CSV export
    getTazioCsvExport: builder.query<
      { error?: { data: Blob }; data?: { data: Blob } },
      { clientId: string; roleProfileId: string; fileName: string }
    >({
      query: ({ clientId, roleProfileId, fileName }) => ({
        url: `assessment-export/${clientId}?roleProfileId=${roleProfileId}&filename=${fileName}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.status === 200) {
            downloadFile(await response.blob(), `${fileName}_roles.zip`);
          }
          if (response.status === 500) return response.json();
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const clientApiInvalidateTags = clientsApi.util.invalidateTags;

export const {
  useGetClientQuery,
  useGetClientByIdQuery,
  useLazyGetClientByIdQuery,
  useSetClientMutation,
  useEditClientMutation,
  useGetAccountIdMutation,
  useGetClientDoceboBranchInfoQuery,
} = clientsApi;

export const { useLazyGetTazioCsvExportQuery } = clientsApi;
