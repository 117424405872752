import React, { useMemo } from 'react';
import { Box, IconButton, Menu, MenuItem, styled } from '@mui/material';
import DetailsHeaderWrapper from '../../../../atoms/header/DetailsHeaderWrapper';
import HDBreadCrumb from '../../../../hd-ui-kit/HDBreadcrumb/HDBreadCrumb';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import { useGetProjectByIdQuery } from '../../../../store/api/projects';
import { showCreatedDate } from '../../../../store/api/helpers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExtractDataToCSVModal from '../../../modals/Project/ProjectDetails/ExtractDataToCSVModal';
import { useGetClientByIdQuery } from '../../../../store/api/clients';
import { useNavigate, useParams } from 'react-router';
import { filterAvailableReportsToExtractPerClient } from '../../../../utils/helpers';
import { routes } from '../../../../routes/routes';
import { Restricted, restrictedHandler } from '../../../../rbac/Restricted';
import { useGetMeQuery } from '../../../../store/api/v2/auth';

interface IProps {
  id: string;
  clientId?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '15px',
  marginBottom: '10px',
}));

export default function ClientProjectDetailsHeader({ id }: IProps) {
  const { id: clientId } = useParams();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { data: userData } = useGetMeQuery();

  const { data: response } = useGetProjectByIdQuery(id);
  const data = response?.data?.at(0);

  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    clientId as string
  );
  const clientName = clientData?.at(0)?.name.toLowerCase() as string;

  const reportsToCSVHandler = useMemo(
    () =>
      (() => {
        const reportsAvailable = filterAvailableReportsToExtractPerClient(
          clientName
        ) as [];
        const accountId = clientData?.at(0)?.applications?.insights?.accountId;
        const projectId = data?.insightsProject?.sourceProjectId;
        const status =
          Boolean(reportsAvailable.length) &&
          Boolean(accountId) &&
          Boolean(projectId);

        return {
          reportsAvailable,
          accountId,
          projectId,
          status,
        };
      })(),
    [clientName, clientData, data]
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setAnchorEl(null);
    setIsOpen(true);
  };

  const handleProjectEditAction = () => {
    setAnchorEl(null);
    navigate(`${routes.company.url}${clientId}/projects/${id}/edit`);
  };

  return (
    <>
      <DetailsHeaderWrapper>
        <StyledTitlesWrapper>
          <BreadCrumbWrapper>
            <HDBreadCrumb
              routes={[
                { text: 'Projects', link: `/company/${clientId}/projects` },
                {
                  text: `${data?.name}` || '',
                  link: `/company/${clientId}/projects/${id}`,
                },
              ]}
            />
          </BreadCrumbWrapper>
          <Title text={data?.name || ''} />
          <StyledSubTitlesWrapper>
            <StyledSubTitlesWrapper>
              <SubTitle
                text='STATUS:'
                bold
              />
              <SubTitle text={data?.status || ''} />
              <SubTitle text='|' />
              <SubTitle
                text='START:'
                bold
              />
              <SubTitle text={showCreatedDate(data?.startDate || '')} />
              <SubTitle text='|' />
              <SubTitle
                text='END:'
                bold
              />
              <SubTitle text={showCreatedDate(data?.closeDate || '')} />
            </StyledSubTitlesWrapper>
          </StyledSubTitlesWrapper>
        </StyledTitlesWrapper>

        <StyledButtonWrapper>
          {(restrictedHandler(
            'project.project_details.edit',
            userData?.permissionsArr
          ) ||
            reportsToCSVHandler.status) && (
            <>
              <IconButton onClick={handleOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleClose}
              >
                {restrictedHandler(
                  'project.project_details.edit',
                  userData?.permissionsArr
                ) && (
                  <MenuItem onClick={() => handleProjectEditAction()}>
                    Edit
                  </MenuItem>
                )}
                {reportsToCSVHandler.status && (
                  <MenuItem onClick={openModal}>Extract Data to CSV</MenuItem>
                )}
              </Menu>
            </>
          )}
        </StyledButtonWrapper>
      </DetailsHeaderWrapper>
      <ExtractDataToCSVModal
        open={isOpen}
        close={closeModal}
        csvReports={reportsToCSVHandler}
      />
    </>
  );
}
