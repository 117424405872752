import React, { useMemo } from 'react';
import Table from '../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { useActions } from '../../../hooks/actions';
import { IFunctionsData } from '../../../store/api/types/leadership';
import { useDeleteFunctionMutation } from '../../../store/api/leadership';

interface IProps {
  data?: Array<IFunctionsData>;
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const FunctionVLTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { openEditFunctionModal, toggleSuccessSnackbar, toggleErrorSnackbar } =
    useActions();

  const [deleteFunction] = useDeleteFunctionMutation();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: IFunctionsData) => {
    openEditFunctionModal(row);
    setAnchorEl(null);
  };

  const handleDelete = (functionId: string) => {
    if (functionId) {
      deleteFunction(functionId)
        .unwrap()
        .then(() =>
          toggleSuccessSnackbar({
            message: 'Function Deleted Successfully',
          })
        )
        .catch(({ data }) => toggleErrorSnackbar({ message: data.detail }));
    }
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<IFunctionsData>[]>(
    () => [
      {
        header: 'Heidrick Function Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
        minSize: 255,
      },
      {
        header: 'Function ID',
        accessorKey: 'code',
        filterFn: 'includesString',
        minSize: 200,
      },
      {
        header: '# of sub-functions',
        accessorFn: () => '',
      },
      {
        header: 'Roles with Function',
        accessorFn: () => '',
      },
      {
        header: 'Statements with Function',
        accessorFn: () => '',
        minSize: 270,
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleEdit(row.original as IFunctionsData)}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleDelete((row.original as IFunctionsData).id || '')
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default FunctionVLTable;
