import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetClientStatementData } from '../api/types/leadership';

const initialStatementDetails = {
  accelerator: '',
  accelerator_name: '',
  custom_response_options: '',
  description: '',
  function: '',
  function_name: '',
  id: '',
  statement_id: '',
  impact_area: '',
  leadership_acumen: '',
  leadership_acumen_name: '',
  module_number: '',
  name: '',
  scale: '',
  skill_category_id: '',
  skill_category_name: '',
  skill_group: '',
  skill_group_name: '',
  statement_in_module_number: '',
  sub_function: '',
  subgroupID: '',
  sub_function_name: '',
  status: '',
};

const initialState: IGetClientStatementData =
  JSON.parse(localStorage.getItem('statementDetails') || 'null') ||
  initialStatementDetails;

const statementDetailsSlice = createSlice({
  name: 'statementDetails',
  initialState,
  reducers: {
    setStatementDetailsInfo: (
      state,
      action: PayloadAction<IGetClientStatementData>
    ) => {
      localStorage.setItem(
        'statementDetails',
        JSON.stringify({ ...state, ...action.payload })
      );
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const statementDetailsActions = statementDetailsSlice.actions;
export const statementDetailsReducer = statementDetailsSlice.reducer;
