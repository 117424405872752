import { createSlice } from '@reduxjs/toolkit';
import { IClientRoleImpactStatementProps } from '../../../../commons/types';

interface IClientRoleImpactStatementModalProps {
  isOpen: boolean;
  modalType: 'add';
  initialValues: IClientRoleImpactStatementProps;
  filteredSubFunctionList: [];
}

const initialState: IClientRoleImpactStatementModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    name: '',
    impact_area: '',
    function: '',
    leadership_acumen: '',
    description: '',
    subfunction: '',
    proficiency: 0,
    statementName: null,
  },
  filteredSubFunctionList: [],
};

const clientRoleImpactStatementSlice = createSlice({
  name: 'clientRoleImpactStatementModal',
  initialState,
  reducers: {
    closeClientRoleImpactStatementModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddClientRoleImpactStatementModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    setClientRoleImpactSubFunctionList: (state, action) => {
      const { list, value } = action.payload;
      state.filteredSubFunctionList = list?.filter(
        (el: IClientRoleImpactStatementProps) => el.function === value
      );
    },
  },
});

export const clientRoleImpactStatementModalActions =
  clientRoleImpactStatementSlice.actions;
export const clientRoleImpactStatementModalReducer =
  clientRoleImpactStatementSlice.reducer;
