import React, { useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import HpContainer from '../../../../atoms/container/HpContainer';
import ProjectAssessmentsDetailsHeader from '../../../../organisms/headers/Company/Projects/ProjectAssessmentsDetailsHeader';
import ProjectAssessmentsDetailsTable from '../../../../organisms/tables/Company/Projects/ProjectDetails/ProjectAssessmentsDetailsTable';
import { MRT_TableInstance } from 'material-react-table';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import ResetButton from '../../../../atoms/button/ResetButton';
import { Box, styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import { useGetProjectByIdQuery } from '../../../../store/api/projects';
import { useGetAssessmentStructureByIdQuery } from '../../../../store/api/assessmentStructure';
import { AssessmentLeadersTable } from '../../../../store/api/types/projects';
import { useGetAllLeaderProfilesQuery } from '../../../../store/api/v2/leaderProfile';
import { useGetRoleProfilesQuery } from '../../../../store/api/leadership';
import {
  useUpdateAssessmentByIdMutation,
  useLazyGetAssessmentsByIdQuery,
} from '../../../../store/api/exportFile';
import { statusConvention } from '../../../../store/api/v2/helpers';
import { useActions } from '../../../../hooks/actions';
import _ from 'lodash';
import { useAppSelector } from '../../../../hooks/redux';
import CalibrationModal from '../../../../organisms/modals/Project/ProjectDetails/AssessmentDetails/CalibrationModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '13px',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  justifyContent: 'end',
  alignItems: 'center',
});

const removeByAttr = function (arr: any[], attr: any, value: any) {
  let i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      Object.prototype.hasOwnProperty.call(arr[i], attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

const ProjectAssessmentsDetails = () => {
  const params = useParams();
  const { pathname } = useLocation();

  const [isSelected, setIsSelected] = useState(false);
  const [saved, setSaved] = useState(false);
  const [leaders, setLeaders] = useState<AssessmentLeadersTable[]>([]);

  const { data: projectResponse } = useGetProjectByIdQuery(
    params.projectId as string
  );
  const projectData = projectResponse?.data?.at(0);

  const isOpen = useAppSelector((state) => state.calibrationModal.isOpen);

  const { data: assessmentData } = useGetAssessmentStructureByIdQuery({
    assessmentStructureId: params.assessmentsId,
  });
  const { data: { data: profileData } = {} } = useGetAllLeaderProfilesQuery({
    id: params.id || '',
  });
  const { data: { data: roleData } = {} } = useGetRoleProfilesQuery(
    pathname.includes('company') ? (params.id as string) : 'Master'
  );

  const [getAssessment] = useLazyGetAssessmentsByIdQuery();
  const [updateAssessment] = useUpdateAssessmentByIdMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar, openCalibrationModal } =
    useActions();

  const [primaryAssessor, setChangePrimaryAssessor] = useState<
    Array<{ userId: string; name: string; assessment: string; leader: string }>
  >([]);
  const [coAssessor, setChangeCoAssessor] = useState<
    Array<{ userId: string; name: string; assessment: string; leader: string }>
  >([]);

  const [assessmentList, setAssessmentList] = useState<any[]>([]);

  const getLeadersOfAssessment = async () => {
    if (
      projectData &&
      projectData?.assignments &&
      projectData?.assignments?.length &&
      profileData &&
      roleData
    ) {
      const assignments = projectData?.assignments;
      const temp: any[] = [];

      Promise.all(
        assignments?.map(async (assignment) => {
          const assessmentsArr = assignment?.assessments?.filter(
            (x) => x.assessmentStructureId === params.assessmentsId
          );

          if (assessmentsArr?.length) {
            const leadersArr: any = profileData?.filter(
              (x) => x.id === assignment.leaderProfileId
            );

            const rolesArray: any = leadersArr?.length
              ? roleData?.filter((x) => x.id === leadersArr[0]?.roleProfileId)
              : [];

            let res: any = { data: [] };

            if (assessmentsArr[0]?.assessmentId) {
              const { data: assessmentRes } = await getAssessment({
                assessment: assessmentsArr[0]?.assessmentId,
              });

              res = assessmentRes;
            }

            res?.data[0] &&
              setAssessmentList((assessment) => [...assessment, res?.data[0]]);

            const primaryObj = res?.data[0]?.source?.assessors?.find(
              (x: any) => x.type === 'Primary'
            );
            const secondaryObj = res?.data[0]?.source?.assessors?.find(
              (x: any) => x.type === 'Secondary'
            );
            const primaryId = primaryObj?.userId;
            const secondaryId = secondaryObj?.userId;

            const status: string = res?.data[0]?.results?.status ?? '';
            const cbiStatus = statusConvention[status];

            temp.push({
              id: assignment.id,
              leaderName:
                assignment.leaderFirstName + ' ' + assignment.leaderLastName,
              roleName: rolesArray?.length ? rolesArray[0]?.name : '',
              pAssessor: primaryId,
              cAssessor: secondaryId,
              cbiStatus,
              projectAssessmentStatus: assessmentsArr[0]?.assignmentStatus,
              resultStatus: res?.data[0]?.results?.status,
              assessmentId: assessmentsArr[0]?.assessmentId,
            });

            return true;
          }
        })
      ).then(() => {
        const sortedObjs = _.sortBy(temp, 'leaderName');
        setLeaders(sortedObjs);
      });
    }
  };

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  React.useEffect(() => {
    setLeaders([]);
    setAssessmentList([]);
    getLeadersOfAssessment();
  }, [projectData, profileData, roleData, saved]);

  const updateAssessors = () => {
    let result1 = false,
      result2 = false;

    const assessorValue = [
      ...coAssessor.map((item) => {
        return leaders.find((lead) => lead.id === item.leader);
      }),
      ...primaryAssessor.map((item) => {
        return leaders.find((lead) => lead.id === item.leader);
      }),
    ];

    const secondaryAssessor = coAssessor.filter(
      (item) => !primaryAssessor.some((pr) => pr.leader !== item.leader)
    );
    const prAssessor = primaryAssessor.filter(
      (item) => !coAssessor.some((co) => co.leader !== item.leader)
    );

    if (secondaryAssessor.length) {
      result1 = assessorValue.some((item) => {
        const sample = secondaryAssessor.find((co) => co.leader === item?.id);
        return sample?.userId === item?.pAssessor;
      });
    }

    if (prAssessor.length) {
      result2 = assessorValue.some((item) => {
        const sample = prAssessor.find((co) => co.leader === item?.id);
        return sample?.userId === item?.cAssessor;
      });
    }

    const result = primaryAssessor.some((item) => {
      const user = coAssessor.find((acc) => acc.leader === item.leader);
      return user?.userId === item.userId;
    });

    if (result || result1 || result2) {
      toggleErrorSnackbar({
        message: 'Primary Assessor and Co-Assessors cannot be same.',
      });
      return;
    }
    try {
      if (primaryAssessor.length) {
        Promise.all([
          ...primaryAssessor.map(async (user) => {
            const filteredAssessment = [
              ...assessmentList.filter(
                (assessment) => assessment.id === user.assessment
              ),
            ];
            const filteredAssessmentData = filteredAssessment[0];
            let found = 0;
            let assessorsArray =
              filteredAssessmentData?.source?.assessors?.map((item: any) => {
                if (item.type === 'Primary') {
                  found = 1;
                  return { ...item, userId: user.userId, name: user.name };
                } else {
                  return item;
                }
              }) || [];

            if (!found) {
              assessorsArray = [
                ...assessorsArray,
                { type: 'Primary', userId: user.userId, name: user.name },
              ];
            }

            const ArrayByCurrentAssessment = coAssessor?.filter(
              (item) => item.assessment === user.assessment
            );

            if (ArrayByCurrentAssessment.length) {
              let cFound = 0;

              assessorsArray.forEach((item: any) => {
                if (item.type === 'Secondary') {
                  cFound = 1;
                  removeByAttr(assessorsArray, 'type', 'Secondary');
                  assessorsArray = [
                    {
                      type: 'Secondary',
                      userId: ArrayByCurrentAssessment[0].userId,
                      name: ArrayByCurrentAssessment[0].name,
                    },
                    ...assessorsArray,
                  ];
                }
              });

              if (!cFound) {
                assessorsArray = [
                  ...assessorsArray,
                  {
                    type: 'Secondary',
                    userId: ArrayByCurrentAssessment[0].userId,
                    name: ArrayByCurrentAssessment[0].name,
                  },
                ];
              }
            }

            const payload = {
              ...filteredAssessmentData,
              source: {
                ...filteredAssessmentData.source,
                assessors: assessorsArray,
              },
            };

            const response = await updateAssessment({
              assessment: user.assessment || '',
              payload: payload,
            }).unwrap();
            return response;
          }),
          ...coAssessor.map(async (user) => {
            const array = primaryAssessor.filter(
              (item) => item.assessment === user.assessment
            );
            if (array.length === 0) {
              const filteredAssessment = [
                ...assessmentList.filter(
                  (assessment) => assessment.id === user.assessment
                ),
              ];
              const filteredAssessmentData = filteredAssessment[0];
              let found = 0;
              let assessorsArray =
                filteredAssessmentData?.source?.assessors?.map((item: any) => {
                  if (item.type === 'Secondary') {
                    found = 1;
                    return { ...item, userId: user.userId, name: user.name };
                  } else {
                    return item;
                  }
                }) || [];

              if (!found) {
                assessorsArray = [
                  ...assessorsArray,
                  { type: 'Secondary', userId: user.userId, name: user.name },
                ];
              }

              const payload = {
                ...filteredAssessmentData,
                source: {
                  ...filteredAssessmentData.source,
                  assessors: assessorsArray,
                },
              };

              const response = await updateAssessment({
                assessment: user.assessment || '',
                payload: payload,
              });

              return response;
            } else {
              return true;
            }
          }),
        ])
          .then(() => {
            setChangePrimaryAssessor([]);
            setChangeCoAssessor([]);
            toggleSuccessSnackbar({
              message: 'Primary Assessors/ Co-Assessors Updated Successfully',
            });
            setSaved(!saved);
          })
          .catch((e) => {
            setChangePrimaryAssessor([]);
            setChangeCoAssessor([]);
            toggleErrorSnackbar({
              message: 'Error Updating Primary Assessor/ Co-Assessors',
            });
            setSaved(!saved);
          });
      } else {
        Promise.all([
          coAssessor.map(async (user) => {
            const filteredAssessment = [
              ...assessmentList.filter(
                (assessment) => assessment.id === user.assessment
              ),
            ];
            const filteredAssessmentData = filteredAssessment[0];
            let found = 0;
            let assessorsArray =
              filteredAssessmentData?.source?.assessors?.map((item: any) => {
                if (item.type === 'Secondary') {
                  found = 1;
                  return { ...item, userId: user.userId, name: user.name };
                } else {
                  return item;
                }
              }) || [];

            if (!found) {
              assessorsArray = [
                ...assessorsArray,
                { type: 'Secondary', userId: user.userId, name: user.name },
              ];
            }

            const payload = {
              ...filteredAssessmentData,
              source: {
                ...filteredAssessmentData.source,
                assessors: assessorsArray,
              },
            };

            const response = await updateAssessment({
              assessment: user.assessment || '',
              payload: payload,
            }).unwrap();

            return response;
          }),
        ])
          .then(() => {
            setChangePrimaryAssessor([]);
            setChangeCoAssessor([]);
            toggleSuccessSnackbar({
              message: 'Primary Assessors/ Co-Assessors Updated Successfully',
            });
            setSaved(!saved);
          })
          .catch(() => {
            setChangePrimaryAssessor([]);
            setChangeCoAssessor([]);
            toggleErrorSnackbar({
              message: 'Error Updating Primary Assessor/ Co-Assessors',
            });
            setSaved(!saved);
          });
      }
    } catch {
      () =>
        toggleErrorSnackbar({
          message: 'Error Updating Primary Assessor/ Co-Assessors',
        });
    }
  };

  React.useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "Are Sure You Don't want to save changes";
      return event;
    };
    if (primaryAssessor.length)
      window.addEventListener('beforeunload', unloadCallback);

    return () => {
      if (primaryAssessor.length)
        window.removeEventListener('beforeunload', unloadCallback);
    };
  }, [primaryAssessor, coAssessor]);

  return (
    <>
      {/* Header has hidden items */}
      <ProjectAssessmentsDetailsHeader
        projectName={projectData?.name}
        assessmentName={assessmentData?.data?.title}
      />
      <HpContainer
        sx={{
          marginTop: '20px',
        }}
      >
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Title
              text={'Assigned Leaders'}
              sx={{
                fontSize: '28px',
              }}
            />
            <StyledHeaderActionWrapper>
              <Button
                btnType={'SECONDARY'}
                text={'Start Cohort Calibration'}
                onClick={() => openCalibrationModal({ leaders: leaders })}
              />
              <Button
                btnType='PRIMARY'
                text={'Save'}
                onClick={() => updateAssessors()}
                disabled={!primaryAssessor.length && !coAssessor.length}
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle text={`${leaders.length} total leaders`} />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <ProjectAssessmentsDetailsTable
          data={leaders}
          isLoading={false}
          tableInstanceRef={tableInstanceRef}
          turnOnButton={setIsSelected}
          setChangePrimaryAssessor={(value: {
            userId: string;
            name: string;
            assessment: string;
            leader: string;
          }) => {
            setChangePrimaryAssessor((values) => {
              const index = values.findIndex(
                (item) => item.leader === value.leader
              );
              if (index === -1) return [...values, value];
              values[index] = value;
              return [...values];
            });
          }}
          setChangeCoAssessor={(value: {
            userId: string;
            name: string;
            assessment: string;
            leader: string;
          }) => {
            setChangeCoAssessor((values) => {
              const index = values.findIndex(
                (item) => item.leader === value.leader
              );
              if (index === -1) return [...values, value];
              values[index] = value;
              return [...values];
            });
          }}
        />
      </HpContainer>
      {isOpen && <CalibrationModal />}
    </>
  );
};

export default ProjectAssessmentsDetails;
