import React, { useEffect } from 'react';
import PageHeader from '../../../../atoms/header/PageHeader';
import Title from '../../../../atoms/title/Title';
import { routes } from '../../../../routes/routes';
import { Box, styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import SummaryMetrics, {
  SummaryMetricsData,
} from '../../../../atoms/header/SummaryMetrics';
import HpContainer from '../../../../atoms/container/HpContainer';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { Restricted } from '../../../../rbac/Restricted';
import { useLazyGetProjectStatsQuery } from '../../../../store/api/projects';
import { MRT_TableInstance } from 'material-react-table';

interface IProps {
  tabValue: number;
  filteredState: any[];
  filteredAssignmentState: any[];
  projectsTableInstanceRef: React.RefObject<MRT_TableInstance>;
  assignmentTableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const ClientProjectsHeader = ({
  tabValue,
  filteredState,
  filteredAssignmentState,
  projectsTableInstanceRef,
  assignmentTableInstanceRef,
}: IProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentLocation = useLocation().pathname;

  const [metricsData, setMetricsData] = React.useState<SummaryMetricsData[]>(
    []
  );

  const [getProjectStats, { data, isFetching }] = useLazyGetProjectStatsQuery();

  useEffect(() => {
    const params = `clientId=${id as string}`;

    getProjectStats(params);
  }, [tabValue]);

  useEffect(() => {
    const columns = projectsTableInstanceRef?.current
      ?.getAllColumns()
      ?.map((column: any) => {
        if (
          column?.id === 'name' ||
          column?.id === 'projectLeadName' ||
          column?.id === 'reference' ||
          column?.id === 'purpose' ||
          column?.id === 'startDate' ||
          column?.id === 'closeDate' ||
          column?.id === 'hiringManager'
        ) {
          return {
            key: column?.id,
            value: column.getFilterValue(),
          };
        }
      })
      .filter((obj) => obj !== undefined);

    const mergedObject: { [key: string]: string } = {};
    columns?.forEach((obj) => {
      mergedObject[obj?.key] = obj?.value;
    });

    let params = `clientId=${id as string}`;
    if (mergedObject?.name) {
      params = params + `&name=${mergedObject?.name}`;
    }
    if (mergedObject?.projectLeadName) {
      params = params + `&projectLeadName=${mergedObject?.projectLeadName}`;
    }
    if (mergedObject?.hiringManager) {
      params = params + `&hiringManager=${mergedObject?.hiringManager}`;
    }
    if (mergedObject?.purpose) {
      params = params + `&purpose=${mergedObject?.purpose}`;
    }
    if (mergedObject?.reference) {
      params = params + `&reference=${mergedObject?.reference}`;
    }
    if (mergedObject?.startDate) {
      const date = new Date(mergedObject.startDate);
      params =
        params +
        `&startDate=${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}T00:00:00.000`;
    }
    if (mergedObject?.closeDate) {
      const date = new Date(mergedObject.closeDate);
      params =
        params +
        `&closeDate=${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}T00:00:00.000`;
    }

    getProjectStats(params);
  }, [filteredState]);

  useEffect(() => {
    const columns = assignmentTableInstanceRef?.current
      ?.getAllColumns()
      ?.map((column: any) => {
        if (
          column?.id === 'project' ||
          column?.id === 'projectStartDate' ||
          column?.id === 'projectEndDate'
        ) {
          return {
            key: column?.id,
            value: column.getFilterValue(),
          };
        }
      })
      .filter((obj) => obj !== undefined);

    const mergedObject: { [key: string]: string } = {};
    columns?.forEach((obj) => {
      mergedObject[obj?.key] = obj?.value;
    });

    let params = `clientId=${id as string}`;
    if (mergedObject?.project) {
      params = params + `&name=${mergedObject?.project}`;
    }
    if (mergedObject?.projectStartDate) {
      const date = new Date(mergedObject.projectStartDate);
      params =
        params +
        `&startDate=${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}T00:00:00.000`;
    }
    if (mergedObject?.projectEndDate) {
      const date = new Date(mergedObject.projectEndDate);
      params =
        params +
        `&closeDate=${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}T00:00:00.000`;
    }

    if (Object.values(mergedObject).filter((el) => el !== undefined).length) {
      getProjectStats(params);
    }
  }, [filteredAssignmentState]);

  React.useEffect(() => {
    const metricsArray =
      tabValue == 0
        ? [
            {
              itemName: 'Total number of projects',
              itemQty: data?.data?.assessments?.totalProjects || 0,
            },
            {
              itemName: 'Total number of projects completed',
              itemQty: data?.data?.assessments?.completedProjects || 0,
            },
            {
              itemName: 'Total number of assessments',
              itemQty: data?.data?.assessments?.totalAssignmentAssessments || 0,
            },
            {
              itemName: 'Total number of assessments completed',
              itemQty:
                data?.data?.assessments?.completedAssignmentAssessments || 0,
            },
            // This part is hidden by request in APS-3871
            // {
            //   itemName: 'Total number of participants',
            //   itemQty:
            //     filteredState?.reduce(
            //       (acc, el) => acc + el?.assignmentStats?.leaders,
            //       0
            //     ) || 0,
            // },
            // {
            //   itemName: 'Total number of participants completed',
            //   itemQty:
            //     filteredState?.reduce((acc, el) => {
            //       return acc + el?.assignmentStats?.Completed;
            //     }, 0) || 0,
            // },
          ]
        : [
            {
              itemName: 'Total number of projects with assignments',
              itemQty:
                data?.data?.assessments?.totalProjectsWithAssignments || 0,
            },
            {
              itemName: 'Total number of participants',
              itemQty: data?.data?.assessments?.totalParticipants || 0,
            },
            {
              itemName: 'Total number of assessments provisioned',
              itemQty:
                data?.data?.assessments?.totalAssessmentsProvisioned || 0,
            },
            {
              itemName: 'Total number of assessments completed',
              itemQty: data?.data?.assessments?.assessmentsCompleted || 0,
            },
          ];

    setMetricsData(metricsArray);
  }, [tabValue, filteredState, filteredAssignmentState, data]);

  return (
    <>
      <PageHeader type='full'>
        <StyledTitlesWrapper>
          <Title text={routes.company.projects.name} />
        </StyledTitlesWrapper>
        <StyledButtonWrapper>
          <Restricted allowedTo='project.project_list.add'>
            <Button
              btnType='PRIMARY'
              text={'Create new project'}
              onClick={() =>
                navigate(`${currentLocation}${routes.company.projects.add.url}`)
              }
            />
          </Restricted>
        </StyledButtonWrapper>
      </PageHeader>
      <HpContainer>
        <SummaryMetrics
          data={metricsData}
          isLoading={isFetching}
        />
      </HpContainer>
    </>
  );
};

export default ClientProjectsHeader;
