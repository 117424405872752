import React from 'react';
import { Box, styled } from '@mui/material';
import PageHeader from '../../../../atoms/header/PageHeader';
import Title from '../../../../atoms/title/Title';
import { routes } from '../../../../routes/routes';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const VariableLibraryHeader = () => {
  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.company.variableLibrary.name} />
      </StyledTitlesWrapper>
    </PageHeader>
  );
};

export default VariableLibraryHeader;
