import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IAttachmentsByIdResponse,
  IAttachmentsDownloadResponse,
  IAddAttachmentResponse,
  IGetAttachmentsRequest,
  IGetAttachmentsResponse,
  DownloadAttachmentResponse,
} from './types/attachments';
import { downloadFile, serializeObjectToParams } from './helpers';

export const attachmentsApi = createApi({
  reducerPath: 'attachments/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}attachments/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['attachments'],
  endpoints: (builder) => ({
    getAttachmentsById: builder.mutation<IAttachmentsByIdResponse, string>({
      query: (id) => ({
        url: `attachments/${id}`,
        method: 'GET',
      }),
    }),
    downloadAttachmentsReports: builder.mutation<
      IAttachmentsDownloadResponse,
      { id: string; name: string }
    >({
      query: (payload) => ({
        url: `attachments/${payload.id}/download`,
        responseHandler: async (response: any) => {
          downloadFile(await response.blob(), payload.name);
          if (response.status === 200) return { status: 'success' };
        },
        cache: 'no-cache',
      }),
    }),
    updateLogoAttachment: builder.mutation<IAddAttachmentResponse, FormData>({
      query: (payload) => ({
        url: 'attachments/',
        method: 'POST',
        body: payload,
      }),
    }),
    deleteLeaderAttachment: builder.mutation<void, string>({
      query: (id) => ({
        url: `attachments/${id}`,
        method: 'DELETE',
      }),
    }),
    getAttachmentDetailsById: builder.query<
      IGetAttachmentsResponse,
      IGetAttachmentsRequest
    >({
      query: (params) => ({
        url: `attachments?${serializeObjectToParams(params)}`,
        method: 'GET',
      }),
    }),

    downloadAttachmentDetails: builder.query<
      DownloadAttachmentResponse,
      { attachmentId: string }
    >({
      query: ({ attachmentId }) => {
        return {
          url: `attachments/${attachmentId}/download/`,
          responseHandler: async (response: any) => {
            if (response.status === 200) return { status: 'success' };
          },
          cache: 'no-cache',
        };
      },
    }),
    deleteLogo: builder.mutation<void, any>({
      query: ({ attachmentId }) => ({
        url: `attachments/${attachmentId}`,
        method: 'DELETE',
      }),
    }),
    getLogoUrl: builder.mutation<
      DownloadAttachmentResponse,
      { attachmentId: string }
    >({
      query: ({ attachmentId }) => ({
        url: `attachments/${attachmentId}/download?preSignedUrl=Y`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useDeleteLeaderAttachmentMutation,
  useLazyGetAttachmentDetailsByIdQuery,
  useGetAttachmentsByIdMutation,
  useDownloadAttachmentsReportsMutation,
  useUpdateLogoAttachmentMutation,
  useLazyDownloadAttachmentDetailsQuery,
  useGetLogoUrlMutation,
} = attachmentsApi;
