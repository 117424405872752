import React, { Dispatch, SetStateAction, useMemo } from 'react';
import Table from '../../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { routes } from '../../../../../routes/routes';
import { useNavigate, useParams } from 'react-router';
import { AssessmentsTable } from '../../../../../store/api/types/projects';
import { restrictedHandler } from '../../../../../rbac/Restricted';
import { useGetMeQuery } from '../../../../../store/api/v2/auth';

interface IProps {
  data: AssessmentsTable[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  setRecord: React.Dispatch<React.SetStateAction<Array<AssessmentsTable>>>;
  setTabValue: Dispatch<SetStateAction<number>>;
  setTableFilters: Dispatch<
    SetStateAction<{
      assessment: string;
      participant: string;
    }>
  >;
}

const ProjectAssessmentsTable = ({
  data,
  isLoading,
  tableInstanceRef,
  setRecord,
  setTabValue,
  setTableFilters,
}: IProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const [rowSelection, setRowSelection] = React.useState({});

  const { data: userData } = useGetMeQuery();

  const columns = useMemo<MRT_ColumnDef<AssessmentsTable>[]>(
    () => [
      {
        header: 'Assessment',
        accessorKey: 'assessment',
        filterFn: 'includesString',
        size: 400,
      },
      {
        header: 'Skill Category',
        accessorKey: 'skillCategory',
        filterFn: 'includesString',
        size: 400,
      },
      {
        header: 'Type',
        accessorKey: 'type',
        filterFn: 'includesString',
      },
      {
        header: 'Not Invited',
        accessorKey: 'staged',
        filterFn: 'includesString',
      },
      {
        header: 'Not Started',
        accessorKey: 'assigned',
        filterFn: 'includesString',
      },
      {
        header: 'In Progress',
        accessorKey: 'inProgress',
        filterFn: 'includesString',
      },
      {
        header: 'Completed',
        accessorKey: 'completed',
        filterFn: 'includesString',
      },
    ],
    []
  );

  React.useEffect(() => {
    const selectedRecords: Array<AssessmentsTable> = [];
    Object.keys(rowSelection).forEach((value: string) => {
      const idx = Number(value) as number;
      if (idx >= 0) {
        const record = data[idx];
        if (record) selectedRecords.push(record);
      }
    });
    setRecord(selectedRecords);
  }, [data, rowSelection, setRecord]);

  const cursorType = (type: string): boolean => {
    const isAllowed = restrictedHandler(
      'project.project_details.cbi.page',
      userData?.permissionsArr
    );
    if (type === 'CBI' && isAllowed) return true;
    if (type === '360') return true;
    return false;
  };

  return (
    <Table
      data={data ?? []}
      enableRowSelection={(row) => {
        const record = row.original as AssessmentsTable;
        return record.type !== '360';
      }}
      enableRowActions={false}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      onRowSelectionChange={setRowSelection}
      state={{ showSkeletons: isLoading, rowSelection }}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          const selection = window.getSelection()!.toString();
          if (
            !cell?.id?.includes('mrt-row-actions') &&
            !selection &&
            (row.original as AssessmentsTable)?.type?.includes('CBI') &&
            restrictedHandler(
              'project.project_details.cbi.page',
              userData?.permissionsArr
            )
          ) {
            navigate(
              `${routes.company.url}${params.id}${
                routes.company.projects.url
              }/${params.projectId}/assessments/${
                (row.original as AssessmentsTable).id
              }`
            );
          }
          if (
            !cell?.id?.includes('mrt-row-actions') &&
            !selection &&
            (row.original as AssessmentsTable)?.type?.includes('360')
          ) {
            setTabValue(3);
            setTableFilters({
              participant: '',
              assessment: (row.original as AssessmentsTable).assessment || '',
            });
          }
        },
        sx: {
          cursor:
            !cell?.id?.includes('mrt-row-actions') &&
            cursorType((row.original as AssessmentsTable)?.type || '')
              ? 'pointer'
              : 'default',
        },
      })}
    />
  );
};

export default ProjectAssessmentsTable;
