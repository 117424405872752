import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoleSkillData } from '../api/types/leadership';

interface IOrganizationalImpactRLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: {
    id: string;
    name: string;
    impact_area: string;
    leadership_acumen: string;
    description: string;
    proficiency: number;
  };
}

const initialState: IOrganizationalImpactRLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    impact_area: '',
    leadership_acumen: '',
    description: '',
    proficiency: 1,
  },
};

const organizationalImpactRLModalSlice = createSlice({
  name: 'organizationalImpactRLModal',
  initialState,
  reducers: {
    closeOrganizationalImpactRLModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddOrganizationalImpactRLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditOrganizationalImpactRLModal: (
      state,
      action: PayloadAction<IRoleSkillData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        description: action.payload.description || '',
        impact_area: action.payload.impact_area || '',
        leadership_acumen: action.payload.leadership_acumen || '',
        proficiency: action.payload.proficiency || 1,
      };
    },
  },
});

export const organizationalImpactRLModalActions =
  organizationalImpactRLModalSlice.actions;
export const organizationalImpactRLModalReducer =
  organizationalImpactRLModalSlice.reducer;
