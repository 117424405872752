import React, { useMemo } from 'react';
import Table from '../../atoms/table/Table';
import { Box, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef } from 'material-react-table';
import { useActions } from '../../hooks/actions';
import SelectDropdown from '../../atoms/select/SelectDropdown';
import { IUserData } from '../../store/api/v2/types/auth';

interface IProps {
  data: Array<IUserData>;
  isLoading: boolean;
}

const AdminsTable = ({ data, isLoading }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { openEditAdminsModal, openDeleteAdminsModal } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (rowData: IUserData) => {
    openDeleteAdminsModal(rowData);
    setAnchorEl(null);
  };

  const handleEditRole = (rowData: IUserData) => {
    openEditAdminsModal(rowData);
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<IUserData>[]>(
    () => [
      {
        header: 'Name',
        filterFn: 'includesString',
        accessorFn: (row) => {
          if (row.firstName && row.lastName) {
            return `${row.firstName.trim()} ${row.lastName.trim()}`;
          } else return undefined;
        },
      },
      {
        header: 'Email',
        accessorKey: 'userName',
        filterFn: 'includesString',
        Cell: ({ renderedCellValue }) => (
          <Box
            sx={{
              color: '#197E95',
              textDecoration: 'underline',
              '&:hover': {
                color: '#00566A',
              },
            }}
          >
            {renderedCellValue}
          </Box>
        ),
      },
      {
        header: 'Role',
        accessorFn: () => 'Admin',
        filterFn: 'contains',
        Filter: ({ header }) => {
          return (
            <div className='row'>
              <SelectDropdown
                value={header.column.getFilterValue() ?? ''}
                dropDownItem={[
                  {
                    keyValue: 'Admin',
                    keyName: 'Admin',
                  },
                ]}
                tooltip={'Tooltip to be Added...'}
                clearfunction={() => header.column.setFilterValue(undefined)}
                onChange={(e) =>
                  header.column.setFilterValue(e.target.value || undefined)
                }
              ></SelectDropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Table
      data={data || []}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditRole(row.original as IUserData)}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleDelete(row.original as IUserData)}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default AdminsTable;
