import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILevelFormData } from '../../api/types/leadership';

interface ILevelVLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: ILevelFormData;
}

const initialState: ILevelVLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    fieldValue: '',
    code: '',
  },
};

const levelVLClientModalSlice = createSlice({
  name: 'levelVLClientModal',
  initialState,
  reducers: {
    closeLevelVLClientModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    openAddLevelVLClientModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditLevelVLClientModal: (
      state,
      action: PayloadAction<ILevelFormData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        fieldValue: action.payload.fieldValue || '',
        code: action.payload.code || '',
        id: action.payload.id || '',
      };
    },
  },
});

export const levelVLClientModalActions = levelVLClientModalSlice.actions;
export const levelVLClientModalReducer = levelVLClientModalSlice.reducer;
