import { Box, styled } from '@mui/material';
import TabContent from '../../atoms/tab/TabContent';
import Title from '../../atoms/title/Title';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import React, { useRef } from 'react';
import { MRT_TableInstance } from 'material-react-table';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import SkillCategoryVLTable from '../../organisms/tables/VariableLibrary/SkillCategoryVLTable';
import { SkillCategoryVLModal } from '../../organisms/modals/VariableLibrary/SkillCategoryVLModal';
import { useAppSelector } from '../../hooks/redux';
import { useGetSkillCategoriesQuery } from '../../store/api/leadership';
import _ from 'lodash';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const SkillCategories = ({ index, value }: ITabContentProps) => {
  const { data: { data } = {}, isLoading } =
    useGetSkillCategoriesQuery('Master');

  const isOpen = useAppSelector((state) => state.skillCategoryVLModal.isOpen);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Skill Categories'}
              sx={{
                fontSize: '28px',
              }}
            />
            <Box>
              <HpTooltip title={' '} />
            </Box>
          </StyledHeaderWrapper>
          <StyledHeaderActionWrapper></StyledHeaderActionWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${
              _.uniqBy(data, 'code')?.length
                ? _.uniqBy(data, 'code')?.length
                : '0'
            } total unique skill categories`}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <SkillCategoryVLTable
        data={_.uniqBy(data, 'code') ?? []}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
      />
      {isOpen && <SkillCategoryVLModal />}
    </TabContent>
  );
};

export default SkillCategories;
