import { createSlice } from '@reduxjs/toolkit';

interface ICompanyUsersModalProps {
  isOpen: boolean;
  modalType: 'download';
}

const initialState: ICompanyUsersModalProps = {
  isOpen: false,
  modalType: 'download',
};

const companyUsersModalSlice = createSlice({
  name: 'companyUsers',
  initialState,
  reducers: {
    openDownloadCompanyUsersModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    closeCompanyUsersModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const companyUsersModalActions = companyUsersModalSlice.actions;
export const companyUsersModalReducer = companyUsersModalSlice.reducer;
