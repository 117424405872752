import { Box, styled } from '@mui/material';
import React from 'react';
import ImpactGroupDetailHeader from '../../../organisms/headers/Company/ImpactGroups/ImpactGroupDetailHeader';
import TabMenu from '../../../atoms/tab/TabMenu';
import TabMenuItem from '../../../atoms/tab/TabMenuItem';
import { useParams } from 'react-router-dom';
import ImpactGroupLeaders from './ImpactGroupLeaders';
import ImpactGroupRoles from './ImpactGroupRoles';
import ImpactGroupStatements from './ImpactGroupStatements';
import AddRolesToImpactGroupModal from '../../../organisms/modals/Company/ImpactGroup/AddRolesToImpactGroupModal';
import AssignedRolesAnotherImpactGroupModal from '../../../organisms/modals/Company/ImpactGroup/AssignedRolesAnotherImpactGroupModal';
import HpContainer from '../../../atoms/container/HpContainer';
import {
  useGetLeaderImpactGroupByIdQuery,
  useGetLeaderRolesQuery,
  useLazyGetRoleSkillsByCategoryQuery,
} from '../../../store/api/leadership';
import { useLazyGetLeaderImpactGroupLeadersQuery } from '../../../store/api/v2/leaderProfile';
import {
  IGetRolesData,
  IRoleSkillData,
} from '../../../store/api/types/leadership';
import { ILeaderProfileData } from '../../../store/api/types/leaderProfile';
import { IMetadataPayload } from '../../../commons/types';
import _ from 'lodash';

const StyledContentWrapper = styled(HpContainer)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  paddingLeft: '20px',
  '.tabContent': {
    paddingTop: '40px',
  },
});

export default function ImpactGroupDetail() {
  const [tabValue, setTabValue] = React.useState(0);
  const [impactGroupRolesData, setImpactGroupRolesData] = React.useState<{
    isSet: boolean;
    data: IGetRolesData[];
  }>({ isSet: false, data: [] });

  const [impactGroupRolesId, setImpactGroupRolesId] = React.useState<{
    isSet: boolean;
    roleIds: string[];
  }>({ isSet: false, roleIds: [] });

  const { id: clientId, groupId } = useParams();

  const { data: { data: impactGroup } = {} } = useGetLeaderImpactGroupByIdQuery(
    (groupId as string) || ''
  );

  const { data: { data: impactGroupRoles } = {}, isLoading: isLoadingRoles } =
    useGetLeaderRolesQuery({
      impactGroupId: (groupId as string) || '',
      clientId: clientId || '',
    });

  const { data: { data: roleData } = {} } = useGetLeaderRolesQuery({
    clientId: clientId as string,
  });

  const [statementData, setStatementData] = React.useState<{
    roles: Array<{ id: string; isLoading: boolean; isFetched: boolean }>;
    data: Array<IRoleSkillData>;
  }>({ roles: [], data: [] });

  const [impactGroupLeaders, setImpactGroupLeaders] = React.useState<{
    data: Array<ILeaderProfileData>;
    metadata: IMetadataPayload | Record<string, never>;
  }>({ data: [], metadata: {} });

  React.useEffect(() => {
    if (impactGroupRolesId.isSet && !impactGroupRolesData.isSet && roleData) {
      const roles = roleData.filter((item) =>
        impactGroupRolesId.roleIds.includes(item.id)
      ) as IGetRolesData[];
      setImpactGroupRolesData((value) => ({
        ...value,
        isSet: true,
        data: roles,
      }));
    }
  }, [impactGroupRolesData.isSet, impactGroupRolesId, roleData]);

  React.useEffect(() => {
    setImpactGroupRolesId({ isSet: false, roleIds: [] });
  }, [impactGroup]);

  React.useEffect(() => {
    setImpactGroupRolesData({ isSet: false, data: [] });
  }, [roleData]);

  React.useEffect(() => {
    if (
      impactGroup &&
      impactGroup[0].roleProfileList &&
      !impactGroupRolesId.isSet &&
      impactGroupRoles &&
      roleData
    ) {
      const filteredRoles: string[] = [
        ...impactGroup[0].roleProfileList,
        ...impactGroupRoles.map((item) => item.id),
      ].filter((value, index, self) => self.indexOf(value) === index);
      setImpactGroupRolesId({
        isSet: true,
        roleIds: filteredRoles.filter((id) =>
          roleData.some((item) => item.id === id)
        ),
      });
      setImpactGroupRolesData({ isSet: false, data: [] });
      setImpactGroupLeaders({ data: [], metadata: {} });
    }
  }, [impactGroup, impactGroupRoles, impactGroupRolesId.isSet, roleData]);

  const [getImpactGroupStatement] = useLazyGetRoleSkillsByCategoryQuery();
  const [getImpactGroupLeaders] = useLazyGetLeaderImpactGroupLeadersQuery();

  const getStatements = React.useCallback(
    async (idx: number) => {
      setStatementData((value) => {
        value.roles[idx].isLoading = true;
        return value;
      });
      return await getImpactGroupStatement({
        client: clientId || '',
        roleId: statementData.roles[idx].id,
        skillType: 'Impact',
        limit: '',
        offset: '',
      })
        .unwrap()
        .then((res) => {
          let data: IRoleSkillData[] = [];
          if (res?.data) {
            if (statementData.data.length) {
              data = res.data.reduce((accum, crrValue) => {
                if (accum.find((item) => item.id === crrValue.id)) return accum;
                accum = [...accum, crrValue];
                return accum;
              }, statementData.data as IRoleSkillData[]);
            } else {
              data = res.data;
            }
          }
          setStatementData((value) => {
            const update = { ...value };
            update.roles[idx].isLoading = false;
            update.roles[idx].isFetched = true;
            update.data = _.uniqBy(data, 'statementCode');
            return update;
          });
        });
    },
    [getImpactGroupStatement, clientId, statementData.data, statementData.roles]
  );

  React.useEffect(() => {
    if (impactGroupRolesId.roleIds.length && !statementData.roles.length) {
      impactGroupRolesId.roleIds.map((item) =>
        setStatementData((value) => ({
          ...value,
          roles: [
            ...value.roles,
            { id: item, isFetched: false, isLoading: false },
          ],
        }))
      );
    }
  }, [impactGroupRolesId.roleIds, statementData]);

  React.useEffect(() => {
    if (statementData.roles.length) {
      if (!statementData.roles.find((item) => item.isLoading)) {
        const index = statementData.roles.findIndex((item) => !item.isFetched);
        if (index !== -1) getStatements(index);
      }
    }
  }, [getStatements, statementData]);

  React.useEffect(() => {
    if (impactGroupRolesData.data.length)
      getImpactGroupLeaders({
        impactGroupId: (groupId as string) || '',
        clientId: clientId || '',
        limit: 20,
        offset: 0,
      })
        .unwrap()
        .then((response) => {
          if (response.data?.length && response?.metadata) {
            setImpactGroupLeaders({
              data: response.data,
              metadata: response.metadata,
            });
          }
        })
        .catch(() => {
          setImpactGroupLeaders({
            data: [],
            metadata: {},
          });
        });
  }, [clientId, getImpactGroupLeaders, groupId, impactGroupRolesData]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 106px - 67px)',
      }}
    >
      {impactGroup && !!impactGroup.length && (
        <>
          <ImpactGroupDetailHeader
            impactGroup={impactGroup[0]}
            statementData={statementData.data}
          />
          <StyledContentWrapper>
            <TabMenu
              value={tabValue}
              onChange={handleTabChange}
            >
              <TabMenuItem
                index={0}
                label={`Roles(${impactGroupRolesData.data.length})`}
              />
              <TabMenuItem
                index={1}
                label={`Leaders(${
                  impactGroupLeaders.metadata?.pagination?.totalCount || 0
                })`}
              />
              <TabMenuItem
                index={2}
                label={`Statements(${statementData.data.length})`}
              />
            </TabMenu>
            <Box className='tabContent'>
              <ImpactGroupRoles
                index={0}
                value={tabValue}
                data={impactGroupRolesData.data || []}
                isLoading={isLoadingRoles}
                setImpactGroupRolesData={setImpactGroupRolesData}
              />

              <ImpactGroupLeaders
                index={1}
                value={tabValue}
                impactGroupLeaders={impactGroupLeaders.data}
                metadata={impactGroupLeaders.metadata}
              />
              <ImpactGroupStatements
                index={2}
                value={tabValue}
                statementData={statementData.data}
              />
            </Box>
          </StyledContentWrapper>
        </>
      )}
      <AssignedRolesAnotherImpactGroupModal />
      <AddRolesToImpactGroupModal />
    </Box>
  );
}
