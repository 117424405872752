import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ImportCompanyUserModalProps {
  isOpen: boolean;
  progress: number;
}

const initialState: ImportCompanyUserModalProps = {
  isOpen: false,
  progress: 0,
};

const importCompanyUserModalSlice = createSlice({
  name: 'importCompanyUserModal',
  initialState,
  reducers: {
    closeImportComapnyUserModal: (state) => {
      state.isOpen = false;
    },
    openImportCompanyUserModal: (state) => {
      state.isOpen = true;
      state.progress = 0;
    },
    setImportCompanyUserUploadProgress: (
      state,
      action: PayloadAction<number>
    ) => {
      state.progress = action.payload;
    },
  },
});

export const importCompanyUserModalActions =
  importCompanyUserModalSlice.actions;
export const importCompanyUserModalReducer =
  importCompanyUserModalSlice.reducer;
