import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { ISkillCategoriesData } from '../../../../store/api/types/leadership';

interface IProps {
  data?: ISkillCategoriesData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const SkillCategoriesVLClientTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const columns = useMemo<MRT_ColumnDef<ISkillCategoriesData>[]>(
    () => [
      {
        header: 'Category Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
      {
        header: 'System Name',
        accessorKey: 'systemName' || 'systemValue', // added both once it is confirmed will remove one
        filterFn: 'includesString',
        Cell: ({ renderedCellValue }) =>
          renderedCellValue ? renderedCellValue : '--',
      },
      {
        header: 'Category ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default SkillCategoriesVLClientTable;
