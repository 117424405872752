import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IProps {
  text: string;
  bold?: boolean;
  textDecoration?: string;
  cursor?: string;
}

interface StyleProps {
  bold?: boolean;
  textDecoration?: string;
  cursor?: string;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bold',
})<StyleProps>(({ bold, textDecoration, cursor }) => ({
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: bold ? 'bold' : '300',
  fontSize: '16px',
  lineHeight: '38px',
  textDecoration: textDecoration === 'underline' ? 'underline' : 'none',
  cursor: cursor && 'pointer',
}));

const SubTitle = ({ text, ...extraProps }: IProps) => {
  return (
    <StyledTypography
      variant={'h3'}
      {...extraProps}
    >
      {text}
    </StyledTypography>
  );
};

export default SubTitle;
