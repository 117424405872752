import React from 'react';
import { Box, Container } from '@mui/material';
import AdminsHeader from '../organisms/headers/AdminsHeader';
import AdminsModal from '../organisms/modals/AdminsModal';
import AdminsTable from '../organisms/tables/AdminsTable';
import SubTitle from '../atoms/title/SubTitle';
import { useGetAdminUsersQuery } from '../store/api/v2/auth';

const Admins = () => {
  const { data: usersData, isLoading } = useGetAdminUsersQuery();
  return (
    <Box
      sx={{
        height: 'calc(100vh - 106px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <AdminsModal />
      <AdminsHeader />
      <Container
        sx={{
          marginBottom: '50px',
        }}
      >
        <Box sx={{ paddingBottom: '20px' }}>
          <SubTitle
            text={`${
              usersData?.data ? usersData.data.length : '0'
            } total users`}
          />
        </Box>
        <AdminsTable
          data={usersData?.data || []}
          isLoading={isLoading}
        />
      </Container>
    </Box>
  );
};

export default Admins;
