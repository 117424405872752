import { createSlice } from '@reduxjs/toolkit';
import { AssessmentLeadersTable } from '../../../../api/types/projects';
interface CalibrationModalProps {
  isOpen: boolean;
  leaders: AssessmentLeadersTable[];
}

const initialState: CalibrationModalProps = {
  isOpen: false,
  leaders: [],
};

const calibrationModalSlice = createSlice({
  name: 'calibrationModal',
  initialState,
  reducers: {
    closeCalibrationModal: (state) => {
      state.isOpen = false;
    },
    openCalibrationModal: (state, action) => {
      state.isOpen = true;
      state.leaders = action.payload.leaders;
    },
  },
});

export const calibrationModalActions = calibrationModalSlice.actions;
export const calibrationModalReducer = calibrationModalSlice.reducer;
