import { Box, styled } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import TabContent from '../../../../atoms/tab/TabContent';
import { ITabContentProps, SelectOptions } from '../../../../commons/types';
import Title from '../../../../atoms/title/Title';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import AssessmentsTable from '../../../../organisms/tables/Company/Assessments/AssessmentsTable';
import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import ViewRatersButton from '../../../../atoms/button/ViewRatersButton';
import { useActions } from '../../../../hooks/actions';
import {
  dateSortingFunction,
  showCreatedDate,
} from '../../../../store/api/helpers';
import { filterForMultipleValues } from '../../../../utils/helpers';
import SubTitle from '../../../../atoms/title/SubTitle';
import RatersModal from '../../../../organisms/modals/Project/ProjectDetails/AssessmentDetails/RatersModal';
import { IAssignmentData } from '../../../../store/api/types/projects';

interface IProps {
  statusDropdown: { text: string; value: string }[];
  assessmentData: IAssignmentData[];
  tableFilters: { assessment?: string; project?: string; participant?: string };
  projectAssignment?: boolean;
}
const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '13px',
}));

const typeDropdownList = [
  { text: 'Self Assessment', value: 'Self Assessment' },
  { text: '360', value: '360' },
  { text: 'CBI', value: 'CBI' },
];

export default function AssignmentsTab({
  index,
  value,
  statusDropdown,
  assessmentData,
  tableFilters,
  projectAssignment,
}: ITabContentProps & IProps) {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  useEffect(() => {
    const project = tableInstanceRef?.current?.getColumn('project');
    const participant = tableInstanceRef?.current?.getColumn('participant');

    project?.setFilterValue(tableFilters?.project);
    participant?.setFilterValue(tableFilters?.participant);

    if (tableFilters?.assessment?.length) {
      const assessment = tableInstanceRef?.current?.getColumn('assessment');
      assessment?.setFilterValue(tableFilters.assessment);
    }
  }, [tableFilters]);

  const { openAssignmentModal } = useActions();

  const onRatersButtonClick = (assessmentData: IAssignmentData) => {
    openAssignmentModal(assessmentData);
  };

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IAssignmentData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IAssignmentData>[]>(
    () => [
      {
        header: 'Participant',
        accessorKey: 'participant',
        filterFn: 'includesString',
      },
      {
        header: 'Assessment',
        accessorKey: 'assessment',
        filterFn: 'includesString',
      },
      {
        header: 'Project Reference',
        accessorKey: 'project',
        filterFn: (row, id, filterValue) =>
          filterForMultipleValues(row, id, filterValue),
      },
      {
        header: 'Type',
        accessorKey: 'type',
        filterVariant: 'multi-select',
        size: 200,
        minSize: 200,
        maxSize: 560,
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={typeDropdownList}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
      {
        header: 'Assigned',
        accessorFn: (row) => showCreatedDate(row['projectStartDate'] ?? ''),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'projectStartDate'),
        // using cell value as accessorFn sorts the date values by string and not by timestamp
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
      },
      {
        header: 'Closed',
        accessorFn: (row) => showCreatedDate(row['projectEndDate'] ?? ''),
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'projectEndDate'),
      },
      {
        header: 'Completed',
        accessorFn: (row) => showCreatedDate(row['completedDate'] ?? ''),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'completedDate'), // custom sorting logic
        // using cell value as accessorFn sorts the date values by string and not by timestamp
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
      },
      {
        header: 'Completed %',
        accessorKey: 'completedPercentage',
        filterFn: 'includesString',
      },
      {
        header: 'Last Reminder Sent',
        accessorKey: 'lastReminderSent',
        accessorFn: (row) => showCreatedDate(row['lastReminderSent'] ?? ''),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'lastReminderSent'), // custom sorting logic
        // using cell value as accessorFn sorts the date values by string and not by timestamp
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
      },
      {
        header: 'Total number of reminders sent',
        accessorKey: 'reminderCount',
        filterFn: 'includesString',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        filterVariant: 'multi-select',
        size: 250,
        minSize: 200,
        maxSize: 560,
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={statusDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              {row.original.type === '360' ? (
                <div>
                  {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                  <span style={{ marginRight: '8px' }}>
                    {renderedCellValue}
                  </span>
                  <span style={{ color: '#CCCCCC' }}>|</span>
                  <ViewRatersButton
                    onClick={() =>
                      onRatersButtonClick(row.original as IAssignmentData)
                    }
                    text='view raters'
                  />
                </div>
              ) : (
                <span>{renderedCellValue}</span>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        {projectAssignment ? (
          <StyledTitlesWrapper>
            <StyledTitleWrapper>
              <Title
                text={'Assignments'}
                sx={{
                  fontSize: '28px',
                }}
              />
            </StyledTitleWrapper>
            <StyledSubHeaderWrapper>
              <SubTitle text={`${assessmentData?.length} total Assignments`} />
            </StyledSubHeaderWrapper>
          </StyledTitlesWrapper>
        ) : (
          <StyledTitlesWrapper>
            <StyledTitleWrapper>
              <StyledHeaderWrapper>
                <Title
                  text={`${assessmentData?.length} total assessments`}
                  sx={{
                    fontSize: '20px',
                  }}
                />
              </StyledHeaderWrapper>
            </StyledTitleWrapper>
          </StyledTitlesWrapper>
        )}
        <AssessmentsTable
          data={assessmentData ?? []}
          columns={columns}
          tableInstanceRef={tableInstanceRef}
          initialState={{
            sorting: [{ id: 'participant', desc: false }],
            showColumnFilters: true,
          }}
        />
        <RatersModal />
      </>
    </TabContent>
  );
}
