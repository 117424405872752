import React, { useState } from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../../molecules/form/FormLabelInput';
import FormLabelDropdown from '../../../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../../../molecules/form/FormWrapper';
import Button from '../../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../../hooks/redux';
import { useActions } from '../../../../hooks/actions';
import { useParams } from 'react-router';
import {
  useAddSubFunctionMutation,
  useEditSubFunctionMutation,
  useGetFunctionsListQuery,
  useGetSubFunctionListQuery,
} from '../../../../store/api/leadership';
import { ISubFunctionFormData } from '../../../../store/api/types/leadership';
import styled from '@emotion/styled';

const StyledLabel = styled('span')({
  font: 'Avenir',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#646a7c',
});

const BoldStyledLabel = styled(StyledLabel)({
  fontWeight: 800,
});

export const SubFunctionVLClientModal = () => {
  const { id } = useParams<{ id?: string }>();
  const { data: functionData } = useGetFunctionsListQuery(id as string);
  const { data: { data: subFunctionList } = {} } = useGetSubFunctionListQuery(
    id as string
  );
  const [functionFieldValue, setFunctionFieldValue] = useState<string>('');
  const [addSubFunction] = useAddSubFunctionMutation();
  const [editSubFunction] = useEditSubFunctionMutation();
  const [systemNameVal, setSystemNameVal] = useState<string>('');

  const initialValues = useAppSelector(
    (state) => state.subFunctionVLClientModal.initialValues
  );
  const isOpen = useAppSelector(
    (state) => state.subFunctionVLClientModal.isOpen
  );
  const modalType = useAppSelector(
    (state) => state.subFunctionVLClientModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeSubFunctionVLClientModal,
  } = useActions();

  const methods = useForm<ISubFunctionFormData>({
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    closeSubFunctionVLClientModal();
  };

  const subFunctionCodeHandler = React.useCallback(() => {
    // This functionality will automatically set Sub-Function ID to first available
    if (modalType === 'add' && isOpen) {
      let i = 1;
      while (subFunctionList!.find((el) => el.code === `SF${i}`)) {
        i++;
      }
      setValue('code', `SF${i}`);
    }
  }, [isOpen, modalType, setValue, subFunctionList]);

  React.useEffect(() => {
    reset(initialValues);
    subFunctionCodeHandler();
    if (modalType === 'edit') {
      setFunctionFieldValue(initialValues && initialValues?.functionFieldValue);
      setValue('functionId', initialValues?.functionId);
      setSystemNameVal(initialValues && initialValues?.systemName);
    }
  }, [isOpen, reset, initialValues, subFunctionCodeHandler]);

  const onSubmit = async (formData: ISubFunctionFormData) => {
    const code = formData.code;
    const reg = new RegExp('^[0-9]*$');
    const checknumber = code.substring(2, code.length);
    const isnumber = reg.test(checknumber);
    if (code.substring(0, 2) !== 'SF' || !isnumber || code.length <= 2) {
      setError('code', { message: 'Please provide valid Sub Function ID' });
      return;
    }

    const modalTypeMap = {
      add: {
        fn: addSubFunction,
        messageSuccess: 'Sub-Function Added Successfully',
        errorMessage: 'Error Adding Sub-Function',
      },
      edit: {
        fn: editSubFunction,
        messageSuccess: 'Sub-Function Updated Successfully',
        errorMessage: 'Error Updating Sub-Function',
      },
    };

    if (
      modalType === 'add' &&
      subFunctionList!.find((el) => el.code === formData.code)
    )
      return setError('code', { message: 'SubFunction Id should be unique' });

    if (
      modalType === 'add' &&
      subFunctionList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'SubFunction name should be unique',
      });

    if (
      modalType === 'edit' &&
      formData.code !== initialValues.code &&
      subFunctionList!.find((el) => el.code === formData.code)
    )
      return setError('code', { message: 'SubFunction Id should be unique' });

    if (
      modalType === 'edit' &&
      formData.fieldValue.toLowerCase() !==
        initialValues.fieldValue.toLowerCase() &&
      subFunctionList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'SubFunction name should be unique',
      });

    const payload = {
      fieldValue: formData.fieldValue,
      code: formData.code,
      abbreviation: formData.abbreviation,
      subFunctionFamily: formData.subFunctionFamily,
      functionId:
        modalType === 'edit' ? initialValues.functionId : formData?.functionId,
      ...(formData?.description && { description: formData?.description }),
      ...(modalType === 'edit' && { id: formData?.id }),
      ...(modalType === 'add' && { clientId: id || 'Master' }),
    } as ISubFunctionFormData;

    try {
      const response = await modalTypeMap[modalType].fn(payload).unwrap();
      if (response.status === 'success') {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
      }
    } catch (e) {
      const error = e as {
        data?: {
          detail: string;
        };
      };
      if (error.data && error.data.detail) {
        toggleErrorSnackbar({ message: error.data.detail });
      } else
        toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add new sub-function' : 'Edit sub-function'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='sub-function-modal'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='fieldValue'
            rules={{ required: 'Please provide sub-function name' }}
            label='Sub-Function Name'
          />
          {modalType === 'edit' ? (
            <>
              <StyledLabel>
                System Name: <BoldStyledLabel>{systemNameVal}</BoldStyledLabel>
              </StyledLabel>
              <StyledLabel>
                Sub-Function ID:
                <BoldStyledLabel>{initialValues?.code || ''}</BoldStyledLabel>
              </StyledLabel>
              <StyledLabel>
                Function:
                <BoldStyledLabel>{functionFieldValue || ''}</BoldStyledLabel>
              </StyledLabel>
              <StyledLabel>
                Sub-Function Abbreviation:
                <BoldStyledLabel>
                  {initialValues?.abbreviation || ''}
                </BoldStyledLabel>
              </StyledLabel>
              <StyledLabel>
                Sub-Function Family:
                <BoldStyledLabel>
                  {initialValues?.subFunctionFamily || ''}
                </BoldStyledLabel>
              </StyledLabel>
            </>
          ) : (
            <>
              <FormLabelDropdown
                name='functionId'
                placeholder='Select function'
                rules={{ required: 'Please select a function' }}
                label='Function'
                tooltip='tooltip info'
                dropDownItem={functionData?.functionsListDropdown ?? []}
                defaultValue={initialValues?.functionId || ''}
              />
              <FormLabelInput
                name='code'
                rules={{ required: 'Please provide sub-function ID' }}
                label='Sub-Function ID'
                tooltip='tooltip info'
              />
              <FormLabelInput
                name='abbreviation'
                label='Sub-Function Abbreviation (optional)'
                tooltip='tooltip info'
              />
              <FormLabelInput
                name='subFunctionFamily'
                label='Sub-Function Family (optional)'
                tooltip='tooltip info'
              />
            </>
          )}
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='sub-function-modal'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};
