import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import { routes } from '../../routes/routes';
import PageHeader from '../../atoms/header/PageHeader';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';

const StyledTitlesWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});
const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
});

export default function AdminsHeader() {
  const { openAddAdminsModal } = useActions();

  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.admins.name} />
      </StyledTitlesWrapper>
      <StyledHeaderActionWrapper>
        <Button
          btnType={'PRIMARY'}
          text={'Add new user'}
          onClick={() => openAddAdminsModal()}
        />
      </StyledHeaderActionWrapper>
    </PageHeader>
  );
}
