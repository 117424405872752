import React, { useRef } from 'react';
import ImpactGroupsHeader from '../../../organisms/headers/Company/ImpactGroups/ImpactGroupsHeader';
import ImpactGroupsTable from '../../../organisms/tables/Company/ImpactGroups/ImpactGroupsTable';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { useGetLeaderImpactGroupQuery } from '../../../store/api/leadership';
import HpContainer from '../../../atoms/container/HpContainer';

const ImpactGroups = () => {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);
  const { id } = useParams();

  const { data: { data: impactGroupsData } = {}, isLoading } =
    useGetLeaderImpactGroupQuery({
      id: id || '',
    });
  return (
    <>
      <ImpactGroupsHeader />
      <HpContainer
        sx={{
          marginBottom: '50px',
        }}
      >
        <ImpactGroupsTable
          isLoading={isLoading}
          data={impactGroupsData || []}
          tableInstanceRef={tableInstanceRef}
        />
      </HpContainer>
    </>
  );
};

export default ImpactGroups;
