import React from 'react';
import { Box, Menu, MenuItem, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Title from '../../../../../atoms/title/Title';
import SubTitle from '../../../../../atoms/title/SubTitle';
import Button from '../../../../../atoms/button/Button';
import DetailsHeaderWrapper from '../../../../../atoms/header/DetailsHeaderWrapper';
import HDBreadCrumb from '../../../../../hd-ui-kit/HDBreadcrumb';
import { useActions } from '../../../../../hooks/actions';
import { IGetRoleData } from '../../../../../store/api/types/leadership';
import {
  useDeleteRoleProfileMutation,
  useGetSubFunctionListQuery,
} from '../../../../../store/api/leadership';
import { useNavigate, useParams } from 'react-router';

interface IProps {
  roleDetails: IGetRoleData;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const CategoryWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '40px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  paddingLeft: '24px',
  paddingRight: '340px',
  maxWidth: '1200px',
}));

export default function RoleDetailsHeader({ roleDetails }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const params = useParams();

  const { data: subFunctionData } = useGetSubFunctionListQuery(params.id ?? '');

  const navigate = useNavigate();

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    setSubFunctionList,
    openEditRoleModal,
    openClientCloneRoleModal,
  } = useActions();

  const [deleteRole] = useDeleteRoleProfileMutation();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditRole = () => {
    setSubFunctionList({
      list: subFunctionData?.subFunctionsListDropdown,
      value: roleDetails.function,
    });
    openEditRoleModal(roleDetails);
    setAnchorEl(null);
  };

  const handleCloneRole = () => {
    setSubFunctionList({
      list: subFunctionData?.subFunctionsListDropdown,
      value: roleDetails.function,
    });
    openClientCloneRoleModal(roleDetails);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteRole(roleDetails.id)
      .unwrap()
      .then(() => {
        toggleSuccessSnackbar({ message: 'Client Role Deleted Successfully' });
        navigate(-1);
      })
      .catch(() => toggleErrorSnackbar({}));
    setAnchorEl(null);
  };

  return (
    <>
      <DetailsHeaderWrapper>
        <StyledTitlesWrapper>
          <BreadCrumbWrapper>
            <HDBreadCrumb
              routes={[
                { text: 'Role Library' },
                { text: roleDetails.name || '' },
              ]}
            />
          </BreadCrumbWrapper>
          <Title text={roleDetails.name || ''} />
          <StyledSubTitlesWrapper>
            <StyledSubTitlesWrapper>
              <SubTitle
                text='ID'
                bold
              />
              <SubTitle text={roleDetails.roleId || ''} />
            </StyledSubTitlesWrapper>
          </StyledSubTitlesWrapper>
          <CategoryWrapper>
            <SubTitle
              text={`${roleDetails.functionName?.toUpperCase()}
                 | ${roleDetails.subFunctionName?.toUpperCase()} 
                 | ${roleDetails.levelName?.toUpperCase()}`}
            />
          </CategoryWrapper>
        </StyledTitlesWrapper>
        <StyledButtonWrapper>
          {/* APS-2258 : Hide Submit for approval button */}
          {/* <Button
            btnType='PRIMARY'
            text={'Submit for approval'}
          /> */}
          <IconButton onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditRole()}>Edit</MenuItem>
            <MenuItem onClick={() => handleCloneRole()}>Clone</MenuItem>
            <MenuItem onClick={() => handleDelete()}>Delete</MenuItem>
          </Menu>
        </StyledButtonWrapper>
      </DetailsHeaderWrapper>
      <StyledWrapper>
        <SubTitle text={roleDetails.description || ''} />
      </StyledWrapper>
    </>
  );
}
