import { styled } from '@mui/material';
import React, { useState } from 'react';

interface IProps {
  text: string;
  length?: number;
}

const StyledToggleText = styled('span')(() => ({
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#18768C',
  cursor: 'pointer',
  textDecoration: 'underline',
}));

const StyledText = styled('p')(() => ({
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#0B0C10',
}));

export default function ReadMoreText({ text, length = 80 }: IProps) {
  const [isReadMore, setIsReadMore] = useState(true);

  return (
    <StyledText>
      {isReadMore ? text && text.slice(0, length) : text}{' '}
      <StyledToggleText onClick={() => setIsReadMore((value) => !value)}>
        {isReadMore ? 'more' : 'less'}
      </StyledToggleText>
      {isReadMore ? '...' : ''}
    </StyledText>
  );
}
