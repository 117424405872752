import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { ILeadershipPotentialFormData } from '../../../commons/types';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import {
  useAddClientStatementMutation,
  useUpdateClientStatementMutation,
} from '../../../store/api/leadership';
import { IAddClientStatementRequest } from '../../../store/api/types/leadership';

export default function LeadershipPotentialSLModal() {
  const [createStatement] = useAddClientStatementMutation();
  const [editStatement] = useUpdateClientStatementMutation();

  const navigate = useNavigate();

  const initialValues = useAppSelector(
    (state) => state.leadershipPotentialSLModal.initialValues
  );
  const isOpen = useAppSelector(
    (state) => state.leadershipPotentialSLModal.isOpen
  );
  const modalType = useAppSelector(
    (state) => state.leadershipPotentialSLModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeLeadershipPotentialSLModal,
    setStatementDetailsInfo,
  } = useActions();

  const methods = useForm<ILeadershipPotentialFormData>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    closeLeadershipPotentialSLModal();
  };

  const submitHandler = async (requestPayload: IAddClientStatementRequest) => {
    const modalTypeMap = {
      edit: {
        fn: editStatement,
        messageSuccess: 'Statement Updated Successfully',
        errorMessage: 'Error Updating Statement',
      },
      add: {
        fn: createStatement,
        messageSuccess: 'Statement Added Successfully',
        errorMessage: 'Error Adding Statement',
      },
    };

    try {
      const response = await modalTypeMap[modalType]
        .fn(requestPayload)
        .unwrap();
      if (response?.status === 'success' && response?.code === 200) {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
        setStatementDetailsInfo(requestPayload.payload as any);
        if (modalType === 'add') {
          // redirect master statement details
          navigate(
            `${routes.statementLibrary.url}/${response?.data?.id}${routes.statementLibrary.details.url}`
          );
        }
      }
      if (response?.message) {
        toggleErrorSnackbar({ message: response.message });
      }
    } catch (e) {
      const error = e as {
        data?: { detail: string };
        status?: number;
        message?: string;
      };
      if (error.data?.detail)
        toggleErrorSnackbar({ message: error.data.detail });
      else if (error.message) toggleErrorSnackbar({ message: error.message });
      else
        toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
    }
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [isOpen, reset, initialValues]);

  const onSubmit = async (data: ILeadershipPotentialFormData) => {
    const client = 'Master';
    const requestPayload: IAddClientStatementRequest = {
      params: {
        ...(data?.id && { id: data.id }),
        clientId: client,
        skillGroup: 'Potetential',
      },
      payload: {
        name: data.name,
        description: data.description,
      },
    };
    await submitHandler(requestPayload);
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add'
          ? 'Add a new Leadership Potential Statement'
          : 'Edit Statement'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-role'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='name'
            rules={{ required: 'Please provide a statement name' }}
            label='Statement name'
            tooltip='tooltip info'
          />
          <FormLabelInput
            name='description'
            rules={{ required: 'Please provide a description ' }}
            label='Description'
            tooltip='Statement description'
            extraLabelInputProps={{
              minRows: 2,
              maxRows: 2,
              multiline: true,
            }}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='add-new-role'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
