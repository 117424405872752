import React, { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import PageHeader from '../../../atoms/header/PageHeader';
import Title from '../../../atoms/title/Title';
import HDBreadCrumb from '../../../hd-ui-kit/HDBreadcrumb';
import FormWrapper from '../../../molecules/form/FormWrapper';
import HpCard from '../../../atoms/card/HpCard';
import HpCardHeader from '../../../atoms/card/HpCardHeader';
import HpCardContent from '../../../atoms/card/HpCardContent';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import FormLabelDatePicker from '../../../molecules/form/FormLabelDatePicker';
import { useActions } from '../../../hooks/actions';
import { routes } from '../../../routes/routes';
import HpContainer from '../../../atoms/container/HpContainer';
import {
  useGetProjectByIdQuery,
  useSetProjectMutation,
  useUpdateProjectMutation,
} from '../../../store/api/projects';
import { showCreatedDateViaSlash } from '../../../store/api/helpers';
import Toggle from '../../../atoms/toggle/Toggle';
import { useGetProjectsMutation } from '../../../store/api/v2/tazio';
import { useGetClientByIdQuery } from '../../../store/api/clients';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import { projectPurpose } from '../../../commons/projectPurposeDropdownData';

interface IFormData {
  name: string;
  clientId: string;
  status: string;
  projectLeadName?: string;
  purpose?: string;
  startDate?: any;
  closeDate?: any;
  insightsProject?: {
    sourceProjectId: string;
    syncEnabled: boolean;
    title: string;
    status: string;
  };
  reference?: string;
  insightProjectId?: string;
  projectId?: string;
  hiringManager?: string;
}

const BreadCrumbWrapper = styled(Box)(() => ({
  marginBottom: '40px',
}));

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButtonsWrapper = styled(Box)(() => ({
  margin: '24px 0px',
  display: 'flex',
  gap: '20px',
}));

const AddNewClientProject = () => {
  const { id: clientId, projectId } = useParams();
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const isSubNav = pathName.includes('/company');

  const { data: { data = [] } = {} } = useGetClientByIdQuery(
    clientId as string,
    {
      skip: !isSubNav,
    }
  );

  const { data: { data: projectData } = {} } = useGetProjectByIdQuery(
    projectId as string,
    { skip: !projectId }
  );

  const [toggleAction, setToggleAction] = React.useState(false);
  const [isLinkedToTazio, setLinkedToTazio] = React.useState(true);

  const [addNewProject, { isLoading }] = useSetProjectMutation();

  const [triggerProjectsData, { data: projectsResponse }] =
    useGetProjectsMutation();
  const [editProject, { isLoading: isProjectUpdateLoading }] =
    useUpdateProjectMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const methods = useForm<IFormData>();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    watch,
    clearErrors,
  } = methods;

  /* Date validation block */
  const startDateWatch = watch('startDate');

  useEffect(() => {
    clearErrors('closeDate');
    clearErrors('startDate');
  }, [clearErrors, startDateWatch]);

  const dateValidation = (start: string, end: string) => {
    if (new Date(start) > new Date(end)) {
      setError('closeDate', {
        type: 'string',
        message: 'End Date should be after Start Date',
      });
      return true;
    }
    return false;
  };
  /* --- */

  const onSubmit = async (formData: IFormData) => {
    formData.startDate = showCreatedDateViaSlash(formData.startDate.ts);

    if (formData.closeDate) {
      formData.closeDate = showCreatedDateViaSlash(formData.closeDate.ts);

      if (dateValidation(formData.startDate, formData.closeDate)) return;
    } else {
      delete formData.closeDate;
    }

    // if insightProjectId  in formData , remove it
    if (formData?.insightProjectId) {
      delete formData.insightProjectId;
    }

    if (formData?.closeDate === '-') {
      delete formData.closeDate;
    }

    if (formData?.startDate === '-') {
      delete formData.startDate;
    }
    if (projectId) {
      if (!toggleAction && formData?.insightsProject?.syncEnabled)
        formData.insightsProject.syncEnabled = false;
      formData.projectId = String(projectId);
      editProject(formData)
        .unwrap()
        .then(() => {
          toggleSuccessSnackbar({ message: 'Project Updated Successfully' });
          navigate(`/company/${clientId}${routes.company.projects.url}`);
        })
        .catch((e) => {
          if (e.data.message) {
            toggleErrorSnackbar({ message: e.data.message });
          } else if (e.data.detail) {
            toggleErrorSnackbar({ message: e.data.detail });
          } else {
            toggleErrorSnackbar({});
          }
        });
    } else {
      formData.status = 'Draft';
      formData.clientId = String(clientId);
      addNewProject(formData)
        .unwrap()
        .then(() => {
          toggleSuccessSnackbar({ message: 'Project Added Successfully' });
          navigate(`/company/${clientId}${routes.company.projects.url}`);
        })
        .catch((e) => {
          if (e.data.message) {
            toggleErrorSnackbar({ message: e.data.message });
          } else if (e.data.detail) {
            toggleErrorSnackbar({ message: e.data.detail });
          } else {
            toggleErrorSnackbar({});
          }
        });
    }
  };

  // Fetch client projects
  React.useEffect(() => {
    // call get fetch API only if toggle is on and tazio data available
    if (toggleAction && data) {
      triggerProjectsData({
        accountId: data[0]?.applications.insights?.accountId || '',
      });
    }
  }, [toggleAction, data]);

  // show snackbar message if getProjects API failed
  React.useEffect(() => {
    if (projectsResponse && !projectsResponse.status) {
      toggleErrorSnackbar({ message: 'invalid accountId' });
    }
  }, [projectsResponse, toggleErrorSnackbar]);

  React.useEffect(() => {
    if (!toggleAction) {
      reset();
    }
  }, [reset, toggleAction]);

  const handleOptionClick = (value: EventTarget) => {
    const projectsData = projectsResponse?.data || [];
    const selectedProjectData = projectsData.find((obj) => {
      return Number(obj.projectID) === Number(value);
    });
    if (selectedProjectData) {
      reset({
        name: selectedProjectData?.title || '',
        projectLeadName: '',
        purpose: '',
        startDate: '',
        hiringManager: '',
        closeDate: '',
        insightsProject: {
          title: selectedProjectData?.title || '',
          sourceProjectId: String(selectedProjectData?.projectID || ''),
          status: '',
          syncEnabled: true,
        },
        insightProjectId: String(selectedProjectData?.projectID || ''),
      });
    }
  };

  // Project Edit Form Fields Update
  React.useEffect(() => {
    if (projectData && projectData) {
      const isInsightsEnabled = !!projectData[0]?.insightsProject;
      if (isInsightsEnabled) {
        setToggleAction(Boolean(projectData[0]?.insightsProject?.syncEnabled));
        const insightProjectId =
          projectData[0]?.insightsProject?.sourceProjectId || '';
        reset({
          name: projectData[0]?.name || '',
          projectLeadName: projectData[0]?.projectLeadName || '',
          purpose: projectData[0]?.purpose || '',
          startDate: projectData[0]?.startDate || '',
          hiringManager: projectData[0]?.hiringManager || '',
          closeDate: projectData[0]?.closeDate || '',
          reference: projectData[0]?.reference || '',
          insightsProject: {
            title: projectData[0]?.insightsProject?.title || '',
            sourceProjectId: insightProjectId || '',
            status: projectData[0]?.insightsProject?.status || '',
            syncEnabled: true,
          },
          insightProjectId,
        });
      } else {
        setLinkedToTazio(false);
        reset({
          name: projectData[0]?.name || '',
          projectLeadName: projectData[0]?.projectLeadName || '',
          purpose: projectData[0]?.purpose || '',
          startDate: projectData[0]?.startDate || '',
          hiringManager: projectData[0]?.hiringManager || '',
          closeDate: projectData[0]?.closeDate || '',
          reference: projectData[0]?.reference || '',
        });
      }
    }
  }, [projectData, reset]);

  const toggleClickHandler = () => {
    if (!data[0]?.applications?.insights?.accountId) {
      toggleErrorSnackbar({
        message: 'Insights Account ID is missing or invalid',
      });
    } else {
      setToggleAction((value) => !value);
    }
  };

  return (
    <>
      <PageHeader type='full'>
        <StyledTitlesWrapper>
          <BreadCrumbWrapper>
            <HDBreadCrumb
              routes={[
                { text: 'Projects' },
                projectId
                  ? { text: 'Edit Project' }
                  : { text: 'Create New Project' },
              ]}
            />
          </BreadCrumbWrapper>
          <Title text={projectId ? 'Edit Project' : 'Create New Project'} />
        </StyledTitlesWrapper>
      </PageHeader>
      <HpContainer
        sx={{
          marginTop: '20px',
        }}
      >
        <FormWrapper
          methods={methods}
          id='add-new-project'
          onSubmit={handleSubmit(onSubmit)}
          key={1}
        >
          {isLinkedToTazio && (
            <HpCard sx={{ marginTop: '50px' }}>
              <HpCardHeader
                title='Import Insights Information'
                action={
                  <Toggle
                    onChange={toggleClickHandler}
                    checked={toggleAction}
                  />
                }
              />
              {toggleAction && projectsResponse?.projectsDropdown && (
                <HpCardContent>
                  <FormLabelDropdown
                    name='insightProjectId'
                    label='Select Insights Project'
                    placeholder='Select Insights Project'
                    dropDownItem={projectsResponse.projectsDropdown}
                    tooltip={'Select Insights Project'}
                    disabled={!!projectId}
                    onChange={(e) =>
                      handleOptionClick(e.target.value as EventTarget)
                    }
                  />
                </HpCardContent>
              )}
            </HpCard>
          )}
          {
            <HpCard sx={{ marginBottom: '20px' }}>
              <HpCardHeader title='Project Details' />
              <HpCardContent sx={{ rowGap: '28px' }}>
                <FormLabelInput
                  name='name'
                  label='Project Name (Required)'
                  rules={{ required: 'Please provide Project Name' }}
                />
                <FormLabelInput
                  name='projectLeadName'
                  label='Project Lead'
                />
                <FormLabelInput
                  name='reference'
                  label='Project Reference'
                />
                <FormLabelInput
                  name='hiringManager'
                  label='Hiring Manager'
                />
                <FormLabelDropdown
                  name='purpose'
                  label='Journey'
                  defaultValue={''}
                  dropDownItem={projectPurpose}
                />
                <FormLabelDatePicker
                  name='startDate'
                  defaultValue={null} // needed to reset selected date on form reset
                  label='Start Date (MM / DD / YYYY) (Required)'
                  rules={{ required: 'Please provide a Start Date' }}
                />
                <FormLabelDatePicker
                  name='closeDate'
                  defaultValue={''} // needed to reset selected date on form reset
                  label='End Date (MM / DD / YYYY) (Used as default due-date for assignments)'
                />
              </HpCardContent>
            </HpCard>
          }
        </FormWrapper>
        <StyledButtonsWrapper>
          <Button
            btnType={'PRIMARY'}
            text={'Save Project'}
            type='submit'
            disabled={
              !!Object.values(errors).length ||
              isLoading ||
              isProjectUpdateLoading
            }
            form='add-new-project'
          />
          <Button
            btnType={'SECONDARY'}
            text={'Cancel'}
            type='button'
            form='add-new-project'
            onClick={() =>
              navigate(`/company/${clientId}${routes.company.projects.url}`)
            }
          />
        </StyledButtonsWrapper>
      </HpContainer>
    </>
  );
};

export default AddNewClientProject;
