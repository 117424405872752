import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IClientAssessmentModalData } from '../../../api/types/clientAssessment';
import { IAssessmentStructureType } from '../../../api/types/assessmentStructure';

interface IClientAssessmentModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: IClientAssessmentModalData;
}

const initialState: IClientAssessmentModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    skillCategory: '',
    assessmentType: '',
    assessmentName: '',
    assessmentCategory: '',
  },
};

const clientAssessmentModalSlice = createSlice({
  name: 'clientassessments',
  initialState,
  reducers: {
    closeClientAssessmentModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openClientAssessmentModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditClientAssessmentModal: (
      state,
      action: PayloadAction<IAssessmentStructureType>
    ) => {
      (state.isOpen = true),
        (state.modalType = 'edit'),
        (state.initialValues = {
          id: action.payload.id || '',
          assessmentName: action.payload.title || '',
          skillCategory: action.payload.skillCategoryId || '',
          assessmentType: action.payload.templateId || '',
          assessmentCategory: action.payload.category || '',
        });
    },
  },
});

export const clientAssessmentModalActions = clientAssessmentModalSlice.actions;
export const clientAssessmentModalReducer = clientAssessmentModalSlice.reducer;
