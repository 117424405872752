import React from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import {
  IAssessmentData,
  ILeadersData,
  IProjectData,
} from '../../../../store/api/types/assessmentStatusDashboard';
import { TableCellProps, TableProps } from '@mui/material';
import { IAssignmentData } from '../../../../store/api/types/projects';

interface IProps {
  data?: IAssessmentData[] | ILeadersData[] | IProjectData[];
  columns:
    | MRT_ColumnDef<IAssessmentData>[]
    | MRT_ColumnDef<ILeadersData>[]
    | MRT_ColumnDef<IProjectData>[]
    | MRT_ColumnDef<IAssignmentData>[];
  tableInstanceRef?: React.RefObject<MRT_TableInstance>;
  muiTableBodyCellProps?: (arg: any) => TableCellProps;
  state?: object;
  initialState?: object;
  functionToFilter?: () => void;
}

const ClientAssessmentsTable = ({
  data,
  columns,
  tableInstanceRef,
  functionToFilter,
  ...extraProps
}: IProps & TableProps) => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [dmyata, setData] = React.useState([]); // data will get updated after re-fetching

  return (
    <Table
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      tableInstanceRef={tableInstanceRef}
      {...extraProps}
    />
  );
};

export default ClientAssessmentsTable;
