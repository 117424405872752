import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

const StyledSwitch = styled(Switch)(() => ({
  width: 63,
  height: 32,
  padding: '0px 1px 2px 0px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(33px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#18768c',
        opacity: 0.9,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 25,
    height: 25,
  },
  '& .MuiSwitch-track': {
    borderRadius: 15,
    backgroundColor: 'rgba(100, 106, 124, 1)',
    opacity: 0.6,
  },
}));

export default function Toggle({ ...extraProps }: SwitchProps) {
  return <StyledSwitch {...extraProps} />;
}
