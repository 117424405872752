import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../atoms/tab/TabContent';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import RoleTable from '../../organisms/tables/RoleTable';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import { MRT_TableInstance } from 'material-react-table';
import Button from '../../atoms/button/Button';
import FunctionalImpactRLModal from '../../organisms/modals/Role/FunctionalImpactRLModal';
import { useActions } from '../../hooks/actions';
import ImportIcon from '../../assets/icons/Import.svg';
import { useLazyGetRoleSkillsByCategoryQuery } from '../../store/api/leadership';

interface IProps {
  roleId?: string;
  skillType: string;
  roleFunctionID?: string | undefined;
  client?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '57%',
  gap: '15px',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

export default function BusinessFunctionalImpact({
  index,
  value,
  roleId,
  roleFunctionID,
  client,
}: ITabContentProps & IProps) {
  const [getImpactStatements, { data: roleSkills, isLoading }] =
    useLazyGetRoleSkillsByCategoryQuery();

  React.useEffect(() => {
    if (value === 0) {
      getImpactStatements({
        client,
        roleId,
        skillType: 'Impact',
      });
    }
  }, [client, getImpactStatements, roleId, value]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  const { openFunctionalImpactRLModal } = useActions();

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Box sx={{ display: 'flex' }}>
              <Title
                text={'Business Impact: Functional Impact'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <Box sx={{ marginTop: '15px' }}>
                <HpTooltip title={'Business Impact: Functional Impact'} />
              </Box>
            </Box>
            <StyledHeaderActionWrapper>
              <Button
                btnType='SECONDARY'
                text={'Import CSV'}
                startIcon={
                  <ButtonIcon
                    src={ImportIcon}
                    alt='Import CSV'
                  />
                }
              />
              <Button
                btnType={'PRIMARY'}
                text={'Add new statement'}
                onClick={() => openFunctionalImpactRLModal()}
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                roleSkills?.functionalImpactData?.length
                  ? roleSkills?.functionalImpactData?.length
                  : '0'
              } total statements`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        {value === 0 && (
          <FunctionalImpactRLModal roleFunctionID={roleFunctionID} />
        )}
        <RoleTable
          statementType='functionalImpact'
          isLoading={isLoading}
          data={roleSkills?.functionalImpactData}
          tableInstanceRef={tableInstanceRef}
        />
      </>
    </TabContent>
  );
}
