import React from 'react';
import './App.css';
import AppRouter from './routes/AppRouter';
import HpSnackbar from './molecules/snackbar/HpSnackbar';
import { darkTheme, lightTheme } from './hd-ui-kit/HDTheme';
import { CssBaseline, ThemeProvider } from '@mui/material';

const themeMode = false;

const App = () => {
  return (
    <>
      <ThemeProvider theme={themeMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <HpSnackbar />
        <AppRouter />
      </ThemeProvider>
    </>
  );
};

export default App;
