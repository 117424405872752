import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../../../atoms/title/Title';
import SummaryMetrics, {
  SummaryMetricsData,
} from '../../../../atoms/header/SummaryMetrics';
import HpContainer from '../../../../atoms/container/HpContainer';

interface IProps {
  metricsData: SummaryMetricsData[];
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledHeaderContainer = styled(Box)(() => ({
  background:
    'radial-gradient(89.54% 90.64% at 6.02% 9.36%, #E3ECEF 0%,' +
    ' rgba(241, 241, 241, 0) 100%)',
  paddingTop: '2rem',
  paddingBottom: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));
const AssessmentsHeader = ({ metricsData }: IProps) => {
  return (
    <StyledHeaderContainer>
      <HpContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <StyledTitlesWrapper className='WRAPPER'>
          <Title text='Assessment Status Dashboard' />
        </StyledTitlesWrapper>
      </HpContainer>
      <HpContainer>
        <SummaryMetrics data={metricsData} />
      </HpContainer>
    </StyledHeaderContainer>
  );
};

export default AssessmentsHeader;
