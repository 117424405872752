import React from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import Toggle from '../../atoms/toggle/Toggle';

const FormToggle = ({
  name,
  defaultValue = false,
  ...extraProps
}: UseControllerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...extraProps}
      render={({ field: { onChange, value } }) => {
        return (
          <Toggle
            onChange={onChange}
            checked={value}
          />
        );
      }}
    />
  );
};

export default FormToggle;
