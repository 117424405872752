import React, { useMemo, useEffect } from 'react';
import Table from '../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import { useCloneRoleMutation } from '../../store/api/core';
import { SelectOptions } from '../../commons/types';
import { useActions } from '../../hooks/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../routes/routes';
import MultiSelectDropdown from '../../atoms/multiselect/MultiSelectDropdown';
import {
  useDeleteRoleProfileMutation,
  useGetSubFunctionListQuery,
} from '../../store/api/leadership';
import { IGetRoleData } from '../../store/api/types/leadership';
import { filterDuplicateDropddownTypes } from '../../utils/helpers';

interface IProps {
  data?: IGetRoleData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

type IFilterTypes = {
  functionDropdown: Array<SelectOptions>;
  subFunctionDropdown: Array<SelectOptions>;
  levelDropdown: Array<SelectOptions>;
};

const RoleLibraryTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    openEditRoleModal,
    openClientCloneRoleModal,
    openApplyToClientRoleModal,
    setSubFunctionList,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const navigate = useNavigate();
  const params = useParams();

  const { data: subFunctionData } = useGetSubFunctionListQuery(
    params.id || 'Master'
  );

  const [cloneRole] = useCloneRoleMutation();
  const [deleteRole] = useDeleteRoleProfileMutation();

  // States to manipulate filter options
  const [columnFilters, setColumnFilter] = React.useState<IFilterTypes>({
    functionDropdown: [],
    subFunctionDropdown: [],
    levelDropdown: [],
  });

  const [resetColumnFilters, setResetColumnFilter] =
    React.useState<IFilterTypes>({
      functionDropdown: [],
      subFunctionDropdown: [],
      levelDropdown: [],
    });

  const [filterApplied, setFilterApplied] = React.useState(0);
  const [currentColumn, setCurrentColumn] = React.useState('');

  // Build Filter DropDown Items for Function,SubFunction and Level from Data
  React.useEffect(() => {
    if (data && data.length) {
      const payload = {
        functionDropdown: data.map((item) => ({
          text: item.functionName,
          value: item.functionName,
        })),
        subFunctionDropdown: data.map((item) => ({
          text: item.subFunctionName,
          value: item.subFunctionName,
        })),
        levelDropdown: data.map((item) => ({
          text: item.levelName,
          value: item.levelName,
        })),
      } as IFilterTypes;
      setColumnFilter({
        functionDropdown: filterDuplicateDropddownTypes(
          payload.functionDropdown
        ),
        subFunctionDropdown: filterDuplicateDropddownTypes(
          payload.subFunctionDropdown
        ),
        levelDropdown: filterDuplicateDropddownTypes(payload.levelDropdown),
      });
      setResetColumnFilter({
        functionDropdown: filterDuplicateDropddownTypes(
          payload.functionDropdown
        ),
        subFunctionDropdown: filterDuplicateDropddownTypes(
          payload.subFunctionDropdown
        ),
        levelDropdown: filterDuplicateDropddownTypes(payload.levelDropdown),
      });
    }
  }, [data]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (roleID: string) => {
    deleteRole(roleID)
      .unwrap()
      .then(() =>
        toggleSuccessSnackbar({ message: 'Role Deleted Successfully' })
      )
      .catch(() => toggleErrorSnackbar({}));
    setAnchorEl(null);
  };

  const handleEditRole = (rowData: IGetRoleData) => {
    setSubFunctionList({
      list: subFunctionData?.subFunctionsListDropdown,
      value: rowData.function,
    });
    openEditRoleModal(rowData);
    setAnchorEl(null);
  };

  const handleApplyToClientRole = (rowData: IGetRoleData) => {
    openApplyToClientRoleModal(rowData);
    setAnchorEl(null);
  };

  const handleCloneRole = (rowData: IGetRoleData) => {
    if (params.id) {
      setSubFunctionList({
        list: subFunctionData?.subFunctionsListDropdown,
        value: rowData.function,
      });
      openClientCloneRoleModal(rowData);
    } else cloneRole(rowData.id);
    setAnchorEl(null);
  };

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IGetRoleData>,
    currentColumn: string
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
      // Need this to get latest filtered data in useEffect from filtered table, without this we will get older data
      setFilterApplied((value) => value + 1);
      setCurrentColumn(currentColumn);
    } else {
      column.setFilterValue(undefined);
      // Need this to get latest filtered data in useEffect from filtered table, without this we will get older data
      setFilterApplied((value) => value + 1);
      setCurrentColumn(currentColumn);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IGetRoleData>[]>(
    () => [
      {
        header: 'Role Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
      {
        header: 'Role ID',
        accessorKey: 'roleId',
        filterFn: 'includesString',
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
      },
      {
        header: 'Function',
        accessorKey: 'functionName',
        id: 'functionName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            key='function'
            width='100% !important'
            dropDownItem={columnFilters.functionDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(
                e,
                newValue,
                reason,
                column,
                'function'
              )
            }
            column={column}
          />
        ),
      },
      {
        header: 'Sub-function',
        accessorKey: 'subFunctionName',
        id: 'subFunctionName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            key={'subfunction'}
            dropDownItem={columnFilters.subFunctionDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(
                e,
                newValue,
                reason,
                column,
                'subfunction'
              )
            }
            column={column}
          />
        ),
      },
      {
        header: 'Level',
        accessorKey: 'levelName',
        id: 'levelName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            key={'level'}
            dropDownItem={columnFilters.levelDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column, 'level')
            }
            column={column}
          />
        ),
      },
    ],
    [columnFilters]
  );

  // Need this useeffect to update subfunction and level list based on filtered table data
  useEffect(() => {
    // Get filtered table data
    const filtereddata = tableInstanceRef?.current?.getSortedRowModel();
    // to check which filters are applied (function/subfunction/level)
    const columnFilters = filtereddata
      ? filtereddata.rows[0]?.columnFilters
      : {};

    // Check if function, subfunction and level filters are removed and reset dropdown values
    if (
      columnFilters !== undefined &&
      Object.keys(columnFilters).length === 0
    ) {
      tableInstanceRef.current !== null &&
        tableInstanceRef.current.resetColumnFilters();
      setColumnFilter(resetColumnFilters);
    }

    // filter function data if level/subfunction column filter applied to table
    if (
      currentColumn !== 'function' &&
      columnFilters !== undefined &&
      Object.keys(columnFilters).length !== 0
    ) {
      // Filter out Sub functions from filtered data, used any as we are not aware of data type from row original data
      const filteredFunctions = filtereddata?.rows.map((row: any) => ({
        text: row.original?.functionName || '',
        value: row.original?.functionName || '',
      })) as Array<SelectOptions>;
      // Remove duplicated from Sub Function List
      // Update function option as per filtered table data
      setColumnFilter((value) => ({
        ...value,
        functionDropdown: filterDuplicateDropddownTypes(filteredFunctions),
      }));
      // setFunctionOptions(uniqueFunctions ?? []);
    }

    // filter Subfunction data if function/level column filter applied to table
    if (
      currentColumn !== 'subfunction' &&
      columnFilters !== undefined &&
      Object.keys(columnFilters).length !== 0
    ) {
      // Filter out Sub functions from filtered data, used any as we are not aware of data type from row original data
      const filteredSubFunctions = filtereddata?.rows.map((row: any) => ({
        text: row.original?.subFunctionName || '',
        value: row.original?.subFunctionName || '',
      })) as Array<SelectOptions>;

      // Update subfunction option as per filtered table data
      setColumnFilter((value) => ({
        ...value,
        subFunctionDropdown:
          filterDuplicateDropddownTypes(filteredSubFunctions),
      }));
    }

    // filter level data if function/subfunction column filter applied to table
    if (
      currentColumn !== 'level' &&
      columnFilters !== undefined &&
      Object.keys(columnFilters).length !== 0
    ) {
      // Filter out Levels from filtered data, used any as we are not aware of data type from row original data
      const filteredLevels = filtereddata?.rows.map((row: any) => ({
        text: row.original?.levelName || '',
        value: row.original?.levelName || '',
      })) as Array<SelectOptions>;

      // Update level list based on filtered table data
      setColumnFilter((value) => ({
        ...value,
        levelDropdown: filterDuplicateDropddownTypes(filteredLevels),
      }));
    }
  }, [currentColumn, resetColumnFilters, filterApplied, tableInstanceRef]);

  return (
    <Table
      muiTableHeadCellProps={(a) => {
        const headCells = ['levelName', 'subFunctionName', 'functionName'];
        if (headCells.includes(a.column.columnDef.id))
          return {
            sx: {
              margin: '2rem',
            },
          };
        return {};
      }}
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleEditRole(row.original as IGetRoleData)}
            >
              Edit
            </MenuItem>
            {!params?.id ? null : (
              <MenuItem
                onClick={() => handleCloneRole(row.original as IGetRoleData)}
              >
                Clone
              </MenuItem>
            )}
            {!params?.id && (
              <MenuItem
                onClick={() =>
                  handleApplyToClientRole(row.original as IGetRoleData)
                }
              >
                Apply to Client
              </MenuItem>
            )}
            <MenuItem
              onClick={() => handleDelete((row.original as IGetRoleData).id)}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          const selection = window.getSelection()!.toString();
          if (!cell.id.includes('mrt-row-actions') && !selection) {
            if (params.id) {
              navigate(
                `${routes.company.url}${params.id as string}${
                  routes.company.roles.url
                }/${(row.original as IGetRoleData).id}`
              );
            } else {
              navigate(
                `${routes.roleLibrary.url}/${(row.original as IGetRoleData).id}`
              );
            }
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default RoleLibraryTable;
