import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ImportRoleModalProps {
  isOpen: boolean;
  progress: number;
}

const initialState: ImportRoleModalProps = {
  isOpen: false,
  progress: 0,
};

const importRoleModalSlice = createSlice({
  name: 'importRoleModal',
  initialState,
  reducers: {
    closeImportRoleModal: (state) => {
      state.isOpen = false;
    },
    openImportRoleModal: (state) => {
      state.isOpen = true;
      state.progress = 0;
    },
    setImportRoleUploadProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
  },
});

export const importRoleModalActions = importRoleModalSlice.actions;
export const importRoleModalReducer = importRoleModalSlice.reducer;
