import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import Button from '../../../atoms/button/Button';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormWrapper from '../../../molecules/form/FormWrapper';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import { useForm } from 'react-hook-form';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import { sendLeadersToTazioRequest } from '../../../store/api/types/tazio';
import { useGetProjectsMutation } from '../../../store/api/v2/tazio';
import { useSendLeadersToTazioMutation } from '../../../store/api/v2/leaderProfile';

const CompanySendToTazioModal = () => {
  const isOpen = useAppSelector(
    (state) => state.companySendToTazioModal.isOpen
  );

  const initialValues = useAppSelector(
    (state) => state.companySendToTazioModal.initialValues
  );

  const [triggerProjectsData, { data: projectsResponse }] =
    useGetProjectsMutation();

  React.useEffect(() => {
    if (initialValues) {
      triggerProjectsData({
        accountId: initialValues.account_id || '',
      });
      reset();
    }
  }, [initialValues]);

  const methods = useForm<sendLeadersToTazioRequest>();
  const { handleSubmit, reset } = methods;

  const {
    closeCompanySendToTazioModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
  } = useActions();

  const [sendLeadersToTazio] = useSendLeadersToTazioMutation();

  const handleClose = () => {
    reset();
    closeCompanySendToTazioModal();
  };

  const onSubmit = async (formData: sendLeadersToTazioRequest) => {
    formData.client_id = initialValues.client_id;
    formData.profileIdList = initialValues.profileIdList;
    formData.emailList = initialValues.emailList;
    formData.account_id = initialValues.account_id;

    sendLeadersToTazio(formData)
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          toggleSuccessSnackbar({
            message: 'Leaders details has been sent to Tazio!',
          });
          handleClose();
        } else {
          toggleErrorSnackbar({
            message:
              'The system encountered an issue while sending the leader information.',
          });
          handleClose();
        }
      })
      .catch(() => {
        toggleErrorSnackbar({
          message:
            ' The system encountered an issue while sending the leader information.',
        });
        handleClose();
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>Send to Tazio Project</ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-impact-group'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelDropdown
            name='project_id'
            label='Tazio Project'
            dropDownItem={projectsResponse?.projectsDropdown || []}
            defaultValue={initialValues.project_id || ''}
            placeholder='Select Project'
            rules={{ required: 'Please select a project' }}
            tooltip=' '
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          form='add-impact-group'
          type='submit'
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default CompanySendToTazioModal;
