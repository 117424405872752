import { createSlice } from '@reduxjs/toolkit';
interface ImportCompanyUserModalProps {
  isOpen: boolean;
}

const initialState: ImportCompanyUserModalProps = {
  isOpen: false,
};

const importCompanyEmployeeResumeModalSlice = createSlice({
  name: 'importCompanyEmployeeResumeModal',
  initialState,
  reducers: {
    closeImportCompanyEmployeeResumeModal: (state) => {
      state.isOpen = false;
    },
    openImportCompanyEmployeeResumeModal: (state) => {
      state.isOpen = true;
    },
  },
});

export const importCompanyEmployeeResumeModalActions =
  importCompanyEmployeeResumeModalSlice.actions;
export const importCompanyEmployeeResumeModalReducer =
  importCompanyEmployeeResumeModalSlice.reducer;
