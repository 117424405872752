import { createTheme, useThemeProps } from '@mui/material';
import AventaMedium from '../HDFonts/AventaFont/Aventa-Medium.woff2';
import AvenirLight from '../HDFonts/AvenirFont/AvenirLTProLight.otf';
import './HDTheme.css';

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: ['Aventa', 'Avenir'].join(','),
        h1: {
            color: '#FFFFFF',
            fontSize: '1.875rem',
            fontWeight: 500,
        },
        h2: {
            color: '#FFFFFF',
            fontSize: '1.375rem',
            fontWeight: 400,
        },
        h3: {
            color: '#FFFFFF',
            fontSize: '1.875rem',
        },
        h4: {
            color: '#FFFFFF',
            fontSize: '1.875rem',
        },
        h5: {
            color: '#FFFFFF',
            fontSize: '1.875rem',
        },
        h6: {
            color: '#002136',
            fontSize: '1rem',
            fontWeight: 500,
        },
        subtitle1: {
            fontFamily: 'Aventa',
            fontWeight: 500,
            fontSize: '41px',
            lineHeight: '54px',
            color: '#FFF',
        },
        caption: {
            fontFamily: 'Avenir',
            fontWeight: 300,
            fontSize: 16,
            lineHeight: '24px',
            color: '#CCC',
        }
    },
    components: {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#cccccc',
                    fontWeight: 300,
                    fontFamily: 'Avenir',
                },
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: 'radial-gradient(127.54% 211.92% at 9.25% 0%, #002136 0%, rgba(11, 12, 14, 0) 70%);',
                    backgroundRepeat: 'repeat-x',
                    height: 'calc(100vh)',
                },
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 33, 54, 0.7)',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: 'radial-gradient(127.54% 211.92% at 9.25% 0%, #002136 0%, rgba(11, 12, 14, 0) 70%)',
                    backgroundImage: 'radial-gradient(127.54% 211.92% at 9.25% 0%, #002136 0%, rgba(11, 12, 14, 0) 70%)',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    padding: '5%',
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    color: '#fff',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    color: '#fff',
                    border: '1px solid #616164',
                    borderRadius: '100px',
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    textTransform: 'none',
                    padding: '11px 25px',
                    ...(ownerState.variant === 'contained' && {
                        backgroundColor: '#18768C',
                        color: '#fff',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: '#334255',
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                            background: 'transparent',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '86%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            background: '#18768C',
                            zIndex: '-1',
                        },
                        '&:disabled': {
                            background: '#334255',
                            color: '#0B0C10',
                        }
                    }),
                    ...(ownerState.variant === 'text' && {
                        color: '#66FCFC',
                        border: '1px solid transparent',
                        '&:hover': {
                            color: '#12ABAB',
                            background: 'none',
                            outline: 'none',
                            textDecoration: 'underline',
                        },
                        '&:focus': {
                            color: '#66FCFC',
                            borderRadius: 0,
                            border: '1px solid #66FCFC',
                            outline: 'none',
                            textDecoration: 'none',
                        },
                        '&:active': {
                            outline: 'none',
                            background: 'transparent',
                        },
                        '&:disabled': {
                            color: '#334255'
                        }
                    }),
                    ...(ownerState.variant === 'outlined' && {
                        '&:hover': {
                            background: 'none',
                            border: '1px solid #fff',
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '86%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            border: '1px solid #6D6D70',
                        },
                        '&:disabled': {
                            border: '1px solid #3C3D40',
                            color: '#334255',
                        }
                    })
                }),
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#0B0C10',
                    width: '100%',
                    height: '100%',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#0B0C10'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(360deg, rgba(0,33,54,1) 0%, rgba(24,63,88,1) 50%, rgba(25,126,149,1) 100%)',
                    padding: '24px',
                    borderRadius: '6px',
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    border: '1px solid #A9A9A9',
                    color: '#A9A9A9',
                    fontFamily: 'Avenir',
                    fontWeight: 300,
                    fontSize: '16px',
                    borderRadius: '10px',
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderRadius: '10px'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#2CDADA'
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A9A9A9',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#2CDADA'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E449BD',
                    },
                },
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },

    },
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    typography: {
        fontFamily: ['Aventa', 'Avenir'].join(','),
        h1: {
            color: '#002136',
            fontSize: '1.875rem',
            fontWeight: 500,
        },
        h2: {
            color: '#002136',
            fontSize: '1.375rem',
            fontWeight: 400,
        },
        h3: {
            color: '#002136',
            fontSize: '1.875rem',
        },
        h4: {
            color: '#002136',
            fontSize: '1.875rem',
        },
        h5: {
            color: '#002136',
            fontSize: '1.875rem',
        },
        h6: {
            color: '#002136',
            fontSize: '1rem',
            fontWeight: 500,

        },
        subtitle1: {
            fontFamily: 'Aventa',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            color: '#002136',
        },
        caption: {
            fontFamily: 'Avenir',
            fontWeight: 300,
            fontSize: 16,
            lineHeight: '24px',
            color: '#334255',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#fff',
                    backgroundImage: '#fff',
                }
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(241, 241, 241, 0.7)',
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#334255',
                    fontWeight: 300,
                    fontFamily: 'Avenir',
                },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    color: '#002136',
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#fff',
                    backgroundImage: '#fff',
                    border: '1px solid rgba(11, 12, 16, 0.2)',
                    padding: '5%',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    color: '#fff',
                    border: '1px solid #616164',
                    borderRadius: '100px',
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    textTransform: 'none',
                    padding: '11px 25px',
                    '&:disabled': {
                        background: '#CCCCCC',
                        color: '#A9A9A9',
                        border: 'none',
                    },
                    ...(ownerState.variant === 'contained' && {
                        backgroundColor: '#18768C',
                        color: '#fff',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: '#334255',
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                            background: 'transparent',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '86%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            background: '#18768C',
                            zIndex: '-1',
                        },
                    }),
                    ...(ownerState.variant === 'text' && {
                        color: '#18768C',
                        border: '1px solid transparent',
                        '&:hover': {
                            color: '#00566A',
                            background: 'none',
                            textDecoration: 'underline',
                        },
                        '&:focus': {
                            color: '#18768C',
                            borderRadius: 0,
                            border: '1px solid #18768C',
                            textDecoration: 'none',
                        },
                        '&:active': {
                            background: 'transparent',
                            textDecoration: 'none',
                        },
                        '&:disabled': {
                            color: '#A9A9A9',
                            background: 'none',
                        }
                    }),
                    ...(ownerState.variant === 'outlined' && {
                        color: '#0B0C10',
                        border: '1px solid #646A7C',
                        '&:hover': {
                            border: '1px solid #002136',
                            background: 'none',
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '86%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            border: '1px solid #646A7C',
                        },
                    })
                }),
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#FFF',
                    width: '100%',
                    height: '100%',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#FFF',
                    color: '#002136',
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: '#18768C',
                    padding: '24px',
                    borderRadius: '6px',
                    border: '1px solid rgba(100, 106, 124, 0.2)'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    border: '1px solid #646A7C',
                    color: '#002136',
                    fontFamily: 'Aventa',
                    fontWeight: 400,
                    fontSize: '16px',
                    borderRadius: '10px',
                },
                icon: {
                    color: '#002136',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderRadius: '10px',
                        borderColor: '#646A7C'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#18768C'
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#646A7C',
                        backgroundColor: 'rgba(241, 241, 241, 0.2)',
                        color: '#646A7C',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#18768C'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#C02195',
                    },
                },
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
    },
});