import { Box, Typography } from '@mui/material';
import Button from '../../atoms/button/Button';
import HpCard from '../../atoms/card/HpCard';
import HpCheckbox from '../../atoms/checkbox/HpCheckbox';
import TabContainerTitle from '../../atoms/title/TabContainerTitle';
import zigzagbg from '../../assets/img/backgrounds/ZigZag.svg';
import { IApplication } from '../../commons/Applications';
import { IMeData } from '../../store/api/v2/types/auth';
import { parsePPState } from '../../store/api/v2/helpers';
import { usePutMeMutation } from '../../store/api/v2/auth';
import {
  useCreateLeaderProfileMutation,
  useLazyGetLeaderProfileByEmailQuery,
} from '../../store/api/v2/leaderProfile';

interface IProps {
  setIsPrivacyAccepted: (arg: boolean) => void;
  meData: IMeData | null;
  ppState?: IApplication[];
  setPPState: (arg: IApplication[]) => void;
}

const UserPrivacyAndPolicy = ({
  ppState,
  setPPState,
  meData,
  setIsPrivacyAccepted,
}: IProps) => {
  const [putMe] = usePutMeMutation();
  const [createLeaderProfile] = useCreateLeaderProfileMutation();
  const [getLeaderProfileByEmail] = useLazyGetLeaderProfileByEmailQuery();
  const AppPolicy = () => {
    return (
      <>
        {ppState?.map((item: IApplication) => {
          const {
            appCode,
            privacyPolicy,
            privacyStatement,
            termsStatement,
            terms,
            enabled,
            checked,
          } = item;
          if (enabled) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  marginBottom: '0.5rem',
                }}
                key={appCode}
              >
                <HpCheckbox
                  name={appCode}
                  color='primary'
                  checked={checked}
                  onChange={handleCheck}
                />
                <Typography variant='h6'>
                  {privacyStatement}{' '}
                  <a
                    href={privacyPolicy}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {appCode === 'SITE' && 'Heidrick & Struggles '}
                    Privacy Policy
                  </a>
                  {appCode === 'SITE' && (
                    <>
                      {' '}
                      {termsStatement}{' '}
                      <a
                        href={terms}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Terms of Use.
                      </a>
                    </>
                  )}
                </Typography>
              </Box>
            );
          }
        })}
      </>
    );
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const appCode = event.target.name;
    const checked = event.target.checked;
    setPPState(
      ppState?.map((pp) => {
        if (pp?.appCode === appCode) {
          return { ...pp, checked };
        }
        return pp;
      }) as IApplication[]
    );
  };

  const handleSubmit = () => {
    if (!meData?.id) {
      return;
    }
    putMe({
      ...parsePPState(meData, ppState as IApplication[]),
    })
      .then(() => {
        if (meData.roles[0].name === 'Client Users') {
          getLeaderProfileByEmail(meData.userName).then((res) => {
            if (!res.data?.data?.length) {
              createLeaderProfile({
                firstName: meData.firstName,
                lastName: meData.lastName,
                email: meData.userName,
              });
            }
          });
        }
      })
      .finally(() => {
        setIsPrivacyAccepted(true);
      });
  };

  const allPPAccepted = ppState?.some((pp) => pp.checked !== pp.enabled);
  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 16px - 66px)',
        backgroundImage: `url(${zigzagbg})`,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HpCard>
        <Box sx={{ paddingLeft: '0.5rem' }}>
          <TabContainerTitle text='Privacy Policy' />
        </Box>
        <Box sx={{ paddingTop: '0.5rem' }}>
          <AppPolicy />
        </Box>
        <Box sx={{ paddingLeft: '0.5rem', paddingTop: '1rem' }}>
          <Button
            text={'Accept'}
            btnType={'PRIMARY'}
            onClick={handleSubmit}
            disabled={allPPAccepted}
          />
        </Box>
      </HpCard>
    </Box>
  );
};

export default UserPrivacyAndPolicy;
