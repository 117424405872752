import { Box, styled } from '@mui/material';
import TabContent from '../../atoms/tab/TabContent';
import Title from '../../atoms/title/Title';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import React, { useRef } from 'react';
import { MRT_TableInstance } from 'material-react-table';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';
import { SubFunctionVLModal } from '../../organisms/modals/VariableLibrary/SubFunctionVLModal';
import SubFunctionVLTable from '../../organisms/tables/VariableLibrary/SubFunctionVLTable';
import { useGetSubFunctionListQuery } from '../../store/api/leadership';
import ImportIcon from '../../assets/icons/Import.svg';
import { useAppSelector } from '../../hooks/redux';
import ImportSubFunctionModal from '../../organisms/modals/ImportSubFunctionModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

const SubFunctions = ({ index, value }: ITabContentProps) => {
  const { data: { data } = {}, isLoading } =
    useGetSubFunctionListQuery('Master');

  const isImportModalOpen = useAppSelector(
    (state) => state.importSubFunctionModal.isOpen
  );

  const { openAddSubFunctionVLModal, openImportSubFunctionModal } =
    useActions();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Sub-Functions'}
              sx={{
                fontSize: '28px',
              }}
            />
            <Box>
              <HpTooltip title={' '} />
            </Box>
          </StyledHeaderWrapper>
          <StyledHeaderActionWrapper>
            <Button
              btnType={'PRIMARY'}
              text={'Add new sub function'}
              onClick={() => openAddSubFunctionVLModal()}
            />
            <Button
              btnType={'SECONDARY'}
              text={'Import Subfunctions'}
              onClick={() => openImportSubFunctionModal()}
              startIcon={
                <ButtonIcon
                  src={ImportIcon}
                  alt='Import CSV'
                />
              }
            />
          </StyledHeaderActionWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${data?.length ? data?.length : '0'} total sub-functions`}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <SubFunctionVLTable
        data={data}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
      />
      <SubFunctionVLModal />
      {isImportModalOpen && <ImportSubFunctionModal />}
    </TabContent>
  );
};

export default SubFunctions;
