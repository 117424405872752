import React, { useRef } from 'react';
import UsersHeader from '../../organisms/headers/Company/UsersHeader';
import UsersTable from '../../organisms/tables/Company/UsersTable';
import { MRT_TableInstance } from 'material-react-table';
import { Container } from '@mui/material';
import { useParams } from 'react-router';
import ImportCompanyUserModal from '../../organisms/modals/Company/ImportCompanyUserModal';
import CompanyUsersModal from '../../organisms/modals/Company/CompanyUsersModal';
import { useGetAllLeaderProfilesQuery } from '../../store/api/v2/leaderProfile';
import { ILeaderProfileData } from '../../store/api/types/leaderProfile';

const Users = () => {
  const { id } = useParams();

  const { data: { data: profileData, metadata } = {}, isLoading } =
    useGetAllLeaderProfilesQuery({
      id: id || '',
    });

  const [rowSelection, setRowSelection] = React.useState<
    Array<ILeaderProfileData>
  >([]);

  const selectedUsersEmailList = rowSelection.map((item) => item.email);
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <div>
      <UsersHeader
        qtyOfUsers={metadata?.pagination.totalCount || 0}
        onResetButtonClick={onResetButtonClick}
        tableInstanceRef={tableInstanceRef}
      />
      <Container sx={{ marginBottom: '50px' }}>
        <UsersTable
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
          roleProfileFilterItems={[]}
          userTypeFilterItems={[]}
          data={profileData || []}
          setRecord={setRowSelection}
          totalCount={metadata?.pagination.totalCount || 0}
          selectedUsersEmailList={selectedUsersEmailList}
        />
        <ImportCompanyUserModal clientId={id as string} />
      </Container>
      <CompanyUsersModal
        qtyOfUsers={metadata?.pagination.totalCount || 0}
        selectedUsersEmailList={selectedUsersEmailList}
      />
    </div>
  );
};

export default Users;
