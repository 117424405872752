import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEditRoleSkillData } from '../../commons/types';
interface ILeadershipCapabilityRLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: {
    id: string;
    name: string;
    description: string;
    accelerator: string;
    proficiency: number;
  };
}

const initialState: ILeadershipCapabilityRLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    description: '',
    accelerator: '',
    proficiency: 1,
  },
};

const editStatementRLModalSlice = createSlice({
  name: 'EditStatementRLModal',
  initialState,
  reducers: {
    closeEditStatementRLModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openEditStatementRLModal: (
      state,
      action: PayloadAction<IEditRoleSkillData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        description: action.payload.description || '',
        accelerator: action.payload.accelerator || '',
        proficiency: action.payload.proficiency || 1,
      };
    },
  },
});

export const editStatementRLModalActions = editStatementRLModalSlice.actions;
export const editStatementRLModalReducer = editStatementRLModalSlice.reducer;
