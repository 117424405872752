import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import { routes } from '../../routes/routes';
import PageHeader from '../../atoms/header/PageHeader';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import ImportIcon from '../../assets/icons/Import.svg';

interface IProps {
  qtyOfCompanies?: number;
  onResetButtonClick: () => void;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  justifyContent: 'end',
  alignItems: 'center',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

const RoleLibraryHeader = ({ qtyOfCompanies, onResetButtonClick }: IProps) => {
  const { openAddRoleModal, openDownloadRoleModal, openImportRoleModal } =
    useActions();

  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.roleLibrary.name} />
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${qtyOfCompanies ? qtyOfCompanies : '0'} total roles`}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>

      <StyledHeaderActionWrapper>
        <Button
          btnType={'SECONDARY'}
          text={'Download data'}
          onClick={() => openDownloadRoleModal()}
          startIcon={
            <ButtonIcon
              src={ImportIcon}
              alt='Import CSV'
            />
          }
        />
        <Button
          btnType={'SECONDARY'}
          text={'Import data'}
          onClick={() => openImportRoleModal()}
          startIcon={
            <ButtonIcon
              src={ImportIcon}
              alt='Import CSV'
            />
          }
        />
        <Button
          btnType={'PRIMARY'}
          text={'Add new role'}
          onClick={() => openAddRoleModal()}
        />
      </StyledHeaderActionWrapper>
    </PageHeader>
  );
};

export default RoleLibraryHeader;
