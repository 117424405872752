import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISubFunctionFormData } from '../api/types/leadership';

interface ISubFunctionVLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: ISubFunctionFormData;
}

const initialState: ISubFunctionVLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    fieldValue: '',
    functionId: '',
    code: '',
    id: '',
    functionFieldValue: '',
    systemName: '',
    abbreviation: '',
    subFunctionFamily: '',
  },
};

const subFunctionVLModalSlice = createSlice({
  name: 'subFunctionVLModal',
  initialState,
  reducers: {
    closeSubFunctionVLModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    openAddSubFunctionVLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditSubFunctionVLModal: (
      state,
      action: PayloadAction<ISubFunctionFormData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        fieldValue: action.payload.fieldValue || '',
        functionId: action.payload.functionId || '',
        code: action.payload.code || '',
        id: action.payload.id || '',
        functionFieldValue: action.payload.functionFieldValue || '',
        systemName: action.payload.systemName || '',
        abbreviation: action.payload.abbreviation || '',
        subFunctionFamily: action.payload.subFunctionFamily || '',
      };
    },
  },
});

export const subFunctionVLModalActions = subFunctionVLModalSlice.actions;
export const subFunctionVLModalReducer = subFunctionVLModalSlice.reducer;
