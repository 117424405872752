import { Container, ContainerProps, styled } from '@mui/material';
import React from 'react';

type IProps = {
  children: React.ReactNode;
};

const StyledContainer = styled(Container)(() => ({
  maxWidth: '1920px !important',
  paddingLeft: '1.5rem !important',
  paddingRight: '1.5rem !important',
  margin: 'auto',
  '@media (min-width: 768px)': {
    paddingLeft: '3rem !important',
    paddingRight: '3rem !important',
  },
}));

const HpContainer = ({ children, ...extraProps }: ContainerProps & IProps) => {
  return <StyledContainer {...extraProps}>{children}</StyledContainer>;
};

export default HpContainer;
