import { IApplication } from '../../../commons/Applications';
import { RatersInfo, RatersTypeInfo } from '../types/assessmentStatusDashboard';
import { IClientApplications } from '../types/client';
import { ICurrentPPVersions, IMeData } from './types/auth';
import _ from 'lodash';

const getAcceptedVersion = (appName: string, meData: IMeData) =>
  meData?.privacyPolicies?.find((pp) => pp.appName === appName)?.version;

const getNaviUrl = (url: string, userType: string) => {
  let baseUrl = url;
  if (!baseUrl.includes('//')) {
    baseUrl = `//${baseUrl}`;
  }
  if (baseUrl?.slice(-1) !== '/') {
    baseUrl = `${baseUrl}/`;
  }
  baseUrl = `${baseUrl}careerhub/`;
  if (userType === 'HRBP' || userType === 'CHRO') {
    return `${baseUrl}team-planning/succession`;
  } else if (userType === 'Employee') {
    return `${baseUrl}profile/`;
  } else return baseUrl;
};

export const populatePPState = (
  meData: IMeData,
  ppv: ICurrentPPVersions,
  apps: IApplication[],
  clientApps?: IClientApplications
) => {
  // updates the enable/disable state for apps
  return apps.map((app) => {
    if (app.appCode === 'NAVI') {
      const navigatorVersion = getAcceptedVersion('NAVI', meData);

      const naviConfig = {
        ...app,
        checked: navigatorVersion ? true : false,
        acceptedVersion: navigatorVersion,
        latestVersion: ppv?.NAVI,
        link:
          clientApps?.navigator?.url &&
          getNaviUrl(
            clientApps?.navigator?.url as string,
            meData?.applications?.naviUserType
          ),
      };

      if (
        meData?.applications?.navigatorEnabled &&
        clientApps?.navigator?.isEnabled
      ) {
        return { ...naviConfig, enabled: true };
      }

      return naviConfig;
    } else if (app.appCode === 'INST') {
      const insightsVersion = getAcceptedVersion('INST', meData);

      const instConfig = {
        ...app,
        checked: insightsVersion ? true : false,
        acceptedVersion: insightsVersion,
        latestVersion: ppv?.INST,
        link: clientApps?.insights?.url,
      };

      if (
        meData?.applications?.insightsEnabled &&
        clientApps?.insights?.isEnabled
      ) {
        return { ...instConfig, enabled: true };
      }

      return instConfig;
    } else if (app.appCode === 'JRNY') {
      const journeyVersion = getAcceptedVersion('JRNY', meData);

      const jrnyConfig = {
        ...app,
        checked: journeyVersion ? true : false,
        acceptedVersion: journeyVersion,
        latestVersion: ppv?.JRNY,
      };

      if (
        meData?.applications?.journeyEnabled &&
        clientApps?.journey?.isEnabled
      ) {
        return { ...jrnyConfig, enabled: true };
      }

      return jrnyConfig;
    } else if (app.appCode === 'SITE') {
      const siteVersion = getAcceptedVersion('SITE', meData);

      return {
        ...app,
        enabled: true,
        checked: siteVersion ? true : false,
        acceptedVersion: siteVersion,
        latestVersion: ppv?.SITE,
      };
    }
    return app;
  });
};

export const parsePPState = (meData: IMeData, apps: IApplication[]) => {
  const privacyPolicies = apps
    ?.map(
      (app) =>
        app.enabled &&
        app.checked && {
          appName: app.appCode,
          version: app.latestVersion,
          acknowledgeTs: new Date().toISOString().split('Z')[0],
        }
    )
    .filter((pp) => pp);
  return { ...meData, privacyPolicies };
};

export const getThreshold = (
  ratertype: string,
  raterTypes: RatersTypeInfo[] | undefined
) => {
  const thresholdValue = raterTypes?.filter(
    (item: any) => item['Rater Type'] === ratertype
  );
  return thresholdValue && thresholdValue[0]['Required Raters'];
};

export const getQuantity = (ratertype: string, raters: RatersInfo[]) => {
  const quantity =
    raters?.reduce((acc, el) => {
      if (
        el['Rater Type'] === ratertype &&
        el['Rater Status'] === 'Completed'
      ) {
        return acc + 1;
      }
      return acc;
    }, 0) || 0;
  return quantity;
};

// APS-2281 - function with logic generate status values
export const getAssessmentStatus = (row: any) => {
  if (!row.original.pAssessor || !row.original.cAssessor) {
    return 'Needs Assessor';
  }
  if (
    row.original.pAssessor &&
    row.original.cAssessor &&
    !row.original.resultStatus
  ) {
    return 'Ready for interview | Open Script';
  }
  if (
    row.original.projectAssessmentStatus === 'Assigned' &&
    row.original.cbiStatus === 'Needs assessor'
  ) {
    return 'Needs Assessor';
  } else if (
    row.original.projectAssessmentStatus === 'Assigned' &&
    row.original.cbiStatus === 'Ready for interview'
  ) {
    return 'Ready for interview | Open Script';
  } else if (
    row.original.projectAssessmentStatus === 'In Progress' &&
    row.original.cbiStatus === 'Interview in progress'
  ) {
    return 'Interview in progress | Complete script';
  } else if (
    row.original.projectAssessmentStatus === 'In Progress' &&
    row.original.cbiStatus === 'Interview complete'
  ) {
    return 'Interview complete | Submit CBI scores';
  } else if (
    row.original.projectAssessmentStatus === 'In Progress' &&
    row.original.cbiStatus === 'CBI scores submitted'
  ) {
    return 'CBI scores submitted | Submit final scores';
  } else if (
    row.original.projectAssessmentStatus === 'In Progress' &&
    row.original.cbiStatus === 'Submitted'
  ) {
    return 'Submitted | Update Scores | Download Report';
  } else if (
    row.original.projectAssessmentStatus === 'Completed' &&
    row.original.cbiStatus === 'Submitted'
  ) {
    return 'Submitted | Update Scores | Download Report';
  } else {
    return '-';
  }
};

export const statusConvention: Record<string, string> = {
  need_assessor: 'Needs assessor',
  ready_for_interview: 'Ready for interview',
  interview_inprogress: 'Interview in progress',
  interview_completed: 'Interview complete',
  cbi_submitted: 'CBI scores submitted',
  cohort_ready: 'Submitted',
  cohort_in_review: 'Submitted',
  final_summary_submitted: 'Submitted',
};

// copying these function here to avoid conflicts
export const getRaterThreshold = (
  ratertype: string,
  raterTypes: RatersTypeInfo[] | undefined
) => {
  const thresholdValue = raterTypes?.filter(
    (item: any) => item['type'] === ratertype
  );
  return thresholdValue && thresholdValue[0]['threshold'];
};

export const getRaterQuantity = (ratertype: string, raters: RatersInfo[]) => {
  const quantity =
    raters?.reduce((acc, el) => {
      if (el['type'] === ratertype && el['status'] === 'Completed') {
        return acc + 1;
      }
      return acc;
    }, 0) || 0;
  return quantity;
};
