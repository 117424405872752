import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled, FormHelperText } from '@mui/material';
import HpTooltip from '../tooltip/HpTooltip';
import AlertIcon from '../../assets/icons/Alert.svg';

type IProps = TextFieldProps & {
  error?: boolean;
  tooltip?: string;
};

const StyledTextFieldLabel = styled('span')<IProps>(({ error }) => ({
  fontFamily: 'Avenir',
  color: error ? '#C02195' : '#646a7c',
  marginBottom: '6px',
  display: 'flex',
}));

const StyledTextField = styled(TextField)(() => ({
  '& .Mui-error': {
    color: '#C02195',
    marginTop: '8px',
    display: 'none',
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C02195',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#C02195',
  },
  '& .MuiFormHelperText-root': {
    display: 'none',
  },
  '& .MuiInputBase-root': {
    color: '#002136',
    background: 'rgb(255, 255, 255)',
    borderRadius: '12px',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    height: '48px',
  },
  '& .MuiInputBase-input': {
    padding: '11.5px 14px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    background: '#F1F1F1',
    borderColor: '#646A7C',
    pointerEvents: 'none',
  },
  '& textarea': {
    padding: '0px !important',
    height: '24px !important',
  },
  '& .MuiInputBase-readOnly': {
    backgroundColor: '#eee',
    borderRadius: '6px',
  },
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  color: '#C02195 !important',
  marginTop: '8px',
}));
const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginTop: '4px',
  height: '12px',
  marginRight: '2px',
}));

const LabelInput = ({ error, label, tooltip, ...extraProps }: IProps) => {
  return (
    <div>
      <StyledTextFieldLabel error={error}>
        {label}
        {tooltip && <HpTooltip title={tooltip} />}
      </StyledTextFieldLabel>
      <StyledTextField
        fullWidth
        size='small'
        variant='outlined'
        {...extraProps}
      />
      {/* {added error with helper text to add error icon as per figma} */}
      <StyledFormHelperText>
        {extraProps.helperText && <StyledImg src={AlertIcon} />}
        {extraProps.helperText}
      </StyledFormHelperText>
    </div>
  );
};

export default LabelInput;
