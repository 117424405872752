import React, { useMemo } from 'react';
import Table from '../../atoms/table/Table';
import { IconButton, Box, styled } from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MRT_Row,
} from 'material-react-table';
import { useActions } from '../../hooks/actions';
import InputSlider from '../../atoms/slider/InputSlider';
import TooltipBenchmarkData from '../../atoms/tooltip/TooltipBenchmarkData';
import Button from '../../atoms/button/Button';
import { IRoleDetailsBenchmarkState } from '../../store/api/types/roleskills';
import HpTableTooltip from '../../atoms/tooltip/HpTableTooltip';
import { IRoleSkillData } from '../../store/api/types/leadership';
import {
  useEditSkillByIdMutation,
  useDeleteRoleSkillByIdMutation,
} from '../../store/api/leadership';
import TrashIcon from '../../assets/icons/trash.svg';

interface IProps {
  statementType:
    | 'functionalImpact'
    | 'organizationalImpact'
    | 'leadershipCapability'
    | 'leadershipPotential'
    | 'cultureImpact';
  data?: IRoleSkillData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const StyledBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  margin: '15px',
  gap: '15px',
  width: '98%',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  gap: '15px',
  width: '100%',
  flexDirection: 'row-reverse',
}));

const RoleTable = ({
  data,
  isLoading,
  statementType,
  tableInstanceRef,
}: IProps) => {
  const [benchmarkData, setBenchmarkData] = React.useState<
    Array<IRoleDetailsBenchmarkState | null>
  >([]);

  const [deleteStatement] = useDeleteRoleSkillByIdMutation();
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const [setRoleSkills] = useEditSkillByIdMutation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDelete = (statementID: string) => {
    deleteStatement(statementID)
      .unwrap()
      .then(() =>
        toggleSuccessSnackbar({ message: 'Statement Deleted Successfully' })
      )
      .catch(() => toggleErrorSnackbar({}));
    setAnchorEl(null);
  };

  const cellCallback = (cellData: string, rowData: MRT_Row<IRoleSkillData>) => {
    // Create an object to get the row data which slider is updated
    const tempBenchmarkData = {
      value: cellData,
      statementId: rowData.original.id as string,
    };
    // Filter out the slider value which was already changed and add new one in state
    setBenchmarkData((state) =>
      state.filter((item) => item?.statementId !== rowData.original.id)
    );

    // Add newly added slider value to state
    setBenchmarkData((value) => [...value, tempBenchmarkData]);
  };

  // Function to update benchmark value against a statement
  const updateBenchmark = () => {
    // Iterate Over benchmarkData state to update one or more benchmark scores
    benchmarkData.map((item) => {
      setRoleSkills({
        id: item?.statementId as string,
        benchmark: Number(item?.value) || 0,
      })
        .unwrap()
        .then(() => {
          toggleSuccessSnackbar({ message: 'Benchmark Updated Successfully' });
          setBenchmarkData([]);
        })
        .catch(() => toggleErrorSnackbar({}));
    });
  };

  const columns = useMemo<MRT_ColumnDef<IRoleSkillData>[]>(
    () => [
      {
        header: 'Impact Statement',
        accessorKey: 'name',
        filterFn: 'includesString',
        size: 231, // added this width as per figma design
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
        size: 368, // added this width as per figma design
      },
      {
        header: 'Benchmark',
        accessorKey: 'benchmark',
        Header: ({ column }) => (
          <>
            <span>{column.columnDef.header}</span>
            <HpTableTooltip
              title={<TooltipBenchmarkData />}
              placement={'bottom-end'}
            />
          </>
        ),
        muiTableHeadCellProps: {
          sx: {
            '& .Mui-TableHeadCell-Content-Labels': {
              display: 'flex',
              alignItems: 'flex-start',
              fontSize: '16px',
            },
            '& .Mui-TableHeadCell-Content-Wrapper': {
              display: 'flex',
              alignItems: 'flex-start',
            },
          },
        },
        Cell: ({ cell, row }) => {
          const defaultCellValue = Number(cell.getValue());
          return (
            <InputSlider
              value={defaultCellValue}
              callback={(cellValue) => cellCallback(cellValue, row)}
            />
          );
        },
        filterFn: 'equals',
        size: 467, // added this width as per figma design
      },
    ],
    []
  );

  return (
    <>
      <StyledBoxWrapper>
        <StyledButtonWrapper>
          <Button
            btnType={'PRIMARY'}
            text={'Save Changes'}
            disabled={!benchmarkData.length}
            onClick={() => updateBenchmark()}
          />
        </StyledButtonWrapper>
      </StyledBoxWrapper>
      <Table
        data={data ?? []}
        tableInstanceRef={tableInstanceRef}
        columns={columns as MRT_ColumnDef<object>[]}
        renderRowActions={({ cell, row }) => (
          <>
            <IconButton
              id={row.id}
              onClick={() => handleDelete((row.original as IRoleSkillData).id)}
            >
              <img src={TrashIcon} />
            </IconButton>
          </>
        )}
        state={{ showSkeletons: isLoading }}
      />
    </>
  );
};

export default RoleTable;
