import pp_app_navigator from '../assets/pdf/Heidrick_Navigator_-_Privacy_Policy.pdf';
import pp_app_insights from '../assets/pdf/Heidrick_Insights_-_Privacy_Policy_(24_May_2022).pdf';
import pp_app_journey from '../assets/pdf/Heidrick_Journey_-_Privacy_Notice_(RS_29_April_2022).pdf';
import pp_global_privacy_notice from '../assets/pdf/Heidrick_Global_Privacy_Notice.pdf';
import pp_heidrick_term_of_use from '../assets/pdf/Heidrick_Digital_-_Terms_of_Use_28_Apr_2022.pdf';

import CultureLogo from '../assets/img/apps/CultureLogo.png';
import CultureImage from '../assets/img/apps/CultureImage.png';
import InsightsLogo from '../assets/img/apps/InsightsLogo.svg';
import InsightsImage from '../assets/img/apps/InsightsImage.jpg';
import NavigatorLogo from '../assets/img/apps/NavigatorLogo.png';
import NavigatorImage from '../assets/img/apps/NavigatorImage.jpg';

export interface IApplication {
  app: string;
  appCode: string;
  picture?: JSX.Element;
  logo?: JSX.Element;
  text?: string;
  link?: string;
  privacyPolicy: string;
  privacyStatement: string | JSX.Element;
  terms?: string;
  termsStatement?: string;
  enabled?: boolean;
  checked?: boolean;
  acceptedVersion?: string;
  latestVersion?: string;
}

const Applications: IApplication[] = [
  {
    app: 'Site',
    appCode: 'SITE',
    link: '#',
    privacyPolicy: pp_global_privacy_notice,
    privacyStatement: (
      <>
        By ticking this box, you are confirming that you: <br /> consent to
        Heidrick & Struggles using your personal information in accordance with
        the
      </>
    ),
    terms: pp_heidrick_term_of_use,
    termsStatement: 'and agree to the',
    enabled: false,
    checked: false,
    acceptedVersion: undefined,
    latestVersion: undefined,
  },
  {
    app: 'Navigator',
    appCode: 'NAVI',
    picture: <img src={NavigatorImage} />,
    logo: <img src={NavigatorLogo} />,
    text: 'Comprehensive, real-time leadership planning.',
    link: 'https://heidrick.eightfold.ai/',
    privacyPolicy: pp_app_navigator,
    privacyStatement:
      'I have read and understood the Heidrick Navigator Privacy Notice',
    enabled: false,
    checked: false,
    acceptedVersion: undefined,
    latestVersion: undefined,
  },
  {
    app: 'Insights',
    appCode: 'INST',
    picture: <img src={InsightsImage} />,
    logo: <img src={InsightsLogo} />,
    text: 'Complete leadership assessment solution.',
    link: '#',
    privacyPolicy: pp_app_insights,
    privacyStatement:
      'I have read and understood the Heidrick Insights Privacy Notice',
    enabled: false,
    checked: false,
    acceptedVersion: undefined,
    latestVersion: undefined,
  },
  {
    app: 'Culture Connect',
    appCode: 'JRNY',
    picture: <img src={CultureImage} />,
    logo: <img src={CultureLogo} />,
    text: 'Building and reinforcing corporate culture.',
    link: 'https://journey.heidrick.com',
    privacyPolicy: pp_app_journey,
    privacyStatement:
      'I have read and understood the Heidrick Culture Connect Privacy Notice',
    enabled: false,
    checked: false,
    acceptedVersion: undefined,
    latestVersion: undefined,
  },
];

export default Applications;
