import { Box, styled } from '@mui/material';
import TabContent from '../../../atoms/tab/TabContent';
import Title from '../../../atoms/title/Title';
import HpTooltip from '../../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../../commons/types';
import React, { useMemo, useRef } from 'react';
import { MRT_TableInstance } from 'material-react-table';
import SubTitle from '../../../atoms/title/SubTitle';
import ResetButton from '../../../atoms/button/ResetButton';
import Button from '../../../atoms/button/Button';
import { useActions } from '../../../hooks/actions';
import { useGetSubFunctionListQuery } from '../../../store/api/leadership';
import { useParams } from 'react-router-dom';
import SubFunctionsVLClientTable from '../../../organisms/tables/Company/VariableLibrary/SubFunctionsVLClientTable';
import { SubFunctionVLClientModal } from '../../../organisms/modals/Company/VariableLibrary/SubFunctionVLClientModal';
import ImportIcon from '../../../assets/icons/Import.svg';
import { useAppSelector } from '../../../hooks/redux';
import ImportSubFunctionModal from '../../../organisms/modals/ImportSubFunctionModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

const ClientSubFunctions = ({ index, value }: ITabContentProps) => {
  const { id } = useParams();

  const isImportModalOpen = useAppSelector(
    (state) => state.importSubFunctionModal.isOpen
  );

  const { data: { data: SubFunctionMasterData } = {}, isLoading } =
    useGetSubFunctionListQuery('Master');

  const { data: { data: SubFunctionIdData } = {} } = useGetSubFunctionListQuery(
    id as string
  );

  const { openAddSubFunctionVLClientModal, openImportSubFunctionModal } =
    useActions();

  // Here we are generating table data mapping thorugh code fieldValue
  const updatedData = useMemo(() => {
    if (!SubFunctionMasterData || !SubFunctionIdData) return [];

    // Create a map of fieldValue to systemName from SubFunctionMasterData
    const systemNameMap = new Map(
      SubFunctionMasterData.map((item) => [item.code, item.fieldValue])
    );

    const merged = SubFunctionIdData.map((item) => {
      const systemName = systemNameMap.get(item.code) || '-';
      return {
        ...item,
        systemName: systemName,
      };
    });

    return merged;
  }, [SubFunctionMasterData, SubFunctionIdData]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  return (
    <>
      <SubFunctionVLClientModal />
      <TabContent
        index={index}
        value={value}
      >
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <StyledHeaderWrapper>
              <Title
                text={'Sub-Functions'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <Box>
                <HpTooltip title={' '} />
              </Box>
            </StyledHeaderWrapper>
            <StyledHeaderActionWrapper>
              <Button
                btnType={'PRIMARY'}
                text={'Add new sub function'}
                onClick={() => openAddSubFunctionVLClientModal()}
              />
              <Button
                btnType={'SECONDARY'}
                text={'Import Subfunctions'}
                onClick={() => openImportSubFunctionModal()}
                startIcon={
                  <ButtonIcon
                    src={ImportIcon}
                    alt='Import CSV'
                  />
                }
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle text={`${updatedData.length} total sub-functions`} />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <SubFunctionsVLClientTable
          data={updatedData}
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
        />
      </TabContent>
      {isImportModalOpen && <ImportSubFunctionModal />}
    </>
  );
};

export default ClientSubFunctions;
