import React from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../../molecules/form/FormWrapper';
import Button from '../../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../../hooks/redux';
import { useActions } from '../../../../hooks/actions';
import { IImpactModuleFormData } from '../../../../store/api/types/leadership';
import {
  useEditImpactModuleMutation,
  useGetImpactModuleQuery,
  useSetImpactModuleMutation,
} from '../../../../store/api/leadership';
import { useParams } from 'react-router';
import styled from '@emotion/styled';

const StyledLabel = styled('span')({
  font: 'Avenir',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#646a7c',
});

const BoldStyledLabel = styled(StyledLabel)({
  fontWeight: 800,
});

export const ImpactModuleVLClientModal = () => {
  const [impactModuleId, setImpactModuleId] = React.useState<string>('');
  const [impactModuleSysetmName, setImpactModuleSysetmName] =
    React.useState<string>('');
  const [abbreviationName, setabbreviationName] = React.useState<string>('');
  const { id } = useParams<{ id?: string }>();

  const { data: { data: impactModuleList } = {} } = useGetImpactModuleQuery(
    id as string
  );

  const [addImpactModule] = useSetImpactModuleMutation();
  const [editImpactModule] = useEditImpactModuleMutation();

  const initialValues = useAppSelector(
    (state) => state.impactModuleVLClientModal.initialValues
  );
  const isOpen = useAppSelector(
    (state) => state.impactModuleVLClientModal.isOpen
  );
  const modalType = useAppSelector(
    (state) => state.impactModuleVLClientModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeImpactModuleVLClientModal,
  } = useActions();

  const methods = useForm<IImpactModuleFormData>();
  const {
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty },
  } = methods;

  const handleClose = () => {
    closeImpactModuleVLClientModal();
  };

  React.useEffect(() => {
    reset(initialValues);
    if (modalType === 'edit') {
      setImpactModuleId(initialValues?.code as string);
      setImpactModuleSysetmName(initialValues?.systemName as string);
      setabbreviationName(initialValues?.abbreviation as string);
    }
  }, [isOpen, reset, initialValues]);

  const onSubmit = async (formData: IImpactModuleFormData) => {
    const modalTypeMap = {
      add: {
        fn: addImpactModule,
        messageSuccess: 'Impact Module Added Successfully',
        errorMessage: 'Error Adding Impact Module',
      },
      edit: {
        fn: editImpactModule,
        messageSuccess: 'Impact Module Updated Successfully',
        errorMessage: 'Error Updating Impact Module',
      },
    };

    const payload: any = {
      fieldValue: formData.fieldValue,
      abbreviation: formData.abbreviation,
      ...(modalType === 'edit' && { id: formData.id }),
      ...(modalType === 'add' && { clientId: id }),
    };

    if (
      modalType === 'add' &&
      impactModuleList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'Impact Module name should be unique',
      });

    if (
      modalType === 'edit' &&
      formData.fieldValue.toLowerCase() !==
        initialValues.fieldValue.toLowerCase() &&
      impactModuleList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'Impact Module name should be unique',
      });

    modalTypeMap[modalType]
      .fn(payload)
      .unwrap()
      .then((response) => {
        if (response.status === 'success') {
          toggleSuccessSnackbar({
            message: modalTypeMap[modalType].messageSuccess,
          });
          handleClose();
        }
      })
      .catch((error) => {
        if (error.data && error.data.detail) {
          toggleErrorSnackbar({ message: error.data.detail });
        } else {
          toggleErrorSnackbar({
            message: modalTypeMap[modalType].errorMessage,
          });
        }
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add new impact module' : 'Edit impact module'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='impactmodule-modal'
          onSubmit={handleSubmit(onSubmit)}
        >
          {modalType === 'add' && (
            <>
              <FormLabelInput
                name='fieldValue'
                rules={{ required: 'Please provide impact module name' }}
                label='Impact Module Name'
              />
              <FormLabelInput
                name='abbreviation'
                label='Impact Module Abbreviation (optional)'
              />
            </>
          )}
          {modalType === 'edit' && (
            <>
              <FormLabelInput
                name='fieldValue'
                rules={{ required: 'Please provide impact module name' }}
                label='Impact Module Name'
              />
              <StyledLabel>
                System Name:{' '}
                <BoldStyledLabel>
                  {impactModuleSysetmName || '-'}
                </BoldStyledLabel>
              </StyledLabel>
              <StyledLabel>
                impact Module ID:{' '}
                <BoldStyledLabel>{impactModuleId}</BoldStyledLabel>
              </StyledLabel>
              <StyledLabel>
                Impact Module Abbreviation:{' '}
                <BoldStyledLabel>{abbreviationName || '-'}</BoldStyledLabel>
              </StyledLabel>
            </>
          )}
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='impactmodule-modal'
          disabled={!isDirty || !!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};
