import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IAddLeaderProfileResponse,
  ICreateLeaderProfileUserRequest,
  ILeaderProfileResponse,
  ILeaderProfilesResponse,
} from '../types/leaderProfile';
import { IExcelResponse } from '../types/leadership';
import { downloadFile, saveFileNameAsCSV } from '../helpers';
import { COMPANY_USERS } from '../constants';
import {
  sendLeadersToTazioRequest,
  sendLeadersToTazioResponse,
} from '../types/tazio';

export const leaderProfileApi = createApi({
  reducerPath: 'leaderProfile/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}profile/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['leaderProfile', 'leaderProfiles', 'impactGroupLeaders'],
  endpoints: (builder) => ({
    getLeaderProfileById: builder.query<ILeaderProfileResponse, string>({
      query: (id) => `leader-profile/${id}`,
      providesTags: ['leaderProfile'],
    }),
    getLeaderProfileByEmail: builder.query<ILeaderProfileResponse, string>({
      query: (email) => `leader-profile?email=${email}`,
      providesTags: ['leaderProfile'],
    }),
    getAllLeaderProfiles: builder.query<
      ILeaderProfilesResponse,
      { id: string; limit?: number; offset?: number }
    >({
      query: ({ id }) => `leader-profile?clientId=${id}`,
      providesTags: ['leaderProfiles'],
    }),
    createLeaderProfile: builder.mutation<
      IAddLeaderProfileResponse,
      ICreateLeaderProfileUserRequest
    >({
      query: (payload) => ({
        url: 'leader-profile',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['leaderProfiles'],
    }),
    updateLeaderProfile: builder.mutation<
      ILeaderProfileResponse,
      { id: string; payload: ICreateLeaderProfileUserRequest }
    >({
      query: ({ id, payload }) => ({
        url: `leader-profile/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['leaderProfiles'],
    }),
    deleteLeaderProfile: builder.mutation<void, string>({
      query: (id) => ({
        url: `leader-profile/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['leaderProfiles'],
    }),
    getLeaderImpactGroupLeaders: builder.query<
      ILeaderProfilesResponse,
      { clientId: string; impactGroupId: string; limit: number; offset: number }
    >({
      query: ({ clientId, impactGroupId, limit, offset }) =>
        `impact-group-assigned?clientId=${clientId}&impactGroupAssigned=${impactGroupId}&limit=${limit}&offset=${offset}`,
      providesTags: ['impactGroupLeaders'],
    }),

    // Download Report
    downloadCompanyUsersEmployee: builder.query<IExcelResponse, any>({
      query: ({ id }) => {
        return {
          url: `leader/export?clientId=${id}`,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsCSV(
                  COMPANY_USERS.Heidrick_ATSNAME_EMPLOYEE_IMPORT
                )
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),

    downloadCompanyNavigatorAccess: builder.query<IExcelResponse, any>({
      query: ({ id }) => {
        return {
          url: `navigator-access-export/${id}`,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsCSV(COMPANY_USERS.EF_STANDARD_Users)
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),

    // Send Leaders to Taio
    sendLeadersToTazio: builder.mutation<
      sendLeadersToTazioResponse,
      sendLeadersToTazioRequest
    >({
      query: (payload) => ({
        url: 'submit-assessment-candidate',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const leaderProfileApiInvalidateTags =
  leaderProfileApi.util.invalidateTags;

export const {
  useLazyGetLeaderProfileByIdQuery,
  useLazyGetLeaderProfileByEmailQuery,
  useGetAllLeaderProfilesQuery,
  useCreateLeaderProfileMutation,
  useUpdateLeaderProfileMutation,
  useDeleteLeaderProfileMutation,
  useLazyGetLeaderImpactGroupLeadersQuery,
  useLazyDownloadCompanyUsersEmployeeQuery,
  useLazyDownloadCompanyNavigatorAccessQuery,
} = leaderProfileApi;

// Send Leaders to Tazio
export const { useSendLeadersToTazioMutation } = leaderProfileApi;
