import { Navigate, Route } from 'react-router-dom';
import { routes } from './routes';
import ProtectRoute from './ProtectRoute';
import Companies from '../pages/Companies';
import RoleLibrary from '../pages/RoleLibrary';
import RoleView from '../pages/Role/RoleView';
import StatementLibrary from '../pages/StatementLibrary';
import StatementDetails from '../pages/Statement/StatementDetails';
import Admins from '../pages/Admins';
import VariableLibrary from '../pages/VariableLibrary/VariableLibrary';
import CompanyOutlet from '../pages/Company/CompanyOutlet';
import Settings from '../pages/Company/Settings';
import Users from '../pages/Company/Users';
import AddNewUser from '../pages/Company/AddNewUser';
import Roles from '../pages/Company/Roles/Roles';
import RoleDetails from '../pages/Company/Roles/RoleDetails/RoleDetails';
import Statements from '../pages/Company/Statements';
import ClientStatementDetails from '../pages/Company/Statements/ClientStatementDetails';
import ClientVariableLibrary from '../pages/Company/VariableLibrary/ClientVariableLibrary';
import AssessmentValidation from '../pages/Company/Assessments/AssessmentValidation';
import ImpactGroups from '../pages/Company/ImpactGroups/ImpactGroups';
import ImpactGroupsDetail from '../pages/Company/ImpactGroups/ImpactGroupDetail';
import ClientAssessment from '../pages/Company/Assessments/ClientAssessments/ClientAssessment';
import { Restricted } from '../rbac/Restricted';
import ClientProjects from '../pages/Company/Projects/ClientProjects';
import AddNewClientProject from '../pages/Company/Projects/AddNewClientProject';
import ClientProjectDetails from '../pages/Company/Projects/ProjectDetails/ClientProjectDetails';
import ProjectAssessmentsDetails from '../pages/Company/Projects/ProjectDetails/ProjectAssessmentsDetails';
import ComponentTest from '../pages/ComponentTest';
import Assessment from '../pages/Assessment';
import Reports from '../pages/Company/Reports/Reports';

export const GlobalRoutes = () => (
  <>
    <Route
      index
      element={<Navigate to={routes.companies.url} />}
    />
    <Route
      path={routes.companies.url}
      element={<Companies />}
    />
    <Route
      path={routes.roleLibrary.url}
      element={
        <ProtectRoute>
          <RoleLibrary />
        </ProtectRoute>
      }
    />
    <Route
      path={`${routes.roleLibrary.url}/:id`}
      element={
        <ProtectRoute>
          <RoleView />
        </ProtectRoute>
      }
    />
    <Route
      path={routes.statementLibrary.url}
      element={
        <ProtectRoute>
          <StatementLibrary />
        </ProtectRoute>
      }
    />
    <Route
      path={`${routes.statementLibrary.url}/:id${routes.statementLibrary.details.url}`}
      element={
        <ProtectRoute>
          <StatementDetails />
        </ProtectRoute>
      }
    />
    <Route
      path={routes.admins.url}
      element={
        <ProtectRoute>
          <Admins />
        </ProtectRoute>
      }
    />
    <Route
      path={routes.variableLibrary.url}
      element={
        <ProtectRoute>
          <VariableLibrary />
        </ProtectRoute>
      }
    />
    <Route element={<CompanyOutlet />}>
      <Route
        path={`${routes.company.url}:id${routes.company.settings.url}`}
        element={
          <ProtectRoute>
            <Settings />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.reports.url}`}
        element={
          <ProtectRoute>
            <Reports />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.users.url}`}
        element={
          <ProtectRoute>
            <Users />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id/user/edit/:userid`}
        element={
          <ProtectRoute>
            <AddNewUser />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.users.url}${routes.company.users.add.url}`}
        element={
          <ProtectRoute>
            <AddNewUser />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.roles.url}`}
        element={
          <ProtectRoute>
            <Roles />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.roles.url}/:roleid`}
        element={
          <ProtectRoute>
            <RoleDetails />
          </ProtectRoute>
        }
      />

      <Route
        path={`${routes.company.url}:id${routes.company.statements.url}`}
        element={
          <ProtectRoute>
            <Statements />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.statements.url}/:statementId`}
        element={
          <ProtectRoute>
            <ClientStatementDetails />
          </ProtectRoute>
        }
      />
      {/* Added this condition to block Variable Library screen routes */}
      <Route
        path={`${routes.company.url}:id${routes.company.variableLibrary.url}`}
        element={
          <ProtectRoute>
            <ClientVariableLibrary />
          </ProtectRoute>
        }
      />
      {/* Company assessment page not added under Protected route */}
      <Route
        path={`${routes.company.url}:id${routes.company.assessmentstatus.url}`}
        element={<AssessmentValidation />}
      />
      <Route
        path={`${routes.company.url}:id${routes.company.impactgroups.url}`}
        element={
          <ProtectRoute>
            <ImpactGroups />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.impactgroups.url}:groupId`}
        element={
          <ProtectRoute>
            <ImpactGroupsDetail />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.newassessment.url}`}
        element={
          <ProtectRoute>
            <ClientAssessment />
          </ProtectRoute>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.projects.url}`}
        element={
          <Restricted allowedTo={'project.page'}>
            <ClientProjects />
          </Restricted>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.projects.url}${routes.company.projects.add.url}`}
        element={
          <Restricted allowedTo={'project.project_list.add'}>
            <AddNewClientProject />
          </Restricted>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.projects.url}/:projectId${routes.company.projects.edit.url}`}
        element={
          <Restricted allowedTo={'project.project_list.edit'}>
            <AddNewClientProject />
          </Restricted>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.projects.url}/:projectId`}
        element={
          <Restricted allowedTo={'project.page'}>
            <ClientProjectDetails />
          </Restricted>
        }
      />
      <Route
        path={`${routes.company.url}:id${routes.company.projects.url}/:projectId/assessments/:assessmentsId`}
        element={
          <Restricted allowedTo={'project.page'}>
            <ProjectAssessmentsDetails />
          </Restricted>
        }
      />
    </Route>
    <Route
      path={routes.test.url}
      element={
        <ProtectRoute>
          <ComponentTest />
        </ProtectRoute>
      }
    />
    <Route
      path={routes.assessment.url}
      element={
        <ProtectRoute>
          <Assessment />
        </ProtectRoute>
      }
    />
  </>
);
