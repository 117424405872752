import React from 'react';
import HpCardHeader from '../../atoms/card/HpCardHeader';
import { Container } from '@mui/material';
import HpCard from '../../atoms/card/HpCard';
import HpCardContent from '../../atoms/card/HpCardContent';
import Button from '../../atoms/button/Button';
import { useOktaAuth } from '@okta/okta-react';

const ProvisioningError = () => {
  const { oktaAuth } = useOktaAuth();

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HpCard>
        <HpCardHeader title='Something went wrong during auto provisioning' />
        <HpCardContent>
          <Button
            text={'Log Out'}
            onClick={() => oktaAuth.signOut()}
            btnType={'PRIMARY'}
          />
        </HpCardContent>
      </HpCard>
    </Container>
  );
};

export default ProvisioningError;
