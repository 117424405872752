import React from 'react';
import { Box } from '@mui/system';
import { MenuItem, MenuList, styled } from '@mui/material';
import logo from './hns-word-logo.png';

export interface HDFooterTypes {
  footerItems?: Array<{ url: string; itemName: string }>;
  logoClick: () => void;
  onNavigationClick: (arg: string) => void;
  contactClick: () => void;
}

const StyledBoxMainWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: #334255;
  height: 66px;
  width: 100%;
`;

const StyledBoxWrapper = styled(Box)`
  max-width: 1920px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledBoxMenuWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

const StyledBoxMenuListWrapper = styled(Box)`
  display: flex;
`;

const StyledMenuList = styled(MenuList)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledMenuItemBar = styled(MenuItem)`
  border-left: 1px solid;
  height: 14px;
  color: #ffffff;
  cursor: default;
  pointer-events: none;
  background: #334255;
`;

const StyledMenuItem = styled(MenuItem)`
  color: #cccccc;
  font-family: Avenir, sans-serif;
  margin-left: 0;
  right: 20px;
  font-weight: 300;
  font-size: 16px;
  background: #334255;
`;

const StyledImage = styled('img')`
  margin-top: 26px;
  margin-right: 20px;
  cursor: pointer;
`;

const StyledMenuWrap = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyleContact = styled(Box)`
  height: 14px;
  margin-top: 18px;
  color: #ffffff;
  cursor: pointer;
  background: #334255;
`;

function HDFooter(props: HDFooterTypes) {
  const onFooterNavClick = (url: string) => {
    props.onNavigationClick(url);
  };

  return (
    <StyledBoxMainWrapper>
      <StyledBoxWrapper>
        <StyledBoxMenuWrapper>
          <Box>
            <a
              onClick={() => props.logoClick()}
              target='_blank'
              rel='noreferrer'
            >
              <StyledImage
                src={logo}
                alt=''
              />
            </a>
          </Box>
          <StyledBoxMenuListWrapper>
            <StyledMenuList>
              {props.footerItems?.map((element: any, index: number) => {
                return (
                  <StyledMenuWrap key={index}>
                    <StyledMenuItemBar></StyledMenuItemBar>
                    <StyledMenuItem
                      onClick={() => onFooterNavClick(element.url)}
                    >
                      {element.itemName}
                    </StyledMenuItem>
                  </StyledMenuWrap>
                );
              })}
            </StyledMenuList>
          </StyledBoxMenuListWrapper>
        </StyledBoxMenuWrapper>
      </StyledBoxWrapper>
      <StyleContact>
        <a
          onClick={() => props.contactClick()}
          target='_blank'
          rel='noreferrer'
        >
          Contact
        </a>
      </StyleContact>
    </StyledBoxMainWrapper>
  );
}

export default HDFooter;
