import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader';
import { styled } from '@mui/material';

const StyledCardHeader = styled(CardHeader)(() => ({
  borderBottom: '1px solid #646a7c33',
  padding: '0px 0px 10px 0px',
  '& .MuiCardHeader-title': {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
  },
}));

const HpCardHeader = ({ ...extraProps }: CardHeaderProps) => {
  return <StyledCardHeader {...extraProps} />;
};

export default HpCardHeader;
