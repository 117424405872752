import React from 'react';
import { Box, styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import Title from '../../../../atoms/title/Title';
import { routes } from '../../../../routes/routes';
import PageHeader from '../../../../atoms/header/PageHeader';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CompanyImpactGroupModal from '../../../modals/Company/CompanyImpactGroupModal';
import { useActions } from '../../../../hooks/actions';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
});

const ImpactGroupsHeader = () => {
  const { openCompanyImpactGroupModal } = useActions();

  return (
    <>
      <PageHeader type='full'>
        <StyledTitlesWrapper>
          <Title text={routes.company.impactgroups.name} />
        </StyledTitlesWrapper>
        <StyledHeaderActionWrapper>
          <Button
            btnType={'PRIMARY'}
            text={'Add new Impact Group'}
            onClick={() => openCompanyImpactGroupModal()}
          />
        </StyledHeaderActionWrapper>
      </PageHeader>
      <CompanyImpactGroupModal />
    </>
  );
};

export default ImpactGroupsHeader;
