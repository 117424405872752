import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganizationalImpactFormData } from '../../commons/types';
import { IGetClientStatementData } from '../api/types/leadership';

interface IOrganizationalImpactSLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: IOrganizationalImpactFormData;
}

const initialState: IOrganizationalImpactSLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    impact_area: '',
    leadership_acumen: '',
    description: '',
  },
};

const organizationalImpactSLModalSlice = createSlice({
  name: 'organizationalImpactSLModal',
  initialState,
  reducers: {
    closeOrganizationalImpactSLModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddOrganizationalImpactSLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditOrganizationalImpactSLModal: (
      state,
      action: PayloadAction<IGetClientStatementData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        description: action.payload.description || '',
        impact_area:
          action.payload.impact_area ||
          action.payload.impactModuleCode ||
          action.payload.module_number ||
          '',
        leadership_acumen:
          action.payload.expertiseCategoryCode ||
          (action.payload.leadership_acumen
            ? action.payload.leadership_acumen
            : '') ||
          '',
      };
    },
  },
});

export const organizationalImpactSLModalActions =
  organizationalImpactSLModalSlice.actions;
export const organizationalImpactSLModalReducer =
  organizationalImpactSLModalSlice.reducer;
