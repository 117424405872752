import React from 'react';
import Button from '../../atoms/button/Button';
import Modal from '../../atoms/modal/Modal';
import ModalActions from '../../atoms/modal/ModalActions';
import ModalBody from '../../atoms/modal/ModalBody';
import ModalTitle from '../../atoms/modal/ModalTitle';
import AlertError from '../../atoms/alert/AlertError';
import { useAppSelector } from '../../hooks/redux';
import { useActions } from '../../hooks/actions';

const StatementLibraryWarningModal = () => {
  const isOpen = useAppSelector(
    (state) => state.statementLibraryWarningModal.isOpen
  );

  const warningData = useAppSelector(
    (state) => state.statementLibraryWarningModal.initialValues
  );

  const { closeStatementLibraryWarningModal } = useActions();

  return (
    <Modal
      open={isOpen}
      onClose={() => closeStatementLibraryWarningModal()}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={() => closeStatementLibraryWarningModal()}>
        {'Warning: multiple roles identified'}
      </ModalTitle>
      <AlertError
        message={
          'This statement is currently used in the following role profiles(s).' +
          ' Please delete the statement from the role profile(s) before deleting' +
          ' it from the statement library.'
        }
        sx={{
          marginTop: '10px',
        }}
      />
      <ModalBody>
        <ul>
          {warningData.map((el, i) => (
            <li key={i}>{`${el.Present_in} / ${el.name}`}</li>
          ))}
        </ul>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={() => closeStatementLibraryWarningModal()}
        />
      </ModalActions>
    </Modal>
  );
};

export default StatementLibraryWarningModal;
