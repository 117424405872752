// downloads
export const COMPANY_USERS = {
  Heidrick_ATSNAME_CANDIDATE_ATTACHMENTS_IMPORT:
    'Heidrick_ATSNAME_CANDIDATE_ATTACHMENTS_IMPORT',
  Heidrick_ATSNAME_EMPOYEE_EDUCATION_IMPORT:
    'Heidrick_ATSNAME_EMPOYEE_EDUCATION_IMPORT',
  Heidrick_ATSNAME_EMPLOYEE_IMPORT: 'Heidrick_ATSNAME_EMPLOYEE_IMPORT',
  Heidrick_ATSNAME_EMPLOYEE_PERFRATING_IMPORT:
    'Heidrick_ATSNAME_EMPLOYEE_PERFRATING_IMPORT',
  EF_STANDARD_Users: 'EF-STANDARD-Users',
  Heidrick_ATSNAME_EMPLOYEE_SKILLASSESSMENT_IMPORT:
    'Heidrick_ATSNAME_EMPLOYEE_SKILLASSESSMENT_IMPORT',
  Heidrick_ATSNAME_EMPLOYEE_CUSTOMFIELD_IMPORT:
    'Heidrick_ATSNAME_EMPLOYEE_CUSTOMFIELD_IMPORT',
};
