import { createSlice } from '@reduxjs/toolkit';

interface IReportGenerateModalProps {
  isOpen: boolean;
  reportData: Array<any>;
}

const initialState: IReportGenerateModalProps = {
  isOpen: false,
  reportData: [],
};

const downloadReportsModalSlice = createSlice({
  name: 'downloadReportsModal',
  initialState,
  reducers: {
    openDownloadReportsModal: (state, action) => ({
      ...initialState,
      isOpen: true,
      reportData: action.payload,
    }),
    closeDownloadReportsModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const downloadReportsModalActions = downloadReportsModalSlice.actions;
export const downloadReportsModalReducer = downloadReportsModalSlice.reducer;
