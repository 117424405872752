import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IExcelResponse } from './types/roleskills';
import { downloadFile, saveFileNameAsCSV, saveFileNameAsJSON } from './helpers';
import { COMPANY_USERS } from './constants';
import {
  IAssessmentRequest,
  ISelfAssessmentResponse,
  IType360AssessmentResponse,
} from './types/assessmentStatusDashboard';
import {
  IAvailableAssessmentObject,
  IAvailableAssessmentRequest,
  IAvailableAssessmentResponse,
} from './types/clientAssessment';
import {
  ICreateAssessmentRequest,
  ICreateAssessmentResponse,
  IImpactAssessmentJSONRequest,
  IImpactAssessmentsRequest,
  IImpactAssessmentsResponse,
} from './types/assessment';
import { assessmentStructureApiInvalidateTags } from './assessmentStructure';

type IRequestProps = {
  id: string;
  selectedUsersEmailList?: string;
};

export const exportFileApi = createApi({
  reducerPath: 'assessments/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}assessments/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Assessment Result'],
  endpoints: (builder) => ({
    downloadCompanyUsersAssessmentResult: builder.mutation<
      IExcelResponse,
      IRequestProps
    >({
      query: ({ id }) => {
        return {
          url: `assessments/export?type=skill-assessments&clientId=${id}`,
          responseHandler: async (response: any) => {
            // using any to avoid complex type compatibility issues
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsCSV(
                  COMPANY_USERS.Heidrick_ATSNAME_EMPLOYEE_SKILLASSESSMENT_IMPORT
                )
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),
    downloadCompanyNavigatorCustomField: builder.mutation<
      IExcelResponse,
      IRequestProps
    >({
      query: ({ id, selectedUsersEmailList }) => {
        let url = `assessments/export?type=custom-field&clientId=${id}`;
        if (selectedUsersEmailList) {
          url += `&emailList=${selectedUsersEmailList}`;
        }
        return {
          url,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsCSV(COMPANY_USERS.EF_STANDARD_Users)
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),

    // Assessment-status
    getSelfAssessmentStatus: builder.mutation<
      ISelfAssessmentResponse,
      IAssessmentRequest
    >({
      query: (payload) => ({
        url: 'assessment-status',
        method: 'POST',
        body: payload,
      }),
    }),
    get360AssessmentStatus: builder.mutation<
      IType360AssessmentResponse,
      IAssessmentRequest
    >({
      query: (payload) => ({
        url: 'assessment-status',
        method: 'POST',
        body: payload,
      }),
    }),

    getAssessmentsById: builder.query<any, { assessment: string | undefined }>({
      query: ({ assessment }) => `assessments/${assessment}`,
    }),
    updateAssessmentById: builder.mutation<
      void,
      { assessment: string; payload: any }
    >({
      query: ({ assessment, payload }) => ({
        url: `assessments/${assessment}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    getAllAvailableAssessments: builder.mutation<
      IAvailableAssessmentResponse,
      IAvailableAssessmentRequest
    >({
      query: (payload) => ({
        url: 'available-assessments',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: IAvailableAssessmentResponse) => {
        return {
          ...response,
          availableAssessmentListDropdown: response.assessments.map(
            (el: IAvailableAssessmentObject) => ({
              keyName: el.assessmentID + ' - ' + el.title || '',
              keyValue: el.assessmentID + ' - ' + el.title || '',
            })
          ),
        };
      },
    }),
    getImpactAssessment: builder.mutation<
      IImpactAssessmentsResponse,
      IImpactAssessmentsRequest
    >({
      query: (payload) => ({
        url: `impact-assessments?clientId=${payload.clientId}&assessmentName=${payload.assessmentName}&statementCodeList=${payload.statementCodeList}`,
        method: 'POST',
      }),
      onCacheEntryAdded: (args, { dispatch }) => {
        dispatch(assessmentStructureApiInvalidateTags(['assessmentstructure']));
      },
    }),
    getImpactAssessmentJSON: builder.mutation<
      IImpactAssessmentsResponse,
      IImpactAssessmentJSONRequest
    >({
      query: ({ id, impactGroupName = 'ImactGroup' }) => {
        const url = `impact-assessments?assessmentStructureId=${id}`;
        return {
          url,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsJSON(impactGroupName)
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),
    createAssessment: builder.mutation<
      ICreateAssessmentResponse,
      ICreateAssessmentRequest
    >({
      query: (payload) => ({
        url: 'create-assessment',
        method: 'POST',
        body: payload,
      }),
    }),
    getImpactAssessmentByAssessmentStructure: builder.mutation<
      any,
      IImpactAssessmentJSONRequest
    >({
      query: ({ id }) => {
        const url = `impact-assessments?assessmentStructureId=${id}`;
        return {
          url,
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const {
  useDownloadCompanyUsersAssessmentResultMutation,
  useDownloadCompanyNavigatorCustomFieldMutation,
} = exportFileApi;

// Assessment Status Dashboard
export const {
  useGetSelfAssessmentStatusMutation,
  useGet360AssessmentStatusMutation,
} = exportFileApi;

// Assessment
export const {
  useLazyGetAssessmentsByIdQuery,
  useUpdateAssessmentByIdMutation,
  useGetAllAvailableAssessmentsMutation,
  useGetImpactAssessmentMutation,
  useGetImpactAssessmentJSONMutation,
  useCreateAssessmentMutation,
  useGetImpactAssessmentByAssessmentStructureMutation,
} = exportFileApi;
