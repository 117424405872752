import React from 'react';
import { Box, Container, styled } from '@mui/material';
import HpContainer from '../container/HpContainer';

interface IProps {
  children: React.ReactNode;
  type?: string;
}

const StyledWrapper = styled(Box)(() => ({
  background:
    'radial-gradient(89.54% 90.64% at 6.02% 9.36%, #E3ECEF 0%,' +
    ' rgba(241, 241, 241, 0) 100%)',
  paddingTop: '32px',
  paddingBottom: '32px',
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const PageHeader = ({ children, type }: IProps) => {
  return (
    <StyledWrapper>
      {type === 'full' ? (
        <HpContainer
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {children}
        </HpContainer>
      ) : (
        <StyledContainer>{children}</StyledContainer>
      )}
    </StyledWrapper>
  );
};

export default PageHeader;
