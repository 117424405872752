import React from 'react';
import Button from '../../atoms/button/Button';
import Modal from '../../atoms/modal/Modal';
import ModalActions from '../../atoms/modal/ModalActions';
import ModalBody from '../../atoms/modal/ModalBody';
import ModalTitle from '../../atoms/modal/ModalTitle';
import AlertError from '../../atoms/alert/AlertError';
import { useAppSelector } from '../../hooks/redux';
import { useActions } from '../../hooks/actions';

const ErrorsModal = () => {
  const isOpen = useAppSelector((state) => state.errorsModal.isOpen);

  const error = useAppSelector((state) => state.errorsModal.initialValues);
  const errorFormat = error.split('\n');
  const errorList =
    errorFormat.length > 1 ? errorFormat.slice(0, -1) : errorFormat;

  const { closeErrorsModal } = useActions();

  return (
    <Modal
      open={isOpen}
      onClose={() => closeErrorsModal()}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={() => closeErrorsModal()}>{'Errors'}</ModalTitle>
      <AlertError
        message={
          'The uploaded file has some errors as below.' +
          ' Please update the file and upload again'
        }
        sx={{
          marginTop: '10px',
        }}
      />
      <ModalBody>
        <ul>
          {errorList.map((errorObj, i) => {
            return <li key={i}>{errorObj}</li>;
          })}
        </ul>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={() => closeErrorsModal()}
        />
      </ModalActions>
    </Modal>
  );
};

export default ErrorsModal;
