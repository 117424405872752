import React from 'react';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { RequiredAuth } from './RequiredAuth';
import config from './config';
import Layout from '../templates/Layout';
import { routes } from './routes';
import { GlobalRoutes } from './GlobalRoutes';
import { ClientRoutes } from './ClientRoutes';
import { useGetMeQueryState } from '../store/api/v2/auth';
import { userTypeHandler } from '../utils/helpers';

const oktaAuth = new OktaAuth(config.oidc);

const AppRouter = () => {
  const navigate = useNavigate();

  const myPersonas = useGetMeQueryState().data?.data?.myPersonas;
  const clientId = useGetMeQueryState()
    .data?.data?.myPersonas.map((el) => el.clientId)
    .filter((el) => Boolean(el))
    .at(0);

  const routesHandler = () => {
    if (userTypeHandler(myPersonas) === 'Global') return GlobalRoutes();
    if (userTypeHandler(myPersonas) === 'Client' && clientId)
      return ClientRoutes(clientId);

    return (
      <Route
        path='*'
        element={<Navigate to={routes.home.url} />}
      />
    );
  };

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || '', window.location.origin), {
      replace: true,
    });
  };
  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route element={<RequiredAuth />}>
          <Route element={<Layout />}>{routesHandler()}</Route>
        </Route>
        <Route
          path={routes.callback.url}
          element={
            <LoginCallback
              errorComponent={() => {
                localStorage.removeItem('okta-original-uri-storage');
                localStorage.removeItem('okta-cache-storage');
                localStorage.removeItem('okta-shared-transaction-storage');
                localStorage.removeItem('okta-token-storage');
                localStorage.removeItem('authzToken');
                navigate(routes.home.url);
                return null;
              }}
            />
          }
        />
      </Routes>
    </Security>
  );
};

export default AppRouter;
