import { useEffect } from 'react';
import { isEqual } from 'lodash';

const useFooterColumn = (
  filteredData: any,
  filteredState: any,
  setFilteredState: any
) => {
  useEffect(() => {
    if (!isEqual(filteredData, filteredState)) {
      setFilteredState(filteredData);
    }
  }, [filteredData]);

  return null;
};

export default useFooterColumn;
