import React from 'react';
import Modal from '../../atoms/modal/Modal';
import ModalTitle from '../../atoms/modal/ModalTitle';
import ModalBody from '../../atoms/modal/ModalBody';
import ModalActions from '../../atoms/modal/ModalActions';
import FormWrapper from '../../molecules/form/FormWrapper';
import Button from '../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useActions } from '../../hooks/actions';
import { useAppSelector } from '../../hooks/redux';
import FormLabelDropdown from '../../molecules/form/FormLabelDropdown';
import FormFileUpload from '../../molecules/form/FormFileUpload';
import { useImportStatementsMutation } from '../../store/api/uploadFile';
import { ImportFileResponse } from '../../store/api/types/uploadFile';
import { useParams } from 'react-router';

export default function ImportStatementModal() {
  const methods = useForm();

  const { id } = useParams();

  const [fileData, setFileData] = React.useState<File | null>(null);

  const {
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const isOpen = useAppSelector((state) => state.importStatementModal.isOpen);
  const progress = useAppSelector(
    (state) => state.importStatementModal.progress
  );

  const fileTypeArray = [{ keyName: 'Statements', keyValue: 'statements' }];

  const {
    closeImportStatementModal,
    toggleSuccessSnackbar,
    setImportStatementUploadProgress,
    openErrorsModal,
  } = useActions();

  const [uploadStatements] = useImportStatementsMutation();

  const handleClose = () => {
    setFileData(null);
    closeImportStatementModal();
  };

  const onSubmit = async () => {
    const formData = new FormData();
    if (fileData) {
      formData.append('file', fileData);
      setImportStatementUploadProgress(0);
      uploadStatements({ formData, clientId: id ? id : 'Master' })
        .unwrap()
        .then((res) => {
          const response = res as ImportFileResponse;
          setImportStatementUploadProgress(0);
          toggleSuccessSnackbar({
            message: 'The file has been uploaded successfully',
          });
          handleClose();
        })
        .catch((e) => {
          handleClose();
          openErrorsModal(e.message);
        });
    } else {
      setError('roleFile', { type: 'string', message: 'Please select a file' });
    }
  };

  const handleFileData = (file: File | null) => {
    setFileData(file);
  };
  React.useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>Upload a file.</ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-user'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelDropdown
            name='File type'
            rules={{ required: 'Please select a file type' }}
            label='File type'
            dropDownItem={fileTypeArray}
            placeholder={'Select a file type'}
          />
          <FormFileUpload
            accept='.xlsx,.csv'
            name='roleFile'
            progress={progress}
            rules={{ required: 'Please select a file' }}
            formatErrorMsg='There was an error with the file you provided. Supported format includes Excel files.'
            onChange={handleFileData}
          />
        </FormWrapper>
      </ModalBody>
      {!!fileData && Boolean(progress) === false && (
        <ModalActions>
          <Button
            btnType={'PRIMARY'}
            text={'Upload file'}
            type='submit'
            form='add-new-user'
            disabled={!!Object.values(errors).length || !!progress}
          />
          <Button
            btnType={'SECONDARY'}
            text={'Cancel'}
            onClick={handleClose}
          />
        </ModalActions>
      )}
    </Modal>
  );
}
