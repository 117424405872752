import React, { useMemo, useRef, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import Button from '../../../../atoms/button/Button';
import ModalActions from '../../../../atoms/modal/ModalActions';
import { useAppSelector } from '../../../../hooks/redux';
import { useActions } from '../../../../hooks/actions';
import SubTitle from '../../../../atoms/title/SubTitle';
import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import {
  RatersInfo,
  RatersTypeInfo,
} from '../../../../store/api/types/assessmentStatusDashboard';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import RatersTable from '../../../tables/Company/Assessments/RatersTable';
import { SelectOptions } from '../../../../commons/types';
import SummaryMetrics, {
  SummaryMetricsData,
} from '../../../../atoms/header/SummaryMetrics';
import { getQuantity, getThreshold } from '../../../../store/api/v2/helpers';

const StyledSubTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
}));

const TableDivider = styled(Box)(() => ({
  width: '100%',
  marginTop: '20px',
}));

const StyledMetricswrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '5px',
  marginLeft: '0px',
  marginBottom: '20px',
  width: 'auto',
  marginRight: '20px',
}));

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '1100px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

const AssessmentDetailsModal = () => {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const [metricsData, setMetricsData] = React.useState<SummaryMetricsData[]>(
    []
  );

  const [uniquemetricsData, setuniqueMetricsData] = React.useState<
    SummaryMetricsData[]
  >([]);

  // state to select filter values on click of metrics
  const [metricsTypeValue, setmetricsTypeValue] = React.useState<number>(0);
  const [metricsStatusValue, setmetricsStatusValue] = React.useState<number>(0);

  const isOpen = useAppSelector((state) => state.assessmentModal.isOpen);
  const initialValues = useAppSelector(
    (state) => state.assessmentModal.initialValues
  );

  const { closeAssessmentModal } = useActions();

  const handleClose = () => {
    closeAssessmentModal();
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  // Status dropdown
  const allStatus = initialValues.raters.map((el) => el['Rater Status']);
  const uniqueStatus = allStatus.filter(
    (item, index) => allStatus.indexOf(item) === index
  );
  const statusDropdownList = uniqueStatus.map((el) => ({
    text: el || '',
    value: el || '',
  }));

  // Type Dropdown
  const allTypes = initialValues.raters.map((el) => el['Rater Type']);
  const uniqueTypes = allTypes.filter(
    (item, index) => allTypes.indexOf(item) === index
  );
  const typesDropdownList = uniqueTypes.map((el) => ({
    text: el || '',
    value: el || '',
  }));

  typesDropdownList.unshift({ text: '', value: 'Select Type' });
  statusDropdownList.unshift({ text: '', value: 'Select Status' });

  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<RatersInfo>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<RatersInfo>[]>(
    () => [
      {
        header: 'Rater',
        filterFn: 'includesString',
        accessorFn: (row) =>
          `${
            row['Rater First Name'].charAt(0).toUpperCase() +
            row['Rater First Name'].slice(1).trim()
          } ${row['Rater Last Name'].trim()}`,
      },
      {
        header: 'Email',
        accessorKey: 'Rater Email',
        filterFn: 'includesString',
        size: 250,
      },
      {
        header: 'Type',
        id: 'Type',
        accessorKey: 'Rater Type',
        // filterFn: 'equals',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={typesDropdownList}
            selectValue={metricsTypeValue}
            onChange={(e, newValue, reason) => {
              setmetricsTypeValue(-1);
              multiSelectOnChangeHandler(e, newValue, reason, column);
            }}
            column={column}
            width={'250px !important'}
          />
        ),
      },
      {
        header: 'Status',
        id: 'Status',
        accessorKey: 'Rater Status',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={statusDropdownList}
            selectValue={metricsStatusValue}
            onChange={(e, newValue, reason) => {
              setmetricsStatusValue(-1);
              multiSelectOnChangeHandler(e, newValue, reason, column);
            }}
            column={column}
            width={'250px !important'}
          />
        ),
      },
    ],
    [typesDropdownList, statusDropdownList]
  );

  useEffect(() => {
    const metricsArray = [
      {
        itemName: 'Total invited',
        itemQty: initialValues.raters?.length || 0,
      },
    ];

    const distinctRaterTypes = raterSummaryMetrixData(
      initialValues.raters,
      initialValues.raterTypes
    );

    distinctRaterTypes && setuniqueMetricsData(distinctRaterTypes as any); // need to use any as there were conflicts with flse and object tpe
    setMetricsData(metricsArray);
  }, [initialValues, metricsData.length]);

  useEffect(() => {
    setMetricsData(uniquemetricsData);
  }, [uniquemetricsData]);

  const raterSummaryMetrixData = (
    raters: RatersInfo[],
    raterTypes: RatersTypeInfo[] | undefined
  ) => {
    const distinctRaterTypes = [];

    const ratersM = raterTypes?.filter(
      (raterType) => raterType['Rater Type'] !== 'Focus'
    );
    if (ratersM?.length) {
      ratersM?.map((item) =>
        distinctRaterTypes.push({
          itemName: `${item?.['Rater Type']} Complete`,
          itemQty: getQuantity(item['Rater Type'], raters),
          threshold: getThreshold(item['Rater Type'], raterTypes),
          btnName: 'Threshold - ',
          type: item['Rater Type'],
        })
      );
    }

    distinctRaterTypes.unshift({
      itemName: 'Total invited',
      itemQty: raters?.length || 0,
      threshold: '',
      btnName: '',
      type: '',
    });

    // filter false values
    const raterMatrixData = distinctRaterTypes.filter((ratervalues) => {
      // Checking if the value is truthy
      if (ratervalues) {
        return ratervalues;
      }
    });

    setMetricsData(raterMatrixData as any); // needed any is type mismatch for false and response types conflict
    return raterMatrixData;
  };

  const onThresholdButtonClick = (type: string): void => {
    const filterRater = type.replace(' Complete', '');
    const raterIndex = typesDropdownList.findIndex(
      (x) => x.text === filterRater
    );

    const statusIndex = statusDropdownList.findIndex(
      (x) => x.text === 'Completed'
    );
    setmetricsTypeValue(raterIndex); // set filter value
    setmetricsStatusValue(statusIndex); // set filter value

    tableInstanceRef?.current?.getColumn('Type').setFilterValue([filterRater]);
    tableInstanceRef?.current
      ?.getColumn('Status')
      .setFilterValue(['Completed']);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>
        Rater Status
        <StyledSubTitlesWrapper>
          <StyledSubTitlesWrapper>
            <SubTitle
              text='STATUS'
              bold
            />
            <SubTitle text={initialValues.status} />
            <SubTitle text='|' />
            <SubTitle
              text='ASSESSMENT'
              bold
            />
            <SubTitle text={initialValues.assessment} />
            <SubTitle text='|' />
            <SubTitle
              text='LEADER'
              bold
            />
            <SubTitle text={initialValues.participant} />
          </StyledSubTitlesWrapper>
        </StyledSubTitlesWrapper>
      </ModalTitle>
      <ModalBody>
        <StyledMetricswrapper>
          <SummaryMetrics
            data={metricsData}
            onThresholdButtonClick={onThresholdButtonClick}
          />
          <TableDivider>
            {initialValues.raters.length ? (
              <RatersTable
                isLoading={false}
                data={initialValues.raters}
                columns={columns}
                tableInstanceRef={tableInstanceRef}
              />
            ) : (
              <></>
            )}
          </TableDivider>
        </StyledMetricswrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'SECONDARY'}
          text={'Done'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default AssessmentDetailsModal;
