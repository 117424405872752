import React from 'react';
import { Clear } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import MaterialReactTable, {
  MaterialReactTableProps,
} from 'material-react-table';
import { styled } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import UncheckedCheckboxIcon from '../../assets/icons/checkbox/default/unchecked-checkbox.svg';
import CheckedCheckboxIcon from '../../assets/icons/checkbox/default/checked-checkbox.svg';
import IndeterminateCheckboxIcon from '../../assets/icons/checkbox/default/indeterminate-checkbox.svg';

const StyledCheckboxIcon = styled('img')(() => ({
  width: '20px',
  height: '20px',
}));

export const checkboxProps = {
  disableRipple: true,
  title: '',
  icon: (
    <StyledCheckboxIcon
      src={UncheckedCheckboxIcon}
      alt={'unchecked'}
    />
  ),
  checkedIcon: (
    <StyledCheckboxIcon
      src={CheckedCheckboxIcon}
      alt={'checked'}
    />
  ),
  indeterminateIcon: (
    <StyledCheckboxIcon
      src={IndeterminateCheckboxIcon}
      alt={'indeterminate'}
    />
  ),
  sx: {
    '&:hover img': {
      outline: '2px solid #18768C',
      borderRadius: '4px',
    },
    '&:focus img': {
      outline: '1px solid #18768C',
      outlineOffset: '1px',
      borderRadius: '4px',
    },
    'input:focus ~ img': {
      outline: '1px solid #18768C',
      outlineOffset: '1px',
      borderRadius: '4px',
    },
    'input:disabled ~ img': {
      outline: '0px solid #18768C',
      outlineOffset: '1px',
      borderRadius: '4px',
      opacity: '0.5',
      filter: 'contrast(0.5)',
    },
  },
};

const Table = ({ ...extraProps }: MaterialReactTableProps) => {
  return (
    <MaterialReactTable
      muiTableContainerProps={() => ({
        sx: {
          '&.MuiTableContainer-root::-webkit-scrollbar': {
            height: '14px',
          },
          '&.MuiTableContainer-root::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            backgroundColor: '#002136',
          },
          '&.MuiTableContainer-root::-webkit-scrollbar-track': {
            WebkitBorderRadius: '0px 0px 2px 2px',
            background: '#F8F8F8',
          },
          '&.MuiTableContainer-root::-webkit-scrollbar-button': {
            width: '7px',
            backgroundColor: '#F8F8F8',
          },
        },
      })}
      enableTopToolbar={false}
      enableColumnActions={false}
      enableRowActions
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          size: 10,
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
      }}
      positionActionsColumn='last'
      initialState={{ showColumnFilters: true }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          border: '1px solid rgba(100, 106, 124, 0.2)',
          borderRadius: '2px',
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          backgroundColor: '#F8F8F8',
          '& .MuiTableCell-root': {
            paddingRight: 0,
            '&:nth-last-of-type(1)': {
              paddingRight: '16px',
            },
            borderBottom: '1px solid #002136',
          },
          '& .Mui-TableHeadCell-Content-Wrapper': {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            fontWeight: 300,
            color: '#646A7C',
            paddingRight: 0,
          },
          '& label': {
            fontSize: '16px',
            fontWeight: '300',
          },
        },
      }}
      muiTableHeadCellFilterTextFieldProps={({ column }) => ({
        placeholder:
          column.columnDef.filterVariant === 'select'
            ? `Filter by ${column.columnDef.header}`
            : 'Search',
        variant: 'outlined',
        InputProps: {
          sx: {
            fontWeight: 500,
          },
          endAdornment: (
            <InputAdornment position='end'>
              {!column.getFilterValue() ? (
                column.columnDef.filterVariant !== 'select' ? (
                  <SearchIcon />
                ) : (
                  ''
                )
              ) : (
                <Clear
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#F1F1F1',
                    },
                  }}
                  onClick={() => column.setFilterValue('')}
                />
              )}
            </InputAdornment>
          ),
        },
        sx: {
          marginTop: '8px',
          marginBottom: '10px',
          borderRadius: '12px',
          backgroundColor: 'white',
          '& input::placeholder': {
            fontFamily: 'Avenir',
            fontStyle: 'normal',
            fontWeight: '300 !important',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#646A7C',
          },
          '& input': {
            color: '#002136',
            padding: '12px 16px',
            fontFamily: 'Avenir',
            fontStyle: 'normal',
            fontWeight: '500 !important',
            fontSize: '16px',
            lineHeight: '24px',
          },
          '& .MuiSelect-select': {
            border: '0px !important',
          },
        },
      })}
      muiTableBodyProps={{
        sx: {
          '& .MuiTableCell-body': {
            fontWeight: '300',
          },
          '& .Mui-selected': {
            backgroundColor: '#F3F8F9 !important',
          },
          '& .MuiTableRow-root:hover': {
            backgroundColor: '#F1F1F1 !important',
          },
        },
      }}
      muiSelectAllCheckboxProps={checkboxProps}
      muiSelectCheckboxProps={checkboxProps}
      {...extraProps}
    />
  );
};

export default Table;
