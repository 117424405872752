export const routes = {
  home: {
    url: '/',
  },
  callback: {
    url: '/login/callback',
  },
  companies: {
    url: '/companies',
    name: 'Companies',
  },
  company: {
    url: '/company/',
    settings: {
      url: '/settings',
      name: 'Settings',
    },
    roles: {
      url: '/roles',
      name: 'Role Library',
    },
    users: {
      url: '/users',
      name: 'Users',
      add: {
        url: '/add',
      },
    },
    newassessment: {
      url: '/assessments',
      name: 'Assessments',
    },
    reports: {
      url: '/reports',
      name: 'Reports',
    },
    statements: {
      url: '/statements',
      name: 'Statement Library',
    },
    assessmentstatus: {
      url: '/assessment-status',
      name: 'Assessment Status',
    },
    impactgroups: {
      url: '/impactgroups/',
      name: 'Impact Groups',
    },
    variableLibrary: {
      url: '/variable-library',
      name: 'Variable Library',
    },
    projects: {
      url: '/projects',
      name: 'Projects',
      add: {
        url: '/add',
      },
      edit: {
        url: '/edit',
      },
    },
  },
  roleLibrary: {
    url: '/role-library',
    name: 'Role Library',
  },
  statementLibrary: {
    url: '/statement-library',
    name: 'Statement Library',
    details: {
      url: '/statement-details',
      name: 'Statement Details',
    },
  },
  admins: {
    url: '/admins',
    name: 'Admins',
  },
  variableLibrary: {
    url: '/variable-library',
    name: 'Variable Library',
  },
  test: {
    url: '/component-test',
    name: 'Component Test',
  },
  assessment: {
    url: '/assessments',
    name: 'Reports',
  },
  landing: {
    url: '/landing',
    name: 'Landing Page',
  },
};
