import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssessmentCohortExportModalProps {
  isOpen: boolean;
  modalType: 'export';
  rowData: any; // passing rowdata from selected rows get model method
}

const initialState: AssessmentCohortExportModalProps = {
  isOpen: false,
  modalType: 'export',
  rowData: [],
};

const AssessmentCohortExportModalSlice = createSlice({
  name: 'AssessmentCohortExportMOdal',
  initialState,
  reducers: {
    closeAssessmentCohortExportModal: (state) => {
      state.isOpen = false;
    },
    openAssessmentCohortExportModal: (
      state,
      action: PayloadAction<{ rowData: any }>
    ) => {
      state.isOpen = true;
      state.rowData = action.payload.rowData;
    },
  },
});

export const assessmentCohortExportModalActions =
  AssessmentCohortExportModalSlice.actions;
export const assessmentCohortExportModalReducer =
  AssessmentCohortExportModalSlice.reducer;
