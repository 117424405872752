import React, { useRef } from 'react';
import { ITabContentProps } from '../../../commons/types';
import TabContent from '../../../atoms/tab/TabContent';
import { MRT_TableInstance } from 'material-react-table';
import { Box, styled } from '@mui/material';
import ImpactGroupStatementsTable from '../../../organisms/tables/Company/ImpactGroups/ImpactGroupStatementsTable';
import { IRoleSkillData } from '../../../store/api/types/leadership';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '6px 16px 10px',
});

const StyledLabel = styled('span')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '29px',
  color: '#0B0C10',
});

export default function ImpactGroupStatements({
  index,
  value,
  statementData,
}: ITabContentProps & {
  statementData: Array<IRoleSkillData>;
}) {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledContentWrapper>
        <StyledLabel>{`${statementData.length} total unique statements`}</StyledLabel>
      </StyledContentWrapper>
      <ImpactGroupStatementsTable
        isLoading={false}
        impactModuleDropDown={statementData.map((item) => ({
          text: item.impactModuleName || '',
          value: item.impactModuleName || '',
        }))}
        data={statementData}
        tableInstanceRef={tableInstanceRef}
      />
    </TabContent>
  );
}
