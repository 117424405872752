import * as React from 'react';
import {
  Box,
  Radio,
  FormControlLabel,
  styled,
  useTheme,
} from '@mui/material';

export interface RadioTypes {
  checked: boolean;
  classes: any;
  name: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;  
}

export interface HDRadioTypes extends RadioTypes {
  label: React.ReactElement;
  hasError: boolean;
}

export const RadioButton = styled(({ ...props }: RadioTypes) => (
  <Radio {...props} />
));

export const HDRadio = ({ label, hasError = false, checked, ...restProps}: HDRadioTypes) => {
  const theme =  useTheme()?.palette.mode;

  const StyledBoxWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px 0px 10px 20px',
    background: theme === 'light'? '#f8f8f8' : '#002136',
    border: theme === 'light'? '2px solid #f8f8f8' : checked ? '2px solid #197e95' : '2px solid #002136',
    borderRadius: '8px',
  }));

  const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    color: theme === 'light'? '#002136' : '#fff',
    '.MuiFormControlLabel-label': {
      fontWeight: '500',
    }
  }));

  const lightRadio = styled(Radio)(() => ({
    color: hasError ? '#e344ba' : '#002136',
    marginBottom: '3px',
    '&.Mui-checked': {
      color: hasError ? '#e344ba' : '#002136',
    },
  }));

  const darkRadio = styled(Radio)(() => ({
    color: hasError ? '#e344ba' : '#fff',
    marginBottom: '3px',
    '&.Mui-checked': {
      color: hasError ? '#e344ba' : '#fff',
    },
  }));

  const StyledRadio = theme === 'light' ? lightRadio : darkRadio;

  return (
    <StyledBoxWrapper>
      <StyledFormControlLabel
        value={label}
        label={label}
        control={
          <StyledRadio
            checked={checked}
            color='primary'
            {...restProps}
          />}
      />
    </StyledBoxWrapper>
  );
};
