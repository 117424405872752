import { styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import checked from '../../../../assets/icons/checked.svg';
import React from 'react';
import { reportStatusAggregator } from '../../../../utils/helpers';

const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginRight: '8px',
  marginBottom: '2.5px',
}));

const StyledText = styled('p')(() => ({
  fontFamily: 'Avenir',
  fontSize: '16px',
  fontWeight: '600',
}));

const StyledBox = styled('div')(() => ({
  display: 'flex',
}));

const StyledDivider = styled('hr')(() => ({
  height: '2px',
  color: '#646A7C',
  opacity: '20%',
}));

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background: 'white',
  },
};

interface IProps {
  reGenerateReports: () => void;
}

const ReportRegenerationModal = ({ reGenerateReports }: IProps) => {
  const isOpen = useAppSelector((state) => state.reportGenerationModal.isOpen);
  const data = useAppSelector(
    (state) => state.reportGenerationModal.reportData
  );

  const regenerationReportCount = data.length;

  const [regenrationData, setRegenrationData] = React.useState({
    fullReport: 0,
    partialReport: 0,
    noData: 0,
  });

  const { closeProjectModal } = useActions();

  const handleClose = () => {
    closeProjectModal();
    setRegenrationData({
      fullReport: 0,
      partialReport: 0,
      noData: 0,
    });
  };

  const onSubmit = async () => {
    reGenerateReports();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>Re-Generate Reports</ModalTitle>
      <ModalBody sx={{ backgroundColor: 'white' }}>
        {
          <StyledBox>
            <StyledImg
              src={checked}
              alt={'checked'}
            />
            <StyledText sx={{ color: '#334255' }}>
              {`Number of Reports: ${regenerationReportCount}`}
            </StyledText>
          </StyledBox>
        }
        <StyledDivider />
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Generate Reports'}
          onClick={onSubmit}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default ReportRegenerationModal;
