import React from 'react';
import { styled, Box, BoxProps } from '@mui/material';
import HpProgressBar from '../progress/HpProgressBar';
import AlertError from '../alert/AlertError';

export interface IHpFileUploadProps {
  accept: string;
  error: string;
  formatErrorMsg: string;
  progress: number;
  fileDataHandler: (files: File | null) => void;
  disabled?: boolean;
  label?: string;
}

interface IStyledBoxProps extends BoxProps {
  error?: boolean;
}

const StyledUploadContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<IStyledBoxProps>(({ error }) => ({
  width: '100%',
  border: '2px dashed #000',
  ...(!!error && { borderColor: '#E344BA' }),
  background: '#F8F8F8',
}));

const StyledUploadBox = styled(Box)(() => ({
  padding: '35px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledRectangleBox = styled(Box)(() => ({
  width: '86px',
  height: '58px',
  backgroundColor: '#D9D9D9',
}));

const StyledFileInfoBox = styled(Box)(() => ({
  padding: '35px 10px',
  display: 'flex',
}));

const StyledFileInfoActionBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}));

const StyledFileDescriptionBox = styled(Box)(() => ({
  marginLeft: '15px',
  width: '100%',
  padding: '5px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

const StyledUploadText = styled(Box)(() => ({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  letterSpacing: '0.02em',
  '&.underline': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '&.colorText': {
    cursor: 'pointer',
    color: '#18768C',
  },
}));

const StyledSelectLabel = styled('span')(() => ({
  fontFamily: 'Avenir',
  color: '#646a7c',
  display: 'flex',
}));

export default function HpFileUpload({
  accept,
  progress,
  error: fieldError,
  fileDataHandler,
  formatErrorMsg,
  disabled,
  label,
}: IHpFileUploadProps) {
  const [error, setError] = React.useState<string>(fieldError);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [fileData, setFileData] = React.useState<File | null>(null);

  const fileTypeChecker = (fileName: string) => {
    const acceptArray = accept.split(',');
    const ext = fileName.split('.');
    return acceptArray.includes(`.${ext[ext.length - 1].toLowerCase()}`);
  };

  const stopEvent = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onChangeHandler = (event: React.ChangeEvent) => {
    stopEvent(event);
    if (!progress) {
      const { files } = event.target as HTMLInputElement;
      if (files && fileTypeChecker(files[0].name)) {
        setError('');
        setFileData(files[0]);
        fileDataHandler(files[0]);
        return;
      }
      setError(formatErrorMsg);
    }
    return;
  };

  const inputClick = (event: React.SyntheticEvent) => {
    stopEvent(event);
    if (disabled) return;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onDrop = (event: React.DragEvent) => {
    stopEvent(event);
    if (disabled) return;
    if (!progress) {
      const { files } = event.dataTransfer;
      if (files && fileTypeChecker(files[0].name)) {
        setError('');
        setFileData(files[0]);
        fileDataHandler(files[0]);
        return;
      }
      setError(formatErrorMsg);
    }
    return;
  };

  const formatFileSize = (bytes: number, decimalPoint: number) => {
    if (bytes == 0) return '0 Bytes';
    const k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const onDragLeave = (event: React.SyntheticEvent) => {
    stopEvent(event);
  };

  const onDragOver = (event: React.SyntheticEvent) => {
    stopEvent(event);
  };

  const deleteAction = (event: React.SyntheticEvent) => {
    if (inputRef.current) inputRef.current.value = '';
    stopEvent(event);
    setFileData(null);
    fileDataHandler(null);
  };

  React.useEffect(() => {
    setError(fieldError);
  }, [fieldError]);

  return (
    <div>
      <StyledSelectLabel>{label}</StyledSelectLabel>
      <StyledUploadContainer
        error={!!error.length}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <input
          type='file'
          accept={accept}
          ref={inputRef}
          onChange={onChangeHandler}
          hidden
        />
        {fileData ? (
          <StyledFileInfoBox>
            {Boolean(progress) === false && (
              <StyledRectangleBox></StyledRectangleBox>
            )}
            <StyledFileDescriptionBox>
              <StyledFileInfoActionBox>
                <StyledUploadText>
                  {fileData.name.split('.')[0]}
                </StyledUploadText>
                {Boolean(progress) === false && (
                  <StyledUploadText
                    onClick={deleteAction}
                    className='colorText'
                  >
                    X Delete
                  </StyledUploadText>
                )}
              </StyledFileInfoActionBox>
              <StyledUploadText>{`${fileData.name
                .split('.')[1]
                .toUpperCase()} ${formatFileSize(
                fileData.size,
                2
              )}`}</StyledUploadText>
              {!!progress && <HpProgressBar progress={progress} />}
            </StyledFileDescriptionBox>
          </StyledFileInfoBox>
        ) : (
          <StyledUploadBox>
            <StyledUploadText>Drag your file here to upload</StyledUploadText>
            <StyledUploadText>or</StyledUploadText>
            <StyledUploadText
              onClick={inputClick}
              className='underline'
            >
              Click to Browse
            </StyledUploadText>
          </StyledUploadBox>
        )}
      </StyledUploadContainer>
      {!!error.length && <AlertError message={error} />}
    </div>
  );
}
