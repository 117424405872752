import React from 'react';
import HpFileUpload, {
  IHpFileUploadProps,
} from '../../atoms/upload/HpFileUpload';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

interface IProps extends Omit<IHpFileUploadProps, 'error' | 'fileDataHandler'> {
  errorMsg?: string;
  name: string;
  onChange: (file: File | null) => void;
  disabled?: boolean;
  label?: string;
}

export default function FormFileUpload({
  name,
  onChange,
  disabled,
  ...extraProps
}: IProps & UseControllerProps) {
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const fileChangeHandler = (file: File | null) => {
    clearErrors(name);
    setValue(name, file);
    onChange(file);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <HpFileUpload
            {...extraProps}
            disabled={disabled}
            fileDataHandler={fileChangeHandler}
            error={(!!errors[name] && (errors[name]?.message as string)) || ''}
          />
        );
      }}
    />
  );
}
