import React from 'react';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { AssessmentsTable } from '../../../../store/api/types/projects';
import { styled } from '@mui/material';
import checked from '../../../../assets/icons/checked.svg';
import close from '../../../../assets/icons/close.svg';
import { useUpdateProjectMutation } from '../../../../store/api/projects';
import { useParams } from 'react-router-dom';

const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginRight: '8px',
  marginBottom: '2.5px',
}));

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

type IRemoveAssessmentsData = {
  deleteAssessments: AssessmentsTable[];
  leaderAssignedAssessments: AssessmentsTable[];
};

type IProps = {
  assignments: AssessmentsTable[];
};

const StyledText = styled('p')(() => ({
  fontFamily: 'Avenir',
  fontSize: '16px',
  fontWeight: '600',
}));

const StyledBox = styled('div')(() => ({
  display: 'flex',
}));

const AssessmentParticipantComponent = ({ assignments }: IProps) => {
  return (
    <StyledBox>
      <StyledImg
        src={close}
        alt={'close'}
      />
      <StyledText sx={{ color: '#334255', opacity: 0.5 }}>
        {`${assignments.length} ${
          assignments.length === 1 ? 'Assessment is' : 'Assessments are'
        } already linked to one or more leaders. You cannot remove an assessment from a project when a leader has already been assigned`}
      </StyledText>
    </StyledBox>
  );
};

const AssessmentConfirmationComponent = ({ assignments }: IProps) => {
  return (
    <StyledBox>
      <StyledImg
        src={checked}
        alt={'checked'}
      />
      <StyledText sx={{ color: '#334255' }}>
        Are you sure you want to remove {assignments.length}
        {assignments.length === 1 ? ' Assessment' : ' Assessments'}?
      </StyledText>
    </StyledBox>
  );
};

const ProjectAssessmentsRemoveModal = () => {
  const params = useParams();

  const [isDisabled, setIsDisabled] = React.useState(false);

  const isOpen = useAppSelector(
    (state) => state.projectAssessmentsModal.isRemoveAssessmentsModal
  );
  const removeAssessmentsData = useAppSelector(
    (state) => state.projectAssessmentsModal.removeAssessmentsData
  );

  // Assessments with status as assigned, In Progress and Completes can not be delete
  // Assessments with only staged status can be deleted as they do  not have participants added to them
  const assignments = removeAssessmentsData.reduce(
    (acc, initValue) => {
      if (initValue.assigned || initValue.completed || initValue.inProgress)
        acc = {
          ...acc,
          leaderAssignedAssessments: [
            ...acc.leaderAssignedAssessments,
            initValue,
          ],
        };
      else
        acc = {
          ...acc,
          deleteAssessments: [...acc.deleteAssessments, initValue],
        };
      return acc;
    },
    {
      deleteAssessments: [],
      leaderAssignedAssessments: [],
    } as IRemoveAssessmentsData
  );

  const [deleteAssessmentFromProject] = useUpdateProjectMutation();

  const {
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
    closeProjectAssessmentsRemoveModal,
  } = useActions();

  const handleClose = () => {
    closeProjectAssessmentsRemoveModal();
  };

  const onSubmit = () => {
    setIsDisabled(true);

    const payload = {
      projectId: params.projectId,
      removeAssessmentStructures: assignments.deleteAssessments.map(
        (item) => item.id
      ),
    };

    deleteAssessmentFromProject(payload)
      .unwrap()
      .then(() => {
        toggleSuccessSnackbar({
          message: `${assignments.deleteAssessments.length} ${
            assignments.deleteAssessments.length === 1
              ? 'Assessment'
              : 'Assessments'
          } Removed Successfully`,
        });
        handleClose();
      })
      .catch((e) => {
        const errorMsg = e?.data?.message || 'Error while deleting assessments';
        setIsDisabled(false);
        toggleErrorSnackbar({ message: errorMsg });
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <>
        <ModalTitle onClose={handleClose}>
          Removing {removeAssessmentsData.length}
          {removeAssessmentsData.length === 1 ? ' Assessment' : ' Assessments'}
        </ModalTitle>
        <ModalBody>
          {!!assignments.leaderAssignedAssessments.length && (
            <AssessmentParticipantComponent
              assignments={assignments.leaderAssignedAssessments}
            />
          )}
          {!!assignments.deleteAssessments.length && (
            <AssessmentConfirmationComponent
              assignments={assignments.deleteAssessments}
            />
          )}
        </ModalBody>
        <ModalActions>
          <Button
            btnType={'PRIMARY'}
            disabled={isDisabled || !assignments.deleteAssessments.length}
            text={'Remove Assessments'}
            onClick={onSubmit}
          />
          <Button
            btnType={'SECONDARY'}
            text={'Cancel'}
            onClick={handleClose}
          />
        </ModalActions>
      </>
    </Modal>
  );
};

export default ProjectAssessmentsRemoveModal;
