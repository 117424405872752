import React from 'react';
import { Box, styled } from '@mui/material';
import TabMenu from '../../atoms/tab/TabMenu';
import TabMenuItem from '../../atoms/tab/TabMenuItem';
import VariableLibraryHeader from '../../organisms/headers/VariableLibraryHeader';
import Functions from './Functions';
import SubFunctions from './SubFunctions';
import ExpertiseCategories from './ExpertiseCategories';
import Levels from './Levels';
import ImpactModules from './ImpactModules';
import SkillCategories from './SkillCategories';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: 'auto',
  paddingLeft: '20px',
  maxWidth: '1200px',
  '.tabContent': {
    paddingTop: '40px',
  },
});

const VariableLibrary = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setTabValue(newValue);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <VariableLibraryHeader />
      <StyledContentWrapper>
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={'Functions'}
          />
          <TabMenuItem
            index={1}
            label={'Sub-Functions'}
          />
          <TabMenuItem
            index={2}
            label={'Expertise Categories'}
          />
          <TabMenuItem
            index={3}
            label={'Levels'}
          />
          <TabMenuItem
            index={4}
            label={'Impact Modules'}
          />
          <TabMenuItem
            index={5}
            label={'Skill Categories'}
          />
        </TabMenu>
        <Box className='tabContent'>
          <Functions
            index={0}
            value={tabValue}
          />
          <SubFunctions
            index={1}
            value={tabValue}
          />
          <ExpertiseCategories
            index={2}
            value={tabValue}
          />
          <Levels
            index={3}
            value={tabValue}
          />
          <ImpactModules
            index={4}
            value={tabValue}
          />
          <SkillCategories
            index={5}
            value={tabValue}
          />
        </Box>
      </StyledContentWrapper>
    </Box>
  );
};

export default VariableLibrary;
