import React, { useState } from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormFileUpload from '../../../molecules/form/FormFileUpload';
import { useForm } from 'react-hook-form';
import FormWrapper from '../../../molecules/form/FormWrapper';
import { ImportFileFormData } from '../../../commons/types';

interface IProps {
  setFileData: React.Dispatch<
    React.SetStateAction<Array<{ docType: string; file: File }>>
  >;
}

export default function ImportCompanyEmployeeResumeModal({
  setFileData,
}: IProps) {
  const methods = useForm<ImportFileFormData>();

  const {
    clearErrors,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const isOpen = useAppSelector(
    (state) => state.importCompanyEmployeeResumeModal.isOpen
  );

  const fileTypeArray = [
    { keyName: 'Report', keyValue: 'report' },
    { keyName: 'Resume', keyValue: 'resume' },
  ];

  const [progress, setProgress] = useState(0);
  const [docType, setDocType] = useState('');
  const [employeeFile, setFile] = useState<File | null>();

  const { closeImportCompanyEmployeeResumeModal } = useActions();

  const handleClose = () => {
    reset();
    setProgress(0);
    closeImportCompanyEmployeeResumeModal();
  };

  React.useEffect(() => {
    reset();
  }, [reset, isOpen]);

  const handleFileData = (file: File | null) => {
    reset();
    if (!docType && file) {
      setProgress(100);
      setFile(file);
      setError('fileType', {
        type: 'string',
        message: 'Please select a file type',
      });
      return;
    }
    if (file && docType) {
      setDocType('');
      setProgress(100);
      setFileData((value) => [...value, { docType, file }]);
      handleClose();
      return;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>Upload a file.</ModalTitle>
      <ModalBody>
        <FormWrapper
          id={'employee-resume'}
          methods={methods}
        >
          <FormLabelDropdown
            name='fileType'
            label='File type'
            rules={{ required: 'Please select a file type' }}
            dropDownItem={fileTypeArray}
            placeholder={'Select a file type'}
            onChange={(e) => {
              const docType = e.target.value as string;
              clearErrors('fileType');
              setDocType(docType);
              if (employeeFile) {
                setDocType('');
                setFileData((value) => [
                  ...value,
                  { docType, file: employeeFile },
                ]);
                setFile(null);
                handleClose();
              }
            }}
          />
          <FormFileUpload
            accept='.pdf,.docx,.doc,.txt'
            name='uploadFile'
            progress={progress}
            rules={{ required: 'Please select a file' }}
            formatErrorMsg='There was an error with the file you provided. Supported formats include PDF, Word Doc, Text files '
            onChange={handleFileData}
          />
        </FormWrapper>
      </ModalBody>
    </Modal>
  );
}
