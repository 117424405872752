export const accessList = {
  project: {
    page: 'CLIENT/PROJECTS/READ',
    project_list: {
      add: 'CLIENT/PROJECTS/PROJECT_LIST/ADD_PROJECT/CREATE',
      edit: 'CLIENT/PROJECTS/PROJECT_LIST/EDIT_PROJECT/UPDATE',
      status_close: 'CLIENT/PROJECTS/PROJECT_LIST_STATUSCLOSE/UPDATE',
    },
    project_details: {
      edit: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/EDIT_PROJECT/UPDATE',
      project_leaders: {
        add: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/ADD_LEADER/CREATE',
        remove: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/REMOVE_LEADER/DELETE',
        send_invite: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/SEND_INVITE/READ',
        add_new_leader:
          'CLIENT/PROJECTS/PROJECT_DETAIL_PG/ADD_NEW_LEADER/CREATE',
      },
      add_assessment: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/ADD_ASSESSMENT/CREATE',
      remove_assessment:
        'CLIENT/PROJECTS/PROJECT_DETAIL_PG/REMOVE_ASSESSMENT/DELETE',
      cbi: {
        page: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/CBI_PG/READ',
        assign_assessor:
          'CLIENT/PROJECTS/PROJECT_DETAIL_PG/CBI_ASSESSMENT/ASSIGN_ASSESSOR/UPDATE',
        survey:
          'CLIENT/PROJECTS/PROJECT_DETAIL_PG/CBI_ASSESSMENT/CBI_INTERVIEW/READ',
      },
    },
    project_report: {
      read: 'CLIENT/PROJECTS/PROJECT_DETAIL_PG/THIRD_PARTY_REPORT/READ',
    },
  },
  client: {
    assessments: {
      read: 'CLIENT/ASSESSMENTS/READ',
      edit: 'CLIENT/ASSESSMENTS/EDIT_ASSESSMENT/UPDATE',
    },
    impact_groups: {
      release_impact_assessment:
        'CLIENT/IMPACT_GROUPS/RELEASE_IMPACT_GROUP/CREATE',
    },
  },
  new_app_access: {
    read: 'HEIDRICK/NEW_APP_ACCESS/READ',
  },
};

export type accessListTypes = {
  project: {
    page: string;
    project_list: {
      add: string;
      edit: string;
      status_close: string;
    };
    project_details: {
      edit: string;
      project_leaders: {
        add: string;
        remove: string;
        send_invite: string;
        add_new_leader: string;
      };
      add_assessment: string;
      remove_assessment: string;
      cbi: {
        page: string;
        assign_assessor: string;
        survey: string;
      };
    };
    project_report: {
      read: string;
    };
  };
  client: {
    assessments: {
      read: string;
      edit: string;
    };
    impact_groups: {
      release_impact_assessment: string;
    };
  };
  new_app_access: {
    read: string;
  };
};
