import React from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import LabelDatePicker from '../../atoms/datepicker/LabelDatePicker';

interface IProps {
  label?: string;
  tooltip?: string;
  isDatePickerDisabled?: boolean;
}

const FormLabelDatePicker = ({
  name,
  label,
  tooltip,
  defaultValue = '',
  rules,
  isDatePickerDisabled,
  ...extraProps
}: IProps & UseControllerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        ...rules,
        validate: {
          formatValidation: (value) =>
            !value?.invalid || value?.invalid.explanation,
        },
      }}
      {...extraProps}
      render={({ field: { onChange, ref, ...restFiled } }) => (
        <LabelDatePicker
          label={label}
          tooltip={tooltip}
          error={!!errors[name]}
          onChange={(event) => {
            onChange(event);
          }}
          inputFieldProps={{
            error: !!errors[name],
            helperText: errors[name]?.message as unknown as string,
            disabled: isDatePickerDisabled,
          }}
          datePickerProps={{
            views: ['day'],
            inputRef: ref,
            ...restFiled,
            disabled: isDatePickerDisabled,
          }}
        />
      )}
    />
  );
};

export default FormLabelDatePicker;
