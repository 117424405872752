import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormFileUpload from '../../../molecules/form/FormFileUpload';
import {
  useImportCompanyUsersMutation,
  useImportEmployeeMutation,
} from '../../../store/api/uploadFile';
import { ImportFileResponse } from '../../../store/api/types/uploadFile';
import { ImportFileFormData } from '../../../commons/types';

interface IProps {
  clientId: string;
}

export default function ImportCompanyUserModal({ clientId }: IProps) {
  const methods = useForm<ImportFileFormData>();

  const [fileData, setFileData] = React.useState<File | null>(null);

  const {
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const isOpen = useAppSelector((state) => state.importCompanyUserModal.isOpen);
  const progress = useAppSelector(
    (state) => state.importCompanyUserModal.progress
  );

  const fileTypeArray = [
    { keyName: 'Employee', keyValue: 'employee' },
    { keyName: 'Education', keyValue: 'education' },
    { keyName: 'Experience', keyValue: 'experience' },
    { keyName: 'Performance Rating', keyValue: 'performanceRating' },
    { keyName: 'Capabilities Data', keyValue: 'capabilitiesData' },
  ];

  const {
    closeImportComapnyUserModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    setImportCompanyUserUploadProgress,
  } = useActions();

  const [uploadFile] = useImportCompanyUsersMutation();
  const [importEmployee] = useImportEmployeeMutation();

  const handleClose = () => {
    setFileData(null);
    closeImportComapnyUserModal();
  };

  const onSubmit = async (data: ImportFileFormData) => {
    const formData = new FormData();

    if (fileData) {
      formData.append('file', fileData);
      formData.append('client', clientId);
      if (
        data.fileType === 'education' ||
        data.fileType === 'experience' ||
        data.fileType === 'performanceRating'
      ) {
        if (data.fileType === 'education') {
          formData.append('importType', 'Education');
        } else if (data.fileType === 'experience') {
          formData.append('importType', 'Experience');
        } else {
          formData.append('importType', 'Performance Rating');
        }
      }
      setImportCompanyUserUploadProgress(0);
      if (
        data.fileType === 'employee' ||
        data.fileType === 'education' ||
        data.fileType === 'experience' ||
        data.fileType === 'performanceRating'
      ) {
        importEmployee({ fileType: data.fileType, formData })
          .then((res) => {
            const response = res as ImportFileResponse;
            setImportCompanyUserUploadProgress(0);
            if (response.data) {
              if (response.data.error) {
                toggleErrorSnackbar({ message: response.data.error });
                return;
              }
              if (response.data.status === 'error') {
                toggleErrorSnackbar({ message: response.data.message });
                return;
              }
            }
            if (response.error) {
              toggleErrorSnackbar({ message: response.error.message });
              return;
            }
            toggleSuccessSnackbar({
              message: 'The file has been uploaded successfully',
            });
            handleClose();
          })
          .catch((e) => {
            setImportCompanyUserUploadProgress(0);
            toggleErrorSnackbar({ message: e.message });
          });
      } else {
        uploadFile({ fileType: data.fileType, formData })
          .then((res) => {
            const response = res as ImportFileResponse;
            setImportCompanyUserUploadProgress(0);
            if (response.data) {
              if (response.data.error) {
                toggleErrorSnackbar({ message: response.data.error });
                return;
              }
              if (response.data.status === 'error') {
                toggleErrorSnackbar({ message: response.data.message });
                return;
              }
            }
            if (response.error) {
              toggleErrorSnackbar({ message: response.error.message });
              return;
            }
            toggleSuccessSnackbar({
              message: 'The file has been uploaded successfully',
            });
            handleClose();
          })
          .catch((e) => {
            setImportCompanyUserUploadProgress(0);
            toggleErrorSnackbar({ message: e.message });
          });
      }
    } else
      setError('uploadFile', {
        type: 'string',
        message: 'Please select a file',
      });
  };

  const handleFileData = (file: File | null) => setFileData(file);

  React.useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>Upload a file.</ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-user'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelDropdown
            name='fileType'
            rules={{ required: 'Please select a file type' }}
            label='File type'
            dropDownItem={fileTypeArray}
            placeholder={'Select a file type'}
          />
          <FormFileUpload
            accept='.xlsx,.xls,.csv'
            name='uploadFile'
            progress={progress}
            rules={{ required: 'Please select a file' }}
            formatErrorMsg='There was an error with the file you provided. Supported formats include Excel files. '
            onChange={handleFileData}
          />
        </FormWrapper>
      </ModalBody>
      {!!fileData && Boolean(progress) === false && (
        <ModalActions>
          <Button
            btnType={'PRIMARY'}
            text={'Upload file'}
            type='submit'
            form='add-new-user'
            disabled={!!Object.values(errors).length || !!progress}
          />
          <Button
            btnType={'SECONDARY'}
            text={'Cancel'}
            onClick={handleClose}
          />
        </ModalActions>
      )}
    </Modal>
  );
}
