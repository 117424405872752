import { Box, styled } from '@mui/material';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import TabContent from '../../../../atoms/tab/TabContent';
import { ITabContentProps } from '../../../../commons/types';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import ResetButton from '../../../../atoms/button/ResetButton';
import { MRT_TableInstance } from 'material-react-table';
import ProjectAssessmentsTable from '../../../../organisms/tables/Company/Projects/ProjectDetails/ProjectAssessmentsTable';
import Button from '../../../../atoms/button/Button';
import plusIcon from '../../../../assets/icons/Plus.svg';
import ProjectAssessmentModal from '../../../../organisms/modals/Project/ProjectDetails/ProjectAssessmentsModal';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { AssessmentsTable } from '../../../../store/api/types/projects';
import { IProjectLeadersData } from '../../../../store/api/types/leaderProfile';
import HDSpinner from '../../../../hd-ui-kit/HDSpinner';
import { Restricted } from '../../../../rbac/Restricted';
import TopToolbar from '../../../../atoms/table/TopToolbar';
import ProjectAssessmentsRemoveModal from '../../../../organisms/modals/Project/ProjectDetails/ProjectAssessmentsRemoveModal';

interface IProps {
  data: AssessmentsTable[] | [];
  isLoading: boolean;
  projectLeaderData: IProjectLeadersData[];
  setTabValue: Dispatch<SetStateAction<number>>;
  setTableFilters: Dispatch<
    SetStateAction<{
      assessment: string;
      participant: string;
    }>
  >;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '13px',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '95%',
  gap: '15px',
  marginTop: '15px',
});

const SpinnerWrapper = styled(Box)(() => ({
  '& .la-ball-spin.la-2x': {
    margin: '0 auto',
    top: '40%',
  },
  width: '100%',
  height: '100%',
  zIndex: 99,
  textAlign: 'center',
}));

export const ProjectAssessments = ({
  index,
  value,
  data,
  isLoading,
  projectLeaderData,
  setTabValue,
  setTableFilters,
}: ITabContentProps & IProps) => {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const [rowSelection, setRowSelection] = React.useState<
    Array<AssessmentsTable>
  >([]);

  const { openProjectAssessmentModal, openProjectAssessmentsRemoveModal } =
    useActions();

  const isOpen = useAppSelector(
    (state) => state.projectAssessmentsModal.isOpen
  );
  const isProjectLoader = useAppSelector(
    (state) => state.projectSpinner.isLoading
  );
  const isOpenRemove = useAppSelector(
    (state) => state.projectAssessmentsModal.isRemoveAssessmentsModal
  );

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      {isProjectLoader ? (
        <SpinnerWrapper>
          <HDSpinner
            size={'la-2x'}
            buttonColor={false}
          />
        </SpinnerWrapper>
      ) : (
        <>
          <StyledTitlesWrapper>
            <StyledTitleWrapper>
              <Title
                text={'Assessments'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <StyledHeaderActionWrapper>
                <Restricted allowedTo='project.project_details.add_assessment'>
                  <Button
                    btnType={'PRIMARY'}
                    text={'Add Assessments'}
                    endIcon={<img src={plusIcon} />}
                    onClick={() =>
                      openProjectAssessmentModal(projectLeaderData)
                    }
                  />
                </Restricted>
              </StyledHeaderActionWrapper>
            </StyledTitleWrapper>
            <StyledSubHeaderWrapper>
              <SubTitle text={`${data.length} total Assessments`} />
              <ResetButton
                onClick={onResetButtonClick}
                text='Reset'
              />
            </StyledSubHeaderWrapper>
          </StyledTitlesWrapper>

          {!!rowSelection.length && (
            <>
              <TopToolbar
                rowSelectionLabel={
                  rowSelection.length === 1
                    ? 'assessment selected'
                    : 'assessments selected'
                }
                rowsSelected={rowSelection.length}
                FirstButtonLabel={'Remove Assessments'}
                FirstButtonAction={() =>
                  openProjectAssessmentsRemoveModal(rowSelection)
                }
                iconType={'reportIcon'}
              />
            </>
          )}

          <ProjectAssessmentsTable
            setRecord={setRowSelection}
            isLoading={isLoading}
            data={data}
            tableInstanceRef={tableInstanceRef}
            setTabValue={setTabValue}
            setTableFilters={setTableFilters}
          />
        </>
      )}
      {isOpen && <ProjectAssessmentModal />}
      {isOpenRemove && <ProjectAssessmentsRemoveModal />}
    </TabContent>
  );
};
