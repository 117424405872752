import CardContent, { CardContentProps } from '@mui/material/CardContent';
import { styled } from '@mui/material';

const StyledCardContent = styled(CardContent)(() => ({
  paddingTop: '24px',
  display: 'flex',
  flexDirection: 'column',
}));

const HpCardContent = ({ children, ...extraProps }: CardContentProps) => {
  return <StyledCardContent {...extraProps}>{children}</StyledCardContent>;
};

export default HpCardContent;
