import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IImpactGroupData } from '../api/types/leadership';

interface IImpactGroupEditPayload {
  id: string;
  name: string;
  description: string;
}
interface ICompanyImpactGroupModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: IImpactGroupEditPayload;
}

const initialState: ICompanyImpactGroupModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    description: '',
  },
};

const companyImpactGroupSlice = createSlice({
  name: 'companyImpactGroup',
  initialState,
  reducers: {
    openCompanyImpactGroupModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    closeCompanyImpactGroupModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    editCompanyImpactGroupModal: (
      state,
      action: PayloadAction<IImpactGroupData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        description: action.payload.description || '',
      };
    },
  },
});

export const companyImpactGroupActions = companyImpactGroupSlice.actions;
export const companyImpactGroupReducer = companyImpactGroupSlice.reducer;
