import React, { useRef } from 'react';
import { Box } from '@mui/material';
import Modal from '../../../../../atoms/modal/Modal';
import ModalTitle from '../../../../../atoms/modal/ModalTitle';
import SubTitle from '../../../../../atoms/title/SubTitle';
import ModalBody from '../../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../../atoms/modal/ModalActions';
import Button from '../../../../../atoms/button/Button';
import { useActions } from '../../../../../hooks/actions';
import { useAppSelector } from '../../../../../hooks/redux';
import { MRT_TableInstance } from 'material-react-table';
import { useGetAdminUsersQuery } from '../../../../../store/api/v2/auth';
import CalibrationTable from '../../../../tables/Company/Projects/ProjectDetails/AssessmentDetails/CalibrationTable';
import { AssessmentLeadersTable } from '../../../../../store/api/types/projects';
import { IUserData } from '../../../../../store/api/v2/types/auth';
import { useParams } from 'react-router';

const modalStyle = {
  '& .MuiDialog-paper': {
    minWidth: '768px',
    maxWidth: '1200px',
  },
};

export default function CalibrationModal() {
  const params = useParams();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const [assessmentSelected, setAssessmentSelected] = React.useState<
    Array<AssessmentLeadersTable>
  >([]);
  const [modalData, setModalData] = React.useState<
    Array<AssessmentLeadersTable>
  >([]);

  const { data: usersData } = useGetAdminUsersQuery();

  const isOpen = useAppSelector((state) => state.calibrationModal.isOpen);
  const leaderData = useAppSelector((state) => state.calibrationModal.leaders);

  React.useEffect(() => {
    // Logic to format and display the primary assessor, co-assessor and status in modal table data
    if (leaderData.length && usersData?.data.length) {
      const updatedData = leaderData.map((leader) => {
        // Initial status
        let status = 'Not Ready';

        // get Primary and Co-Assessor name from their respective ids
        const pAssessor: IUserData[] = usersData?.data.filter(
          (item) => item.id === leader?.pAssessor
        );
        const cAssessor: IUserData[] = usersData?.data.filter(
          (item) => item.id === leader?.cAssessor
        );
        const pAssessorDetails = pAssessor.length && pAssessor[0];
        const cAssessorDetails = cAssessor.length && cAssessor[0];

        // update status to ready for these particular assessment's status
        if (
          leader?.resultStatus === 'final_summary_submitted' ||
          leader?.resultStatus === 'cohort_in_review' ||
          leader?.resultStatus === 'cohort_ready' ||
          leader?.resultStatus === 'cbi_submitted'
        ) {
          status = 'Ready';
        }

        // return the updated object
        return {
          ...leader,
          pAssessor: pAssessorDetails
            ? pAssessorDetails.firstName + ' ' + pAssessorDetails.lastName
            : '',
          cAssessor: cAssessorDetails
            ? cAssessorDetails.firstName + ' ' + cAssessorDetails.lastName
            : '',
          status,
        };
      });

      // set the modal data with the updated array containing primary assessor name, co-assessor name and aggregated status
      setModalData(updatedData);
    }
  }, [leaderData, usersData?.data]);

  const { closeCalibrationModal } = useActions();

  const handleClose = () => {
    closeCalibrationModal();
  };

  const onStartCalibration = () => {
    const assessmentIds = assessmentSelected
      .map((assessment) => assessment.assessmentId)
      .join(',');
    window.location.href = `/cbi-assessments/${params.id}/assessment/cohort-calibration?assessmentIds=${assessmentIds}`;
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>Cohort Calibration</ModalTitle>
      <SubTitle text='Select leaders to include in calibration' />
      <Box>
        <ModalBody>
          <CalibrationTable
            data={modalData}
            tableInstanceRef={tableInstanceRef}
            checkSave={setAssessmentSelected}
          />
        </ModalBody>
        <ModalActions>
          <Button
            btnType={'PRIMARY'}
            text={'Start Calibration'}
            disabled={!assessmentSelected.length}
            onClick={onStartCalibration}
          />
        </ModalActions>
      </Box>
    </Modal>
  );
}
