import styled from '@emotion/styled';
import { Box, List, ListItem, BoxProps } from '@mui/material';

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
}));

const TooltipBenchmarkData = (props: BoxProps) => (
  <Box {...props}>
    Benchmark scale:
    <List>
      <StyledListItem>1 - Developing</StyledListItem>
      <StyledListItem>2 - Emerging</StyledListItem>
      <StyledListItem>3 - Proficient</StyledListItem>
      <StyledListItem>4 - Advanced</StyledListItem>
      <StyledListItem>5 - Expert</StyledListItem>
    </List>
  </Box>
);

export default TooltipBenchmarkData;
