import { Box, styled } from '@mui/material';
import { MRT_TableInstance } from 'material-react-table';
import React, { useRef } from 'react';
import Button from '../../atoms/button/Button';
import ResetButton from '../../atoms/button/ResetButton';
import TabContent from '../../atoms/tab/TabContent';
import SubTitle from '../../atoms/title/SubTitle';
import Title from '../../atoms/title/Title';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import { useActions } from '../../hooks/actions';
import ExpertiseCategoriesVLModal from '../../organisms/modals/VariableLibrary/ExpertiseCategoriesVLModal';
import ExpertiseCategoriesVLTable from '../../organisms/tables/VariableLibrary/ExpertiseCategoriesVLTable';
import { useGetExpertiseCategoryQuery } from '../../store/api/leadership';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ExpertiseCategories = ({ index, value }: ITabContentProps) => {
  const { data: { data } = {}, isLoading } =
    useGetExpertiseCategoryQuery('Master');

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const { openAddExpertiseCategoryVLModal } = useActions();

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <ExpertiseCategoriesVLModal />
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Expertise Categories'}
              sx={{ fontSize: '28px' }}
            />
            <Box>
              <HpTooltip title={' '} />
            </Box>
          </StyledHeaderWrapper>
          <StyledHeaderActionWrapper>
            <Button
              btnType={'PRIMARY'}
              text={'Add new expertise category'}
              onClick={() => openAddExpertiseCategoryVLModal()}
            />
          </StyledHeaderActionWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${
              data?.length ? data?.length : '0'
            } total expertise categories`}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <ExpertiseCategoriesVLTable
        data={data}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
      />
    </TabContent>
  );
};

export default ExpertiseCategories;
