import React, { useMemo, useRef } from 'react';
import { Box, styled } from '@mui/material';
import TabContent from '../../../atoms/tab/TabContent';
import Title from '../../../atoms/title/Title';
import { ITabContentProps } from '../../../commons/types';
import SubTitle from '../../../atoms/title/SubTitle';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { useGetImpactModuleQuery } from '../../../store/api/leadership';
import ImpactModulesClientVLTable from '../../../organisms/tables/Company/VariableLibrary/ImpactModulesClientVLTable';
import Button from '../../../atoms/button/Button';
import { useActions } from '../../../hooks/actions';
import { ImpactModuleVLClientModal } from '../../../organisms/modals/Company/VariableLibrary/ImpactModuleVLClientModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ClientImpactModules = ({ index, value }: ITabContentProps) => {
  const { id } = useParams();

  const { data: { data: impactModuleList } = {}, isLoading } =
    useGetImpactModuleQuery(id as string);

  const { openAddImpactModuleVLClientModal } = useActions();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  return (
    <>
      <TabContent
        index={index}
        value={value}
      >
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <StyledHeaderWrapper>
              <Title
                text={'Impact Modules'}
                sx={{ fontSize: '28px' }}
              />
            </StyledHeaderWrapper>
            <StyledHeaderActionWrapper>
              <Button
                btnType={'PRIMARY'}
                text={'Add new impact module'}
                onClick={() => openAddImpactModuleVLClientModal()}
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            {impactModuleList ? (
              <SubTitle
                text={`${impactModuleList.length} total impact modules`}
              />
            ) : null}
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <ImpactModulesClientVLTable
          data={impactModuleList || []}
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
        />
      </TabContent>
      <ImpactModuleVLClientModal />
    </>
  );
};

export default ClientImpactModules;
