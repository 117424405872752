import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import TabContent from '../../../../atoms/tab/TabContent';
import { ITabContentProps } from '../../../../commons/types';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import ResetButton from '../../../../atoms/button/ResetButton';
import ProjectLeadersTable from '../../../../organisms/tables/Company/Projects/ProjectDetails/ProjectLeadersTable';
import Button from '../../../../atoms/button/Button';
import plusIcon from '../../../../assets/icons/Plus.svg';
import ReportGenerationModal from '../../../../organisms/modals/Project/ProjectDetails/ReportGenerationModal';
import { useActions } from '../../../../hooks/actions';
import TopToolbar from '../../../../atoms/table/TopToolbar';
import AddLeadersModal from '../../../../organisms/modals/Project/ProjectDetails/AddLeadersModal';
import { useAppSelector } from '../../../../hooks/redux';
import RemoveLeadersModal from '../../../../organisms/modals/Project/ProjectDetails/RemoveLeadersModal';
import { IProjectLeadersData } from '../../../../store/api/types/leaderProfile';
import HDSpinner from '../../../../hd-ui-kit/HDSpinner';
import { Restricted } from '../../../../rbac/Restricted';
import MuiButton from '@mui/material/Button';
import {
  Assessments,
  IProjectData,
} from '../../../../store/api/types/projects';
import { useSendLeadersToTazioMutation } from '../../../../store/api/v2/leaderProfile';
import { useGetClientByIdQuery } from '../../../../store/api/clients';
import { useParams } from 'react-router';
import AddNewParticipantModal from '../../../../organisms/modals/Project/ProjectDetails/AddNewParticipantModal';
import { IAssessmentData } from '../../../../store/api/types/assessmentStatusDashboard';

interface IProps {
  data: IProjectLeadersData[];
  projectData?: IProjectData;
  setTabValue: Dispatch<SetStateAction<number>>;
  setAssignmentTableFilter: Dispatch<
    SetStateAction<{
      assessment: string;
      participant: string;
    }>
  >;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '95%',
  gap: '15px',
});

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '13px',
}));

const SpinnerWrapper = styled(Box)(() => ({
  '& .la-ball-spin.la-2x': {
    margin: '0 auto',
    top: '40%',
  },
  width: '100%',
  height: '100%',
  zIndex: 99,
  textAlign: 'center',
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const ColumnTooltip = ({ row }: { row: IProjectLeadersData }) => {
  return (
    <StyledTooltip
      placement='right-start'
      title={
        <>
          <Typography>Not Invited : {row.staged}</Typography>
          <Typography>Not Started : {row.assigned}</Typography>
        </>
      }
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -30],
              },
            },
          ],
        },
      }}
    >
      <MuiButton>{row.notStarted}</MuiButton>
    </StyledTooltip>
  );
};

export default function ProjectLeaders({
  index,
  value,
  data,
  projectData,
  setTabValue,
  setAssignmentTableFilter,
}: ITabContentProps & IProps) {
  const { id } = useParams();
  const tableInstanceRef = useRef<MRT_TableInstance>(null);
  const [rowSelection, setRowSelection] = React.useState<
    Array<IProjectLeadersData>
  >([]);

  const {
    openProjectModal,
    openAddLeadersModal,
    openRemoveLeadersModal,
    openAddNewParticipantModal,
  } = useActions();
  const isLeadersModalOpen = useAppSelector(
    (state) => state.addLeadersModal.isOpen
  );
  const isAddNewParticipantModalOpen = useAppSelector(
    (state) => state.addNewParticipantModal.isOpen
  );
  const isLoading = useAppSelector((state) => state.projectSpinner.isLoading);
  const isReportsModalOpen = useAppSelector(
    (state) => state.reportGenerationModal.isOpen
  );

  const [sendLeadersToTazio] = useSendLeadersToTazioMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    id as string
  );

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();

    tableInstanceRef.current !== null &&
      tableInstanceRef.current.setRowSelection({});
  };

  const reportDownloadHandle = () => {
    openProjectModal(rowSelection);
  };
  const remove = () => {
    openRemoveLeadersModal(rowSelection);
  };

  const ReleaseToTanzioHandle = async () => {
    try {
      const emailList = rowSelection.map((row) => row.leaderEmail);
      const leaderProfileIdList = rowSelection.map(
        (row) => row.leaderProfileId
      );
      const payload = {
        client_id: clientData && clientData[0]?.id,
        profileIdList: leaderProfileIdList as string[],
        emailList: emailList as string[],
        account_id:
          (clientData && clientData[0]?.applications?.insights?.accountId) ||
          '',
        project_id:
          (projectData && projectData?.insightsProject?.sourceProjectId) || '',
      };

      const response = await sendLeadersToTazio(payload).unwrap();

      if (response.code === 200) {
        toggleSuccessSnackbar({
          message: 'Leaders details have been sent to Tazio!',
        });
      } else {
        toggleErrorSnackbar({
          message:
            'The system encountered an issue while sending the leader information.',
        });
      }
    } catch (error) {
      toggleErrorSnackbar({
        message:
          'The system encountered an issue while sending the leader information.',
      });
    }
  };

  const handleAddLeaderClick = () => {
    const leaderIds = (data.map((item) => item.id) as Array<string>) || [];
    openAddLeadersModal(leaderIds);
  };

  // Get assessmentStructureIds added to project , to  fetch its type on add leader modal
  let assessmentStructureIds: string[] = [];
  if (projectData?.assessmentStructures) {
    assessmentStructureIds =
      projectData?.assessmentStructures.map(
        (item: { assessmentStructureId: string }) => item?.assessmentStructureId
      ) || [];
  }

  const columns = useMemo<MRT_ColumnDef<IProjectLeadersData>[]>(
    () => [
      {
        header: 'Participant',
        accessorKey: 'leader',
        filterFn: 'includesString',
        size: 250,
      },
      {
        header: 'Email',
        accessorKey: 'leaderEmail',
        filterFn: 'includesString',
        size: 250,
      },
      {
        header: 'Assessments',
        accessorKey: 'totalAssessments',
        filterFn: 'includesString',
      },
      {
        header: 'Not Started',
        accessorFn: (row) => {
          if (!row.notStarted) return row.notStarted;
          return <ColumnTooltip row={row} />;
        },
        filterFn: 'includesString',
      },
      {
        header: 'In Progress',
        accessorKey: 'inProgress',
        filterFn: 'includesString',
      },
      {
        header: 'Completed',
        accessorKey: 'complete',
        filterFn: 'includesString',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        filterFn: 'includesString',
      },
      {
        header: 'Report',
        accessorKey: 'report',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      {' '}
      {isLoading ? (
        <SpinnerWrapper>
          <HDSpinner
            size={'la-2x'}
            buttonColor={false}
          />
        </SpinnerWrapper>
      ) : (
        <>
          {isReportsModalOpen && (
            <ReportGenerationModal resetTableSelections={onResetButtonClick} />
          )}
          <StyledTitlesWrapper>
            <StyledTitleWrapper>
              <Title
                text={'Participants'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <StyledHeaderActionWrapper>
                <Restricted allowedTo='project.project_details.project_leaders.add'>
                  <Button
                    btnType={'PRIMARY'}
                    text={'Add Participants'}
                    endIcon={<img src={plusIcon} />}
                    onClick={handleAddLeaderClick}
                  />
                </Restricted>
                <Restricted allowedTo='project.project_details.project_leaders.add_new_leader'>
                  <Button
                    btnType={'PRIMARY'}
                    text={'Add New User'}
                    endIcon={<img src={plusIcon} />}
                    onClick={() => openAddNewParticipantModal()}
                  />
                </Restricted>
              </StyledHeaderActionWrapper>
            </StyledTitleWrapper>
            <StyledSubHeaderWrapper>
              <SubTitle text={`${data.length} total Participants`} />
              <ResetButton
                onClick={onResetButtonClick}
                text='Reset'
              />
            </StyledSubHeaderWrapper>
          </StyledTitlesWrapper>
          {!!rowSelection.length && (
            <>
              <TopToolbar
                rowsSelected={rowSelection.length}
                SecondButtonLabel={'Generate Reports'}
                FirstButtonLabel={'Remove leaders'}
                ThirdButtonLabel={
                  projectData && projectData?.insightsProject?.sourceProjectId
                    ? 'Send Invite'
                    : ''
                }
                ThirdButtonAction={ReleaseToTanzioHandle}
                SecondButtonAction={reportDownloadHandle}
                FirstButtonAction={remove}
                iconType={'reportIcon'}
              />
              <RemoveLeadersModal />
            </>
          )}
          <ProjectLeadersTable
            setRowSelection={setRowSelection}
            tableInstanceRef={tableInstanceRef}
            tabIndex={3}
            data={data || []}
            setTabValue={setTabValue}
            columns={columns}
            setTableFilters={setAssignmentTableFilter}
          />
          {isLeadersModalOpen && (
            <AddLeadersModal
              leadersData={data}
              projectData={projectData}
              assessmentStructureIds={assessmentStructureIds || []}
            />
          )}
          {isAddNewParticipantModalOpen && <AddNewParticipantModal />}
        </>
      )}
    </TabContent>
  );
}
