import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import { routes } from '../../../../routes/routes';
import PageHeader from '../../../../atoms/header/PageHeader';
import ResetButton from '../../../../atoms/button/ResetButton';
import Button from '../../../../atoms/button/Button';
import { useActions } from '../../../../hooks/actions';
import plusIcon from '../../../../assets/icons/Plus.svg';

interface IProps {
  onResetButtonClick: () => void;
  total: number;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  justifyContent: 'end',
  alignItems: 'center',
});

const ReportsHeader = ({ onResetButtonClick, total }: IProps) => {
  const { openAddReportsModal } = useActions();

  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.company.reports.name} />
        <StyledSubHeaderWrapper>
          <SubTitle text={`${total ? total : '0'} total reports`} />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <StyledHeaderActionWrapper>
        <Button
          btnType={'PRIMARY'}
          text={'Add Report'}
          endIcon={<img src={plusIcon} />}
          onClick={() => openAddReportsModal()}
        />
      </StyledHeaderActionWrapper>
    </PageHeader>
  );
};

export default ReportsHeader;
