import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../atoms/modal/Modal';
import ModalTitle from '../../atoms/modal/ModalTitle';
import ModalBody from '../../atoms/modal/ModalBody';
import ModalActions from '../../atoms/modal/ModalActions';
import SubTitle from '../../atoms/title/SubTitle';
import Button from '../../atoms/button/Button';
import FormLabelInput from '../../molecules/form/FormLabelInput';
import FormWrapper from '../../molecules/form/FormWrapper';
import { useActions } from '../../hooks/actions';
import { useAppSelector } from '../../hooks/redux';
import { IAdminUserFormData } from '../../commons/types';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} from '../../store/api/v2/auth';

// delete modal has different width in UI. To keep the logic intact Within this component for add, edit and delete,
// the component is provided with a conditional style rather than employing a dedicated molecule currently.
const deleteModalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    width: '504px',
    minWidth: '504px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

export default function AdminsModal() {
  const methods = useForm<IAdminUserFormData>();

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const initialValues = useAppSelector(
    (state) => state.adminsModal.initialValues
  );

  const isOpen = useAppSelector((state) => state.adminsModal.isOpen);
  const modalType = useAppSelector((state) => state.adminsModal.modalType);

  const { closeAdminsModal, toggleErrorSnackbar, toggleSuccessSnackbar } =
    useActions();

  const [addUser] = useCreateUserMutation();
  const [editUser] = useEditUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const handleClose = () => {
    closeAdminsModal();
  };

  const onSubmit = async (formData: IAdminUserFormData) => {
    const payload = {
      id: formData.id || '',
      firstName: formData.firstName,
      lastName: formData.lastName,
      userName: formData.emailAddress,
      roles: [{ name: 'Heidrick Admins' }],
      myPersonas: [
        {
          name: 'Managed Services Admin',
        },
      ],
    };
    const modalTypeMap = {
      add: {
        fn: addUser,
        messageSuccess: 'User Added Successfully',
        errorMessage: 'Error Adding User',
      },
      edit: {
        fn: editUser,
        messageSuccess: 'User Updated Successfully',
        errorMessage: 'Error Updating User',
      },
      delete: {
        fn: deleteUser,
        messageSuccess: 'The user has been deleted',
        errorMessage: 'Error Deleting User',
      },
    };
    try {
      const response = await modalTypeMap[modalType].fn(payload).unwrap();
      if (response.status === 'success') {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
      } else {
        if (
          response.errors &&
          response.errors[Object.keys(response.errors)[0]].length
        ) {
          toggleErrorSnackbar({
            message: response.errors[Object.keys(response.errors)[0]][0],
          });
        }
      }
    } catch (e) {
      toggleErrorSnackbar({
        message:
          typeof e == 'string'
            ? (e as string)
            : modalTypeMap[modalType].errorMessage,
      });
    }
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues, isOpen]);

  const modalTitle = (type: string) => {
    if (type === 'add') return 'Add a new user';
    else if (type === 'edit') return 'Edit user';
    else return 'Are you sure you want to delete this user?';
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalType === 'delete' ? deleteModalStyle : undefined}
    >
      <ModalTitle onClose={handleClose}>{modalTitle(modalType)}</ModalTitle>
      <ModalBody>
        {modalType === 'delete' ? (
          <SubTitle text='Deleting the user will remove the user’s permission to access the product' />
        ) : (
          <FormWrapper
            methods={methods}
            id='add-new-user'
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormLabelInput
              name='firstName'
              rules={{ required: 'Please provide first name' }}
              label='First name'
              defaultValue={initialValues.firstName}
            />
            <FormLabelInput
              name='lastName'
              rules={{ required: 'Please provide last name' }}
              label='Last name'
              defaultValue={initialValues.lastName}
            />
            <FormLabelInput
              name='emailAddress'
              rules={{ required: 'Please provide email address' }}
              label='Email address'
              defaultValue={initialValues.emailAddress}
            />
          </FormWrapper>
        )}
      </ModalBody>
      <ModalActions>
        {modalType === 'delete' ? (
          <Button
            btnType={'PRIMARY'}
            text={'Delete'}
            onClick={() => onSubmit(initialValues)}
          />
        ) : (
          <Button
            btnType={'PRIMARY'}
            text={'Save'}
            type='submit'
            form='add-new-user'
            disabled={!!Object.values(errors).length}
          />
        )}
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
