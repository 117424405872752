import { Box } from '@mui/system';
import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../atoms/button/Button';
import Modal from '../../../atoms/modal/Modal';
import ModalActions from '../../../atoms/modal/ModalActions';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import TooltipBenchmarkData from '../../../atoms/tooltip/TooltipBenchmarkData';
import { DropdownTypes, IAddToRoleFormData } from '../../../commons/types';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import FormInputSlider from '../../../molecules/form/FormInputSlider';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../../molecules/form/FormWrapper';
import {
  useAddRoleToStatementMutation,
  useGetRoleProfilesQuery,
  useLazyGetRolesAssignedToStatementQuery,
} from '../../../store/api/leadership';
import { IAddRoleToStatementRequest } from '../../../store/api/types/leadership';

const addToRoleModalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    width: '493px',
    minWidth: '493px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

// NOTE:
// Currently this component only handles addToRole functionality as the page only has
// one CTA button at the time of commit. This component can be merged to a common modal
// once other types of modals get added in the statement detail page.

const AddToRoleModal = () => {
  const skill = useAppSelector((state) => state.addToRoleModal.skillID);
  const clientId = useAppSelector((state) => state.addToRoleModal.clientId);
  const statementUUID = useAppSelector(
    (state) => state.addToRoleModal.statementUUID
  );
  const skillGroup = useAppSelector((state) => state.addToRoleModal.skillGroup);

  const [filteredRoleList, setFilteredRoleList] = React.useState<
    Array<DropdownTypes>
  >([]);

  const [getRolesAssignedToStatement, { data }] =
    useLazyGetRolesAssignedToStatementQuery();

  const { data: { data: roleList } = {} } = useGetRoleProfilesQuery(
    clientId || 'Master'
  );
  const [addStatementToRole] = useAddRoleToStatementMutation();

  const { closeAddToRoleModal, toggleSuccessSnackbar, toggleErrorSnackbar } =
    useActions();
  const isOpen = useAppSelector((state) => state.addToRoleModal.isOpen);
  const modalType = useAppSelector((state) => state.addToRoleModal.modalType);
  const initialValues = useAppSelector(
    (state) => state.addToRoleModal.initialValues
  );

  React.useEffect(() => {
    if (skill) getRolesAssignedToStatement(skill || '').unwrap();
  }, [getRolesAssignedToStatement, skill]);

  // filters roleList , as only the roles which are not already associated with the statements should be in the dropdown list.
  React.useEffect(() => {
    if (
      data &&
      data.data &&
      Array.isArray(data.data?.roles) &&
      Array.isArray(roleList)
    ) {
      setFilteredRoleList(
        roleList
          .filter(
            (roleProfile) =>
              !data.data?.roles.some(
                (statementRole) => statementRole.id === roleProfile.id
              )
          )
          .map((item) => ({ keyValue: item.id, keyName: item.name }))
      );
    }
  }, [roleList, data]);

  const methods = useForm<IAddToRoleFormData>();

  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data: IAddToRoleFormData) => {
    const payload = {
      clientId: clientId || 'Master',
      payload: {
        roleProfileId: data.role || '',
        benchmark: Number(data.proficiency) || 0,
        statementLibraryUUID: statementUUID,
        skillGroup,
      },
    } as IAddRoleToStatementRequest;
    const modalTypeMap = {
      addToRole: {
        fn: addStatementToRole,
        messageSuccess: 'Statement added to role successfully',
        errorMessage: 'Error adding statement to role',
      },
    };
    try {
      const response = await modalTypeMap[modalType].fn(payload).unwrap();
      if (response.status === 'success') {
        setFilteredRoleList([]);
        handleClose();
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
      } else {
        toggleErrorSnackbar({
          message: modalTypeMap[modalType].errorMessage,
        });
      }
    } catch (e) {
      toggleErrorSnackbar({
        message:
          typeof e == 'string'
            ? (e as string)
            : modalTypeMap[modalType].errorMessage,
      });
    }
  };

  const handleClose = () => {
    reset(initialValues);
    closeAddToRoleModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => handleClose()}
      disableEscapeKeyDown={true}
      sx={addToRoleModalStyle}
    >
      <ModalTitle onClose={() => handleClose()}>{'Apply to Role'}</ModalTitle>
      <ModalBody>
        <Box sx={{ paddingBottom: '25px' }}>
          This statement WILL NOT be added to existing client roles. it will be
          applied to all future use of this role.
        </Box>
        <FormWrapper
          methods={methods}
          id='add-to-role'
          onSubmit={handleSubmit(onSubmit)}
        >
          {filteredRoleList && (
            <FormLabelDropdown
              name='role'
              placeholder='Select role'
              rules={{ required: 'Please select a role' }}
              label='Role'
              defaultValue={''}
              dropDownItem={filteredRoleList}
            />
          )}
          <FormInputSlider
            name='proficiency'
            label='Default Benchmark Score'
            setValue={setValue}
            tooltip={<TooltipBenchmarkData />}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Apply'}
          type='submit'
          form='add-to-role'
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={() => closeAddToRoleModal()}
        />
      </ModalActions>
    </Modal>
  );
};

export default AddToRoleModal;
