import React from 'react';
import StatementDetailsHeader from '../../organisms/headers/StatementDetailsHeader';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router';
import CultureImpactSLModal from '../../organisms/modals/Statement/CultureImpactSLModal';
import FunctionalImpactSLModal from '../../organisms/modals/Statement/FunctionalImpactSLModal';
import LeadershipCapabilitySLModal from '../../organisms/modals/Statement/LeadershipCapabilitySLModal';
import LeadershipPotentialSLModal from '../../organisms/modals/Statement/LeadershipPotentialSLModal';
import OrganizationalImpactSLModal from '../../organisms/modals/Statement/OrganizationalImpactSLModal';
import { useGetRolesAssignedToStatementQuery } from '../../store/api/leadership';
import StatementDetailsTable from '../../organisms/tables/Statement/StatementDetailsTable';

const StatementDetails = () => {
  const params = useParams();
  const { data: { data } = {}, isLoading } =
    useGetRolesAssignedToStatementQuery(params.id || '');

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 98px - 66px)',
          overflowY: 'scroll',
        }}
      >
        <StatementDetailsHeader id={params.id as string} />
        <Container
          sx={{
            marginBottom: '50px',
          }}
        >
          <StatementDetailsTable
            isLoading={isLoading}
            data={data?.roles || []}
          />
        </Container>
      </Box>
      <CultureImpactSLModal />
      <FunctionalImpactSLModal />
      <LeadershipCapabilitySLModal />
      <LeadershipPotentialSLModal />
      <OrganizationalImpactSLModal />
    </>
  );
};

export default StatementDetails;
