import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAddToRoleModalProps {
  isOpen: boolean;
  modalType: 'addToRole';
  initialValues: {
    role: string;
    skill: string;
    proficiency: number;
  };
  skillID: string;
  clientId: string;
  statementUUID: string;
  skillGroup: string;
}

const initialState: IAddToRoleModalProps = {
  isOpen: false,
  modalType: 'addToRole',
  initialValues: {
    role: '',
    skill: '',
    proficiency: 1,
  },
  clientId: '',
  skillID: '',
  statementUUID: '',
  skillGroup: '',
};

const addToRoleModalSlice = createSlice({
  name: 'addToRoleModal',
  initialState,
  reducers: {
    openAddToRoleModal: (
      state,
      action: PayloadAction<{
        skill: string;
        clientId?: string;
        statementUUID: string;
        skillGroup: string;
      }>
    ) => ({
      ...initialState,
      isOpen: true,
      skillID: action.payload.skill,
      clientId: action.payload.clientId || '',
      skillGroup: action.payload.skillGroup,
      statementUUID: action.payload.statementUUID,
    }),
    closeAddToRoleModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const addToRoleModalActions = addToRoleModalSlice.actions;
export const AddToRoleModalReducer = addToRoleModalSlice.reducer;
