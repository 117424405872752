import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetClientStatementData } from '../api/types/leadership';

interface ICultureImpactSLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: {
    id: string;
    name: string;
    description: string;
    skill_group: string;
  };
}

const initialState: ICultureImpactSLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    description: '',
    skill_group: '',
  },
};

const cultureImpactSLModalSlice = createSlice({
  name: 'cultureImpactSLModal',
  initialState,
  reducers: {
    closeCultureImpactSLModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddCultureImpactSLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditCultureImpactSLModal: (
      state,
      action: PayloadAction<IGetClientStatementData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        description: action.payload.description || '',
        skill_group:
          action.payload.cultureImpactType || action.payload.skillGroup || '',
      };
    },
  },
});

export const cultureImpactSLModalActions = cultureImpactSLModalSlice.actions;
export const cultureImpactSLModalReducer = cultureImpactSLModalSlice.reducer;
