import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminUserFormData } from '../commons/types';
import { IUserData } from './api/v2/types/auth';

interface IUserModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit' | 'delete';
  initialValues: IAdminUserFormData;
}

const initialState: IUserModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
  },
};

const adminsModalSlice = createSlice({
  name: 'userModal',
  initialState,
  reducers: {
    closeAdminsModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddAdminsModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditAdminsModal: (state, action: PayloadAction<IUserData>) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        firstName: action.payload.firstName || '',
        lastName: action.payload.lastName || '',
        emailAddress: action.payload.userName || '',
      };
    },
    openDeleteAdminsModal: (state, action: PayloadAction<IUserData>) => {
      state.isOpen = true;
      state.modalType = 'delete';
      state.initialValues = {
        id: action.payload.id || '',
        firstName: '',
        lastName: '',
        emailAddress: '',
      };
    },
  },
});

export const adminsModalActions = adminsModalSlice.actions;
export const adminsModalReducer = adminsModalSlice.reducer;
