import React, { useRef, useMemo } from 'react';
import { Box, styled } from '@mui/material';
import TabContent from '../../../atoms/tab/TabContent';
import Title from '../../../atoms/title/Title';
import HpTooltip from '../../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../../commons/types';
import FunctionClientVLTable from '../../../organisms/tables/Company/VariableLibrary/FunctionVLClientTable';
import { MRT_TableInstance } from 'material-react-table';
import SubTitle from '../../../atoms/title/SubTitle';
import ResetButton from '../../../atoms/button/ResetButton';
import Button from '../../../atoms/button/Button';
import FunctionVLModal from '../../../organisms/modals/VariableLibrary/FunctionVLModal';
import { useGetFunctionsListQuery } from '../../../store/api/leadership';
import { useParams } from 'react-router-dom';
import { useActions } from '../../../hooks/actions';
import FunctionVLClientModal from '../../../organisms/modals/Company/VariableLibrary/FunctionVLClientModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ClientFunctions = ({ index, value }: ITabContentProps) => {
  const { data: { data: FunctionMasterData } = {}, isLoading } =
    useGetFunctionsListQuery('Master');

  const { id } = useParams();

  const { data: { data: FunctionIdData } = {} } = useGetFunctionsListQuery(
    id as string
  );

  const { openAddFunctionVLClientModal } = useActions();

  // Here we are generating table data mapping thorugh code fieldValue
  const updatedData = useMemo(() => {
    if (!FunctionMasterData || !FunctionIdData) return [];

    // Create a map of fieldValue to systemName from FunctionMasterData
    const systemNameMap = new Map(
      FunctionMasterData.map((item) => [item.code, item.fieldValue])
    );

    const merged = FunctionIdData.map((item) => {
      const systemName = systemNameMap.get(item.code) || '-';

      return {
        ...item,
        systemName: systemName,
      };
    });

    return merged;
  }, [FunctionMasterData, FunctionIdData]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <>
      <FunctionVLClientModal />
      <TabContent
        index={index}
        value={value}
      >
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <StyledHeaderWrapper>
              <Title
                text={'Functions'}
                sx={{ fontSize: '28px' }}
              />
              <Box>
                <HpTooltip title={' '} />
              </Box>
            </StyledHeaderWrapper>
            <StyledHeaderActionWrapper>
              <Button
                btnType={'PRIMARY'}
                text={'Add new function'}
                onClick={() => openAddFunctionVLClientModal()}
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                updatedData?.length ? updatedData?.length : '0'
              } total functions`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <FunctionClientVLTable
          data={updatedData}
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
        />
      </TabContent>
    </>
  );
};

export default ClientFunctions;
