import React, { useState } from 'react';
import Modal from '../../atoms/modal/Modal';
import ModalTitle from '../../atoms/modal/ModalTitle';
import ModalBody from '../../atoms/modal/ModalBody';
import ModalActions from '../../atoms/modal/ModalActions';
import FormLabelInput from '../../molecules/form/FormLabelInput';
import FormLabelDropdown from '../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../molecules/form/FormWrapper';
import Button from '../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useGetClientQuery } from '../../store/api/clients';
import { useActions } from '../../hooks/actions';
import { useAppSelector } from '../../hooks/redux';
import { DropdownTypes, IRoleFormData } from '../../commons/types';
import HpCheckbox from '../../atoms/checkbox/HpCheckbox';
import { Box } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { IUpdateRole } from '../../store/api/types';
import { IAddToClientResponse } from '../../store/api/types/clientrole';
import LabelDropdown from '../../atoms/dropdown/LabelDropdown';
import {
  useGetFunctionsListQuery,
  useGetSubFunctionListQuery,
  useGetLevelsListQuery,
  useGetSkillCategoriesQuery,
  useAddRoleMutation,
  useEditRoleMutation,
  useGetRoleProfilesQuery,
  useAddRoleProfileToClientMutation,
  useLazyDownloadCompanyRolesQuery,
} from '../../store/api/leadership';
import {
  IAddRoleResponse,
  IAddToClientPayload,
  IDownloadRole,
  IEditRoleRequest,
} from '../../store/api/types/leadership';
import _ from 'lodash';

// need defaultSelectedClient to prevent dropdown from crashing due to undefined state
const defaultSelectedClient = { keyName: '', keyValue: '' };

export default function RoleModal() {
  // need state to allow checkbox action in download button. As form is not required for only one checkbox currently.
  const [roleProfilesCheckbox, setRoleProfilesCheckbox] =
    useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<DropdownTypes>(
    defaultSelectedClient
  );

  const { id: clientId } = useParams();
  const { pathname } = useLocation();

  const [addRole] = useAddRoleMutation();
  const [editRole] = useEditRoleMutation();
  const [downloadRoles] = useLazyDownloadCompanyRolesQuery();
  const [addRoleToClient] = useAddRoleProfileToClientMutation();

  const initialValues = useAppSelector(
    (state) => state.roleModal.initialValues
  );
  const isOpen = useAppSelector((state) => state.roleModal.isOpen);
  const modalType = useAppSelector((state) => state.roleModal.modalType);
  const filteredSubFunctionList = useAppSelector(
    (state) => state.roleModal.filteredSubFunctionList
  );

  const { data: functionData } = useGetFunctionsListQuery(
    pathname.includes('company') ? (clientId as string) : 'Master',
    {
      skip: modalType === 'applyToClient',
    }
  );
  const { data: subFunctionData } = useGetSubFunctionListQuery(
    pathname.includes('company') ? (clientId as string) : 'Master',
    {
      skip: modalType === 'applyToClient',
    }
  );
  const { data: levelData } = useGetLevelsListQuery(
    pathname.includes('company') ? (clientId as string) : 'Master',
    {
      skip: modalType === 'applyToClient',
    }
  );
  const { data: { data: roleData } = {} } = useGetRoleProfilesQuery(
    pathname.includes('company') ? (clientId as string) : 'Master',
    {
      skip: modalType === 'applyToClient',
    }
  );
  const { data: clientData } = useGetClientQuery(undefined, {
    skip: modalType !== 'applyToClient',
  });

  const handleCompanyOnChange = (value: string) => {
    const parsedClient = clientData?.clientDropdown?.filter(
      (c) => c.keyValue === value
    )[0];
    parsedClient && setSelectedClient(parsedClient);
  };

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeRoleModal,
    setSubFunctionList,
  } = useActions();

  const navigate = useNavigate();

  const methods = useForm<IRoleFormData>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = methods;

  const handleClose = () => {
    setRoleProfilesCheckbox(false);
    closeRoleModal();
    setSelectedClient(defaultSelectedClient);
  };

  const functionOnChangeHandler = (value: string) => {
    setValue('sub_function', '');
    setSubFunctionList({
      list: subFunctionData?.subFunctionsListDropdown,
      value,
    });
  };

  const onSubmit = async (formData: IRoleFormData) => {
    const modalTypeMap = {
      edit: {
        fn: editRole,
        messageSuccess: 'Role Updated Successfully',
        errorMessage: 'Error Updating Role',
      },
      add: {
        fn: addRole,
        messageSuccess: 'Role Added Successfully',
        errorMessage: 'Error Adding Role',
      },
      download: {
        fn: downloadRoles,
        messageSuccess: 'The file has been downloaded successfully',
        errorMessage: 'The file could not be downloaded',
      },
      applyToClient: {
        fn: addRoleToClient,
        messageSuccess: `${initialValues.name} has been successfully assigned to ${selectedClient?.keyName}`,
        errorMessage: 'Please select a client account',
      },
      clientClone: {
        fn: addRole,
        messageSuccess: 'Role Cloned Successfully',
        errorMessage: 'Error Cloning Role',
      },
    };

    try {
      const {
        function: functionId,
        sub_function,
        level,
        ...restFormData
      } = formData;
      const payload = {
        ...restFormData,
      };
      if (modalType !== 'download') {
        const selectedFunctionData = functionData?.data.find(
          (item) => item.id === functionId
        );
        const selectedSubFunctionData = subFunctionData?.data.find(
          (item) => item.id === sub_function
        );
        const selectedLevelData = levelData?.data.find(
          (item) => item.id === level
        );
        const roleId =
          (selectedLevelData?.code || '') + selectedSubFunctionData?.code;
        const idx = roleData?.findIndex((item) => item.roleId === roleId);
        if (modalType === 'edit') {
          if (initialValues.roleId !== roleId && idx !== -1) {
            toggleSuccessSnackbar({
              message:
                'Duplicate role id, select different level or sub function',
            });
            return;
          }
        }
        if (modalType == 'add') {
          if (idx !== -1) {
            toggleSuccessSnackbar({
              message:
                'Duplicate role id, select different level or sub function',
            });
            return;
          }
        }

        if (modalType !== 'applyToClient') {
          payload.roleId = formData.roleId;
          payload.functionCode = selectedFunctionData?.code;
          payload.subFunctionCode = selectedSubFunctionData?.code;
          payload.levelCode = selectedLevelData?.code;
        }
        if (
          modalType === 'edit' ||
          modalType === 'add' ||
          modalType === 'clientClone'
        ) {
          payload.roleId = roleId;
        }
        if (modalType == 'add' || modalType == 'clientClone') {
          payload.clientId = pathname.includes('company')
            ? (clientId as string)
            : 'Master';
        }
      }

      if (modalType === 'download') {
        payload.id = 'Master';
        payload.name = 'Roles.zip';
      }

      const response = (await modalTypeMap[modalType]
        .fn(
          payload as IUpdateRole &
            IAddToClientPayload &
            IRoleFormData &
            IEditRoleRequest &
            IDownloadRole
        )
        .unwrap()) as IAddRoleResponse & IAddToClientResponse;

      if (response.status === 'success') {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });

        handleClose();

        if (
          modalType !== 'applyToClient' &&
          modalType !== 'download' &&
          modalType === 'add'
        ) {
          const role = response.data;

          if (pathname.includes('company')) {
            navigate(
              `${routes.company.url}${clientId as string}${
                routes.company.roles.url
              }/${role.roleProfile.id}`
            );
          } else {
            navigate(`${routes.roleLibrary.url}/${role.roleProfile.id}`);
          }
        }
      } else {
        if (response.Role) {
          // if the role already exists on a client it should show a user-friendly error message as defined below
          toggleErrorSnackbar({
            message: `${initialValues.name} has already been assigned to ${selectedClient?.keyName}`,
          });
        } else if (
          response.errors &&
          response.errors[Object.keys(response.errors)[0]].length
        ) {
          toggleErrorSnackbar({
            message: response.errors[Object.keys(response.errors)[0]][0],
          });
        }
      }
    } catch (e) {
      const error = e as { status: number; data: { detail: string } };
      toggleErrorSnackbar({
        message: error.data.detail ?? modalTypeMap[modalType].errorMessage,
      });
    }
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues, isOpen]);

  const modalTitle = (type: string) => {
    // es-lint dismissed warnings with this type of linting
    switch (type) {
      case 'add':
        return pathname.includes('company')
          ? 'Add new client role'
          : 'Add new role';
      case 'edit':
        return pathname.includes('company')
          ? 'Edit a client role'
          : 'Edit a role';
      case 'download':
        return 'Select file types to download';
      case 'clientClone':
        return 'Clone client role';
      default:
        return `Add ${initialValues?.name} to Client Library`;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>{modalTitle(modalType)}</ModalTitle>
      <ModalBody>
        {modalType === 'download' ? (
          <Box sx={{ padding: '5px' }}>
            <HpCheckbox
              label='Role Profiles'
              onChange={(e) => setRoleProfilesCheckbox(e.target.checked)}
            />
          </Box>
        ) : modalType === 'applyToClient' ? (
          <Box sx={{ padding: '5px' }}>
            <LabelDropdown
              label='Client Name'
              value={selectedClient?.keyValue}
              dropDownItem={clientData?.clientDropdown ?? []}
              onChange={(e) => handleCompanyOnChange(e.target.value as string)}
              placeholder='Select a client account'
              rules={{ required: 'Please select a client account' }}
            />
          </Box>
        ) : (
          <FormWrapper
            methods={methods}
            id='add-new-role'
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormLabelInput
              name='name'
              rules={{ required: 'Please provide a role name' }}
              label='Role Name'
              defaultValue={initialValues.name}
            />
            {(modalType === 'edit' || modalType === 'clientClone') &&
              pathname.includes('company') && (
                <FormLabelInput
                  name='clientRoleCode'
                  label='Client Role ID'
                  defaultValue={initialValues.clientRoleCode}
                />
              )}
            <FormLabelInput
              name='abbreviation'
              rules={{ required: 'Please provide a role abbreviation' }}
              label='Role Abbreviation'
              defaultValue={initialValues.abbreviation}
            />
            <FormLabelDropdown
              name='function'
              rules={{ required: 'Please select a function' }}
              label='Function'
              onChange={(e) =>
                functionOnChangeHandler(e.target.value as string)
              }
              dropDownItem={functionData?.functionsListDropdown || []}
              defaultValue={
                functionData?.functionsListDropdown && initialValues.function
              }
            />
            <FormLabelDropdown
              name='sub_function'
              rules={{ required: 'Please select a sub-function' }}
              label='Sub-function'
              dropDownItem={filteredSubFunctionList || []}
              defaultValue={
                filteredSubFunctionList && initialValues.sub_function
              }
            />
            <FormLabelDropdown
              name='level'
              rules={{ required: 'Please select a level' }}
              label='Level'
              dropDownItem={levelData?.levelListDropdown || []}
              defaultValue={levelData?.levelListDropdown && initialValues.level}
            />
            <FormLabelInput
              name='description'
              rules={{ required: 'Please provide a role description' }}
              label='Description'
              extraLabelInputProps={{
                minRows: 2,
                maxRows: 2,
                multiline: true,
              }}
              defaultValue={initialValues.description}
            />
          </FormWrapper>
        )}
      </ModalBody>
      <ModalActions>
        {modalType === 'download' ? (
          <Button
            btnType={'PRIMARY'}
            text={'Download'}
            disabled={!roleProfilesCheckbox}
            onClick={() => onSubmit(initialValues)}
          />
        ) : modalType === 'applyToClient' ? (
          <Button
            btnType={'PRIMARY'}
            text={'Save'}
            disabled={!selectedClient.keyValue}
            onClick={() =>
              onSubmit({
                roleProfileId: initialValues?.id,
                clientId: selectedClient?.keyValue as string,
              } as IRoleFormData)
            }
          />
        ) : (
          <Button
            btnType={'PRIMARY'}
            text={'Save'}
            type='submit'
            form='add-new-role'
            disabled={!!Object.values(errors).length}
          />
        )}
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
