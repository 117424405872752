import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IRoleResponse,
  IRoleRequest,
  IUpdateRole,
  ICreateStatementLibrarySkillRequest,
  ICreateStatementLibrarySkillResponse,
  IClientConfigResponse,
  ICreateRoleResponse,
  ICreateAdminUserResponse,
  ICreateAdminUserErrorResponse,
} from './types';
import { IExcelResponse } from './types/roleskills';
import { downloadFile, getDatedFileName, saveFileNameAsCSV } from './helpers';
import { IEmployeeResponse } from './types/employee';
import { COMPANY_USERS } from './constants';
import {
  ICreateClientUserRequest,
  ICreateClientUserResponse,
} from './types/clientuser';
import { IClientByUserIdResponse } from './types/client';

export const coreApi = createApi({
  reducerPath: 'core/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_KEY}core/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Role',
    'Skill',
    'RoleSkills',
    'Employee',
    'StatementRoleProfiles',
    'ClientRole',
    'ClientRoleSkills',
    'Branch',
    'OktaGroups',
    'OktaApps',
  ],
  endpoints: (builder) => ({
    releaseInvite: builder.mutation<void, string>({
      query: (clientId) => ({
        url: 'ReleaseInvite/',
        method: 'POST',
        body: {
          client: clientId,
        },
      }),
    }),

    // Downloads
    downloadRoles: builder.query<IExcelResponse, IUpdateRole>({
      // IUpdateRole is added to keep compatible type in implementation
      query: () => {
        return {
          url: 'RoleSkillsExcelFile/',
          // https://github.com/reduxjs/redux-toolkit/issues/1522#issuecomment-1167482553
          responseHandler: async (response: any) => {
            // using any to avoid complex type compatibility issues
            downloadFile(await response.blob(), 'Roles.xlsx');
            if (response.status === 200) return { status: 'success' };
            // populated string based status from raw response to keep consistency
          },
          cache: 'no-cache',
        };
      },
    }),
    downloadCompanyUsersResumes: builder.query<IExcelResponse, any>({
      query: ({ id }) => {
        return {
          url: `EmployeeAttachmentExcelFile/${id}`,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                getDatedFileName(
                  COMPANY_USERS.Heidrick_ATSNAME_CANDIDATE_ATTACHMENTS_IMPORT
                )
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),
    downloadCompanyUsersEducation: builder.query<IExcelResponse, any>({
      query: ({ id }) => {
        return {
          url: `EmployeeEducationExcelFile/${id}`,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                getDatedFileName(
                  COMPANY_USERS.Heidrick_ATSNAME_EMPOYEE_EDUCATION_IMPORT
                )
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),
    downloadCompanyUsersPerformance: builder.query<IExcelResponse, any>({
      query: ({ id }) => {
        return {
          url: `EmployeePerformanceRatingExcelFile/${id}`,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                getDatedFileName(
                  COMPANY_USERS.Heidrick_ATSNAME_EMPLOYEE_PERFRATING_IMPORT
                )
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),

    // Role endpoints:
    getRoleByName: builder.query<IRoleResponse, string>({
      query: (name) => `Role/?search_key=${name}&filter_name=`,
      transformResponse: (response: IRoleResponse, meta, arg) => {
        if (response.data.length) {
          return {
            status: response.status,
            data: response.data,
            role: response.data.find((role) => role.id === arg),
          };
        }
        return response;
      },
      providesTags: ['Role'],
    }),
    getRoleById: builder.query<IRoleResponse, string>({
      query: () => 'Role/?search_key=&filter_name=',
      transformResponse: (response: IRoleResponse, meta, arg) => {
        if (response.data.length) {
          return {
            status: response.status,
            data: response.data,
            role: response.data.find((role) => role.id === arg),
          };
        }
        return response;
      },
      providesTags: ['Role'],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (clientId) => ({
        url: `Role/${clientId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
    setRole: builder.mutation<ICreateRoleResponse, IRoleRequest>({
      query: (payload) => ({
        url: 'Role/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Role'],
    }),
    cloneRole: builder.mutation<void, string>({
      query: (clientId) => ({
        url: `RoleCopy/${clientId}/`,
        method: 'POST',
      }),
      invalidatesTags: ['Role'],
    }),
    editRole: builder.mutation<ICreateRoleResponse, IUpdateRole>({
      query: (payload) => ({
        url: `Role/${payload.id}/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Role'],
    }),

    // Get Client User by Id
    getClientByUserId: builder.query<IClientByUserIdResponse, string>({
      query: (id) => ({
        url: `ClientByUserId/${id}/`,
        method: 'GET',
      }),
    }),

    // Employee endpoints:
    getEmployeeById: builder.query<IEmployeeResponse, string>({
      query: (id) => `Employee/?search_key=&client=${id}`,
      transformResponse: (response: IEmployeeResponse) => {
        return {
          status: response.status,
          data: response.data,
          roleProfileFilterItems: [
            ...new Set(response.data.map((el) => el.role_name)),
          ]
            .filter((el) => !!el)
            .map((el) => ({ text: el!, value: el! })),
          userTypeFilterItems: [
            ...new Set(response.data.map((el) => el.ef_usertype)),
          ]
            .filter((el) => !!el)
            .map((el) => ({ text: el!, value: el! })),
        };
      },
      providesTags: ['Employee'],
    }),
    deleteEmployeeById: builder.mutation<void, string>({
      query: (id) => ({
        url: `Employee/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employee'],
    }),
    createClientEmployee: builder.mutation<
      ICreateClientUserResponse,
      ICreateClientUserRequest
    >({
      query: (payload) => ({
        url: 'Employee/',
        method: 'POST',
        body: payload,
      }),
      transformErrorResponse: (response: ICreateAdminUserErrorResponse) => {
        if (response.data.code === 406)
          return Object.values(response.data.message)[0][0];
        return response.data.message;
      },
      invalidatesTags: ['Employee'],
    }),
    updateClientEmployee: builder.mutation<
      ICreateClientUserResponse,
      ICreateClientUserRequest
    >({
      query: (payload) => ({
        url: `Employee/${payload.id}/`,
        method: 'PATCH',
        body: payload,
      }),
      transformErrorResponse: (response: ICreateAdminUserErrorResponse) => {
        if (response.data.code === 406)
          return Object.values(response.data.message)[0][0];
        return response.data.message;
      },
      invalidatesTags: ['Employee'],
    }),

    // Navigator
    syncNavigatorUsers: builder.mutation<ICreateAdminUserResponse, string>({
      query: (id) => ({
        url: 'GetEightFoldProfileDetails/',
        method: 'POST',
        body: { client_id: id },
      }),
    }),
    syncNavigatorRole: builder.mutation<ICreateAdminUserResponse, string>({
      query: (id) => ({
        url: 'GetEightFoldRoleDetails/',
        method: 'POST',
        body: { client_id: id },
      }),
    }),
  }),
});

// Invalidates tags of coreAPI in other API
export const coreApiInvalidateTags = coreApi.util.invalidateTags;

export const { useCloneRoleMutation } = coreApi;

// Employee exports:
export const { useGetEmployeeByIdQuery } = coreApi;

// Download exports:
export const {
  useLazyDownloadRolesQuery,
  useLazyDownloadCompanyUsersResumesQuery,
  useLazyDownloadCompanyUsersEducationQuery,
  useLazyDownloadCompanyUsersPerformanceQuery,
} = coreApi;

// Roles exports
export const { useLazyGetRoleByNameQuery } = coreApi;

// Navigator exports
export const { useSyncNavigatorUsersMutation, useSyncNavigatorRoleMutation } =
  coreApi;
