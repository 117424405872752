import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssessmentData } from '../../api/types/assessmentStatusDashboard';

interface IAssessmentModalProps {
  isOpen: boolean;
  modalType: 'view';
  initialValues: IAssessmentData;
}

const initialState: IAssessmentModalProps = {
  isOpen: false,
  modalType: 'view',
  initialValues: {
    participant: '',
    assessment: '',
    project: '',
    projectStartDate: '',
    projectEndDate: '',
    type: '',
    status: '',
    id: '',
    raters: [],
    completedDate: '',
    completedPercentage: '',
    lastReminderSent: '',
    reminderCount: '',
    raterTypes: [],
  },
};

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    openAssessmentModal: (state, action: PayloadAction<IAssessmentData>) => ({
      ...initialState,
      isOpen: true,
      initialValues: {
        id: action.payload.id || '',
        status: action.payload.status || '',
        type: action.payload.type || '',
        project: action.payload.project || '',
        projectStartDate: action.payload.projectStartDate || '',
        projectEndDate: action.payload.projectEndDate || '',
        assessment: action.payload.assessment || '',
        participant: action.payload.participant || '',
        raters: action.payload.raters,
        completedDate: action.payload.completedDate || '',
        completedPercentage: action.payload.completedPercentage || '',
        lastReminderSent: action.payload.lastReminderSent || '',
        reminderCount: action.payload.reminderCount || '',
        raterTypes: action.payload.raterTypes,
      },
    }),
    closeAssessmentModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const assessmentActions = assessmentSlice.actions;
export const assessmentReducer = assessmentSlice.reducer;
