import React from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../../molecules/form/FormWrapper';
import Button from '../../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../../hooks/redux';
import { useActions } from '../../../../hooks/actions';
import {
  useAddLevelMutation,
  useEditLevelMutation,
  useGetLevelsListQuery,
} from '../../../../store/api/leadership';
import { ILevelFormData } from '../../../../store/api/types/leadership';
import { useParams } from 'react-router';
import styled from '@emotion/styled';

const StyledLabel = styled('span')({
  font: 'Avenir',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#646a7c',
});

const BoldStyledLabel = styled(StyledLabel)({
  fontWeight: 800,
});

export const LevelVLClientModal = () => {
  const [levelId, setLevelId] = React.useState<string>('');

  const { id } = useParams<{ id?: string }>();
  const { data: { data: levelList } = {} } = useGetLevelsListQuery(
    id as string
  );

  const [addLevel] = useAddLevelMutation();
  const [editLevel] = useEditLevelMutation();

  const initialValues = useAppSelector(
    (state) => state.levelVLClientModal.initialValues
  );
  const isOpen = useAppSelector((state) => state.levelVLClientModal.isOpen);
  const modalType = useAppSelector(
    (state) => state.levelVLClientModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeLevelVLClientModal,
  } = useActions();

  const methods = useForm<ILevelFormData>();
  const {
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors, isDirty },
  } = methods;

  const handleClose = () => {
    closeLevelVLClientModal();
  };

  const levelCodeHandler = React.useCallback(() => {
    if (modalType === 'add' && isOpen && levelList) {
      let i = 1;
      while (levelList!.find((el) => el.code === `L${i}`)) {
        i++;
      }
      setValue('code', `L${i}`);
    }
  }, [levelList, isOpen, modalType, setValue]);

  React.useEffect(() => {
    reset(initialValues);
    levelCodeHandler();
    if (modalType === 'edit') {
      setLevelId(initialValues?.code as string);
    }
  }, [isOpen, reset, initialValues, levelCodeHandler]);

  const onSubmit = async (formData: ILevelFormData) => {
    const modalTypeMap = {
      add: {
        fn: addLevel,
        messageSuccess: 'Level Added Successfully',
        errorMessage: 'Error Adding Level',
      },
      edit: {
        fn: editLevel,
        messageSuccess: 'Level Updated Successfully',
        errorMessage: 'Error Updating Level',
      },
    };

    // Check the Level ID for after one character it should be followed by Number Only
    const code = formData.code;
    const reg = new RegExp('^[0-9]*$');
    const checknumber = code.substring(1, code.length);
    const isnumber = reg.test(checknumber);

    // Checking the validity of level ID
    if (code.substring(0, 1) !== 'L' || !isnumber || code.length <= 1) {
      setError('code', {
        message: 'Please provide valid level ID',
      });
      return;
    }

    const payload = {
      fieldValue: formData.fieldValue,
      code: formData.code,
      ...(modalType === 'edit' && { id: formData.id || '' }),
      ...(modalType === 'add' && { clientId: id || 'Master' }),
    };

    if (
      modalType === 'add' &&
      levelList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'Level name should be unique',
      });

    if (
      modalType === 'edit' &&
      formData.fieldValue.toLowerCase() !==
        initialValues.fieldValue.toLowerCase() &&
      levelList!.find(
        (el) =>
          el.fieldValue.toLowerCase() === formData.fieldValue.toLowerCase()
      )
    )
      return setError('fieldValue', {
        message: 'Level name should be unique',
      });

    modalTypeMap[modalType]
      .fn(payload)
      .unwrap()
      .then((response) => {
        if (response.status === 'success') {
          toggleSuccessSnackbar({
            message: modalTypeMap[modalType].messageSuccess,
          });
          handleClose();
        }
      })

      .catch((error) => {
        if (error.data && error.data.detail) {
          if (error.data.detail.includes('Level with code')) {
            setError('code', {
              message: error.data.detail,
            });
          } else if (error.data.detail.includes('Level with the fieldValue')) {
            setError('fieldValue', {
              message: error.data.detail,
            });
          } else toggleErrorSnackbar({ message: error.data.detail });
        }
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add new level' : 'Edit level'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='level-modal'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='fieldValue'
            rules={{ required: 'Please provide level name' }}
            label='Level Name'
          />
          {modalType === 'edit' ? (
            <>
              <StyledLabel>
                Level ID: <BoldStyledLabel>{levelId}</BoldStyledLabel>
              </StyledLabel>
            </>
          ) : (
            <>
              <FormLabelInput
                name='code'
                rules={{ required: 'Please provide level ID' }}
                label='Level ID'
                tooltip='tooltip info'
              />
            </>
          )}
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='level-modal'
          disabled={!isDirty || !!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};
