import { useEffect } from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../../molecules/form/FormWrapper';
import Button from '../../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { useCreateLeaderProfileMutation } from '../../../../store/api/v2/leaderProfile';
import { useParams } from 'react-router';

interface INewParticipantFormData {
  firstName: string;
  lastName: string;
  email: string;
  profileType: string;
}

export default function AddNewParticipantModal() {
  const { id } = useParams();

  const isOpen = useAppSelector((state) => state.addNewParticipantModal.isOpen);
  const modalType = useAppSelector(
    (state) => state.addNewParticipantModal.modalType
  );

  const [addNewUser, { isLoading }] = useCreateLeaderProfileMutation();

  const methods = useForm<INewParticipantFormData>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeAddNewParticipantModal,
  } = useActions();

  const handleClose = () => {
    closeAddNewParticipantModal();
  };

  const modalTypeMap = {
    add: {
      fn: addNewUser,
      messageSuccess: 'User Added Successfully',
      title: 'Add New User',
    },
  };

  const onSubmit = async (formData: INewParticipantFormData) => {
    const payload = {
      ...formData,
      clientId: id,
      profileType: 'employee',
    };

    modalTypeMap[modalType]
      .fn(payload)
      .unwrap()
      .then((response) => {
        if (response.status === 'success') {
          toggleSuccessSnackbar({
            message: modalTypeMap[modalType].messageSuccess,
          });
          handleClose();
        }
      })
      .catch((error) => {
        if (error.data && error.data.detail) {
          toggleErrorSnackbar({ message: error.data.detail });
        }
      });
  };

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalTypeMap[modalType].title}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-participant'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='firstName'
            rules={{ required: 'Please provide a first name' }}
            label='First Name'
          />
          <FormLabelInput
            name='lastName'
            rules={{ required: 'Please provide a last name' }}
            label='Last Name'
          />
          <FormLabelInput
            name='email'
            rules={{
              required: 'Please provide an email',
              pattern: {
                value: /^[\w+.-]+@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: 'Please provide a valid email ID',
              },
            }}
            label='Email'
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Add'}
          type='submit'
          form='add-new-participant'
          disabled={!!Object.values(errors).length || isLoading}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
