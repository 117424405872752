import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IIGAddAssessmentID {
  id: string;
  assessmentId: string;
}

interface IIGAddAssessmentIDModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: IIGAddAssessmentID;
}

const initialState: IIGAddAssessmentIDModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    assessmentId: '',
  },
};

const IGAddAssessmentIdSlice = createSlice({
  name: 'IGAddAssessmentID',
  initialState,
  reducers: {
    openIGAddAssessmentIDModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    closeIGAddAssessmentIDModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    addAssessmentIdModal: (
      state,
      action: PayloadAction<IIGAddAssessmentID>
    ) => {
      state.isOpen = true;
      state.modalType = 'add';
      state.initialValues = {
        id: action.payload.id || '',
        assessmentId: action.payload.assessmentId || '',
      };
    },
  },
});

export const IGAddAssessmentIdActions = IGAddAssessmentIdSlice.actions;
export const IGAddAssessmentIdReducer = IGAddAssessmentIdSlice.reducer;
