import React, { useMemo } from 'react';
import Table from '../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { useActions } from '../../../hooks/actions';
import { ISubFunctionsData } from '../../../store/api/types/leadership';
import { useDeleteSubFunctionMutation } from '../../../store/api/leadership';

interface IProps {
  data?: ISubFunctionsData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const SubFunctionVLTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    openEditSubFunctionVLModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const [deleteSubFunction] = useDeleteSubFunctionMutation();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: ISubFunctionsData) => {
    openEditSubFunctionVLModal(row);
    setAnchorEl(null);
  };

  const handleDelete = (subFunctionId: string) => {
    if (subFunctionId) {
      deleteSubFunction(subFunctionId)
        .unwrap()
        .then(() =>
          toggleSuccessSnackbar({
            message: 'Sub-Function Deleted Successfully',
          })
        )
        .catch(({ data }) => {
          toggleErrorSnackbar({ message: data.detail });
        });
    }
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<ISubFunctionsData>[]>(
    () => [
      {
        header: 'Sub-Function Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
      },
      {
        header: 'Function',
        accessorKey: 'functionFieldValue',
        filterFn: 'includesString',
      },
      {
        header: 'Sub-Function ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
      {
        header: 'Roles w/Sub-Function',
        accessorFn: () => '',
      },
      {
        header: 'Statements w/Sub-Function',
        accessorFn: () => '',
      },
      {
        header: 'Sub-Function Abbreviation',
        accessorKey: 'abbreviation',
        filterFn: 'includesString',
      },
      {
        header: 'Sub-Function Family',
        accessorKey: 'subFunctionFamily',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleEdit(row.original as ISubFunctionsData)}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleDelete((row.original as ISubFunctionsData).id || '')
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default SubFunctionVLTable;
