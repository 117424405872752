import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../../../atoms/tab/TabContent';
import HpTooltip from '../../../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../../../commons/types';
import { MRT_TableInstance } from 'material-react-table';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import ResetButton from '../../../../atoms/button/ResetButton';
import { useLazyGetRoleSkillsByCategoryQuery } from '../../../../store/api/leadership';
import RoleTable from '../../../../organisms/tables/RoleTable';
import EditStatementRLModal from '../../../../organisms/modals/Role/EditStatementRLModal';

interface IProps {
  roleId?: string;
  skillType: string;
  client?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export default function LeadershipCapability({
  index,
  value,
  roleId,
  skillType,
  client,
}: ITabContentProps & IProps) {
  const [getImpactStatements, { data: roleSkills, isLoading }] =
    useLazyGetRoleSkillsByCategoryQuery();

  React.useEffect(() => {
    if (value === 2) {
      getImpactStatements({
        client,
        roleId,
        skillType: 'Capabilities',
      });
    }
  }, [value]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Title
              text={'Leadership Capability'}
              sx={{
                fontSize: '28px',
              }}
            />
            <Box>
              <HpTooltip title={'Leadership Capability'} />
            </Box>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                roleSkills?.capabilitiesData?.length
                  ? roleSkills?.capabilitiesData?.length
                  : '0'
              } total statements`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        {value === 2 && <EditStatementRLModal />}
        <RoleTable
          statementType='leadershipCapability'
          isLoading={isLoading}
          data={roleSkills?.capabilitiesData}
          tableInstanceRef={tableInstanceRef}
        />
      </>
    </TabContent>
  );
}
