import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IAddClientStatementRequest,
  IAddImpactGroupRequest,
  IAddImpactGroupResponse,
  IAddStatementResponse,
  IClientImpactModuleResponse,
  ICreateFunctionsRequest,
  ICreateFunctionsResponse,
  IEditFunctionsRequest,
  IEditFunctionsResponse,
  IEditImpactGroupRequest,
  IEditImpactGroupResponse,
  IFunctionsResponse,
  IExcelResponse,
  IGetClientStatementLibraryResponse,
  IGetStatementResponse,
  IGetImpactGroupRolesResponse,
  IImpactGroupsRequest,
  IImpactGroupsResponse,
  ILevelsResponse,
  ICreateLevelResponse,
  ICreateLevelRequest,
  IEditLevelResponse,
  IEditLevelRequest,
  ISubFunctionsResponse,
  ICreateSubFunctionsRequest,
  ICreateSubFunctionsResponse,
  IEditSubFunctionsRequest,
  IEditSubFunctionsResponse,
  IImpactModuleFormData,
  IUpdateImpactModuleResponse,
  IAssignRoleToImpactGroupRequest,
  IGetClientRoleStatementResponse,
  ISkillCategoriesResponse,
  ISkillCategoriesSetRequest,
  ISkillCategoriesSetResponse,
  ISkillCategoriesEditRequest,
  ISkillCategoriesEditResponse,
  IExpertiseCategoryResponse,
  IExpertiseCategorySetResponse,
  IExpertiseCategorySetRequest,
  IExpertiseCategoryEditResponse,
  IExpertiseCategoryEditRequest,
  IAcceleratorResponse,
  ICultureImpactTypeResponse,
  IGetRoleDetailsResponse,
  IGetRoleSkillsResponse,
  IGetRoleSkillsRequest,
  IRolesAssignedToStatementResponse,
  ISkillEditBenchmarkResponse,
  ISkillEditBenchmarkRequest,
  IAddRoleToStatementRequest,
  IAddRoleToStatementResponse,
  IEditRoleRequest,
  IAddToClientPayload,
  IDownloadRole,
  IValidateStatementResponse,
  validateRequest,
  ISkillGroupsResponse,
} from './types/leadership';
import { leaderProfileApiInvalidateTags } from './v2/leaderProfile';
import { downloadFile } from './helpers';
import { IGenericResponse, IRoleFormData } from '../../commons/types';
import { IAddToClientResponse } from './types/clientrole';
import { IUpdateRole } from './types';

export const leadershipApi = createApi({
  reducerPath: 'leadership/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}leadership/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        ?.accessToken?.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'impactGroups',
    'impactGroupById',
    'clientStatements',
    'impactModule',
    'expertiseCategory',
    'functions',
    'singleStatement',
    'levels',
    'subFunctions',
    'impactGroupStatements',
    'impactGroupRoles',
    'skillCategories',
    'roleProfile',
    'roleSkill',
    'roleProfiles',
  ],
  endpoints: (builder) => ({
    // Impact Groups APIs
    getLeaderImpactGroup: builder.query<
      IImpactGroupsResponse,
      IImpactGroupsRequest
    >({
      query: ({ id }) => `impact-groups?clientId=${id}`,
      providesTags: ['impactGroups'],
    }),
    getImpactGroup: builder.query<IImpactGroupsResponse, IImpactGroupsRequest>({
      query: ({ id, limit, offset }) => ({
        url: `impact-groups?clientId=${id}&limit=${limit}&offset=${offset}`,
      }),
      transformResponse: (response: IImpactGroupsResponse) => {
        return {
          status: response.status,
          data: response.data,
          metadata: response.metadata,
          dropdownData:
            response.data &&
            response?.data.map((el) => ({
              text: el.name || '',
              value: el.id || '',
            })),
          impactGroupListDropdown:
            response.data &&
            response?.data.map((el) => ({
              keyName: el.name || '',
              keyValue: el.id || '',
            })),
        };
      },
      providesTags: ['impactGroups'],
    }),
    addLeaderImpactGroup: builder.mutation<
      IAddImpactGroupResponse,
      IAddImpactGroupRequest
    >({
      query: (payload) => ({
        url: 'impact-groups/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['impactGroups'],
    }),
    getLeaderImpactGroupById: builder.query<IImpactGroupsResponse, string>({
      query: (id) => `impact-groups/${id}`,
      providesTags: ['impactGroupById'],
    }),
    editLeaderImpactGroup: builder.mutation<
      IEditImpactGroupResponse,
      IEditImpactGroupRequest
    >({
      query: ({ id, payload }) => ({
        url: `impact-groups/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['impactGroupById', 'impactGroups'],
    }),
    assignRoleToImpactGroup: builder.mutation<
      IEditImpactGroupResponse,
      IAssignRoleToImpactGroupRequest
    >({
      query: ({ id, payload }) => ({
        url: `impact-groups/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['impactGroupById', 'impactGroupRoles'],
    }),
    deleteLeaderImpactGroup: builder.mutation<
      { detail?: string; code?: number; status?: string },
      string
    >({
      query: (id) => ({
        url: `impact-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['impactGroups'],
    }),

    // statement libraries APIs

    getClientStatements: builder.query<
      IGetClientStatementLibraryResponse,
      { skillGroup: string; clientId: string; limit?: number; offset?: number }
    >({
      query: ({ skillGroup, clientId }) =>
        `statement-libraries?clientId=${clientId}&skillGroup=${skillGroup}`,
      providesTags: (result, error, arg) => [
        { type: 'clientStatements' as const, id: arg.skillGroup },
      ],
    }),
    addClientStatement: builder.mutation<
      IAddStatementResponse,
      IAddClientStatementRequest
    >({
      query: ({ params, payload }) => ({
        url: `statement-libraries?clientId=${params.clientId}&skillGroup=${params.skillGroup}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'clientStatements', id: arg.params.skillGroup },
      ],
    }),
    updateClientStatement: builder.mutation<
      IAddStatementResponse,
      IAddClientStatementRequest
    >({
      query: ({ params, payload }) => ({
        url: `statement-libraries/${params.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['clientStatements', 'singleStatement'],
    }),
    deleteClientStatement: builder.mutation<void, string>({
      query: (id) => ({
        url: `statement-libraries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['clientStatements'],
    }),
    getClientStatementById: builder.query<IGetStatementResponse, string>({
      query: (id) => ({
        url: `statement-libraries/${id}`,
        method: 'GET',
      }),
      providesTags: ['singleStatement'],
    }),
    validateStatementById: builder.query<
      IValidateStatementResponse,
      validateRequest
    >({
      query: (payload) => ({
        url: `statement-libraries?clientId=${payload.clientId}&statementId=${payload.statementId}`,
        method: 'GET',
      }),
      providesTags: ['singleStatement'],
    }),

    // Impact Modules APIs
    getImpactModule: builder.query<IClientImpactModuleResponse, string>({
      query: (id) => `impact-module?clientId=${id}`,
      providesTags: ['impactModule'],
      transformResponse: (response: IClientImpactModuleResponse) => {
        return {
          status: response.status,
          data: response.data,
          code: response.code,
          dropdownList: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
          dropdownData: response.data.map((el) => ({
            text: el.fieldValue || '',
            value: el.fieldValue || '',
          })),
        };
      },
    }),
    setImpactModule: builder.mutation<
      IUpdateImpactModuleResponse,
      IImpactModuleFormData
    >({
      query: ({ clientId, ...payload }) => ({
        url: `impact-module?clientId=${clientId}`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['impactModule'],
    }),
    editImpactModule: builder.mutation<
      IUpdateImpactModuleResponse,
      IImpactModuleFormData
    >({
      query: ({ id, ...restPayload }) => ({
        url: `impact-module/${id}`,
        body: restPayload,
        method: 'PATCH',
      }),
      invalidatesTags: ['impactModule'],
    }),
    deleteImpactModule: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `impact-module/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['impactModule'],
    }),

    // Expertise Category

    getExpertiseCategory: builder.query<IExpertiseCategoryResponse, string>({
      query: (clientId) => `expertise-category?clientId=${clientId}`,
      providesTags: ['expertiseCategory'],
      transformResponse: (response: IExpertiseCategoryResponse) => {
        return {
          status: response.status,
          data: response.data,
          code: response.code,
          dropdownList: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
          // Needed for V1 Response , to populate field value
          dropdownData: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.fieldValue || '',
          })),
        };
      },
    }),
    setExpertiseCategory: builder.mutation<
      IExpertiseCategorySetResponse,
      IExpertiseCategorySetRequest
    >({
      query: ({ clientId, ...restPayload }) => ({
        url: `expertise-category?clientId=${clientId}`,
        body: restPayload,
        method: 'POST',
      }),
      invalidatesTags: ['expertiseCategory'],
    }),
    editExpertiseCategory: builder.mutation<
      IExpertiseCategoryEditResponse,
      IExpertiseCategoryEditRequest
    >({
      query: ({ id, ...restPayload }) => ({
        url: `expertise-category/${id}`,
        body: restPayload,
        method: 'PATCH',
      }),
      invalidatesTags: ['expertiseCategory'],
    }),
    deleteExpertiseCategory: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `expertise-category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['expertiseCategory'],
    }),

    // Functions
    getFunctionsList: builder.query<IFunctionsResponse, string>({
      query: (id) => `functions?clientId=${id}`,
      providesTags: ['functions'],
      transformResponse: (response: IFunctionsResponse) => {
        return {
          ...response,
          dropdownData: response.data.map((el) => ({
            text: el.fieldValue || '',
            value: el.fieldValue || '',
          })),
          functionsListDropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.id || '',
          })),
          functionCodeDropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
        };
      },
    }),
    addFunction: builder.mutation<
      ICreateFunctionsResponse,
      ICreateFunctionsRequest
    >({
      query: ({ clientId, ...payload }) => ({
        url: `functions?clientId=${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['functions'],
    }),
    editFunction: builder.mutation<
      IEditFunctionsResponse,
      IEditFunctionsRequest
    >({
      query: (payload) => ({
        url: `functions/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['functions'],
    }),
    deleteFunction: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `functions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['functions'],
    }),

    // Levels API
    getLevelsList: builder.query<ILevelsResponse, string>({
      query: (id) => `level?clientId=${id}`,
      providesTags: ['levels'],
      transformResponse: (response: ILevelsResponse) => {
        return {
          ...response,
          dropdownData: response.data.map((el) => ({
            text: el.fieldValue || '',
            value: el.fieldValue || '',
          })),
          levelListDropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.id || '',
          })),
          levelCodeDropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
        };
      },
    }),
    addLevel: builder.mutation<ICreateLevelResponse, ICreateLevelRequest>({
      query: ({ clientId, ...payload }) => ({
        url: `level?clientId=${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['levels'],
    }),
    editLevel: builder.mutation<IEditLevelResponse, IEditLevelRequest>({
      query: (payload) => ({
        url: `level/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['levels'],
    }),
    deleteLevel: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `level/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['levels'],
    }),

    // Sub Function
    getSubFunctionList: builder.query<ISubFunctionsResponse, string>({
      query: (id) => `sub-functions?clientId=${id}`,
      providesTags: ['subFunctions'],
      transformResponse: (response: ISubFunctionsResponse) => {
        return {
          ...response,
          dropdownData: response.data.map((el) => ({
            text: el.fieldValue || '',
            value: el.fieldValue || '',
          })),
          subFunctionsListDropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.id || '',
            function: el.functionId,
          })),
          subFunctionsCodeDropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
        };
      },
    }),
    addSubFunction: builder.mutation<
      ICreateSubFunctionsResponse,
      ICreateSubFunctionsRequest
    >({
      query: (payload) => ({
        url: 'sub-functions',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['subFunctions'],
    }),
    editSubFunction: builder.mutation<
      IEditSubFunctionsResponse,
      IEditSubFunctionsRequest
    >({
      query: (payload) => ({
        url: `sub-functions/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['subFunctions'],
    }),
    deleteSubFunction: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `sub-functions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['subFunctions'],
    }),

    // Skill Groups
    getSkillGroups: builder.query<ISkillGroupsResponse, string>({
      query: (id) => `skill-groups?clientId=${id}`,
      // providesTags: ['skillCategories'],
      transformResponse: (response: ISkillGroupsResponse) => {
        return {
          ...response,
          dropdownData: response.data.map((el) => ({
            text: el.code + ' - ' + el.name || '',
            value: el.id || '',
          })),
          skillCategoryListDropdown: response.data.map((el) => ({
            keyName: el.code + ' - ' + el.name || '',
            keyValue: el.id || '',
          })),
        };
      },
    }),

    // Skill Categories
    getSkillCategories: builder.query<ISkillCategoriesResponse, string>({
      query: (id) => `skill-categories?clientId=${id}`,
      providesTags: ['skillCategories'],
      transformResponse: (response: ISkillCategoriesResponse) => {
        return {
          ...response,
          dropdownData: response.data.map((el) => ({
            text: el.code + ' - ' + el.name || '',
            value: el.id || '',
          })),
          skillCategoryListDropdown: response.data.map((el) => ({
            keyName: el.code + ' - ' + el.name || '',
            keyValue: el.id || '',
          })),
          skillCategoryListDropdownForName: response.data.map((el) => ({
            keyName: el.code + ' - ' + el.name || '',
            keyValue: el.name || '',
          })),
        };
      },
    }),
    setSkillCategories: builder.mutation<
      ISkillCategoriesSetResponse,
      ISkillCategoriesSetRequest
    >({
      query: (payload) => ({
        url: 'skill-categories',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['skillCategories'],
    }),
    editSkillCategories: builder.mutation<
      ISkillCategoriesEditResponse,
      ISkillCategoriesEditRequest
    >({
      query: ({ id, ...rest }) => ({
        url: `skill-categories/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['skillCategories'],
    }),

    // Culture Imact Type
    getCultureImpactType: builder.query<ICultureImpactTypeResponse, string>({
      query: (id) => `culture-impact-type?clientId=${id}`,
      transformResponse: (response: ICultureImpactTypeResponse) => {
        return {
          status: response.status,
          data: response.data,
          dropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
          dropdownData: response.data.map((el) => ({
            text: el.fieldValue || '',
            value: el.fieldValue || '',
          })),
        };
      },
    }),

    // Accelerator
    getAccelerator: builder.query<IAcceleratorResponse, string>({
      query: (id) => `accelerator?clientId=${id}`,
      transformResponse: (response: IAcceleratorResponse) => {
        return {
          status: response.status,
          data: response.data,
          dropdown: response.data.map((el) => ({
            keyName: el.fieldValue || '',
            keyValue: el.code || '',
          })),
          dropdownData: response.data.map((el) => ({
            text: el.fieldValue || '',
            value: el.fieldValue || '',
          })),
        };
      },
    }),

    // Role Profile APIs
    getRoleProfilesById: builder.query<IGetRoleDetailsResponse, string>({
      query: (id) => `role-profiles/${id}`,
      providesTags: ['roleProfile'],
    }),
    getRoleProfiles: builder.query<IGetImpactGroupRolesResponse, string>({
      query: (id) => `role-profiles?clientId=${id}`,
      transformResponse: (response: IGetImpactGroupRolesResponse) => {
        return {
          status: response.status,
          data: response.data,
          dropdown: response.data.map((el) => ({
            keyName: el.name || '',
            keyValue: el.id || '',
          })),
        };
      },
      providesTags: ['roleProfiles'],
    }),
    addRoleProfileToClient: builder.mutation<
      IAddToClientResponse,
      IAddToClientPayload & IUpdateRole
    >({
      // additional type to avoid type mismatch
      query: (payload) => ({
        url: 'client-role',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['roleProfiles'],
    }),
    deleteRoleProfile: builder.mutation<
      { detail?: string; code?: number; status?: string },
      string
    >({
      query: (id) => ({
        url: `role-profiles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['roleProfiles'],
    }),
    downloadCompanyRoles: builder.query<IExcelResponse, IDownloadRole>({
      query: ({ id, name }) => {
        return {
          url: `role-profiles/export?clientId=${id}`,
          responseHandler: async (response: any) => {
            downloadFile(await response.blob(), name);
            if (response.status === 200) return { status: 'success' };
          },
          cache: 'no-cache',
        };
      },
    }),
    assignImpactGroupToRole: builder.mutation<
      void,
      { id: string; payload: { impactGroupAssigned: string } }
    >({
      query: ({ id, payload }) => ({
        url: `role-profiles/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      onCacheEntryAdded: (args, { dispatch }) => {
        dispatch(leaderProfileApiInvalidateTags(['impactGroupLeaders']));
      },
      invalidatesTags: [
        'impactGroupRoles',
        'impactGroupStatements',
        'impactGroupById',
      ],
    }),
    getLeaderRoles: builder.query<
      IGetImpactGroupRolesResponse,
      { clientId: string; impactGroupId?: string }
    >({
      query: ({ clientId, impactGroupId }) =>
        `role-profiles?clientId=${clientId}${
          impactGroupId ? `&impactGroupAssigned=${impactGroupId}` : ''
        }`,
      providesTags: ['impactGroupRoles'],
    }),
    addRole: builder.mutation<IGetImpactGroupRolesResponse, IRoleFormData>({
      query: ({ clientId, ...payload }) => ({
        url: `role-profiles?clientId=${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['roleProfiles'],
    }),
    editRole: builder.mutation<IGetImpactGroupRolesResponse, IEditRoleRequest>({
      query: ({ id, ...payload }) => ({
        url: `role-profiles/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['roleProfile', 'roleProfiles'],
    }),

    // Skill APIs
    getLeaderImpactGroupStatements: builder.query<
      IGetClientRoleStatementResponse,
      { clientId: string; roleProfileId: string; limit: number; offset: number }
    >({
      query: ({ clientId, roleProfileId, limit, offset }) =>
        `skills?clientId=${clientId}&roleProfileId=${roleProfileId}&limit=${limit}&offset=${offset}`,
      providesTags: ['impactGroupStatements'],
    }),
    getRoleSkillsByCategory: builder.query<
      IGetRoleSkillsResponse,
      IGetRoleSkillsRequest
    >({
      query: ({ client, roleId, skillType }) =>
        `skills?clientId=${client}&roleProfileId=${roleId}&skillCategory=${skillType}`,
      providesTags: ['roleSkill'],
      transformResponse: (response: IGetRoleSkillsResponse) => {
        return {
          ...response,
          functionalImpactData: response.data.filter(
            (item) => item.skillGroup === 'Functional Impact'
          ),
          organizationalImpactData: response.data.filter(
            (item) => item.skillGroup === 'Organizational Impact'
          ),
          capabilitiesData: response.data.filter(
            (item) => item.skillGroup === 'Capabilities'
          ),
          potentialData: response.data.filter(
            (item) => item.skillGroup === 'Potential'
          ),
        };
      },
    }),
    getRolePurposeSkillsByCategory: builder.query<
      IGetRoleSkillsResponse,
      IGetRoleSkillsRequest
    >({
      query: ({ client, skillType }) =>
        `skills?clientId=${client}&skillCategory=${skillType}&limit=10000`,
      providesTags: ['roleSkill'],
    }),
    editSkillById: builder.mutation<
      ISkillEditBenchmarkResponse,
      ISkillEditBenchmarkRequest
    >({
      query: ({ id, benchmark }) => ({
        url: `skills/${id}`,
        method: 'PATCH',
        body: {
          benchmark: benchmark,
        },
      }),
      invalidatesTags: ['roleSkill'],
    }),
    getRolesAssignedToStatement: builder.query<
      IRolesAssignedToStatementResponse,
      string
    >({
      query: (statementId) => `skills?statementId=${statementId}`,
      providesTags: ['roleSkill'],
    }),
    addRoleToStatement: builder.mutation<
      IAddRoleToStatementResponse,
      IAddRoleToStatementRequest
    >({
      query: ({ clientId, payload }) => ({
        url: `skills?clientId=${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['roleSkill'],
    }),
    deleteRoleSkillById: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `skills/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['roleSkill'],
    }),
  }),
});

export const leadershipApiInvalidateTags = leadershipApi.util.invalidateTags;

// Impact Groups
export const {
  useGetLeaderImpactGroupQuery,
  useGetImpactGroupQuery,
  useAddLeaderImpactGroupMutation,
  useGetLeaderImpactGroupByIdQuery,
  useEditLeaderImpactGroupMutation,
} = leadershipApi;

// Statement-libraries
export const {
  useAddClientStatementMutation,
  useGetClientStatementsQuery,
  useLazyGetClientStatementsQuery,
  useGetClientStatementByIdQuery,
  useUpdateClientStatementMutation,
  useDeleteClientStatementMutation,
  useDeleteLeaderImpactGroupMutation,
  useLazyValidateStatementByIdQuery,
} = leadershipApi;

// Impact Modules
export const {
  useLazyGetImpactModuleQuery,
  useGetImpactModuleQuery,
  useSetImpactModuleMutation,
  useEditImpactModuleMutation,
  useDeleteImpactModuleMutation,
} = leadershipApi;

// Expertise Category
export const {
  useLazyGetExpertiseCategoryQuery,
  useGetExpertiseCategoryQuery,
  useSetExpertiseCategoryMutation,
  useEditExpertiseCategoryMutation,
  useDeleteExpertiseCategoryMutation,
} = leadershipApi;

// Functions
export const {
  useGetFunctionsListQuery,
  useAddFunctionMutation,
  useEditFunctionMutation,
  useLazyGetLeaderImpactGroupStatementsQuery,
  useAssignRoleToImpactGroupMutation,
  useDeleteFunctionMutation,
} = leadershipApi;

// Levels
export const {
  useGetLevelsListQuery,
  useAddLevelMutation,
  useEditLevelMutation,
  useDeleteLevelMutation,
} = leadershipApi;

// Sub Functions
export const {
  useGetSubFunctionListQuery,
  useAddSubFunctionMutation,
  useEditSubFunctionMutation,
  useDeleteSubFunctionMutation,
} = leadershipApi;

// Skill Categories
export const {
  useGetSkillCategoriesQuery,
  useSetSkillCategoriesMutation,
  useGetSkillGroupsQuery,
  useEditSkillCategoriesMutation,
} = leadershipApi;

// Culture Impact Type
export const { useGetCultureImpactTypeQuery } = leadershipApi;

// Accelerator
export const { useGetAcceleratorQuery } = leadershipApi;
// Roles APIs
export const {
  useGetRoleProfilesQuery,
  useAddRoleProfileToClientMutation,
  useDeleteRoleProfileMutation,
  useGetRoleProfilesByIdQuery,
  useGetLeaderRolesQuery,
  useLazyDownloadCompanyRolesQuery,
  useAssignImpactGroupToRoleMutation,
  useAddRoleMutation,
  useEditRoleMutation,
} = leadershipApi;

// Skills APIs
export const {
  useLazyGetRoleSkillsByCategoryQuery,
  useLazyGetRolePurposeSkillsByCategoryQuery,
  useEditSkillByIdMutation,
  useGetRolesAssignedToStatementQuery,
  useLazyGetRolesAssignedToStatementQuery,
  useAddRoleToStatementMutation,
  useDeleteRoleSkillByIdMutation,
} = leadershipApi;
