import React, { useMemo } from 'react';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import Table from '../../../../../atoms/table/Table';
import { IAssessmentStructureType } from '../../../../../store/api/types/assessmentStructure';
import { useActions } from '../../../../../hooks/actions';
import { useParams } from 'react-router';
import { Restricted } from '../../../../../rbac/Restricted';

interface IProps {
  data?: IAssessmentStructureType[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  cloneAssessmentHandler: (assessment: IAssessmentStructureType) => void;
}

const ClientAssessmentTable = ({
  data,
  isLoading,
  tableInstanceRef,
  cloneAssessmentHandler,
}: IProps) => {
  const params = useParams();

  const { openEditClientAssessmentModal } = useActions();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditRole = (rowData: IAssessmentStructureType) => {
    openEditClientAssessmentModal(rowData);
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<IAssessmentStructureType>[]>(
    () => [
      {
        header: 'Assessment Name',
        accessorKey: 'title',
        filterFn: 'includesString',
      },
      {
        header: 'Skill Category',
        accessorKey: 'skillCategory',
        filterFn: 'includesString',
      },
      {
        header: 'Type',
        accessorKey: 'templateName',
        filterFn: 'includesString',
      },
      {
        header: 'Assessment Category',
        accessorKey: 'category',
        filterFn: 'includesString',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ showSkeletons: isLoading }}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <Restricted allowedTo='client.assessments.edit'>
              <MenuItem
                onClick={() =>
                  handleEditRole(row.original as IAssessmentStructureType)
                }
              >
                Edit
              </MenuItem>
            </Restricted>
            <MenuItem
              onClick={() => {
                cloneAssessmentHandler(
                  row.original as IAssessmentStructureType
                );
                setAnchorEl(null);
              }}
            >
              Clone Assessment
            </MenuItem>
          </Menu>
        </>
      )}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          const selection = window.getSelection()!.toString();
          if (
            !cell.id.includes('mrt-row-actions') &&
            !selection &&
            (row.original as IAssessmentStructureType).category === 'CBI'
          ) {
            window.location.href = `/cbi-assessments/${
              params.id
            }/assessmentstructure/${
              (row.original as IAssessmentStructureType)?.id
            }`;
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
    />
  );
};

export default ClientAssessmentTable;
