import React, { useRef, useState } from 'react';
import {
  IFilteredDataProps,
  ITabContentProps,
  SelectOptions,
} from '../../../commons/types';
import TabContent from '../../../atoms/tab/TabContent';
import ImpactGroupRoleTable from '../../../organisms/tables/Company/ImpactGroups/ImpactGroupRoleTable';

import { MRT_TableInstance } from 'material-react-table';
import { Box, styled } from '@mui/material';
import EditActionIcon from '../../../assets/img/icons/EditActionIcon.svg';
import DeleteActionIcon from '../../../assets/img/icons/DeleteActionIcon.svg';
import Button from '../../../atoms/button/Button';
import {
  useGetLevelsListQuery,
  useGetLeaderImpactGroupQuery,
  useGetFunctionsListQuery,
  useAssignImpactGroupToRoleMutation,
} from '../../../store/api/leadership';
import { IGetRolesData } from '../../../store/api/types/leadership';
import { useActions } from '../../../hooks/actions';
import { useParams } from 'react-router-dom';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid rgba(0, 33, 54, 0.2)',
  borderRadius: ' 4px 4px 0px 0px',
  padding: '6px 16px 10px',
});

const StyledActionWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
});

const StyledActionContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '15px',
});

const StyledLabel = styled('span')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '29px',
  color: '#0B0C10',
});
const StyledActionLabel = styled('span')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: '22px',
  color: '#18768C',
});

export default function ImpactGroupRoles({
  index,
  value,
  data,
  isLoading,
  setImpactGroupRolesData,
}: ITabContentProps & {
  data: IGetRolesData[];
  isLoading: boolean;
  setImpactGroupRolesData: React.Dispatch<
    React.SetStateAction<{ isSet: boolean; data: IGetRolesData[] }>
  >;
}) {
  const [filteredData, setFilteredData] = React.useState<
    IFilteredDataProps & { filteredImpactGroups?: Array<SelectOptions | null> }
  >({
    filteredFunctions: [],
    filteredLevels: [],
    filteredImpactGroups: [],
  });

  const [rowSelection, setRowSelection] = React.useState<
    Array<IGetRolesData | null>
  >([]);

  const [assignedImpactGroup, setChangeImpactGroup] = useState<
    Array<{ roleId: string; impactGroupId: string }>
  >([]);

  const { id: clientId } = useParams();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const { data: functionData } = useGetFunctionsListQuery(clientId as string);
  const { data: levelData } = useGetLevelsListQuery(clientId as string);
  const { data: { data: impactGroupsData } = {} } =
    useGetLeaderImpactGroupQuery({
      id: clientId || '',
      offset: 0,
      limit: 1000,
    });

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const [assignedImpactGroupToRole] = useAssignImpactGroupToRoleMutation();

  React.useEffect(() => {
    if (
      data &&
      data.length &&
      functionData?.dropdownData.length &&
      levelData?.dropdownData.length &&
      impactGroupsData
    ) {
      const filteredFunctions: Array<SelectOptions | null> =
        functionData.dropdownData.filter(
          (item) => !!data.find((role) => role.functionName === item.value)
        ) || [];
      const filteredLevels: Array<SelectOptions | null> =
        levelData.dropdownData.filter(
          (item) => !!data.find((role) => role.levelName === item.value)
        ) || [];
      const filteredImpactGroups: Array<SelectOptions | null> =
        impactGroupsData.map((item) => ({
          value: item.name,
          text: item.id,
        })) || [];

      setFilteredData({
        filteredFunctions,
        filteredLevels,
        filteredImpactGroups,
      });
    }
  }, [data, functionData, impactGroupsData, levelData]);

  React.useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "Are Sure You Don't want to save changes";
      return event;
    };
    if (assignedImpactGroup.length)
      window.addEventListener('beforeunload', unloadCallback);

    return () => {
      if (assignedImpactGroup.length)
        window.removeEventListener('beforeunload', unloadCallback);
    };
  }, [assignedImpactGroup]);

  const updateAssignedGroup = () => {
    Promise.all(
      assignedImpactGroup.map(async (role) => {
        const response = await assignedImpactGroupToRole({
          id: role.roleId || '',
          payload: {
            impactGroupAssigned: role.impactGroupId || '',
          },
        });
        return response;
      })
    )
      .then(() => {
        setChangeImpactGroup([]);
        setImpactGroupRolesData({ isSet: false, data: [] });
        toggleSuccessSnackbar({
          message: 'Impact Group Roles Updated Successfully',
        });
      })
      .catch(() => toggleErrorSnackbar({ message: 'Error Adding Roles' }));
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      {' '}
      <StyledActionContainer>
        <Button
          btnType={'PRIMARY'}
          text={'Save Changes'}
          onClick={() => updateAssignedGroup()}
          disabled={!assignedImpactGroup.length}
        />
      </StyledActionContainer>
      {!!rowSelection.length && (
        <StyledContentWrapper>
          <StyledLabel>{`${rowSelection.length} role selected`}</StyledLabel>
          <StyledActionWrapper>
            <StyledActionLabel>
              <img
                src={EditActionIcon}
                alt={'editIcon'}
              />{' '}
              Edit selected
            </StyledActionLabel>
            <StyledActionLabel>
              <img
                src={DeleteActionIcon}
                alt={'deleteIcon'}
              />{' '}
              Remove selected
            </StyledActionLabel>
          </StyledActionWrapper>
        </StyledContentWrapper>
      )}
      <ImpactGroupRoleTable
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
        data={data || []}
        IGDropdownData={filteredData.filteredImpactGroups as SelectOptions[]}
        recordSelection={setRowSelection}
        setChangeImpactGroup={(value: {
          roleId: string;
          impactGroupId: string;
        }) => {
          setChangeImpactGroup((values) => {
            const index = values.findIndex(
              (item) => item.roleId === value.roleId
            );
            if (index === -1) return [...values, value];
            values[index].impactGroupId = value.impactGroupId;
            return [...values];
          });
        }}
        levelList={filteredData.filteredLevels as SelectOptions[]}
        functionList={filteredData.filteredFunctions as SelectOptions[]}
      />
    </TabContent>
  );
}
