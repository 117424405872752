import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoleFormData } from '../commons/types';
import { IGetRoleData } from './api/types/leadership';

interface IRoleModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit' | 'download' | 'applyToClient' | 'clientClone';
  initialValues: IRoleFormData;
  filteredSubFunctionList: [];
}

const initialState: IRoleModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    abbreviation: '',
    function: '',
    sub_function: '',
    level: '',
    description: '',
  },
  filteredSubFunctionList: [],
};

const roleModalSlice = createSlice({
  name: 'roleModal',
  initialState,
  reducers: {
    closeRoleModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddRoleModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openDownloadRoleModal: (state) => {
      state.isOpen = true;
      state.modalType = 'download';
    },
    openEditRoleModal: (state, action: PayloadAction<IGetRoleData>) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        roleId: action.payload.roleId || '',
        id: action.payload.id || '',
        name: action.payload.name || '',
        abbreviation: action.payload.abbreviation || '',
        function: action.payload.function || '',
        sub_function: action.payload.subFunction || '',
        level: action.payload.level || '',
        description: action.payload.description || '',
        clientRoleCode: action.payload.clientRoleCode || '',
      };
    },
    openClientCloneRoleModal: (state, action: PayloadAction<IGetRoleData>) => {
      state.isOpen = true;
      state.modalType = 'clientClone';
      state.initialValues = {
        roleId: action.payload.roleId || '',
        id: action.payload.id || '',
        name: `CLONE ${action.payload.name}`,
        abbreviation: action.payload.abbreviation || '',
        function: action.payload.function || '',
        sub_function: action.payload.subFunction || '',
        level: action.payload.level || '',
        description: action.payload.description || '',
      };
    },
    openApplyToClientRoleModal: (
      state,
      action: PayloadAction<IGetRoleData>
    ) => {
      state.isOpen = true;
      state.modalType = 'applyToClient';
      state.initialValues = {
        roleId: action.payload.roleId || '',
        id: action.payload.id || '',
        name: action.payload.name || '',
        abbreviation: action.payload.abbreviation || '',
        function: action.payload.function || '',
        sub_function: action.payload.subFunction || '',
        level: action.payload.level || '',
        description: action.payload.description || '',
      };
    },
    setSubFunctionList: (state, action) => {
      const { list, value } = action.payload;

      state.filteredSubFunctionList = list.filter(
        (el: IRoleFormData) => el.function === value
      );
    },
  },
});

export const roleModalActions = roleModalSlice.actions;
export const roleModalReducer = roleModalSlice.reducer;
