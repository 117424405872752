import { accessList, accessListTypes } from './accessList';
import { getObjectValueFromString } from '../utils/helpers';
import { useGetMeQuery } from '../store/api/v2/auth';

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, ...0[]];

type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

type IProps = {
  children: JSX.Element;
  allowedTo: Leaves<accessListTypes, 9>;
};

export const Restricted = ({ allowedTo, children }: IProps) => {
  const { data: meData } = useGetMeQuery();

  const backEndPermissionsArr = meData?.permissionsArr || [];
  const frontEndPermissionValue = getObjectValueFromString(
    accessList,
    allowedTo
  );

  const isAllowed = backEndPermissionsArr.includes(frontEndPermissionValue);

  if (isAllowed) return children;
  else return null;
};

export const restrictedHandler = (
  allowedTo: Leaves<accessListTypes, 9>,
  permissionArr?: string[]
): boolean => {
  const backEndPermissionsArr = permissionArr;
  const frontEndPermissionValue = getObjectValueFromString(
    accessList,
    allowedTo
  );

  return backEndPermissionsArr?.includes(frontEndPermissionValue) || false;
};
