import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExpertiseCategoryData } from '../api/types/leadership';

interface IExpertiseCategoryVLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: {
    id?: string;
    fieldValue: string;
    code: string;
  };
}

const initialState: IExpertiseCategoryVLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    fieldValue: '',
    code: '',
  },
};

const expertiseCategoryVLModalSlice = createSlice({
  name: 'expertiseCategoryVLModal',
  initialState,
  reducers: {
    closeExpertiseCategoryVLModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddExpertiseCategoryVLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditExpertiseCategoryVLModal: (
      state,
      action: PayloadAction<IExpertiseCategoryData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        fieldValue: action.payload.fieldValue || '',
        code: action.payload.code || '',
      };
    },
  },
});

export const expertiseCategoryVLModalActions =
  expertiseCategoryVLModalSlice.actions;
export const expertiseCategoryVLModalReducer =
  expertiseCategoryVLModalSlice.reducer;
