import React, { useRef } from 'react';
import HpContainer from '../../../../atoms/container/HpContainer';
import Title from '../../../../atoms/title/Title';
import { useParams } from 'react-router';
import { MRT_TableInstance } from 'material-react-table';
import ResetButton from '../../../../atoms/button/ResetButton';
import { Box, styled } from '@mui/material';
import ClientAssessmentHeader from '../../../../organisms/headers/Company/Assessments/ClientAssessments/ClientAssessmentHeader';
import ClientAssessmentTable from '../../../../organisms/tables/Company/Assessments/ClientAssessments/ClientAssessmentTable';
import {
  useAddAssessmentStructureMutation,
  useGetAssessmentsStructureQuery,
} from '../../../../store/api/assessmentStructure';
import { IAssessmentStructureType } from '../../../../store/api/types/assessmentStructure';
import { useActions } from '../../../../hooks/actions';
import Loader from '../../../../commons/Loader';

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
}));

const ClientAssessment = () => {
  const { id } = useParams();

  const { data: { data: assessmentData } = {}, isLoading } =
    useGetAssessmentsStructureQuery({
      clientId: id,
      limit: undefined,
      offset: undefined,
    });
  const [cloneAssessment, { isLoading: isCloneLoading }] =
    useAddAssessmentStructureMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  const cloneAssessmentHandler = (assessment: IAssessmentStructureType) => {
    const regex = /\((.*?)\)/gi;
    let assessmentName = '';
    let i = 2;
    const titleArray = assessment.title.split(regex);
    // Filter  Assessments by matching title
    const filteredAssessments =
      assessmentData?.filter(
        (item) => item.title.includes(titleArray[0]) && item.title.match(regex)
      ) || [];
    // Generate unique cloning number
    if (filteredAssessments.length) {
      while (filteredAssessments!.find((el) => el.title.includes(`(${i})`))) {
        i++;
      }
    }
    assessmentName = `${titleArray[0]} (${i})`;
    cloneAssessment({
      assessmentStructureId: assessment.id,
      title: assessmentName,
    })
      .unwrap()
      .then((response) => {
        if (response.status === 'error')
          toggleErrorSnackbar({ message: response.data });
        else toggleSuccessSnackbar({ message: 'Cloned Successfully' });
      })
      .catch(() => toggleErrorSnackbar({ message: 'Cloning Failed' }));
  };

  return isCloneLoading ? (
    <Loader />
  ) : (
    <>
      <ClientAssessmentHeader />
      <HpContainer
        sx={{
          marginTop: '20px',
        }}
      >
        <StyledSubHeaderWrapper>
          <Title
            text={`${
              assessmentData?.length ? assessmentData?.length : '0'
            } total assessments`}
            sx={{
              fontSize: '20px',
            }}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
        <ClientAssessmentTable
          data={assessmentData}
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
          cloneAssessmentHandler={cloneAssessmentHandler}
        />
      </HpContainer>
    </>
  );
};

export default ClientAssessment;
