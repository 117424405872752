import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { routes } from '../../../../routes/routes';
import {
  dateFiltering,
  dateSortingFunction,
  showCreatedDate,
} from '../../../../store/api/helpers';
import { IProjectData } from '../../../../store/api/types/projects';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Restricted, restrictedHandler } from '../../../../rbac/Restricted';
import { useUpdateProjectMutation } from '../../../../store/api/projects';
import { useActions } from '../../../../hooks/actions';
import { useGetMeQuery } from '../../../../store/api/v2/auth';
import TableDatePicker from '../../../../atoms/datepicker/TableDatePicker';
import useFooterColumn from '../../../../hooks/useFooterColumn';

interface IProps {
  data?: IProjectData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  filteredState: any[];
  setFilteredState: React.Dispatch<React.SetStateAction<Array<never>>>;
}

const ClientProjectsTable = ({
  data,
  isLoading,
  tableInstanceRef,
  filteredState,
  setFilteredState,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const { id: companyId } = useParams();

  const { data: userData } = useGetMeQuery();
  const [editProject] = useUpdateProjectMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditProject = (projectId: string) => {
    navigate(
      `${routes.company.url}${companyId}${routes.company.projects.url}/${projectId}${routes.company.projects.edit.url}`
    );
  };

  const handleCloseProjectStatus = (projectId: string) => {
    const dataToSend = {
      projectId,
      status: 'Closed',
    };

    editProject(dataToSend)
      .unwrap()
      .then(() => {
        handleClose();
        toggleSuccessSnackbar({
          message: 'Project Status Updated Successfully',
        });
      })
      .catch((e) => {
        handleClose();
        if (e.data.message) {
          toggleErrorSnackbar({ message: e.data.message });
        } else if (e.data.detail) {
          toggleErrorSnackbar({ message: e.data.detail });
        } else {
          toggleErrorSnackbar({});
        }
      });
  };

  const handleVisibilityOfThreeDots = () => {
    return (
      restrictedHandler(
        'project.project_list.edit',
        userData?.permissionsArr
      ) ||
      restrictedHandler(
        'project.project_list.status_close',
        userData?.permissionsArr
      )
    );
  };

  const columns = useMemo<MRT_ColumnDef<IProjectData>[]>(
    () => [
      {
        header: 'Project Name',
        accessorKey: 'name',
        filterFn: 'includesString',
        Footer: ({ table }) => {
          const filteredData = table
            .getFilteredRowModel()
            .rows.map((row) => row.original);

          useFooterColumn(filteredData, filteredState, setFilteredState);

          return null;
        },
      },
      {
        header: 'Project Lead',
        accessorKey: 'projectLeadName',
        filterFn: 'includesString',
      },
      {
        header: 'Hiring Manager',
        accessorKey: 'hiringManager',
        filterFn: 'includesString',
      },
      {
        header: 'Project Reference',
        accessorKey: 'reference',
        filterFn: 'includesString',
      },
      {
        header: 'Journey',
        accessorKey: 'purpose',
        filterFn: 'includesString',
      },
      {
        header: 'Start Date',
        accessorFn: (row) => showCreatedDate(row.startDate),
        Filter: ({ column }) => (
          <TableDatePicker
            onChange={(event) => {
              if (!event) {
                column.setFilterValue('');
              } else {
                column.setFilterValue(
                  `${event.c.month}/${event.c.day}/${event.c.year}`
                );
              }
            }}
            datePickerProps={{
              value: column.getFilterValue() || null,
            }}
          />
        ),
        id: 'startDate',
        filterFn: (row, columnId, filterValue) =>
          dateFiltering(row, columnId, filterValue, 'Start'),
        sortingFn: (rowA, rowB) => dateSortingFunction(rowA, rowB, 'startDate'),
      },
      {
        header: 'Close Date',
        accessorFn: (row) => showCreatedDate(row.closeDate),
        Filter: ({ column }) => (
          <TableDatePicker
            onChange={(event) => {
              if (!event) {
                column.setFilterValue('');
              } else {
                column.setFilterValue(
                  `${event.c.month}/${event.c.day}/${event.c.year}`
                );
              }
            }}
            datePickerProps={{
              value: column.getFilterValue() || null,
            }}
          />
        ),
        id: 'closeDate',
        filterFn: (row, columnId, filterValue) =>
          dateFiltering(row, columnId, filterValue, 'Close'),
        sortingFn: (rowA, rowB) => dateSortingFunction(rowA, rowB, 'closeDate'),
      },
      {
        header: 'Participants',
        accessorFn: ({ assignmentStats }) => assignmentStats?.leaders || '-',
        filterFn: 'includesString',
      },
      {
        header: 'Not Started',
        accessorFn: ({ assignmentStats }) =>
          assignmentStats?.['Not Invited'] + assignmentStats?.['Not Started'],
        filterFn: 'includesString',
      },
      {
        header: 'In Progress',
        accessorFn: ({ assignmentStats }) =>
          assignmentStats?.['In Progress'] || '-',
        filterFn: 'includesString',
      },
      {
        header: 'Completed',
        accessorFn: ({ assignmentStats }) =>
          assignmentStats?.['Completed'] || '-',
        filterFn: 'includesString',
      },
      {
        header: 'Status',
        accessorKey: 'aggregatedStatus',
        filterFn: 'includesString',
      },
    ],
    [filteredState]
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      renderRowActions={({ cell, row }) => (
        <>
          {handleVisibilityOfThreeDots() && (
            <>
              <IconButton
                id={cell.id}
                onClick={handleOpen}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl?.id === cell.id}
                onClose={handleClose}
              >
                <Restricted allowedTo='project.project_list.edit'>
                  <MenuItem
                    onClick={() =>
                      handleEditProject((row.original as IProjectData).id)
                    }
                  >
                    Edit
                  </MenuItem>
                </Restricted>
                <Restricted allowedTo='project.project_list.status_close'>
                  <MenuItem
                    onClick={() =>
                      handleCloseProjectStatus(
                        (row.original as IProjectData).id
                      )
                    }
                  >
                    Close
                  </MenuItem>
                </Restricted>
              </Menu>
            </>
          )}
        </>
      )}
      columns={columns as MRT_ColumnDef<object>[]}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          const selection = window.getSelection()!.toString();
          if (!cell.id.includes('mrt-row-actions') && !selection) {
            navigate(
              `${routes.company.url}${companyId}${
                routes.company.projects.url
              }/${(row.original as IProjectData).id}`
            );
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
      initialState={{
        sorting: [{ id: 'startDate', desc: true }],
        showColumnFilters: true,
      }}
    />
  );
};

export default ClientProjectsTable;
