import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientRoleStatementFormData } from '../../api/types/clientrole';
import { IRoleSkillData } from '../../api/types/leadership';

interface IClientRoleStatementModalProps {
  isOpen: boolean;
  modalType: 'edit';
  initialValues: IClientRoleStatementFormData;
}

const initialState: IClientRoleStatementModalProps = {
  isOpen: false,
  modalType: 'edit',
  initialValues: {
    role: '',
    id: '',
    skill_copy: '',
    skill_copy_name: '',
    skill_copy_description: '',
    proficiency: 1,
  },
};

const clientRoleStatementModalSlice = createSlice({
  name: 'clientRoleStatementModal',
  initialState,
  reducers: {
    closeClientRoleStatementModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openEditClientRoleStatementModal: (
      state,
      action: PayloadAction<IRoleSkillData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        role: action.payload.role || '',
        id: action.payload.id || '',
        skill_copy: action.payload.skill_copy || '',
        skill_copy_name: action.payload.skill_copy_name || '',
        skill_copy_description: action.payload.skill_copy_description || '',
        proficiency: action.payload.proficiency || 1,
      };
    },
  },
});

export const clientRoleStatementModalActions =
  clientRoleStatementModalSlice.actions;
export const clientRoleStatementModalReducer =
  clientRoleStatementModalSlice.reducer;
