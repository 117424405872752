import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import { routes } from '../../routes/routes';
import PageHeader from '../../atoms/header/PageHeader';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const VariableLibraryHeader = () => {
  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.variableLibrary.name} />
      </StyledTitlesWrapper>
    </PageHeader>
  );
};

export default VariableLibraryHeader;
