import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

const RemoveLeadersErrorModal = () => {
  const isOpen = useAppSelector((state) => state.removeLeadersModal.isOpen);

  const { closeRemoveLeadersModal } = useActions();

  const handleClose = () => {
    closeRemoveLeadersModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <>
        <ModalTitle onClose={handleClose}>Error</ModalTitle>
        <ModalBody>
          You cannot remove any leaders assigned to assignment(s){' '}
        </ModalBody>
        <ModalActions>
          <Button
            btnType={'PRIMARY'}
            text={'Done'}
            onClick={handleClose}
          />
        </ModalActions>
      </>
    </Modal>
  );
};

export default RemoveLeadersErrorModal;
