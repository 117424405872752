import React, { useMemo } from 'react';
import Table from '../../../../../atoms/table/Table';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import { Box, styled } from '@mui/material';
import { DropdownTypes, SelectOptions } from '../../../../../commons/types';
import MultiSelectDropdown from '../../../../../atoms/multiselect/MultiSelectDropdown';
import { getAssessmentStatus } from '../../../../../store/api/v2/helpers';
import ViewRatersButton from '../../../../../atoms/button/ViewRatersButton';
import { useParams } from 'react-router';
import TopToolbar from '../../../../../atoms/table/TopToolbar';
import { useActions } from '../../../../../hooks/actions';
import { useAppSelector } from '../../../../../hooks/redux';
import AssessmentCohortExportSummaryModal from '../../../../modals/Project/AssessmentCohortExportSummaryModal';
import { AssessmentLeadersTable } from '../../../../../store/api/types/projects';
import {
  useGetMeQuery,
  useGetAssessorsUsersQuery,
} from '../../../../../store/api/v2/auth';
import LabelDropdown from '../../../../../atoms/dropdown/LabelDropdown';
import { restrictedHandler } from '../../../../../rbac/Restricted';
import DownloadReportModal from '../../../../modals/Project/ProjectDetails/AssessmentDetails/DownloadReportModal';

interface IProps {
  data: AssessmentLeadersTable[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  turnOnButton: any;
  setChangePrimaryAssessor: (value: {
    userId: string;
    name: string;
    assessment: string;
    leader: string;
  }) => void;
  setChangeCoAssessor: (value: {
    userId: string;
    name: string;
    assessment: string;
    leader: string;
  }) => void;
}

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const ProjectAssessmentsDetailsTable = ({
  data,
  isLoading,
  tableInstanceRef,
  turnOnButton,
  setChangePrimaryAssessor,
  setChangeCoAssessor,
}: IProps) => {
  const [rowSelection, setRowSelection] = React.useState({});
  const [rowsselected, setRowsSelected] = React.useState(0);
  const [selectedRecords, setSelectedRecords] = React.useState([{}]);

  const isDownloadReportModalOpen = useAppSelector(
    (state) => state.downloadReportModal.isOpen
  );

  const params = useParams();

  const { data: usersData } = useGetAssessorsUsersQuery(params.id!);
  const { data: meData } = useGetMeQuery();

  const {
    openAssessmentCohortExportModal,
    closeAssessmentCohortExportModal,
    openDownloadReportModal,
  } = useActions();

  const isOpen = useAppSelector(
    (state) => state.assessmentCohortExportModal.isOpen
  );

  const [usersDropDown, setUsersDropDown] = React.useState<{
    multiDropdown: Array<SelectOptions>;
    dropDown: Array<DropdownTypes>;
  }>({ multiDropdown: [], dropDown: [] });

  React.useEffect(() => {
    if (usersData?.data) {
      const dropDown = usersData?.data.map((item) => ({
        value: item.firstName + ' ' + item.lastName,
        text: item.id,
      }));
      setUsersDropDown({
        multiDropdown: dropDown.filter(
          (item) => !!usersData?.data.find((x) => x.id === item.text)
        ),
        dropDown: usersData?.data.map((item) => ({
          keyName: item.firstName + ' ' + item.lastName,
          keyValue: item.id,
        })),
      });
    }
  }, [usersData?.data]);

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<any>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    const selectedItems = newValue.map((value: { text: string }) => value.text);
    column.setFilterValue(selectedItems || undefined);
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Leader',
        accessorKey: 'leaderName',
        filterFn: 'includesString',
      },
      {
        header: 'Role',
        accessorKey: 'roleName',
        filterFn: 'includesString',
      },
      {
        header: 'Primary Assessor',
        accessorKey: 'pAssessor',
        filterVariant: 'multi-select',
        Filter: ({ column }) => {
          return (
            <Box sx={{ paddingRight: '16px' }}>
              <MultiSelectDropdown
                width='100% !important'
                dropDownItem={usersDropDown.multiDropdown}
                onChange={(e, newValue, reason) =>
                  multiSelectOnChangeHandler(e, newValue, reason, column)
                }
                column={column}
              />
            </Box>
          );
        },
        Cell: ({ cell, row }) =>
          usersDropDown.dropDown.length ? (
            <LabelDropdown
              label={''}
              dropDownItem={usersDropDown.dropDown}
              onChange={(value) => {
                const userInfo = usersDropDown.dropDown.filter(
                  (user) => user.keyValue === value.target.value
                );
                setChangePrimaryAssessor({
                  userId: (value.target.value as string) || '',
                  name: (userInfo[0]?.keyName as string) || '',
                  assessment: row.original.assessmentId as string,
                  leader: row.original.id,
                });
              }}
              defaultValue={cell.getValue()}
              disabled={
                !restrictedHandler(
                  'project.project_details.cbi.assign_assessor',
                  meData?.permissionsArr
                )
              }
            />
          ) : (
            ''
          ),
      },
      {
        header: 'Co-Assessor',
        accessorKey: 'cAssessor',
        filterVariant: 'multi-select',
        Filter: ({ column }) => {
          return (
            <Box sx={{ paddingRight: '16px' }}>
              <MultiSelectDropdown
                width='100% !important'
                dropDownItem={usersDropDown.multiDropdown}
                onChange={(e, newValue, reason) =>
                  multiSelectOnChangeHandler(e, newValue, reason, column)
                }
                column={column}
              />
            </Box>
          );
        },
        Cell: ({ cell, row }) =>
          usersDropDown.dropDown.length ? (
            <LabelDropdown
              label={''}
              dropDownItem={usersDropDown.dropDown}
              onChange={(value) => {
                const userInfo = usersDropDown.dropDown.filter(
                  (user) => user.keyValue === value.target.value
                );
                setChangeCoAssessor({
                  userId: (value.target.value as string) || '',
                  name: (userInfo[0]?.keyName as string) || '',
                  assessment: row.original.assessmentId as string,
                  leader: row.original.id,
                });
              }}
              defaultValue={cell.getValue()}
              disabled={
                !restrictedHandler(
                  'project.project_details.cbi.assign_assessor',
                  meData?.permissionsArr
                )
              }
            />
          ) : (
            ''
          ),
      },
      {
        header: 'Status',
        accessorKey: 'cbiStatus',
        filterFn: 'includesString',
        size: 350,
        Cell: ({ cell, row }) => formatAssessmentStatus(row),
      },
    ],
    [data]
  );

  const formatAssessmentStatus = (row: any) => {
    const temp = getAssessmentStatus(row);
    const splitTemp = temp.split('|');

    return (
      <StyledHeaderWrapper>
        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        <span style={{ marginRight: '8px' }}>{splitTemp[0]}</span>
        {splitTemp[2] ? (
          restrictedHandler(
            'project.project_details.cbi.survey',
            meData?.permissionsArr
          ) && (
            <StyledSubHeaderWrapper>
              <ViewRatersButton
                onClick={() => onStatusButtonClick(row.original?.assessmentId)}
                text={splitTemp[1]}
              />
              <ViewRatersButton
                onClick={() => onDownloadReport(row.original?.assessmentId)}
                text={splitTemp[2]}
              />
            </StyledSubHeaderWrapper>
          )
        ) : splitTemp[1] ? (
          restrictedHandler(
            'project.project_details.cbi.survey',
            meData?.permissionsArr
          ) && (
            <StyledSubHeaderWrapper>
              <ViewRatersButton
                onClick={() => onStatusButtonClick(row.original?.assessmentId)}
                text={splitTemp[1]}
              />
            </StyledSubHeaderWrapper>
          )
        ) : (
          <StyledSubHeaderWrapper></StyledSubHeaderWrapper>
        )}
      </StyledHeaderWrapper>
    );
  };

  const onStatusButtonClick = (assignmentId: string) => {
    // redirect to CBI assessment status page
    window.location.href = `/cbi-assessments/${params.id}/assessment/${assignmentId}`;
  };

  const onDownloadReport = (assessmentId: string) => {
    openDownloadReportModal({ assessmentId });
  };

  React.useEffect(() => {
    const rowSelection =
      tableInstanceRef.current?.getSelectedRowModel().flatRows;
    if (rowSelection && rowSelection?.length) {
      setRowsSelected(Object.keys(rowSelection)?.length);
      setSelectedRecords(rowSelection);
    } else {
      setRowsSelected(0);
      setSelectedRecords([{}]);
    }
  }, [rowSelection]);

  const exportCohortSummary = () => {
    const leadersData = selectedRecords.map((item: any) => {
      return item.original;
    });
    const payload = { rowData: leadersData };
    rowsselected && openAssessmentCohortExportModal(payload);
  };

  const handleClose = () => {
    closeAssessmentCohortExportModal();
  };

  return (
    <>
      {/* Our Custom External Top Toolbar */}
      {rowsselected && tableInstanceRef.current ? (
        <TopToolbar
          rowsSelected={rowsselected}
          SecondButtonLabel={'Export Cohort Summary'}
          SecondButtonAction={exportCohortSummary}
          iconType='download'
        />
      ) : (
        ''
      )}
      <Table
        data={data ?? []}
        enableRowActions={false}
        tableInstanceRef={tableInstanceRef}
        columns={columns as MRT_ColumnDef<object>[]}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        state={{ showSkeletons: isLoading, rowSelection: rowSelection }}
      />
      <AssessmentCohortExportSummaryModal
        open={isOpen}
        close={handleClose}
      />
      {isDownloadReportModalOpen && <DownloadReportModal />}
    </>
  );
};

export default ProjectAssessmentsDetailsTable;
