import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { Box, Container } from '@mui/material';
import RolesHeader from '../../../organisms/headers/Company/Roles/RolesHeader';
import { MRT_TableInstance } from 'material-react-table';
import CompanyRolesModal from '../../../organisms/modals/Company/CompanyRolesModal';
import { useGetRoleProfilesQuery } from '../../../store/api/leadership';
import RoleModal from '../../../organisms/modals/RoleModal';
import { useAppSelector } from '../../../hooks/redux';
import RoleLibraryTable from '../../../organisms/tables/RoleLibraryTable';
import ImportRoleModal from '../../../organisms/modals/ImportRoleModal';

const Roles = () => {
  const { id: clientId } = useParams();
  const { data: { data } = {}, isLoading } = useGetRoleProfilesQuery(
    clientId as string
  );
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const isRoleModalOpen = useAppSelector((state) => state.roleModal.isOpen);
  const isImportModalOpen = useAppSelector(
    (state) => state.importRoleModal.isOpen
  );

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 106px - 66px)',
        overflowY: 'scroll',
      }}
    >
      {isRoleModalOpen && <RoleModal />}
      {isImportModalOpen && <ImportRoleModal />}
      <RolesHeader
        qtyOfUsers={data?.length}
        onResetButtonClick={onResetButtonClick}
      />
      <Container
        sx={{
          marginBottom: '50px',
        }}
      >
        <RoleLibraryTable
          isLoading={isLoading}
          data={data}
          tableInstanceRef={tableInstanceRef}
        />
      </Container>
      <CompanyRolesModal />
    </Box>
  );
};
export default Roles;
