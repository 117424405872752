import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImpactModuleFormData } from '../../api/types/leadership';

interface IImpactModuleVLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: IImpactModuleFormData;
}

const initialState: IImpactModuleVLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    fieldValue: '',
    code: '',
    systemName: '',
    abbreviation: '',
  },
};

const impactModuleVLClientModalSlice = createSlice({
  name: 'impactModuleVLClientModal',
  initialState,
  reducers: {
    closeImpactModuleVLClientModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    openAddImpactModuleVLClientModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditImpactModuleVLClientModal: (
      state,
      action: PayloadAction<IImpactModuleFormData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        fieldValue: action.payload.fieldValue || '',
        id: action.payload.id || '',
        code: action.payload.code || '',
        systemName: action.payload.systemName || '',
        abbreviation: action.payload.abbreviation || '',
      };
    },
  },
});

export const impactModuleVLClientModalActions =
  impactModuleVLClientModalSlice.actions;
export const impactModuleVLClientModalReducer =
  impactModuleVLClientModalSlice.reducer;
