import { Box, styled } from '@mui/material';
import * as React from 'react';
import './HDSpinner.css';

export interface HDSpinnerTypes {
  size: string;
  buttonColor : boolean | undefined
}

const HDSpinner = (props: HDSpinnerTypes) => {
  const StyledBox = styled('div')(({ theme }) => ({
    color: props.buttonColor ? 'inherit' : theme.palette.mode === 'dark' ? '#12ABAB' : '#18768C',
  }));

  return (
    <StyledBox className={`la-ball-spin ${props.size} `}>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
    </StyledBox>
  );
};
export default HDSpinner;
