import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { IClientImpactModuleData } from '../../../../store/api/types/leadership';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useActions } from '../../../../hooks/actions';

interface IProps {
  data?: IClientImpactModuleData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const ImpactModulesClientVLTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    openEditImpactModuleVLClientModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: IClientImpactModuleData) => {
    openEditImpactModuleVLClientModal(row);
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<IClientImpactModuleData>[]>(
    () => [
      {
        header: 'Module Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
      },
      {
        header: 'System Name',
        accessorKey: 'systemValue' || 'systemName', // added both once it is confirmed will remove one
        filterFn: 'includesString',
        Cell: ({ row }) =>
          row.original.systemName || row.original.systemValue || '-',
      },
      {
        header: 'ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
      {
        header: 'Impact Module Abbreviation',
        accessorKey: 'abbreviation',
        filterFn: 'includesString',
        Cell: ({ row }) => row.original.abbreviation,
      },
      // It will be uncommented once it is done and returned in API response as per AC
      //   {
      //     header: 'Statements in Module',
      //     accessorFn: () => '',
      //   },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ showSkeletons: isLoading }}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                handleEdit(row.original as IClientImpactModuleData)
              }
            >
              Edit
            </MenuItem>
          </Menu>
        </>
      )}
    />
  );
};

export default ImpactModulesClientVLTable;
