import React from 'react';
import { styled } from '@mui/material/styles';

const TitleLabel = styled('p')({
  padding: 0,
  marginLeft: '8px',
});

interface IProps {
  text: string;
}

export default function LabelTitle({ text }: IProps) {
  return <TitleLabel>{text}</TitleLabel>;
}
