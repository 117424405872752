import { Box, styled } from '@mui/material';
import Title from '../../../atoms/title/Title';
import SubTitle from '../../../atoms/title/SubTitle';
import Button from '../../../atoms/button/Button';
import { routes } from '../../../routes/routes';
import PageHeader from '../../../atoms/header/PageHeader';
import ResetButton from '../../../atoms/button/ResetButton';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { useActions } from '../../../hooks/actions';
import { useSyncNavigatorUsersMutation } from '../../../store/api/core';
import Toggle from '../../../atoms/toggle/Toggle';
import React, { useEffect } from 'react';
import ImportIcon from '../../../assets/icons/Import.svg';
import { sendTazioInfo } from '../../../store/api/types/tazio';
import { MRT_TableInstance } from 'material-react-table';
import {
  useEditClientMutation,
  useGetClientByIdQuery,
} from '../../../store/api/clients';
import { useSendLeadersToTazioMutation } from '../../../store/api/v2/leaderProfile';

interface IProps {
  qtyOfUsers?: number;
  onResetButtonClick: () => void;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButtonOuterWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  flexDirection: 'column',
  justifyContent: 'end',
  paddingBottom: '16px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '22px',
  gap: '15px',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const StyledToggleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

const UsersHeader = ({ qtyOfUsers, onResetButtonClick }: IProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentLocation = useLocation().pathname;

  const [isTazioLoading, setIsTazioLoading] = React.useState(false);
  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    id as string
  );

  const [editClient] = useEditClientMutation();

  const [SyncNavigatorUsers, { isLoading }] = useSyncNavigatorUsersMutation();

  const [sendLeadersToTazio] = useSendLeadersToTazioMutation();

  const {
    openImportCompanyUserModal,
    openDownloadCompanyUsersModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
    toggleInfoSnackbar,
  } = useActions();

  const handleSyncNavigator = async () => {
    try {
      const { status } = await SyncNavigatorUsers(id as string).unwrap();
      if (status === 'success') {
        toggleSuccessSnackbar({
          message: 'Successfully updated the user information',
        });
      } else {
        toggleErrorSnackbar({
          message: 'EightFold API credentials are blank or invalid',
        });
      }
    } catch (error) {
      toggleErrorSnackbar({
        message: 'EightFold API credentials are blank or invalid',
      });
    }
  };

  const handleTazioDownload = async () => {
    setIsTazioLoading(false);

    const payload = {
      clientId: id,
      payload: {
        applications: {
          insights: {
            apiKey:
              (clientData && clientData[0].applications?.insights?.apiKey) ||
              null,
            url:
              (clientData && clientData[0].applications?.insights?.url) || null,
            accountId:
              (clientData && clientData[0].applications?.insights?.accountId) ||
              null,
            projectId:
              (clientData && clientData[0].applications?.insights?.projectId) ||
              null,
            isEnabled:
              (clientData && clientData[0].applications?.insights?.isEnabled) ||
              null,
            bulkImportAssessmentsEnabled:
              clientData &&
              !clientData[0].applications?.insights
                ?.bulkImportAssessmentsEnabled,
          },
        },
      },
    };

    try {
      const response = await editClient(payload).unwrap();
      if (response.status === 'success') {
        toggleSuccessSnackbar({
          message: 'Updating the system with new assessment information',
        });
        // Show snackbar only if it is set true
        if (
          clientData &&
          !clientData[0].applications?.insights?.bulkImportAssessmentsEnabled
        ) {
          setIsTazioLoading(true);
        }
      } else {
        toggleErrorSnackbar({
          message: 'Tazio API credentials are blank or invalid',
        });
      }
    } catch (e) {
      toggleErrorSnackbar({
        message: 'Tazio API credentials are blank or invalid',
      });
    }
  };

  useEffect(() => {
    if (isTazioLoading) {
      toggleInfoSnackbar({
        message: 'Successfully updated the assessment information',
      });
    }
  }, [isTazioLoading]);

  if (isLoading) {
    toggleInfoSnackbar({
      message: 'Updating the system with new user information',
    });
  }

  const sendToTazio = () => {
    const payload = {
      client_id: clientData && clientData[0]?.id,
      account_id:
        clientData && clientData[0]?.applications?.insights?.accountId,
      project_id:
        clientData && clientData[0]?.applications?.insights?.projectId,
    };

    if (
      !(clientData && clientData[0]?.applications?.insights?.accountId) &&
      !(clientData && clientData[0]?.applications?.insights?.projectId)
    ) {
      toggleErrorSnackbar({ message: 'Project ID is missing or invalid' });
    } else {
      sendLeadersToTazio(payload as sendTazioInfo)
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            toggleSuccessSnackbar({
              message: 'Leaders details has been sent to Tazio!',
            });
          } else {
            toggleErrorSnackbar({
              message:
                'The system encountered an issue while sending the leader information.',
            });
          }
        })
        .catch(() => {
          toggleErrorSnackbar({
            message:
              ' The system encountered an issue while sending the leader information.',
          });
        });
    }
  };

  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.company.users.name} />
        <StyledSubHeaderWrapper>
          <SubTitle text={`${qtyOfUsers ? qtyOfUsers : '0'} total users`} />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <StyledButtonOuterWrapper>
        <StyledButtonWrapper>
          <StyledToggleWrapper>
            <SubTitle text='Tazio Download' />
            <Toggle
              onChange={handleTazioDownload}
              checked={
                clientData?.at(0)?.applications?.insights
                  ?.bulkImportAssessmentsEnabled || false
              }
            />
          </StyledToggleWrapper>
          {clientData &&
            clientData[0]?.applications?.insights?.bulkImportAssessmentsTs && (
              <Box>{`Last download : ${new Date(
                clientData[0]?.applications?.insights?.bulkImportAssessmentsTs
              ).toLocaleString()}`}</Box>
            )}
          <Button
            btnType='PRIMARY'
            text={'Sync Navigator'}
            onClick={handleSyncNavigator}
          />
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <Button
            btnType='SECONDARY'
            text={'Send Tazio'}
            onClick={sendToTazio}
          />
          <Button
            btnType='SECONDARY'
            text={'Download data'}
            onClick={openDownloadCompanyUsersModal}
            startIcon={
              <ButtonIcon
                src={ImportIcon}
                alt='Import CSV'
              />
            }
          />
          <Button
            btnType='SECONDARY'
            text={'Import data'}
            onClick={() => openImportCompanyUserModal()}
            startIcon={
              <ButtonIcon
                src={ImportIcon}
                alt='Import CSV'
              />
            }
          />
          <Button
            btnType='PRIMARY'
            text={'Add new user'}
            onClick={() =>
              navigate(`${currentLocation}${routes.company.users.add.url}`)
            }
          />
        </StyledButtonWrapper>
      </StyledButtonOuterWrapper>
    </PageHeader>
  );
};

export default UsersHeader;
