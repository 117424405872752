import React from 'react';
import LabelDropdown, { IRules } from '../../atoms/dropdown/LabelDropdown';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { DropdownTypes } from '../../commons/types';
import { SelectChangeEvent } from '@mui/material';

interface IProps {
  label: string;
  tooltip?: string | JSX.Element;
  onChange?: (value: SelectChangeEvent<unknown>) => void;
  placeholder?: string;
  disabled?: boolean;
  rules?: IRules;
  dropDownItem: Array<DropdownTypes | null>;
  multiple?: boolean;
  onClick?: () => void;
}

export default function FormLabelDropdown({
  name,
  label,
  tooltip,
  onChange,
  dropDownItem,
  defaultValue = '',
  placeholder = '',
  ...extraProps
}: IProps & UseControllerProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...extraProps}
      render={({ field }) => (
        <LabelDropdown
          inputProps={{ ...field }}
          onChange={onChange}
          error={!!errors[name]}
          label={label}
          tooltip={tooltip}
          dropDownItem={dropDownItem}
          placeholder={placeholder}
          {...extraProps}
        />
      )}
    />
  );
}
