import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetRoleValidateInformation } from './api/types/leadership';

interface statementLibraryWarningModalProps {
  isOpen: boolean;
  initialValues: GetRoleValidateInformation[];
}

const initialState: statementLibraryWarningModalProps = {
  isOpen: false,
  initialValues: [],
};

const statementLibraryWarningModalSlice = createSlice({
  name: 'statementLibraryWarningModal',
  initialState,
  reducers: {
    closeStatementLibraryWarningModal: (state) => {
      state.isOpen = false;
    },
    openStatementLibraryWarningModal: (
      state,
      action: PayloadAction<GetRoleValidateInformation[]>
    ) => {
      state.isOpen = true;
      state.initialValues = action.payload;
    },
  },
});

export const statementLibraryWarningModalActions =
  statementLibraryWarningModalSlice.actions;
export const statementLibraryWarningModalReducer =
  statementLibraryWarningModalSlice.reducer;
