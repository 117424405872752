import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface errorsModalProps {
  isOpen: boolean;
  initialValues: string;
}

const initialState: errorsModalProps = {
  isOpen: false,
  initialValues: '',
};

const errorsModalSlice = createSlice({
  name: 'errorsModal',
  initialState,
  reducers: {
    closeErrorsModal: (state) => {
      state.isOpen = false;
    },
    openErrorsModal: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.initialValues = action.payload;
    },
  },
});

export const errorsModalActions = errorsModalSlice.actions;
export const errorsModalReducer = errorsModalSlice.reducer;
