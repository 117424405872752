import React, { useEffect } from 'react';
import { Box, Container, SelectChangeEvent, styled, Grid } from '@mui/material';
import PageHeader from '../../atoms/header/PageHeader';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import HDBreadCrumb from '../../hd-ui-kit/HDBreadcrumb';
import FormWrapper from '../../molecules/form/FormWrapper';
import HpCard from '../../atoms/card/HpCard';
import HpCardHeader from '../../atoms/card/HpCardHeader';
import HpCardContent from '../../atoms/card/HpCardContent';
import FormLabelInput from '../../molecules/form/FormLabelInput';
import Button from '../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import FormLabelDropdown from '../../molecules/form/FormLabelDropdown';
import { useParams, useNavigate } from 'react-router';
import FormLabelDatePicker from '../../molecules/form/FormLabelDatePicker';
import { useActions } from '../../hooks/actions';
import FormToggle from '../../molecules/form/FormToggle';
import { routes } from '../../routes/routes';
import HDSpinner from '../../hd-ui-kit/HDSpinner';
import LabelTitle from '../../atoms/title/LabelTitle';
import documentIcon from '../../assets/icons/document.svg';
import closeIcon from '../../assets/icons/close.svg';
import uploadIcon from '../../assets/icons/upload.svg';
import ImportCompanyEmployeeResumeModal from '../../organisms/modals/Company/ImportCompanyEmployeeResumeModal';
import {
  useCreateLeaderProfileMutation,
  useLazyGetLeaderProfileByIdQuery,
  useUpdateLeaderProfileMutation,
} from '../../store/api/v2/leaderProfile';
import {
  IAttachmentType,
  ICreateLeaderProfileUserRequest,
  ILeaderProfileData,
  ILeaderProfileResponse,
} from '../../store/api/types/leaderProfile';
import {
  useEditUserMutation,
  useLazyGetUsersQuery,
} from '../../store/api/v2/auth';
import { IUserData, userApplications } from '../../store/api/v2/types/auth';
import {
  useGetRoleProfilesQuery,
  useGetImpactGroupQuery,
} from '../../store/api/leadership';
import {
  useDeleteLeaderAttachmentMutation,
  useLazyGetAttachmentDetailsByIdQuery,
  useUpdateLogoAttachmentMutation,
} from '../../store/api/attachments';

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  clientEmpId: string;
  clientJobCode: string;
  companyName: string;
  phoneNumber?: string;
  officeLocation?: string;
  gender?: string;
  race?: string;
  roleProfileId: string;
  jobTitle: string;
  jobCode: string;
  jobLevel: string;
  businessUnit: string;
  division: string;
  hiringDate: string;
  roleChangeDate?: string;
  terminationDate?: string;
  tag: string;
  linkedInUrl: string;
  impactGroupAssigned: string;
  id: string;
  tazioSent?: boolean;
  candidateId?: string;
  managerFullName?: string;
  managerEmail?: string;
  userId?: string;
  efId?: string;
  assessments?: string[];
  employeeManagerId?: string;
  navigator_enabled?: boolean;
  journey_enabled?: boolean;
  jrny_usertype?: string;
  insights_enabled?: boolean;
  ef_usertype?: string;
}

const SpinnerWrapper = styled(Box)(() => ({
  '& .la-ball-spin.la-2x': {
    margin: '0 auto',
    top: '40%',
  },
  width: '100%',
  height: '100%',
  zIndex: 99,
  textAlign: 'center',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginBottom: '40px',
}));

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledResumeContainer = styled(Box)(() => ({
  background: '#FFFFFF',
  border: '1px solid #646A7C',
  borderRadius: '6px',
  marginTop: '10px',
  paddingLeft: '25px',
}));

const StyleResumeLabel = styled('p')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#002136',
  display: 'flex',
  alignItems: 'center',
});

const StyledFileContainer = styled(Box)(() => ({
  margin: '5px 0px',
  paddingLeft: '25px',
  paddingRight: '20px',
  paddingBottom: '10px',
  minHeight: '50px',
  maxHeight: '300px',
  overflowY: 'auto',
  '& > *:not(:first-of-type)': {
    borderTop: '1px solid rgba(0, 0, 0, 0.5)',
  },
  '& .file-info': {
    marginBottom: '15px',
    '& .filename-box': {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: 'Aventa',
      fonStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.02em',
      color: '#002136',
    },
    '& .file-description': {
      fontFamily: 'Avenir',
      fonStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: '#334255',
    },
  },
}));

const StyledBodyContainer = styled(Container)(() => ({
  marginBottom: '40px',
}));

const StyledGridSpan = styled('span')(() => ({
  color: '#646a7c',
  margin: '17px',
  fontFamily: 'Avenir',
}));

const StyledGrid = styled(Grid)(() => ({
  marginTop: '10px',
  border: '1px solid #eee',
  padding: '30px 15px 15px 15px',
  borderRadius: '5px',
}));

const formatBytes = (bytes: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;

  for (i; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(2) + units[i];
};

const AddNewUser = () => {
  const [initialValues, setInitialValues] = React.useState<
    ILeaderProfileData | undefined
  >(undefined);
  const [userAppData, setUserAppData] =
    React.useState<userApplications | null>();
  const [loadForm, setLoadForm] = React.useState(false);
  const [deletedItems, setDeletedItems] = React.useState<Array<string>>([]);
  const [employeeResume, setEmployeeResume] = React.useState<IAttachmentType[]>(
    []
  );
  const [fileData, setFileData] = React.useState<
    Array<{ docType: string; file: File }>
  >([]);

  const { id, userid } = useParams();

  const [authUserId, setUserId] = React.useState('');

  const navigate = useNavigate();

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const { data: { dropdown, data: rolesData } = {} } = useGetRoleProfilesQuery(
    id || ''
  );
  const [addEmployee] = useCreateLeaderProfileMutation();
  const [updateEmployee] = useUpdateLeaderProfileMutation();
  const [employeeResumeUpload] = useUpdateLogoAttachmentMutation();
  const [deleteEmployeeResume] = useDeleteLeaderAttachmentMutation();
  const [getLeaderProfileData] = useLazyGetLeaderProfileByIdQuery();

  const { data: impactGroupData } = useGetImpactGroupQuery({
    id: id || '',
  });

  const onEmployeeResumeDelete = (docId: string) => {
    setEmployeeResume((value) => value.filter((item) => item.id !== docId));
    setDeletedItems((value) => [...value, docId]);
  };

  // Added this useeffect to check if edit user action is in progress and fetch user data by ID
  useEffect(() => {
    if (userid && !initialValues) {
      // Get Leader profile data
      // getLeaderProfileAttachments(userid);
      getLeaderProfileData(userid)
        .unwrap()
        .then((res: ILeaderProfileResponse) => {
          const response = res as ILeaderProfileResponse;
          if (response?.data) {
            setInitialValues(response.data[0]);
            if (response.data[0]?.attachments) {
              setEmployeeResume(response.data[0].attachments);
            }
            setLoadForm(false);
          }
        });
    } else {
      // load add form
      setLoadForm(true);
    }
  }, [getLeaderProfileData, initialValues, userid]);

  const methods = useForm<IFormData>();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
  } = methods;
  // To Find the Value of ClientJobCode, It based on roleProfileId

  useEffect(() => {
    if (rolesData && initialValues?.roleProfileId?.length) {
      const role = rolesData?.find(
        (el) => el.id === initialValues.roleProfileId
      );
      if (role) {
        setValue('clientJobCode', role?.clientRoleCode || '');
      }
    }
  }, [rolesData, initialValues, setValue]);

  const [watchNavigatorEnabled, watchJrnyEnabled] = watch([
    'navigator_enabled',
    'journey_enabled',
  ]);

  const { openImportCompanyEmployeeResumeModal } = useActions();

  const handleNavigator = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value) {
      setValue('ef_usertype', '');
      clearErrors('ef_usertype');
    }
    return e;
  };
  const handleJourney = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value) {
      setValue('jrny_usertype', '');
      clearErrors('jrny_usertype');
    }
    return e;
  };

  const formatDate = (timeStamp: Date | string) => {
    const date = new Date(timeStamp);
    const day = String(date.getDate()).padStart(2, '0'); // to get date in 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // to get month in 2 digits
    const convertedDate = date.getFullYear() + '-' + month + '-' + day;
    return convertedDate;
  };

  const onDeleteEmployeeResume = async () => {
    const promises = deletedItems.map(
      async (id) => await deleteEmployeeResume(id).unwrap()
    );
    await Promise.all(promises)
      .then(() =>
        toggleSuccessSnackbar({ message: 'Files Deleted Successfully' })
      )
      .catch(() => toggleErrorSnackbar({ message: 'Error Deleting Files' }));
  };

  const uploadEmployeeResumes = async (profileId: string) => {
    const promises = fileData.map(async (resume) => {
      const formData = new FormData();
      formData.append('file', resume.file);
      formData.append(
        'category',
        resume.docType === 'resume' ? 'Reports' : 'Assessments'
      );
      if (resume.docType !== 'resume') {
        formData.append('reportName', resume?.file?.name || '');
        formData.append('reportType', 'employeeReport');
      }
      formData.append('contentType', resume.file.type);
      formData.append('leaderProfileId', profileId);
      formData.append('clientId', id || '');
      formData.append('refType', 'Leader');
      return await employeeResumeUpload(formData).unwrap();
    });
    await Promise.all(promises)
      .then(() => {
        toggleSuccessSnackbar({
          message: 'Files Uploaded Successfully',
        });
      })
      .catch((e) =>
        toggleErrorSnackbar({
          message: e?.data?.message || 'Error Uploading Files',
        })
      );
  };

  const onSubmit = async (formData: IFormData) => {
    formData.tag = '';
    formData.linkedInUrl = '';
    formData.email = formData.email.toLowerCase(); // save email in small case

    // Check if manager email date is passed and convert in it in small case
    if (formData.managerEmail) {
      formData.managerEmail = formData.managerEmail.toLowerCase();
    }

    // Check if hiring date is passed and convert in payload accepted format
    if (formData.hiringDate) {
      formData.hiringDate = formatDate(formData.hiringDate);
    }

    // Check if Role Change date is passed and convert in payload accepted format
    if (formData.roleChangeDate) {
      formData.roleChangeDate = formatDate(formData.roleChangeDate);
    }

    // Check if termination date is passed and convert in payload accepted format
    if (formData.terminationDate) {
      formData.terminationDate = formatDate(formData.terminationDate);
    }
    // Need in future
    // const userPayload = {
    //   firstName: formData.firstName,
    //   lastName: formData.lastName,
    //   userName: formData.email,
    //   applications: {
    //     navigatorEnabled: formData.navigator_enabled,
    //     insightsEnabled: formData.insights_enabled,
    //     journeyEnabled: formData.journey_enabled,
    //     ...(formData.ef_usertype && { naviUserType: formData.ef_usertype }),
    //     ...(formData.jrny_usertype && { jrnyUserType: formData.jrny_usertype }),
    //   },
    //   roles: [{ name: 'Client Users' }],
    // } as IUserData;

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      clientEmpId: formData.clientEmpId,
      companyName: formData.companyName,
      ...(formData.phoneNumber && { phoneNumber: formData.phoneNumber }),
      ...(formData.officeLocation && {
        officeLocation: formData.officeLocation,
      }),
      ...(formData.gender && { gender: formData.gender }),
      ...(formData.race && { race: formData.race }),
      roleProfileId: formData.roleProfileId,
      jobTitle: formData.jobTitle,
      // jobCode: formData.jobCode,
      jobLevel: formData.jobLevel,
      businessUnit: formData.businessUnit,
      division: formData.division,
      ...(formData.hiringDate && {
        hiringDate: formData.hiringDate,
      }),
      ...(formData.roleChangeDate && {
        roleChangeDate: formData.roleChangeDate,
      }),
      ...(formData.terminationDate && {
        terminationDate: formData.terminationDate,
      }),
      tag: formData.tag,
      linkedInUrl: formData.linkedInUrl,
      ...((formData.managerEmail || formData.managerFullName) && {
        manager: {
          ...(formData.managerFullName && {
            fullName: formData.managerFullName,
          }),
          ...(formData.managerEmail && { email: formData.managerEmail }),
        },
      }),
      impactGroupAssigned: formData.impactGroupAssigned,
      applications: {
        navigatorEnabled: formData.navigator_enabled,
        insightsEnabled: formData.insights_enabled,
        journeyEnabled: formData.journey_enabled,
        ...(formData.ef_usertype && { naviUserType: formData.ef_usertype }),
        ...(formData.jrny_usertype && { jrnyUserType: formData.jrny_usertype }),
      },
    } as ICreateLeaderProfileUserRequest;

    if (userid) {
      updateEmployee({ id: userid, payload })
        .unwrap()
        .then((res) => {
          if (res.status === 'error') {
            toggleErrorSnackbar({ message: 'Error updating employee' });
          } else {
            if (deletedItems.length) {
              onDeleteEmployeeResume();
            }
            if (fileData.length) {
              uploadEmployeeResumes(userid);
            }
            toggleSuccessSnackbar({
              message: 'Employee Updated Successfully',
            });
            // Navigate to users table after successful user update
            navigate(`${routes.company.url}${id}${routes.company.users.url}`);
          }
        })
        .catch((err) => {
          toggleErrorSnackbar({ message: err.message });
        });
    } else {
      payload.clientId = id;
      addEmployee(payload)
        .unwrap()
        .then((res) => {
          const { id: profileId } = res.data;
          if (fileData.length && id) {
            uploadEmployeeResumes(profileId);
          }

          toggleSuccessSnackbar({
            message: 'Employee Added Successfully',
          });
          // Navigate to users table after successful user creation
          navigate(`${routes.company.url}${id}${routes.company.users.url}`);
        })
        .catch((err) => {
          const error = err as { data?: { detail: string } };
          const { data: { detail } = {} } = error;
          if (detail) toggleErrorSnackbar({ message: detail });
          else toggleErrorSnackbar({ message: err });
        });
    }
  };

  const onRoleChange = (e: SelectChangeEvent<unknown>) => {
    const role = rolesData?.find((el) => el.id === e.target.value);
    if (role) {
      setValue('jobLevel', role?.levelName || '');
      setValue('clientJobCode', role?.clientRoleCode || '');
    }
  };

  // User type to show in Navigator usertype dropdown
  const navigatorUsertypes = [
    {
      keyName: 'Employee',
      keyValue: 'Employee',
    },
    {
      keyName: 'CHRO',
      keyValue: 'CHRO',
    },
    {
      keyName: 'HRBP',
      keyValue: 'HRBP',
    },
    {
      keyName: 'Admin',
      keyValue: 'Admin',
    },
  ];

  // User types to show in Journey usertype dropdown
  const journeyUsertypes = [
    {
      keyName: 'Facilitator',
      keyValue: 'Facilitator',
    },
    {
      keyName: 'Learner',
      keyValue: 'Learner',
    },
    {
      keyName: 'Champion',
      keyValue: 'Champion',
    },
  ];

  return (
    <>
      {loadForm ? (
        <>
          <PageHeader>
            <StyledTitlesWrapper>
              <BreadCrumbWrapper>
                {initialValues ? (
                  <HDBreadCrumb
                    routes={[
                      { text: 'Users' },
                      {
                        text: `${initialValues.firstName} ${initialValues.lastName}`,
                      },
                    ]}
                  />
                ) : (
                  <HDBreadCrumb
                    routes={[{ text: 'Users' }, { text: 'Add New User' }]}
                  />
                )}
              </BreadCrumbWrapper>
              {initialValues ? (
                <Title
                  text={`${initialValues.firstName} ${initialValues.lastName}`}
                />
              ) : (
                <Title text={'Add New User'} />
              )}
              <StyledSubHeaderWrapper>
                <SubTitle text={'Optional fields are labeled "optional".'} />
              </StyledSubHeaderWrapper>
            </StyledTitlesWrapper>
          </PageHeader>
          <StyledBodyContainer>
            <FormWrapper
              methods={methods}
              id='add-new-user'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={6}
                  item
                >
                  <HpCard sx={{ marginBottom: '20px' }}>
                    <HpCardHeader title='Employee ID information' />
                    <HpCardContent sx={{ rowGap: '28px' }}>
                      <FormLabelInput
                        name='firstName'
                        label='First Name'
                        rules={{ required: 'Please provide First Name' }}
                        defaultValue={initialValues && initialValues.firstName}
                      />
                      <FormLabelInput
                        name='lastName'
                        label='Last Name'
                        rules={{ required: 'Please provide Last Name' }}
                        defaultValue={initialValues && initialValues.lastName}
                      />
                      <FormLabelInput
                        name='email'
                        label='Email'
                        rules={{
                          required: 'Please provide an Email',
                          pattern: {
                            value: /^[\w+.-]+@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Please provide a valid email ID',
                          },
                        }}
                        defaultValue={initialValues && initialValues.email}
                        disabled={!!initialValues}
                        tooltip='Leader emails cannot be updated from the Admin Portal.
                         If you need an email to be updated please open a support ticket.'
                      />
                      <FormLabelInput
                        name='clientEmpId'
                        label='Client Employee ID'
                        defaultValue={
                          initialValues && initialValues?.clientEmpId
                        }
                      />
                      <FormLabelInput
                        name='clientJobCode'
                        label='Client Job Code'
                        disabled={true}
                        defaultValue={
                          initialValues && initialValues?.clientJobCode
                        }
                      />
                      <FormLabelInput
                        name='companyName'
                        label='Company Name'
                        defaultValue={
                          initialValues && initialValues?.companyName
                        }
                      />
                      <FormLabelInput
                        name='phoneNumber'
                        label='Phone (optional)'
                        defaultValue={
                          initialValues && initialValues?.phoneNumber
                        }
                      />
                      <FormLabelInput
                        name='officeLocation'
                        label='Location (optional)'
                        defaultValue={
                          initialValues && initialValues?.officeLocation
                        }
                      />
                      <FormLabelDropdown
                        name='gender'
                        label='Gender (optional)'
                        dropDownItem={[
                          { keyValue: 'M', keyName: 'Male' },
                          { keyValue: 'F', keyName: 'Female' },
                        ]}
                        defaultValue={
                          (initialValues && initialValues.gender) || ''
                        } // We will need it when we have gender dropdown
                      />
                      <FormLabelDropdown
                        name='race'
                        label='Race (optional)'
                        dropDownItem={[{ keyValue: '', keyName: '' }]}
                        // defaultValue={initialValues && initialValues?.race} // We will need it when we have race dropdown
                      />
                    </HpCardContent>
                  </HpCard>
                  <HpCard sx={{ marginBottom: '20px' }}>
                    <HpCardHeader title='Role Information' />
                    <HpCardContent sx={{ rowGap: '28px' }}>
                      {dropdown && (
                        <FormLabelDropdown
                          name='roleProfileId'
                          label='Role'
                          onChange={onRoleChange}
                          dropDownItem={dropdown}
                          defaultValue={
                            (dropdown && initialValues?.roleProfileId) || ''
                          }
                        />
                      )}
                      <FormLabelInput
                        name='jobTitle'
                        label='Title'
                        defaultValue={initialValues && initialValues?.jobTitle}
                      />
                      <FormLabelInput
                        name='jobCode'
                        label='Heidrick Job Code'
                        extraLabelInputProps={{
                          disabled: true,
                        }}
                        defaultValue={initialValues && initialValues?.jobCode}
                      />
                      <FormLabelInput
                        name='jobLevel'
                        label='Level'
                        extraLabelInputProps={{
                          disabled: true,
                        }}
                        defaultValue={initialValues && initialValues?.jobLevel}
                      />
                      <FormLabelInput
                        name='businessUnit'
                        label='Business Unit'
                        defaultValue={
                          initialValues && initialValues?.businessUnit
                        }
                      />
                      <FormLabelInput
                        name='division'
                        label='Division'
                        defaultValue={initialValues && initialValues?.division}
                      />
                      <FormLabelDatePicker
                        name='hiringDate'
                        label='Hiring Date (MM / DD / YYYY)'
                        defaultValue={
                          initialValues
                            ? initialValues?.hiringDate !== ''
                              ? initialValues.hiringDate
                              : null
                            : null
                        }
                      />
                      <FormLabelDatePicker
                        name='roleChangeDate'
                        label='Role Change Date (MM / DD / YYYY) - optional'
                        defaultValue={
                          initialValues
                            ? initialValues?.roleChangeDate !== ''
                              ? initialValues.roleChangeDate
                              : null
                            : null
                        }
                      />
                      <FormLabelDatePicker
                        name='terminationDate'
                        label='Termination Date (MM / DD / YYYY) - optional'
                        defaultValue={
                          initialValues
                            ? initialValues?.terminationDate !== ''
                              ? initialValues.terminationDate
                              : null
                            : null
                        }
                      />
                      <FormLabelInput
                        name='employeeManagerId'
                        label='Manager ID (optional)'
                        defaultValue={
                          initialValues && initialValues?.employeeManagerId
                        }
                      />
                      <FormLabelInput
                        name='managerEmail'
                        label='Manager Email (optional)'
                        rules={{
                          pattern: {
                            value:
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Please provide a valid email ID',
                          },
                        }}
                        defaultValue={
                          initialValues && initialValues?.manager?.email
                        }
                      />
                      <FormLabelInput
                        name='managerFullName'
                        label='Manager Full Name (optional)'
                        defaultValue={
                          initialValues && initialValues?.manager?.fullName
                        }
                      />
                      <Box>
                        <LabelTitle text={'File (optional)'} />
                        <StyledResumeContainer>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <img
                              src={documentIcon}
                              alt={'document icon'}
                            />
                            <StyleResumeLabel> {'  Files'}</StyleResumeLabel>
                          </Box>
                          <StyledFileContainer>
                            {fileData.reverse().map((item, key) => (
                              <Box
                                key={key}
                                className='file-info'
                              >
                                <span className='filename-box'>
                                  <p>{item?.file.name}</p>
                                  <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      setFileData((value) => {
                                        const prevArray = [...value];
                                        prevArray.splice(key, 1);
                                        return prevArray;
                                      })
                                    }
                                    alt={'close icon'}
                                    src={closeIcon}
                                  />
                                </span>
                                <span className='file-description'>
                                  Uploaded{' '}
                                  {
                                    new Date()
                                      .toLocaleString('en-US')
                                      .split(',')[0]
                                  }{' '}
                                  | {formatBytes(item?.file.size || 0)} |{' '}
                                  {item.docType === 'resume'
                                    ? 'Resume'
                                    : 'Report'}
                                </span>
                              </Box>
                            ))}
                            {employeeResume.map((item, key) => (
                              <Box
                                key={key}
                                className='file-info'
                              >
                                <span className='filename-box'>
                                  <p>{item.name}</p>

                                  <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      onEmployeeResumeDelete(item.id)
                                    }
                                    src={closeIcon}
                                    alt={'close icon'}
                                  />
                                </span>
                                <span className='file-description'>
                                  Uploaded{' '}
                                  {
                                    new Date(item.addedTs)
                                      .toLocaleString('en-US')
                                      .split(',')[0]
                                  }{' '}
                                  | {formatBytes(item.contentLength || 0)} |{' '}
                                  {item.category === 'Resume'
                                    ? 'Resume'
                                    : 'Report'}
                                </span>
                              </Box>
                            ))}
                          </StyledFileContainer>
                          <Button
                            sx={{ marginLeft: '25px', marginBottom: '10px' }}
                            btnType={'SECONDARY'}
                            text={'Add New'}
                            type='submit'
                            startIcon={
                              <img
                                src={uploadIcon}
                                alt={'upload icon'}
                              />
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              openImportCompanyEmployeeResumeModal();
                            }}
                          />
                        </StyledResumeContainer>
                      </Box>
                    </HpCardContent>
                  </HpCard>
                  {impactGroupData?.impactGroupListDropdown && (
                    <HpCard sx={{ marginBottom: '20px' }}>
                      <HpCardHeader title='Impact Group' />
                      <HpCardContent sx={{ rowGap: '5px' }}>
                        <FormLabelDropdown
                          name='impactGroupAssigned'
                          label='Assigned Impact Group'
                          dropDownItem={
                            impactGroupData?.impactGroupListDropdown || []
                          }
                          defaultValue={
                            initialValues?.impactGroupAssigned || ''
                          }
                          placeholder={'Select'}
                        />
                      </HpCardContent>
                    </HpCard>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <HpCard>
                    <HpCardHeader title='Application Privileges' />
                    <HpCardContent sx={{ rowGap: '5px' }}>
                      <StyledGrid>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={10}
                          >
                            <StyledGridSpan>Heidrick Navigator</StyledGridSpan>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                          >
                            <FormToggle
                              name='navigator_enabled'
                              rules={{ onChange: handleNavigator }}
                              defaultValue={
                                (initialValues &&
                                  initialValues?.navigator_enabled) ||
                                false
                              }
                            />
                          </Grid>
                        </Grid>
                        <FormLabelDropdown
                          name='ef_usertype'
                          label='User Type'
                          rules={{
                            required:
                              watchNavigatorEnabled &&
                              'Please select a User Type',
                          }}
                          placeholder={'Select a User Type'}
                          dropDownItem={
                            userid
                              ? [...navigatorUsertypes]
                              : navigatorUsertypes
                          }
                          disabled={!watchNavigatorEnabled}
                          defaultValue={
                            userAppData ? userAppData.naviUserType : ''
                          }
                        />
                      </StyledGrid>
                      <StyledGrid>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={10}
                          >
                            <StyledGridSpan>Heidrick Journey</StyledGridSpan>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                          >
                            <FormToggle
                              name='journey_enabled'
                              rules={{ onChange: handleJourney }}
                              defaultValue={
                                (initialValues &&
                                  initialValues?.journey_enabled) ||
                                false
                              }
                            />
                          </Grid>
                        </Grid>
                        <FormLabelDropdown
                          name='jrny_usertype'
                          label='User Type'
                          rules={{
                            required:
                              watchJrnyEnabled && 'Please select a User Type',
                          }}
                          disabled={!watchJrnyEnabled}
                          dropDownItem={
                            userid ? [...journeyUsertypes] : journeyUsertypes
                          }
                          placeholder={'Select a User Type'}
                          defaultValue={
                            initialValues ? initialValues?.jrny_usertype : ''
                          }
                        />
                      </StyledGrid>
                      <StyledGrid>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={10}
                          >
                            <StyledGridSpan>Heidrick Insights</StyledGridSpan>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                          >
                            <FormToggle
                              name='insights_enabled'
                              defaultValue={
                                (initialValues &&
                                  initialValues?.insights_enabled) ||
                                false
                              }
                            />
                          </Grid>
                        </Grid>
                      </StyledGrid>
                    </HpCardContent>
                  </HpCard>
                </Grid>
              </Grid>
            </FormWrapper>
            <Box sx={{ marginTop: '24px' }}>
              <Button
                btnType={'PRIMARY'}
                text={'Save User'}
                type='submit'
                disabled={!!Object.values(errors).length}
                form='add-new-user'
              />
            </Box>
          </StyledBodyContainer>
        </>
      ) : (
        <SpinnerWrapper>
          <HDSpinner
            size={'la-2x'}
            buttonColor={false}
          />
        </SpinnerWrapper>
      )}
      <ImportCompanyEmployeeResumeModal setFileData={setFileData} />
    </>
  );
};

export default AddNewUser;
