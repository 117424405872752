import React, { useMemo } from 'react';
import Table from '../../../atoms/table/Table';
import { Box, IconButton, styled } from '@mui/material';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import Button from '../../../atoms/button/Button';
import InputSlider from '../../../atoms/slider/InputSlider';
import MultiSelectDropdown from '../../../atoms/multiselect/MultiSelectDropdown';
import { useActions } from '../../../hooks/actions';
import TrashIcon from '../../../assets/icons/trash.svg';
import HpTableTooltip from '../../../atoms/tooltip/HpTableTooltip';
import TooltipBenchmarkData from '../../../atoms/tooltip/TooltipBenchmarkData';
import {
  useDeleteRoleSkillByIdMutation,
  useEditSkillByIdMutation,
} from '../../../store/api/leadership';
import { IRolesData } from '../../../store/api/types/leadership';
import { SelectOptions } from '../../../commons/types';

interface IProps {
  data?: IRolesData[];
  isLoading: boolean;
}

interface IRoleBenchmarkUpdate {
  benchmark: string;
  skillId: string;
}

const StyledBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  margin: '15px',
  gap: '15px',
  width: '96%',
}));

const StyledCountWrapper = styled(Box)(() => ({
  display: 'flex',
  margin: '15px',
  gap: '15px',
  width: '30%',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  gap: '15px',
  width: '60%',
  flexDirection: 'row-reverse',
}));

const StatementDetailsTable = ({ data, isLoading }: IProps) => {
  const [benchmarkData, setBenchmarkData] = React.useState<
    Array<IRoleBenchmarkUpdate>
  >([]);

  const [editRoleBenchmark] = useEditSkillByIdMutation();
  const [deleteRoleSkill] = useDeleteRoleSkillByIdMutation();

  const subFunctionDropDownData = React.useMemo(() => {
    return (
      data
        ?.map((item) => ({
          text: item.subFunctionName,
          value: item.subFunctionName,
        }))
        .reduce((accum, initValue) => {
          if (accum.find((item) => item.value === initValue.value))
            return accum;
          accum.push(initValue);
          return accum;
        }, [] as Array<SelectOptions>) || []
    );
  }, [data]);

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const cellCallback = React.useCallback(
    (cellData: string, rowData: MRT_Row<IRolesData>) => {
      // Filter out the slider value which was already changed and add new one in state
      setBenchmarkData((state) => {
        const idx = state.findIndex(
          (item) => item?.skillId === rowData.original.skillId
        );

        if (idx === -1)
          return [
            ...state,
            { skillId: rowData.original.skillId, benchmark: cellData },
          ];
        const value = [...state] as Array<IRoleBenchmarkUpdate>;
        if (value[idx]) {
          value[idx].benchmark = cellData || '';
        }
        return value;
      });
    },
    []
  );

  // Function to update benchmark value against a statement
  const updateBenchmark = () => {
    // Iterate Over benchmarkData state to update one or more benchmark scores
    benchmarkData.map((item) => {
      editRoleBenchmark({
        benchmark: Number(item?.benchmark),
        id: item?.skillId || '',
      })
        .unwrap()
        .then(() => {
          toggleSuccessSnackbar({ message: 'Benchmark Updated Successfully' });
          setBenchmarkData([]);
        })
        .catch(() => toggleErrorSnackbar({}));
    });
  };

  const handleDelete = (rowData: IRolesData) => {
    deleteRoleSkill(rowData.skillId)
      .unwrap()
      .then(() =>
        toggleSuccessSnackbar({ message: 'Skill Deleted Successfully' })
      )
      .catch(() => toggleErrorSnackbar({}));
  };

  const columns = useMemo<MRT_ColumnDef<IRolesData>[]>(
    () => [
      {
        header: 'Role Name',
        filterFn: 'includesString',
        accessorKey: 'name',
      },
      {
        header: 'Role ID',
        accessorKey: 'roleId',
        filterFn: 'includesString',
      },
      {
        header: 'Sub-function',
        accessorKey: 'subFunctionName',
        filterSelectOptions: subFunctionDropDownData,
        filterVariant: 'multi-select',
        Filter: (header) => {
          return (
            <MultiSelectDropdown
              dropDownItem={subFunctionDropDownData}
              // This is an onChange event to update the filter value for this column
              onChange={(e, newValue, reason) => {
                if (
                  e.type === 'keydown' &&
                  (e as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                const selectedItems = newValue.map(
                  (value: { value: string }) => {
                    return value.value;
                  }
                );
                if (selectedItems.length > 0 && selectedItems !== null) {
                  header.column.setFilterValue(
                    (selectedItems.length > 0 && selectedItems) || undefined
                  );
                } else {
                  header.column.setFilterValue(undefined);
                }
              }}
            />
          );
        },
      },
      {
        header: 'Benchmark',
        accessorKey: 'benchmark',
        filterFn: 'equals',
        Header: ({ column }) => (
          <>
            <span>{column.columnDef.header}</span>
            <HpTableTooltip
              title={<TooltipBenchmarkData />}
              placement={'bottom-end'}
            />
          </>
        ),
        Cell: ({ cell, row }) => (
          <InputSlider
            value={cell.getValue<number>()}
            callback={(cellValue) => cellCallback(cellValue, row)}
          />
        ),
        size: 400,
      },
    ],
    [cellCallback, subFunctionDropDownData]
  );

  return (
    <>
      <StyledBoxWrapper>
        <StyledCountWrapper>
          {data && data.length} Roles Applied
        </StyledCountWrapper>
        <StyledButtonWrapper>
          <Button
            btnType={'PRIMARY'}
            text={'Save Changes'}
            onClick={() => updateBenchmark()}
            disabled={!benchmarkData.length}
          />
        </StyledButtonWrapper>
      </StyledBoxWrapper>
      <Table
        data={data ?? []}
        columns={columns as MRT_ColumnDef<object>[]}
        renderRowActions={({ row }) => {
          const rowData = row.original as IRolesData;
          return (
            <>
              <IconButton
                id={row.id}
                onClick={() => handleDelete(rowData)}
              >
                <img src={TrashIcon} />
              </IconButton>
            </>
          );
        }}
        state={{ showSkeletons: isLoading }}
      />
    </>
  );
};

export default StatementDetailsTable;
