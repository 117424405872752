import React, { useState, useEffect, useRef } from 'react';
import ClientProjectsHeader from '../../../organisms/headers/Company/Projects/ClientProjectsHeader';
import HpContainer from '../../../atoms/container/HpContainer';
import {
  useGetAssignmentsQuery,
  useGetProjectsByClientIdQuery,
} from '../../../store/api/projects';
import { useParams } from 'react-router';
import { Box, styled } from '@mui/material';
import { IProjectData } from '../../../store/api/types/projects';
import TabMenu from '../../../atoms/tab/TabMenu';
import TabMenuItem from '../../../atoms/tab/TabMenuItem';
import { ProjectsTab } from './ProjectsTab';
import { statusDropdown } from '../../../commons/projectPurposeDropdownData';
import { getProjectStatus } from '../../../utils/helpers';
import { useLazyGetAssessmentsStructureQuery } from '../../../store/api/assessmentStructure';
import HeidrickAssignmentTab from './HeidrickAssignmentTab';
import { MRT_TableInstance } from 'material-react-table';

const StyledTabContentWrapper = styled(Box)(() => ({
  paddingTop: '40px',
}));

const ClientProjects = () => {
  const { id } = useParams();

  const projectsTableInstanceRef = useRef<MRT_TableInstance>(null);
  const assignmentTableInstanceRef = useRef<MRT_TableInstance>(null);

  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<IProjectData[]>([]);
  const [filteredState, setFilteredState] = useState([]);
  const [filteredAssignmentState, setFilteredAssignmentState] = useState([]);

  const [allAssessmentsData, setAllAssessmentsData] = React.useState<any>([]);

  const { data: { data } = {}, isLoading } = useGetProjectsByClientIdQuery(
    id as string,
    { refetchOnMountOrArgChange: true }
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const createTableData = () => {
    if (data?.length) {
      const tempTableData = data.map((project) => {
        const status = getProjectStatus(project.assignmentStats);
        return { ...project, aggregatedStatus: status };
      });
      setTableData(tempTableData);
    }
  };

  const { data: allAssignmentsData = {}, isLoading: inProgress } =
    useGetAssignmentsQuery({
      params: { clientId: id },
    });

  const [getAssessmentStructures] = useLazyGetAssessmentsStructureQuery();

  React.useEffect(() => {
    allAssignmentsData?.data && getAllAssessmentsData(allAssignmentsData);
  }, [allAssignmentsData]);

  const getAllAssessmentsData = (allAssignmentsData: any) => {
    const temp: any[] = [];
    getAssessmentStructures({
      clientId: id,
      limit: undefined,
      offset: undefined,
    })
      .unwrap()
      .then((response) => {
        const allAssessmentStructureData = response.data;
        allAssignmentsData.data &&
          allAssignmentsData.data.map(async (data: any) => {
            if (data !== undefined && data?.assessmentStructures?.length > 0) {
              if (allAssessmentStructureData) {
                // compare assesment structure id to get type
                data?.assignments?.forEach((leader: any) => {
                  leader?.assessments?.forEach((assessment: any) => {
                    const assessmentType = allAssessmentStructureData.find(
                      (assessmentResponse: { id: any }) => {
                        return (
                          assessmentResponse.id ===
                          assessment.assessmentStructureId
                        );
                      }
                    );

                    temp.push({
                      participant:
                        leader.leaderFirstName + ' ' + leader.leaderLastName,
                      assessment: assessmentType?.title || '-',
                      skillCategory: assessmentType?.category || '-',
                      type:
                        assessment.appraisalStatus || assessment.raters
                          ? '360'
                          : assessmentType?.category === 'CBI'
                          ? 'CBI'
                          : 'Self Assessment',
                      email: leader?.leaderEmail || '-',
                      project: data.name || '-',
                      projectId: data.id,
                      projectStartDate: data.startDate || '-',
                      projectEndDate: data?.closeDate || '-',
                      status: assessment.assignmentStatus || '-',
                      id: leader.id || '-',
                      raters: assessment.raters || [],
                      completedDate: data.closeDate || '-',
                      completedPercentage: assessment.completion || '-',
                      lastReminderSent: assessment.latestReminderTs || '-',
                      reminderCount: assessment.reminderCount || '-',
                      raterTypes: assessment.ratersRequired || [],
                    });
                  });
                });
                if (temp.length) {
                  setAllAssessmentsData(temp);
                }
              }
            }
          });
      });
  };

  useEffect(() => {
    if (data?.length) createTableData();
  }, [data]);

  return (
    <>
      {/* Header has hidden summary metrics */}
      <ClientProjectsHeader
        tabValue={tabValue}
        filteredState={filteredState}
        filteredAssignmentState={filteredAssignmentState}
        projectsTableInstanceRef={projectsTableInstanceRef}
        assignmentTableInstanceRef={assignmentTableInstanceRef}
      />
      <HpContainer
        sx={{
          marginTop: '20px',
        }}
      >
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={`Projects(${tableData.length})`}
            disabled={isLoading}
          />
          <TabMenuItem
            index={1}
            label={`Assignments(${
              inProgress ? ' loading... ' : allAssessmentsData?.length
            })`}
          />
        </TabMenu>
        <StyledTabContentWrapper>
          <ProjectsTab
            tableInstanceRef={projectsTableInstanceRef}
            index={0}
            value={tabValue}
            data={tableData}
            isLoading={isLoading}
            setTabValue={setTabValue}
            filteredState={filteredState}
            setFilteredState={setFilteredState}
          />
          <HeidrickAssignmentTab
            index={1}
            tableInstanceRef={assignmentTableInstanceRef}
            value={tabValue}
            statusDropdown={statusDropdown}
            assessmentData={allAssessmentsData || []}
            isLoading={isLoading}
            filteredAssignmentState={filteredAssignmentState}
            setFilteredAssignmentState={setFilteredAssignmentState}
          />
        </StyledTabContentWrapper>
      </HpContainer>
    </>
  );
};

export default ClientProjects;
