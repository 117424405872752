import React, { useEffect, useState, useMemo } from 'react';
import HpCard from '../../../atoms/card/HpCard';
import HpCardHeader from '../../../atoms/card/HpCardHeader';
import HpCardContent from '../../../atoms/card/HpCardContent';
import { Box } from '@mui/material';
import TabContainerTitle from '../../../atoms/title/TabContainerTitle';
import Button from '../../../atoms/button/Button';
import FormWrapper from '../../../molecules/form/FormWrapper';
import { useForm } from 'react-hook-form';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormMaskedInput from '../../../molecules/form/FormMaskedInput';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormToggle from '../../../molecules/form/FormToggle';
import { IApplicationAccessData } from '../../../store/api/types';
import { useGetProjectsMutation } from '../../../store/api/v2/tazio';
import { useParams } from 'react-router';
import { transformApplicationAccessPayload } from '../../../store/api/helpers';
import { useActions } from '../../../hooks/actions';
import FormFileUpload from '../../../molecules/form/FormFileUpload';
import styled from '@emotion/styled';
import { DropdownTypes } from '../../../commons/types';
import {
  useEditClientMutation,
  useGetClientByIdQuery,
  useGetAccountIdMutation,
  useGetClientDoceboBranchInfoQuery,
} from '../../../store/api/clients';
import {
  useLazyDownloadAttachmentDetailsQuery,
  useUpdateLogoAttachmentMutation,
  useGetLogoUrlMutation,
} from '../../../store/api/attachments';

type FieldName =
  | 'navigator'
  | 'navigatorApiUsername'
  | 'navigatorApiPassword'
  | 'navigatorApiKey'
  | 'navigatorUrl'
  | 'insight'
  | 'tazioApiKey'
  | 'tazioApiDomain'
  | 'tazioAccountId'
  | 'tazioProjectId'
  | 'journey'
  | 'branchName';
interface FileDataState {
  file?: File | null;
  id?: string | null;
}
const navigatorInputs = [
  'navigatorApiUsername',
  'navigatorApiPassword',
  'navigatorApiKey',
  'navigatorUrl',
];
const insightsInputs = [
  'tazioApiKey',
  'tazioApiDomain',
  'tazioAccountId',
  'tazioProjectId',
];
const branchInputs = ['branchName'];

const ApplicationAccessErrors = {
  NAVIGATOR: {
    API_USERNAME: 'Please provide Navigator API username',
    API_PASSWORD: 'Please provide Navigator API password',
    API_KEY: 'Please provide Navigator API key',
    URL: 'Please provide Navigator URL',
  },
  INSIGHTS: {
    API_KEY: 'Please provide Insights API key',
    URL: 'Please provide Insights URL',
  },
  JOURNEY: {
    BRANCH: 'Please select a branch name',
  },
};

const StyledImage = styled('img')(() => ({
  display: 'block',
}));

const ApplicationAccess = () => {
  const { id } = useParams();

  const [branchesData, setBranchesData] = useState<DropdownTypes[]>([]);
  const [fileData, setFileData] = useState<FileDataState>({});
  const [logoUrl, setLogoUrl] = useState<string | null>();

  const [getUrlLogo] = useGetLogoUrlMutation();

  const { data: clientInfo } = useGetClientByIdQuery(id as string);
  const { data: { data: clientBranchData } = {} } =
    useGetClientDoceboBranchInfoQuery(id as string);

  const [downloadAttachment] = useLazyDownloadAttachmentDetailsQuery();
  const [triggerAccountIdData, { data: accountIdResponse }] =
    useGetAccountIdMutation();
  const [triggerProjectsData, { data: projectsResponse }] =
    useGetProjectsMutation();
  const [editClient] = useEditClientMutation();
  const [updateAttachment] = useUpdateLogoAttachmentMutation();

  const clientData = clientInfo?.data;

  const formData = useMemo(
    () => [
      {
        insight:
          clientInfo?.data[0]?.applications?.insights?.isEnabled ?? false,
        journey: clientInfo?.data[0]?.applications?.journey?.isEnabled ?? false,
        navigator:
          clientInfo?.data[0]?.applications?.navigator?.isEnabled ?? false,
        navigatorApiKey:
          clientInfo?.data[0]?.applications?.navigator?.apiKey ?? '',
        navigatorApiPassword:
          clientInfo?.data[0]?.applications?.navigator?.apiPassword ?? '',
        navigatorApiUsername:
          clientInfo?.data[0]?.applications?.navigator?.apiUsername ?? '',
        navigatorUrl: clientInfo?.data[0]?.applications?.navigator?.url ?? '',
        tazioAccountId:
          clientInfo?.data[0]?.applications?.insights?.accountId ?? '',
        tazioApiDomain: clientInfo?.data[0]?.applications?.insights?.url ?? '',
        tazioApiKey: clientInfo?.data[0]?.applications?.insights?.apiKey ?? '',
        tazioProjectId:
          clientInfo?.data[0]?.applications?.insights?.projectId ?? '',
      },
    ],
    [clientInfo]
  );

  const methods = useForm<IApplicationAccessData>();
  const { handleSubmit, watch, clearErrors, setValue, getValues } = methods;

  const isNavigatorEnabled = watch('navigator');
  const isInsightsEnabled = watch('insight');
  const isJourneyEnabled = watch('journey');
  const watchAccountId = watch('tazioAccountId');

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const handleNavigator = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value) {
      clearErrors(navigatorInputs as FieldName[]);
      for (const navigatorInput of navigatorInputs) {
        methods.resetField(navigatorInput as FieldName, { defaultValue: '' });
      }
    }
    return e;
  };

  const handleInsights = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value) {
      clearErrors(insightsInputs as FieldName[]);
      for (const insightsInput of insightsInputs) {
        methods.resetField(insightsInput as FieldName, { defaultValue: '' });
      }
    }
    return e;
  };

  const handleJourney = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value) {
      clearErrors(branchInputs as FieldName[]);
      for (const branchInput of branchInputs) {
        methods.resetField(branchInput as FieldName, { defaultValue: '' });
      }
    }
    return e;
  };

  const handleFileData = async (file: File | null) => {
    setFileData({ file, id: String(Date.now()) });
  };

  const deleteLogoHandler = async () => {
    if (fileData.id) {
      setFileData({ file: null, id: null });
      setLogoUrl(null);
    }
  };

  const getAttachmentLogoUrl = async (attachmentId: string) => {
    if (attachmentId.length) {
      try {
        await downloadAttachment({ attachmentId }).unwrap();
        const urlLogoResponse = await getUrlLogo({ attachmentId }).unwrap();
        const logoUrl = urlLogoResponse?.data?.s3_url;
        setFileData({ id: attachmentId });
        if (logoUrl?.length) setLogoUrl(logoUrl);
      } catch (e) {
        console.log(e);
      }
    }
  };

  React.useEffect(() => {
    if (clientData && clientData[0]?.applications?.journey?.logoAttachmentId) {
      getAttachmentLogoUrl(
        clientData[0]?.applications?.journey?.logoAttachmentId
      );
    }
  }, [clientData]);

  const onSubmit = async (data: IApplicationAccessData) => {
    const branchData = branchesData.find(
      (item) => item?.keyValue === data?.branchName
    );

    if (branchData) {
      data.branchName = (branchData?.keyName as string) || '';
      data.branchId = (branchData?.keyValue as string) || '';
    }

    if (fileData.file) {
      const formData = new FormData();
      formData.append('file', fileData.file);
      formData.append('contentType', fileData.file?.type);
      const res = await updateAttachment(formData).unwrap();
      const logoId = res?.data?.id;
      data['logoAttachmentId'] = logoId || null;
      if (logoId) {
        getAttachmentLogoUrl(logoId);
      }
    }
    if (!fileData.id) {
      data['logoAttachmentId'] = '';
    }
    const requestTypeMap = {
      update: {
        fn: editClient,
        successMessage: 'Application access updated successfully',
        errorMessage: 'Error updating application access',
      },
    };

    const response = await requestTypeMap['update']
      .fn({
        clientId: id,
        payload: transformApplicationAccessPayload(data, clientData),
      })
      .unwrap();

    if (response.status === 'success') {
      toggleSuccessSnackbar({
        message: requestTypeMap['update'].successMessage,
      });
    } else {
      if (
        response.errors &&
        response.errors[Object.keys(response.errors)[0]].length
      ) {
        toggleErrorSnackbar({
          message: response.errors[Object.keys(response.errors)[0]][0],
        });
      }
    }
  };

  const onError = () => {
    !isNavigatorEnabled && clearErrors(navigatorInputs as FieldName[]);
    !isInsightsEnabled && clearErrors(insightsInputs as FieldName[]);
    !isJourneyEnabled && clearErrors(branchInputs as FieldName[]);
  };

  const getAccountIdData = (payload: IApplicationAccessData) =>
    triggerAccountIdData({
      clientId: id || '',
    });

  const getProjectsData = (
    accountId: IApplicationAccessData['tazioAccountId'],
    payload: IApplicationAccessData
  ) =>
    triggerProjectsData({
      accountId: accountId || '',
    });

  // fetches account id array for the dropdown component when Application Access data is available
  useEffect(() => {
    if (formData) {
      getAccountIdData(formData[0]);
    }
  }, [clientInfo, methods]);

  // Sets value of account id in the form, need to keep separate as interdependent dropdowns are present in the form
  useEffect(() => {
    if (formData) {
      if (
        accountIdResponse?.accountIdDropdown?.length &&
        formData[0]?.tazioAccountId
      ) {
        methods.setValue('tazioAccountId', formData[0]?.tazioAccountId);
      }
    }
  }, [formData, methods, accountIdResponse]);

  // Sets value of project id in the form, also provides fallback in case of value change to protect dropdown from crashing
  useEffect(() => {
    if (formData) {
      if (
        projectsResponse?.projectsDropdown?.length &&
        formData[0]?.tazioProjectId
      ) {
        if (formData[0]?.tazioAccountId !== watchAccountId) {
          methods.setValue('tazioProjectId', '');
        } else {
          methods.setValue('tazioProjectId', formData[0]?.tazioProjectId);
        }
      }
    }
  }, [formData, methods, projectsResponse, watchAccountId]);

  // Sets value of toggle buttons on form
  useEffect(() => {
    if (clientInfo?.data[0]?.applications) {
      if (clientInfo?.data[0]?.applications?.navigator) {
        methods.setValue(
          'navigator',
          clientInfo?.data[0]?.applications?.navigator.isEnabled
        );
      }
      if (clientInfo?.data[0]?.applications?.insights) {
        methods.setValue(
          'insight',
          clientInfo?.data[0]?.applications?.insights.isEnabled
        );
      }
      if (clientInfo?.data[0]?.applications?.journey) {
        methods.setValue(
          'journey',
          clientInfo?.data[0]?.applications?.journey.isEnabled
        );
      }

      methods.setValue(
        'navigatorApiKey',
        clientInfo?.data[0]?.applications?.navigator?.apiKey ?? ''
      );

      methods.setValue(
        'navigatorApiPassword',
        clientInfo?.data[0]?.applications?.navigator?.apiPassword ?? ''
      ),
        methods.setValue(
          'navigatorApiUsername',
          clientInfo?.data[0]?.applications?.navigator?.apiUsername ?? ''
        ),
        methods.setValue(
          'navigatorUrl',
          clientInfo?.data[0]?.applications?.navigator?.url ?? ''
        ),
        methods.setValue(
          'tazioApiDomain',
          clientInfo?.data[0]?.applications?.insights?.url ?? ''
        ),
        methods.setValue(
          'tazioApiKey',
          clientInfo?.data[0]?.applications?.insights?.apiKey ?? ''
        );
    }
  }, [clientInfo]);

  // fetches project id array whenever the account id changes.
  useEffect(() => {
    if (formData) {
      if (watchAccountId) {
        methods.setValue('tazioProjectId', '');
        getProjectsData(watchAccountId, formData[0]);
      }
    }
  }, [formData, methods, watchAccountId]);

  useEffect(() => {
    const defaultBranchName =
      clientInfo?.data[0]?.applications?.journey?.branchName || '';
    const defaultBranchId =
      clientInfo?.data[0]?.applications?.journey?.branchId || '';
    // Use clientBranchData directly to get branches
    // On component loading we are getting empty array but after API call we are getting array of object so to avoid type mismatch i have used both.
    const dataToPopulateDropdown = ((clientBranchData as any) || []).map(
      (item: any) => {
        // need to use any type to avoid type mismatch
        return {
          keyName: item?.title || '',
          keyValue: item?.id || '',
        };
      }
    );

    // Checking if default branch data is not empty before pushing selected branch to dropdown
    if (defaultBranchName && defaultBranchId) {
      dataToPopulateDropdown.push({
        keyName: defaultBranchName,
        keyValue: defaultBranchId,
      });
    }

    setBranchesData(dataToPopulateDropdown);

    // Set default value for branchName dropdown
    methods.setValue('branchName', defaultBranchId);
  }, [clientBranchData, clientInfo, methods]);

  return (
    <>
      <FormWrapper
        methods={methods}
        id='application-access'
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Box sx={{ marginTop: '32px' }}>
          <TabContainerTitle text='Application Access' />
        </Box>
        <HpCard>
          <HpCardHeader
            title='Navigator'
            action={
              <FormToggle
                name='navigator'
                rules={{ onChange: handleNavigator }}
                defaultValue={methods.getValues('navigator')}
              />
            }
          />
          <HpCardContent sx={{ rowGap: '28px' }}>
            <FormLabelInput
              name='navigatorApiUsername'
              label='Navigator API Username'
              tooltip=' '
              rules={{
                required:
                  isNavigatorEnabled &&
                  ApplicationAccessErrors.NAVIGATOR.API_USERNAME,
              }}
              extraLabelInputProps={{ disabled: !isNavigatorEnabled }}
            />
            <FormMaskedInput
              name='navigatorApiPassword'
              label='Navigator API Password'
              tooltip=' '
              rules={{
                required:
                  isNavigatorEnabled &&
                  ApplicationAccessErrors.NAVIGATOR.API_PASSWORD,
              }}
              extraLabelInputProps={{ disabled: !isNavigatorEnabled }}
            />
            <FormMaskedInput
              name='navigatorApiKey'
              label='Navigator API Key'
              tooltip=' '
              rules={{
                required:
                  isNavigatorEnabled &&
                  ApplicationAccessErrors.NAVIGATOR.API_KEY,
              }}
              extraLabelInputProps={{ disabled: !isNavigatorEnabled }}
            />
            <FormLabelInput
              name='navigatorUrl'
              label='Navigator URL'
              tooltip=' '
              rules={{
                required:
                  isNavigatorEnabled && ApplicationAccessErrors.NAVIGATOR.URL,
              }}
              extraLabelInputProps={{ disabled: !isNavigatorEnabled }}
            />
          </HpCardContent>
        </HpCard>
        <HpCard>
          <HpCardHeader
            title='Insights'
            action={
              <FormToggle
                name='insight'
                rules={{ onChange: handleInsights }}
                defaultValue={methods.getValues('insight')}
              />
            }
          />
          <HpCardContent sx={{ rowGap: '28px' }}>
            <FormMaskedInput
              name='tazioApiKey'
              label='Insights API Key'
              tooltip=' '
              rules={{
                required:
                  isInsightsEnabled && ApplicationAccessErrors.INSIGHTS.API_KEY,
              }}
              extraLabelInputProps={{ disabled: !isInsightsEnabled }}
            />
            <FormLabelInput
              name='tazioApiDomain'
              label='Insights URL'
              tooltip=' '
              rules={{
                required:
                  isInsightsEnabled && ApplicationAccessErrors.INSIGHTS.URL,
              }}
              extraLabelInputProps={{ disabled: !isInsightsEnabled }}
            />
            <FormLabelDropdown
              name='tazioAccountId'
              label='Insights Account ID'
              dropDownItem={accountIdResponse?.accountIdDropdown || []}
              tooltip=' '
              disabled={!isInsightsEnabled}
            />
            <FormLabelDropdown
              name='tazioProjectId'
              label='Insights Project ID'
              dropDownItem={projectsResponse?.projectsDropdown || []}
              tooltip=' '
              disabled={!isInsightsEnabled}
            />
          </HpCardContent>
        </HpCard>
        <HpCard>
          <HpCardHeader
            title='Journey'
            action={
              <FormToggle
                name='journey'
                rules={{ onChange: handleJourney }}
                defaultValue={methods.getValues('journey')}
              />
            }
          />
          <HpCardContent sx={{ rowGap: '28px' }}>
            <FormLabelDropdown
              name='branchName'
              label='Branch Name'
              dropDownItem={branchesData || []}
              onChange={() => deleteLogoHandler()}
              rules={{
                required:
                  isJourneyEnabled && ApplicationAccessErrors.JOURNEY.BRANCH,
              }}
              tooltip=' '
              disabled={!isJourneyEnabled}
            />
            {logoUrl ? (
              <>
                <StyledImage
                  src={logoUrl}
                  alt=''
                />
                <Button
                  btnType={'SECONDARY'}
                  text={'Delete Logo'}
                  onClick={deleteLogoHandler}
                />
              </>
            ) : (
              <FormFileUpload
                name='company_logo'
                label={'Company Logo'}
                onChange={handleFileData}
                accept='.png,.jpg,.jpeg'
                formatErrorMsg={
                  'There was an error with the file you provided. Supported formats include PNG and JPEG files.'
                }
                progress={0}
                disabled={!isJourneyEnabled}
              />
            )}
          </HpCardContent>
        </HpCard>
      </FormWrapper>
      <Box sx={{ paddingTop: '40px' }}>
        <Button
          btnType={'PRIMARY'}
          text={'Save Changes'}
          type='submit'
          form='application-access'
        />
      </Box>
    </>
  );
};

export default ApplicationAccess;
