import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import { routes } from '../../routes/routes';
import PageHeader from '../../atoms/header/PageHeader';
import AddToRoleModal from '../modals/Statement/AddToRoleModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StatementLibraryHeader = () => {
  return (
    <PageHeader>
      <AddToRoleModal />
      <StyledTitlesWrapper>
        <Title text={routes.statementLibrary.name} />
      </StyledTitlesWrapper>
    </PageHeader>
  );
};

export default StatementLibraryHeader;
