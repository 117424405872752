import { createSlice } from '@reduxjs/toolkit';

interface IAddNewParticipantModalProps {
  isOpen: boolean;
  modalType: 'add';
}

const initialState: IAddNewParticipantModalProps = {
  isOpen: false,
  modalType: 'add',
};

const addNewParticipantModalSlice = createSlice({
  name: 'addNewParticipantModal',
  initialState,
  reducers: {
    openAddNewParticipantModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    closeAddNewParticipantModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const addNewParticipantModalActions =
  addNewParticipantModalSlice.actions;
export const addNewParticipantModalReducer =
  addNewParticipantModalSlice.reducer;
