import React, { useEffect } from 'react';
import HpCard from '../../../atoms/card/HpCard';
import HpCardHeader from '../../../atoms/card/HpCardHeader';
import HpCardContent from '../../../atoms/card/HpCardContent';
import { Box } from '@mui/material';
import TabContainerTitle from '../../../atoms/title/TabContainerTitle';
import Button from '../../../atoms/button/Button';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../../molecules/form/FormWrapper';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import { useForm } from 'react-hook-form';
import {
  useGetClientIdentityGroupNamesQuery,
  useGetClientIdentityAppIDForAPIsQuery,
} from '../../../store/api/v2/auth';
import { useParams } from 'react-router';
import { useActions } from '../../../hooks/actions';
import { transformConfigurationPayload } from '../../../store/api/helpers';
import { DropdownTypes } from '../../../commons/types';
import {
  useEditClientMutation,
  useGetClientByIdQuery,
} from '../../../store/api/clients';

interface IFormData {
  companyName: string;
  oktaAppIDForAPIs: string;
  clientGroupName: string;
  accountName?: string;
  NAICSIndustry?: string;
  annualRevenue?: string;
  closedWonHdCount?: number;
  numberOfEmployees?: number;
  primaryCompanyOwnership?: string;
  primaryHandSIndustry?: string;
}

const Configuration = () => {
  const [clientGroup, setClientGroup] = React.useState<DropdownTypes[]>([]);
  const [oktaAppID, setOktaAppId] = React.useState<DropdownTypes[]>([]);

  const { id } = useParams();

  const { data: clientGroupNames } = useGetClientIdentityGroupNamesQuery(
    id as string
  );
  const { data: oktaAppIDForAPIs } = useGetClientIdentityAppIDForAPIsQuery(
    id as string
  );
  const [editClient] = useEditClientMutation();

  const { data: clientInfo } = useGetClientByIdQuery(id as string);

  const methods = useForm<IFormData>();
  // Save client Data for future use
  const clientData = clientInfo?.data;
  const { handleSubmit } = methods;

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const onSubmit = async (data: IFormData) => {
    const requestTypeMap = {
      update: {
        fn: editClient,
        successMessage: 'Configuration updated successfully',
        errorMessage: 'Error updating configuration',
      },
    };

    const response = await requestTypeMap['update']
      .fn({
        clientId: id,
        payload: transformConfigurationPayload(data, clientData),
      })
      .unwrap();

    if (response.status === 'success') {
      toggleSuccessSnackbar({
        message: requestTypeMap['update'].successMessage,
      });
    } else {
      if (
        response.errors &&
        response.errors[Object.keys(response.errors)[0]].length
      ) {
        toggleErrorSnackbar({
          message: response.errors[Object.keys(response.errors)[0]][0],
        });
      }
    }
  };

  useEffect(() => {
    if (clientInfo) {
      const initialClientGroupName = clientInfo?.data[0]?.oktaGroup || '';

      const initialOktaAppIDForAPIs =
        (clientInfo?.data && clientInfo?.data[0]?.oktaAPIClientId) || '';

      methods.setValue('companyName', clientInfo?.data[0]?.name || '');

      // Check for Okta App Ids and dropdown preparation
      if (oktaAppIDForAPIs?.oktaAppIDForAPIsDropdown?.length) {
        // Defaut dropdown blank
        const tempIDs = [
          {
            keyName: '--Select Okta App ID--',
            keyValue: '',
          },
        ];

        // Add free Okta App Id to dropdown Item
        oktaAppIDForAPIs?.oktaAppIDForAPIsDropdown?.map((item) =>
          tempIDs.push({
            keyName: item.keyName as string,
            keyValue: item.keyValue as string,
          })
        );
        setOktaAppId(tempIDs); // set dropdown values
        methods.setValue('oktaAppIDForAPIs', initialOktaAppIDForAPIs);
      } else {
        const tempIDs = [
          {
            keyName: '--Select Okta App ID --',
            keyValue: '',
          },
        ];
        if (initialOktaAppIDForAPIs) {
          tempIDs.push({
            keyName: initialOktaAppIDForAPIs,
            keyValue: initialOktaAppIDForAPIs,
          });
        }
        setOktaAppId(tempIDs); // set dropdown values
        methods.setValue('oktaAppIDForAPIs', initialOktaAppIDForAPIs);
      }

      // Check for Client Group name and dropdown preparation
      if (clientGroupNames?.clientGroupNameDropdown?.length) {
        methods.setValue('clientGroupName', initialClientGroupName);
        // Defaut dropdown blank
        const temp = [
          {
            keyName: '--Select Client Group--',
            keyValue: '',
          },
        ];

        // Add free client Groups to dropdown Item
        clientGroupNames?.clientGroupNameDropdown?.map((item) =>
          temp.push({
            keyName: item.keyName as string,
            keyValue: item.keyValue as string,
          })
        );
        setClientGroup(temp); // set dropdown values
        methods.setValue('clientGroupName', initialClientGroupName);
      } else {
        const temp = [
          {
            keyName: '--Select Client Group--',
            keyValue: '',
          },
        ];
        // Add Selected Client Group to dropdown item
        if (initialClientGroupName) {
          temp.push({
            keyName: initialClientGroupName,
            keyValue: initialClientGroupName,
          });
        }
        setClientGroup(temp); // set dropdown values
        methods.setValue('clientGroupName', initialClientGroupName);
      }

      // Set Client Attribute fields values
      methods.setValue('accountName', clientInfo?.data[0]?.accountName || '');
      methods.setValue(
        'NAICSIndustry',
        clientInfo?.data[0]?.NAICSIndustry || ''
      );
      methods.setValue(
        'annualRevenue',
        clientInfo?.data[0]?.annualRevenue || ''
      );
      methods.setValue(
        'closedWonHdCount',
        clientInfo?.data[0]?.closedWonHdCount || 0
      );
      methods.setValue(
        'numberOfEmployees',
        clientInfo?.data[0]?.numberOfEmployees || 0
      );
      methods.setValue(
        'primaryCompanyOwnership',
        clientInfo?.data[0]?.primaryCompanyOwnership || ''
      );
      methods.setValue(
        'primaryHandSIndustry',
        clientInfo?.data[0]?.primaryHandSIndustry || ''
      );
    }
  }, [clientInfo, clientGroupNames, oktaAppIDForAPIs, methods]);

  return (
    <>
      <Box sx={{ marginTop: '32px', marginBottom: '24px' }}>
        <TabContainerTitle text='Configuration' />
      </Box>
      <FormWrapper
        methods={methods}
        id='configuration'
        onSubmit={handleSubmit(onSubmit)}
      >
        <HpCard>
          <HpCardHeader title='Company details' />
          <HpCardContent sx={{ rowGap: '28px' }}>
            <FormLabelInput
              name='companyName'
              label='Company Name'
              rules={{ required: 'Please provide a company name' }}
              disabled={true}
              tooltip=' '
            />
            <FormLabelDropdown
              key={'oktgroup'}
              name='oktaAppIDForAPIs'
              label='Okta App ID for APIs'
              dropDownItem={oktaAppID || []}
              tooltip=' '
            />
            <FormLabelDropdown
              key={'clientgroup'}
              name='clientGroupName'
              label='Client Group Name'
              dropDownItem={clientGroup || []}
              tooltip=' '
            />
          </HpCardContent>
        </HpCard>
        <HpCard>
          <HpCardHeader title='Client Attributes' />
          <HpCardContent sx={{ rowGap: '28px' }}>
            <FormLabelInput
              name='accountName'
              label='Account Name'
              tooltip='Account Name'
            />
            <FormLabelInput
              name='numberOfEmployees'
              label='Number of Employees'
              tooltip='Number of Employees'
              extraLabelInputProps={{
                type: 'number',
              }}
            />
            <FormLabelInput
              name='annualRevenue'
              label='Annual Revenue'
              tooltip='Annual Revenue'
            />
            <FormLabelInput
              name='primaryCompanyOwnership'
              label='Primary Company Ownership'
              tooltip='Primary Company Ownership'
            />
            <FormLabelInput
              name='primaryHandSIndustry'
              label='Primary H&S Industry'
              tooltip='Primary H&S Industry'
            />
            <FormLabelInput
              name='NAICSIndustry'
              label='NAICSIndustry'
              tooltip='NAICSIndustry'
            />
            <FormLabelInput
              name='closedWonHdCount'
              label='Closed Won HD Count'
              tooltip='Closed Won HD Count'
              extraLabelInputProps={{
                type: 'number',
              }}
            />
          </HpCardContent>
        </HpCard>
      </FormWrapper>
      <Box sx={{ marginTop: '24px' }}>
        <Button
          btnType={'PRIMARY'}
          text={'Save changes'}
          type='submit'
          form='configuration'
        />
      </Box>
    </>
  );
};

export default Configuration;
