import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { useGetClientByIdQuery } from '../../../store/api/clients';
import Assessments from './Assessments';
import Loader from '../../../commons/Loader';
import { useGetMeQuery } from '../../../store/api/v2/auth';

const AssessmentValidation = () => {
  // Protect content if user directly copy and paste url
  const params = useParams();
  const { data: { data = [] } = {}, isSuccess } = useGetClientByIdQuery(
    params.id as string
  );
  const { data: meData } = useGetMeQuery();

  const [showData, setShowData] = React.useState(true);

  React.useEffect(() => {
    // check if company has insights enabled, then show them Assessments Tab
    if (data[0]?.applications?.insights?.isEnabled) {
      setShowData(true);
    } else {
      setShowData(false);
    }
  }, [data]);

  return isSuccess ? (
    showData ? (
      <Assessments myPersonas={meData?.data?.myPersonas} />
    ) : (
      <Box sx={{ margin: '50px' }}>
        <span style={{ fontSize: '30px', fontWeight: '500' }}>
          You do not have access to Assessment Dashboard. Please contact your
          administrator.
        </span>
      </Box>
    )
  ) : (
    <Loader />
  );
};
export default AssessmentValidation;
