import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { routes } from '../../routes/routes';
import { useActions } from '../../hooks/actions';
import { IGetClientStatementData } from '../../store/api/types/leadership';
import { IPagination } from '../../commons/types';
import {
  useDeleteClientStatementMutation,
  useLazyValidateStatementByIdQuery,
} from '../../store/api/leadership';

interface IProps {
  data?: IGetClientStatementData[];
  isLoading: boolean;
  columns: MRT_ColumnDef<IGetClientStatementData>[];
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const StatementLibraryTable = ({
  data,
  isLoading,
  columns,
  tableInstanceRef,
}: IProps) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [apiPagination, setApiPagination] = React.useState<IPagination>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [deleteStatement] = useDeleteClientStatementMutation();
  const [validateStatement] = useLazyValidateStatementByIdQuery();

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    openEditLeadershipCapabilitySLModal,
    openEditCultureImpactSLModal,
    openEditLeadershipPotentialSLModal,
    openEditOrganizationalImpactSLModal,
    openAddToRoleModal,
    openEditFunctionalImpactSLModal,
    openStatementLibraryWarningModal,
  } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Use this code when skillValidate api is ready
  const handleDelete = async (statementId: string) => {
    setAnchorEl(null);

    try {
      const payload = {
        clientId: 'Master',
        statementId: statementId,
      };
      const { data: validateData } = await validateStatement(payload).unwrap();

      if (validateData && validateData.length) {
        openStatementLibraryWarningModal(validateData);
      } else {
        deleteStatement(statementId)
          .unwrap()
          .then(() =>
            toggleSuccessSnackbar({ message: 'Statement Deleted Successfully' })
          )
          .catch(() => toggleErrorSnackbar({}));
      }
    } catch (e) {
      toggleErrorSnackbar({});
    }
  };

  const handleEditStatement = (rowData: IGetClientStatementData) => {
    if (rowData.skillGroup === 'Functional Impact') {
      openEditFunctionalImpactSLModal(rowData);
    } else if (rowData.skillGroup === 'Organizational Impact') {
      openEditOrganizationalImpactSLModal(rowData);
    } else if (rowData.skillGroup === 'Capabilities') {
      openEditLeadershipCapabilitySLModal(rowData);
    } else if (rowData.skillGroup === 'Potential') {
      openEditLeadershipPotentialSLModal(rowData);
    } else if (rowData.skillGroup === 'Purpose') {
      openEditCultureImpactSLModal(rowData);
    }
    setAnchorEl(null);
  };

  const handleApplyToRole = (skill: IGetClientStatementData) => {
    openAddToRoleModal({
      skill: skill.id || '',
      statementUUID: skill.statementLibraryUUID || '',
      skillGroup: skill.skillGroup || '',
    });
    setAnchorEl(null);
  };

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => {
        const enabledSkillGroup = [
          'Functional Impact',
          'Organizational Impact',
        ];
        const rowData = row.original as IGetClientStatementData;
        return (
          <>
            <IconButton
              id={cell.id}
              onClick={handleOpen}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl?.id === cell.id}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEditStatement(rowData)}>
                Edit
              </MenuItem>
              {enabledSkillGroup.includes(rowData.skillGroup || '') && (
                <MenuItem onClick={() => handleApplyToRole(rowData)}>
                  Apply To Role
                </MenuItem>
              )}
              <MenuItem onClick={() => handleDelete(rowData.id || '')}>
                Delete
              </MenuItem>
            </Menu>
          </>
        );
      }}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          const selection = window.getSelection()!.toString();
          if (!cell.id.includes('mrt-row-actions') && !selection) {
            navigate(
              `${routes.statementLibrary.url}/${
                (row.original as IGetClientStatementData).id
              }${routes.statementLibrary.details.url}`
            );
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default StatementLibraryTable;
