import { createSlice } from '@reduxjs/toolkit';

interface DownloadReportModalProps {
  isOpen: boolean;
  assessmentId: string;
}

const initialState: DownloadReportModalProps = {
  isOpen: false,
  assessmentId: '',
};

const downloadReportModalSlice = createSlice({
  name: 'downloadReportModal',
  initialState,
  reducers: {
    closeDownloadReportModal: (state) => {
      state.isOpen = false;
    },
    openDownloadReportModal: (state, action) => {
      state.isOpen = true;
      state.assessmentId = action.payload.assessmentId;
    },
  },
});

export const downloadReportActions = downloadReportModalSlice.actions;
export const downloadReportReducer = downloadReportModalSlice.reducer;
