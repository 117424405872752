import { removeLeadersModalActions } from '../store/Company/Project/ProjectDetails/RemoveLeadersModalSlice';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { snackbarActions } from '../store/snackbarSlice';
import { roleModalActions } from '../store/roleModalSlice';
import { functionalImpactSLModalActions } from '../store/Statement/FunctionalImpactSLModalSlice';
import { functionalImpactRLModalActions } from '../store/Role/FunctionalImpactRLModalSlice';
import { adminsModalActions } from '../store/adminsModalSlice';
import { cultureImpactSLModalActions } from '../store/Statement/CultureImpactSLModalSlice';
import { leadershipPotentialSLModalActions } from '../store/Statement/leadershipPotentialSLModalSlice';
import { leadershipCapabilitySLModalActions } from '../store/Statement/LeadershipCapabilitySLModalSlice';
import { organizationalImpactSLModalActions } from '../store/Statement/OrganizationalImpactSLModalSlice';
import { addToRoleModalActions } from '../store/addToRoleModalSlice';
import { importRoleModalActions } from '../store/importRoleModalSlice';
import { importStatementModalActions } from '../store/importStatementModalSlice';
import { companyRolesModalActions } from '../store/Company/companyRolesModalSlice';
import { importCompanyUserModalActions } from '../store/Company/importCompanyUserModalSlice';
import { clientRoleStatementModalActions } from '../store/Company/Roles/clientRoleStatementModalSlice';
import { subFunctionVLModalActions } from '../store/VariableLibrary/subFunctionVLModalSlice';
import { functionVLModalActions } from '../store/VariableLibrary/functionVLModalSlice';
import { companyUsersModalActions } from '../store/Company/companyUsersModalSlice';
import { clientRoleImpactStatementModalActions } from '../store/Company/Roles/RoleDetails/clientRoleImpactStatementSlice';
import { organizationalImpactRLModalActions } from '../store/Role/OrganizationalImpactRLModalSlice';
import { editStatementRLModalActions } from '../store/Role/EditStatementModalSlice';
import { companyImpactGroupActions } from '../store/Company/CompanyImpactGroupSlice';
import { userInformationActions } from '../store/userInformationSlice';
import { expertiseCategoryVLModalActions } from '../store/VariableLibrary/expertiseCategoriesVLModalSlice';
import { levelVLModalActions } from '../store/VariableLibrary/levelVLModalSlice';
import { impactModuleVLModalActions } from '../store/VariableLibrary/impactModuleVLModalSlice';
import { skillCategoryVLModalActions } from '../store/VariableLibrary/skillCategoryVLModalSlice';
import { statementLibraryWarningModalActions } from '../store/statementLibraryWarningModalSlice';
import { errorsModalActions } from '../store/errorsModalSlice';
import { assessmentActions } from '../store/Company/Assessments/AssessmentSlice';
import { IGAddAssessmentIdActions } from '../store/Company/IGAddAssementIdSlice';
import { addRoleToImpactGroupModalActions } from '../store/Company/ImpactGroup/addRoleToImpactGroupModalSlice';
import { assignedRolesAnotherImpactGroupModalActions } from '../store/Company/ImpactGroup/assignedRolesAnotherImpactGroupModalslice';
import { importCompanyEmployeeResumeModalActions } from '../store/Company/importCompanyEmployeeResumeModalSlice';
import { companySendToTazioActions } from '../store/Company/CompanySendToTazioSlice';
import { statementDetailsActions } from '../store/Statement/statementDetailsSlice';
import { addLeadersModalActions } from '../store/Company/Project/ProjectDetails/AddLeadersModalSlice';
import { reportGenerationModalActions } from '../store/Company/Project/ProjectDetails/reportGenerationModalSlice';
import { projectAssessmentsModalActions } from '../store/Company/Project/ProjectDetails/projectAssessmentsModalSlice';
import { assessmentCohortExportModalActions } from '../store/Company/Project/ProjectDetails/AssessmentCohortExportModalSlice';
import { leadersReportGenerateModalActions } from '../store/Company/Project/ProjectDetails/leadersReportGenerateModalSlice';
import { downloadReportsModalActions } from '../store/Company/Project/ProjectDetails/downloadReportsModalSlice';
import { clientAssessmentModalActions } from '../store/Company/Assessments/ClientAssessments/ClientAssessmentModalSlice';
import { functionVLClientModalActions } from '../store/Company/VariableLibrary/FunctionVLClientModalSlice';
import { subFunctionVLClientModalActions } from '../store/Company/VariableLibrary/SubFunctionVLClientModalSlice';
import { projectSpinnerActions } from '../store/Company/Project/ProjectDetails/projectSpinnerSlice';
import { levelVLClientModalActions } from '../store/Company/VariableLibrary/LevelVLClientModalSlice';
import { reportsModalActions } from '../store/Company/Reports/reportsModalSlice';
import { impactModuleVLClientModalActions } from '../store/Company/VariableLibrary/ImpactModuleVLClientModalSlice';
import { calibrationModalActions } from '../store/Company/Project/ProjectDetails/AssessmentDetails/CalibrationModalSlice';
import { downloadReportActions } from '../store/Company/Project/ProjectDetails/AssessmentDetails/DownloadReportModalSlice';
import { addNewParticipantModalActions } from '../store/Company/Project/ProjectDetails/addNewParticipantModalSlice';
import { assignmentActions } from '../store/Company/Project/ProjectDetails/AssessmentDetails/AssignmentSlice';
import { importSubFunctionModalActions } from '../store/importSubFunctionModalSlice';

const actions = {
  ...functionalImpactSLModalActions,
  ...organizationalImpactSLModalActions,
  ...cultureImpactSLModalActions,
  ...leadershipCapabilitySLModalActions,
  ...roleModalActions,
  ...snackbarActions,
  ...leadershipPotentialSLModalActions,
  ...statementLibraryWarningModalActions,
  ...errorsModalActions,
  ...adminsModalActions,
  ...addToRoleModalActions,
  ...importRoleModalActions,
  ...importStatementModalActions,
  ...companyRolesModalActions,
  ...importCompanyUserModalActions,
  ...clientRoleStatementModalActions,
  ...subFunctionVLModalActions,
  ...functionVLModalActions,
  ...skillCategoryVLModalActions,
  ...companyUsersModalActions,
  ...clientRoleImpactStatementModalActions,
  ...organizationalImpactRLModalActions,
  ...editStatementRLModalActions,
  ...functionalImpactRLModalActions,
  ...companyImpactGroupActions,
  ...userInformationActions,
  ...expertiseCategoryVLModalActions,
  ...levelVLModalActions,
  ...impactModuleVLModalActions,
  ...assessmentActions,
  ...reportGenerationModalActions,
  ...IGAddAssessmentIdActions,
  ...addRoleToImpactGroupModalActions,
  ...assignedRolesAnotherImpactGroupModalActions,
  ...importCompanyEmployeeResumeModalActions,
  ...statementDetailsActions,
  ...companySendToTazioActions,
  ...projectAssessmentsModalActions,
  ...assessmentCohortExportModalActions,
  ...leadersReportGenerateModalActions,
  ...addLeadersModalActions,
  ...downloadReportsModalActions,
  ...removeLeadersModalActions,
  ...clientAssessmentModalActions,
  ...functionVLClientModalActions,
  ...subFunctionVLClientModalActions,
  ...projectSpinnerActions,
  ...levelVLClientModalActions,
  ...reportsModalActions,
  ...impactModuleVLClientModalActions,
  ...calibrationModalActions,
  ...downloadReportActions,
  ...addNewParticipantModalActions,
  ...assignmentActions,
  ...importSubFunctionModalActions,
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};

export const setImportStatementUploadProgress =
  importStatementModalActions.setImportStatementUploadProgress;
export const setImportRoleUploadProgress =
  importRoleModalActions.setImportRoleUploadProgress;
export const setImportCompanyUserUploadProgress =
  importCompanyUserModalActions.setImportCompanyUserUploadProgress;
export const setImportSubFunctionUploadProgress =
  importSubFunctionModalActions.setImportSubFunctionUploadProgress;
