import React, { useMemo, useRef } from 'react';
import { Box, styled } from '@mui/material';
import TabContent from '../../../atoms/tab/TabContent';
import Title from '../../../atoms/title/Title';
import { ITabContentProps } from '../../../commons/types';
import SubTitle from '../../../atoms/title/SubTitle';
import LevelVLClientTable from '../../../organisms/tables/Company/VariableLibrary/LevelVLClientTable';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { useGetLevelsListQuery } from '../../../store/api/leadership';
import HpTooltip from '../../../atoms/tooltip/HpTooltip';
import Button from '../../../atoms/button/Button';
import { useActions } from '../../../hooks/actions';
import { LevelVLClientModal } from '../../../organisms/modals/Company/VariableLibrary/LevelVLClientModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const Levels = ({ index, value }: ITabContentProps) => {
  const { id } = useParams();

  const { data: { data: levelsMasterData } = {}, isLoading } =
    useGetLevelsListQuery('Master');

  const { data: { data: levelsIdData } = {} } = useGetLevelsListQuery(
    id as string
  );

  const { openAddLevelVLClientModal } = useActions();
  // Here we are generating table data mapping through code fieldValue
  const ClientLevelData = useMemo(() => {
    if (!levelsMasterData || !levelsIdData) return [];

    // Create a map of fieldValue to systemName from levelsMasterData
    const systemNameMap = new Map(
      levelsMasterData.map((item) => [item.code, item.fieldValue])
    );

    const merged = levelsIdData.map((item) => {
      const systemName = systemNameMap.get(item.code) || '-';

      return {
        ...item,
        systemName: systemName,
      };
    });

    return merged;
  }, [levelsMasterData, levelsIdData]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Levels'}
              sx={{
                fontSize: '26px',
              }}
            />
            <Box>
              <HpTooltip title={' '} />
            </Box>
          </StyledHeaderWrapper>
          <StyledHeaderActionWrapper>
            <Button
              btnType={'PRIMARY'}
              text={'Add new level'}
              onClick={() => openAddLevelVLClientModal()}
            />
          </StyledHeaderActionWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle text={`${ClientLevelData.length} total levels`} />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <LevelVLClientTable
        data={ClientLevelData}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
      />
      <LevelVLClientModal />
    </TabContent>
  );
};

export default Levels;
