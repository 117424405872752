import React from 'react';
import { Box, styled } from '@mui/material';
import DetailsHeaderWrapper from '../../../../atoms/header/DetailsHeaderWrapper';
import Title from '../../../../atoms/title/Title';
import Button from '../../../../atoms/button/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HDBreadCrumb from '../../../../hd-ui-kit/HDBreadcrumb/HDBreadCrumb';
import { useParams } from 'react-router';

interface IProps {
  projectName?: string;
  assessmentName?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '15px',
  marginBottom: '10px',
}));

function ProjectAssessmentDetailsHeader({
  projectName,
  assessmentName,
}: IProps) {
  const params = useParams();

  return (
    <>
      <DetailsHeaderWrapper>
        <StyledTitlesWrapper>
          <BreadCrumbWrapper>
            <HDBreadCrumb
              routes={[
                { text: 'Projects', link: `/company/${params.id}/projects` },
                {
                  text: `${projectName}`,
                  link: `/company/${params.id}/projects/${params.projectId}`,
                },
                {
                  text: `${assessmentName}`,
                  link: `/company/${params.id}/projects/${params.projectId}/assessments/${params.assessmentsId}`,
                },
              ]}
            />
          </BreadCrumbWrapper>
          <Title text={assessmentName || ''} />
        </StyledTitlesWrapper>
        {/* Hidden */}
        {/* <StyledButtonWrapper> */}
        {/*   <Button */}
        {/*     btnType='SECONDARY' */}
        {/*     text={'View CBI Script'} */}
        {/*     onClick={() => null} */}
        {/*   /> */}
        {/*   <IconButton onClick={() => null}> */}
        {/*     <MoreVertIcon /> */}
        {/*   </IconButton> */}
        {/* </StyledButtonWrapper> */}
      </DetailsHeaderWrapper>
    </>
  );
}

export default ProjectAssessmentDetailsHeader;
