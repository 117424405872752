import { createSlice } from '@reduxjs/toolkit';

interface ICompanyRolesModalProps {
  isOpen: boolean;
  modalType: 'download';
}

const initialState: ICompanyRolesModalProps = {
  isOpen: false,
  modalType: 'download',
};

const companyRolesModalSlice = createSlice({
  name: 'companyRoles',
  initialState,
  reducers: {
    openDownloadCompanyRolesModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    closeCompanyRolesModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const companyRolesModalActions = companyRolesModalSlice.actions;
export const companyRolesModalReducer = companyRolesModalSlice.reducer;
