import React from 'react';
import { Box, styled } from '@mui/material';
import TabMenu from '../../../atoms/tab/TabMenu';
import TabMenuItem from '../../../atoms/tab/TabMenuItem';
import VariableLibraryHeader from '../../../organisms/headers/Company/VariableLibrary/VariableLibraryHeader';
import ClientFunctions from './ClientFunctions';
import Levels from './ClientLevels';
import ClientSubFunctions from './ClientSubFunctions';
import ClientImpactModules from './ClientImpactModules';
import ClientSkillGroups from './ClientSkillGroups';
import ClientSkillCategories from './ClientSkillCategories';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: 'auto',
  paddingLeft: '20px',
  maxWidth: '1200px',
  '.tabContent': {
    paddingTop: '40px',
  },
});

const ClientVariableLibrary = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setTabValue(newValue);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <VariableLibraryHeader />
      <StyledContentWrapper>
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={'Functions'}
          />
          <TabMenuItem
            index={1}
            label={'Sub-Functions'}
          />
          <TabMenuItem
            index={2}
            label={'Levels'}
          />
          <TabMenuItem
            index={3}
            label={'Impact Modules'}
          />
          <TabMenuItem
            index={4}
            label={'Skill Categories'}
          />
          <TabMenuItem
            index={5}
            label={'Skill Groups'}
          />
        </TabMenu>
        <Box className='tabContent'>
          <ClientFunctions
            index={0}
            value={tabValue}
          />
          <ClientSubFunctions
            index={1}
            value={tabValue}
          />
          <Levels
            index={2}
            value={tabValue}
          />
          <ClientImpactModules
            index={3}
            value={tabValue}
          />
          <ClientSkillCategories
            index={4}
            value={tabValue}
          />
          <ClientSkillGroups
            index={5}
            value={tabValue}
          />
        </Box>
      </StyledContentWrapper>
    </Box>
  );
};

export default ClientVariableLibrary;
