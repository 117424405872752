import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

type IProps = Omit<TooltipProps, 'children'>;

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  marginLeft: '6px',
}));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 320,
    padding: '16px',
    font: 'Aventa',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20.09px',
    letterSpacing: '2%',
  },
});

export default function HpTableTooltip({ ...extraProps }: IProps) {
  return (
    <CustomWidthTooltip
      placement='right'
      {...extraProps}
    >
      <StyledIconButton>
        <HelpIcon fontSize={'small'} />
      </StyledIconButton>
    </CustomWidthTooltip>
  );
}
