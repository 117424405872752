import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';

import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import { SelectOptions } from '../../../../commons/types';
import ReadMoreText from '../../../../atoms/readmoreText/ReadMoreText';
import { IRoleSkillData } from '../../../../store/api/types/leadership';

interface IProps {
  data: IRoleSkillData[] | [];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  impactModuleDropDown: SelectOptions[];
  functionList?: SelectOptions[];
  subFunctionList?: SelectOptions[];
}

const ImpactGroupStatementsTable = ({
  data,
  isLoading,
  tableInstanceRef,
  impactModuleDropDown,
}: IProps) => {
  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IRoleSkillData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IRoleSkillData>[]>(
    () => [
      {
        header: 'Statement Name',
        filterFn: 'includesString',
        accessorKey: 'name',
        size: 250,
        minSize: 250,
        maxSize: 560,
      },
      {
        header: 'Statement ID',
        accessorKey: 'statementCode',
        filterFn: 'includesString',
        size: 40,
        minSize: 40,
        maxSize: 40,
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
        Cell: ({ cell }) => (
          <ReadMoreText
            text={cell.getValue() as string}
            length={50}
          />
        ),
        size: 300,
        minSize: 300,
        maxSize: 560,
      },
      // Right now API response is not sending use the response value
      // {
      //   header: 'Function',
      //   accessorKey: 'function',
      //   filterVariant: 'multi-select',
      //   Filter: ({ column }) => (
      //     <MultiSelectDropdown
      //       dropDownItem={functionList}
      //       onChange={(e, newValue, reason) =>
      //         multiSelectOnChangeHandler(e, newValue, reason, column)
      //       }
      //       column={column}
      //     />
      //   ),
      //   size: 50,
      // },
      // {
      //   header: 'Sub-function',
      //   accessorKey: 'subFunction',
      //   filterVariant: 'multi-select',
      //   Filter: ({ column }) => (
      //     <MultiSelectDropdown
      //       dropDownItem={subFunctionList}
      //       onChange={(e, newValue, reason) =>
      //         multiSelectOnChangeHandler(e, newValue, reason, column)
      //       }
      //       column={column}
      //     />
      //   ),
      //   size: 50,
      // },
      {
        header: 'Impact Module',
        accessorKey: 'impactModuleName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={
              impactModuleDropDown
                .filter((item) => item.value)
                .reduce((accum, crrValue, idx) => {
                  if (idx === 0) accum = [crrValue];
                  if (accum.find((item) => item.value === crrValue.value))
                    return accum;
                  else accum = [...accum, crrValue];
                  return accum;
                }, [] as SelectOptions[]) || []
            }
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        size: 50,
        minSize: 50,
        maxSize: 50,
      },
      // Part of another ticket keep it commented so that it will be helpfull in upcoming sprint ticket
      // {
      //   header: 'Duplicated Statement',
      //   accessorKey: 'duplicatedStatement',
      //   accessorFn: (item) => {
      //     return item.duplicatedStatement ? 'Yes' : 'No';
      //   },
      //   filterVariant: 'select',
      //   filterFn: 'contains',
      //   Filter: ({ header }) => {
      //     return (
      //       <div className='row'>
      //         <SelectDropdown
      //           value={header.column.getFilterValue() ?? ''}
      //           dropDownItem={[
      //             {
      //               keyValue: 'Yes',
      //               keyName: 'Yes',
      //             },
      //             {
      //               keyValue: 'No',
      //               keyName: 'No',
      //             },
      //           ]}
      //           tooltip={'Tooltip to be Added...'}
      //           clearfunction={() => header.column.setFilterValue(undefined)}
      //           onChange={(e) =>
      //             header.column.setFilterValue(e.target.value || undefined)
      //           }
      //         ></SelectDropdown>
      //       </div>
      //     );
      //   },
      //   filterSelectOptions: [
      //     { text: 'Yes', value: 'Yes' },
      //     { text: 'No', value: 'No' },
      //   ],
      //   Cell: ({ cell }) => {
      //     return cell.getValue() === 'Yes' ? (
      //       <StyledText>
      //         Yes | <StyledToggleText>view duplicate </StyledToggleText>
      //       </StyledText>
      //     ) : (
      //       'No'
      //     );
      //   },
      // },
    ],
    [impactModuleDropDown]
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      muiSelectCheckboxProps={{ color: 'default' }}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default ImpactGroupStatementsTable;
