import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AddReportStructureResponse,
  ReportStructureDataType,
  ReportStructureResponse,
} from './types/reportStructure';

export const reportStructureApi = createApi({
  reducerPath: 'reportstructure/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}reportstructure/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['reportstructure'],
  endpoints: (builder) => ({
    getReportStructure: builder.query<
      ReportStructureResponse,
      { clientId?: string }
    >({
      query: ({ clientId }) => `report-structure?clientId=${clientId}`,
      transformResponse: (response: ReportStructureResponse) => {
        return {
          status: response.status,
          code: response.code,
          data: response.data,
          dropdownList: response.data.map((el: ReportStructureDataType) => ({
            keyName: el.name || '',
            keyValue: el._id || '',
          })),
        };
      },
      providesTags: ['reportstructure'],
    }),
    addReportStructure: builder.mutation<
      AddReportStructureResponse,
      ReportStructureDataType
    >({
      query: (payload) => ({
        url: 'report-structure',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['reportstructure'],
    }),
  }),
});

export const { useGetReportStructureQuery, useAddReportStructureMutation } =
  reportStructureApi;
