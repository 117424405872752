import React, { useMemo } from 'react';
import Table from '../../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { Box } from '@mui/material';
import { IProjectLeaderType } from '../../../../../store/api/types/leaderProfile';

interface IProps {
  data: Array<IProjectLeaderType>;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  checkSave: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const AddLeadersTable = ({ data, tableInstanceRef, checkSave }: IProps) => {
  const columns = useMemo<MRT_ColumnDef<IProjectLeaderType>[]>(
    () => [
      {
        header: 'Participant',
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        filterFn: 'includesString',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        filterFn: 'includesString',
      },
    ],
    []
  );
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    const selection = tableInstanceRef?.current
      ?.getSelectedRowModel()
      .flatRows.map((item) => item.original) as Array<IProjectLeaderType>;
    if (selection) {
      checkSave(selection.map((item) => item.id));
    }
  }, [checkSave, rowSelection, tableInstanceRef]);

  return (
    <Box>
      <Table
        enableRowSelection
        tableInstanceRef={tableInstanceRef}
        enableRowActions={false}
        data={data}
        columns={columns as MRT_ColumnDef<object>[]}
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
      />
    </Box>
  );
};

export default AddLeadersTable;
