import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserInfoProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

const initialState: IUserInfoProps = {
  firstName: '',
  lastName: '',
  emailAddress: '',
};

const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,
  reducers: {
    addUserInformation: (state, action: PayloadAction<IUserInfoProps>) => {
      state.firstName = action.payload.firstName || '';
      state.lastName = action.payload.lastName || '';
      state.emailAddress = action.payload.emailAddress || '';
    },
  },
});

export const userInformationActions = userInformationSlice.actions;
export const userInformationReducer = userInformationSlice.reducer;
