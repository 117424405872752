import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import FormInputSlider from '../../../molecules/form/FormInputSlider';
import TooltipBenchmarkData from '../../../atoms/tooltip/TooltipBenchmarkData';
import { useEditSkillByIdMutation } from '../../../store/api/leadership';

interface IRoleStatementEditFormData {
  id: string;
  name: string;
  description: string;
  accelerator: string;
}

type IBenchmark = {
  proficiency: number;
};

export default function EditStatementRLModal() {
  const [editRoleBenchmark] = useEditSkillByIdMutation();

  const initialValues = useAppSelector(
    (state) => state.editStatementRLModal.initialValues
  );
  const isOpen = useAppSelector((state) => state.editStatementRLModal.isOpen);

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeEditStatementRLModal,
  } = useActions();

  const methods = useForm<IRoleStatementEditFormData & IBenchmark>();
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    closeEditStatementRLModal();
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [isOpen, reset, initialValues]);

  const onSubmit = async (data: IRoleStatementEditFormData & IBenchmark) => {
    const benchmarkPayload: IBenchmark = { proficiency: data.proficiency };

    const modalTypeMap = {
      edit: {
        fn: editRoleBenchmark,
        messageSuccess: 'Statement Updated Successfully',
        errorMessage: 'Error Updating Statement',
      },
    };
    const payload = {
      benchmark: Number(benchmarkPayload.proficiency) || 1,
      id: data.id || '',
    };
    try {
      await modalTypeMap['edit'].fn(payload).unwrap();
      toggleSuccessSnackbar({
        message: modalTypeMap['edit'].messageSuccess,
      });
      handleClose();
    } catch (e) {
      toggleErrorSnackbar({ message: modalTypeMap['edit'].errorMessage });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>{'Edit Statement'}</ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-role'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='name'
            rules={{ required: 'Please provide a statement name' }}
            label='Statement name'
            tooltip='tooltip info'
            extraLabelInputProps={{
              disabled: true,
            }}
          />
          <FormLabelInput
            name='description'
            rules={{ required: 'Please provide a description ' }}
            label='Description'
            tooltip='Statement description'
            extraLabelInputProps={{
              minRows: 2,
              maxRows: 2,
              multiline: true,
              disabled: true,
            }}
          />
          <FormInputSlider
            name={'proficiency'}
            label={'Set a Benchmark Score'}
            setValue={setValue}
            tooltip={<TooltipBenchmarkData />}
            defaultValue={initialValues.proficiency}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='add-new-role'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
