import { Box, styled } from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import TabContent from '../../../atoms/tab/TabContent';
import { ITabContentProps } from '../../../commons/types';
import Title from '../../../atoms/title/Title';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { ILeadersData } from '../../../store/api/types/assessmentStatusDashboard';
import AssessmentsTable from '../../../organisms/tables/Company/Assessments/AssessmentsTable';
import { filterForMultipleValues } from '../../../utils/helpers';

interface IProps {
  leaderData?: ILeadersData[];
  isLoading: boolean;
  tableFilters: { project?: string; participant?: string };
  setTabValue: Dispatch<SetStateAction<number>>;
  setTableFilters: Dispatch<
    SetStateAction<{
      project?: string | undefined;
      participant?: string | undefined;
    }>
  >;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export default function Leaders({
  index,
  value,
  leaderData,
  isLoading,
  setTabValue,
  tableFilters,
  setTableFilters,
}: ITabContentProps & IProps) {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  useEffect(() => {
    const project = tableInstanceRef?.current?.getColumn('project');
    project?.setFilterValue(tableFilters?.project);
  }, [tableFilters]);

  const columns = useMemo<MRT_ColumnDef<ILeadersData>[]>(
    () => [
      {
        header: 'Participants',
        accessorKey: 'participant',
        filterFn: 'includesString',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        filterFn: 'includesString',
      },
      {
        header: 'Project Reference',
        accessorKey: 'project',
        filterFn: (row, id, filterValue) =>
          filterForMultipleValues(row, id, filterValue),
      },
      {
        header: 'Assessments',
        accessorKey: 'total',
        filterFn: 'equals',
        maxSize: 30,
      },
      {
        header: 'Complete',
        accessorKey: 'complete',
        filterFn: 'equals',
        maxSize: 30,
      },
      {
        header: 'Incomplete',
        accessorKey: 'incomplete',
        filterFn: 'equals',
        maxSize: 30,
      },
      {
        header: 'Not Started',
        accessorKey: 'notStarted',
        filterFn: 'equals',
        maxSize: 30,
      },
    ],
    []
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <StyledHeaderWrapper>
              <Title
                text={`${
                  leaderData?.length ? leaderData?.length : '0'
                } total participants`}
                sx={{
                  fontSize: '20px',
                }}
              />
            </StyledHeaderWrapper>
          </StyledTitleWrapper>
        </StyledTitlesWrapper>
        <AssessmentsTable
          data={leaderData ?? []}
          state={{ showSkeletons: isLoading }}
          initialState={{
            sorting: [{ id: 'participant', desc: false }],
            showColumnFilters: true,
          }}
          columns={columns}
          tableInstanceRef={tableInstanceRef}
          muiTableBodyCellProps={({ cell, row }) => ({
            onClick: () => {
              const selection = window.getSelection()!.toString();
              if (!cell.id.includes('mrt-row-actions') && !selection) {
                setTabValue(2);
                setTableFilters({
                  ...tableFilters,
                  participant: row.original.participant,
                });
              }
            },
            sx: {
              cursor: !cell.id.includes('mrt-row-actions')
                ? 'pointer'
                : 'default',
            },
          })}
        />
      </>
    </TabContent>
  );
}
