import React, { useMemo, useState } from 'react';
import Table from '../../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { showCreatedDate } from '../../../../../store/api/helpers';

type IReportData = {
  name: string;
  report: string;
  type: string;
  assessments: string;
  generateddate: string;
  generatedby: string;
};

interface IProps {
  data: Array<IReportData>;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  recordSelection: (records: Array<any | null>) => void;
  isLoading: boolean;
}

const LeadersReportsTable = ({
  data,
  tableInstanceRef,
  recordSelection,
  isLoading,
}: IProps) => {
  const [rowSelection, setRowSelection] = useState({});

  const selfDropdown = [
    { text: 'Self', value: 'Self' },
    { text: '360', value: '360' },
  ];

  React.useEffect(() => {
    const selectedRecords: Array<any | null> = [];
    Object.keys(rowSelection).forEach((value: string) => {
      const idx = Number(value) as number;
      if (idx >= 0) {
        const record = data[idx];
        if (record) selectedRecords.push(record);
      }
    });
    recordSelection(selectedRecords);
  }, [recordSelection, rowSelection]);

  // const multiSelectOnChangeHandler = (
  //   e: React.KeyboardEvent,
  //   newValue: SelectOptions[],
  //   reason: unknown,
  //   column: MRT_Column<IReportData>
  // ) => {
  //   // This is an onChange event to update the filter value for this column
  //   if (
  //     e.type === 'keydown' &&
  //     (e as React.KeyboardEvent).key === 'Backspace' &&
  //     reason === 'removeOption'
  //   ) {
  //     return;
  //   }
  //   const selectedItems = newValue.map((value: { text: string }) => value.text);
  //   column.setFilterValue(selectedItems);
  // };

  const columns = useMemo<MRT_ColumnDef<IReportData>[]>(
    () => [
      {
        header: 'Participant Name',
        accessorKey: 'name',
        filterFn: 'includesString',
        size: 250,
      },
      {
        header: 'Report Name',
        accessorKey: 'report',
        filterFn: 'includesString',
        size: 250,
      },
      // commented in APS-2810
      // {
      //   header: 'Report Type',
      //   accessorKey: 'type',
      //   filterVariant: 'multi-select',
      //   Filter: ({ column }) => (
      //     <MultiSelectDropdown
      //       width='100% !important'
      //       dropDownItem={selfDropdown}
      //       onChange={(e, newValue, reason) =>
      //         multiSelectOnChangeHandler(e, newValue, reason, column)
      //       }
      //       column={column}
      //     />
      //   ),
      //   size: 200,
      //   minSize: 200,
      //   maxSize: 560,
      // },
      {
        header: 'Generated Date',
        accessorFn: (row) => showCreatedDate(row.generateddate ?? ''),
        filterFn: 'includesString',
      },
      // commented in APS-2810
      // {
      //   header: 'Generated By',
      //   accessorKey: 'generatedby',
      //   filterFn: 'includesString',
      // },
    ],
    [selfDropdown]
  );

  return (
    <Table
      enableRowSelection
      onRowSelectionChange={setRowSelection}
      enableSelectAll={true}
      tableInstanceRef={tableInstanceRef}
      enableRowActions={true}
      data={(data as Array<IReportData>) || []}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ rowSelection, showSkeletons: isLoading }}
    />
  );
};

export default LeadersReportsTable;
