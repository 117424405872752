import React from 'react';
import { Box, Menu, MenuItem, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import DetailsHeaderWrapper from '../../atoms/header/DetailsHeaderWrapper';
import HDBreadCrumb from '../../hd-ui-kit/HDBreadcrumb';
import { useActions } from '../../hooks/actions';
import {
  useDeleteRoleProfileMutation,
  useGetSubFunctionListQuery,
} from '../../store/api/leadership';
import { IGetRoleData } from '../../store/api/types/leadership';
import { useNavigate } from 'react-router';
import { routes } from '../../routes/routes';

interface IProps {
  roleDetails: IGetRoleData | undefined;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const CategoryWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '40px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  paddingLeft: '24px',
  paddingRight: '340px',
  maxWidth: '1200px',
}));

export default function RoleDetailsHeader({ roleDetails }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data: subFunctionData } = useGetSubFunctionListQuery('Master');
  const [deleteRole] = useDeleteRoleProfileMutation();
  const {
    openEditRoleModal,
    setSubFunctionList,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditRole = () => {
    setSubFunctionList({
      list: subFunctionData?.subFunctionsListDropdown,
      value: roleDetails?.function,
    });
    openEditRoleModal(roleDetails!);
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleDeleteRole = (roleID: string) => {
    deleteRole(roleID)
      .unwrap()
      .then(() => {
        toggleSuccessSnackbar({ message: 'Role Deleted Successfully' });
        navigate(routes.roleLibrary.url);
      })
      .catch(() => toggleErrorSnackbar({}));
    setAnchorEl(null);
  };

  return (
    <>
      <DetailsHeaderWrapper>
        <StyledTitlesWrapper>
          <BreadCrumbWrapper>
            <HDBreadCrumb
              routes={[
                { text: 'Role Library' },
                { text: roleDetails?.name as string },
              ]}
            />
          </BreadCrumbWrapper>
          <Title text={roleDetails?.name as string} />
          <StyledSubTitlesWrapper>
            <StyledSubTitlesWrapper>
              <SubTitle
                text='ID'
                bold
              />
              <SubTitle text={roleDetails?.roleId || ''} />
            </StyledSubTitlesWrapper>
          </StyledSubTitlesWrapper>
          <CategoryWrapper>
            <SubTitle
              text={`${roleDetails?.functionName?.toUpperCase()}
                 | ${roleDetails?.subFunctionName?.toUpperCase()} 
                 | ${roleDetails?.levelName?.toUpperCase()}`}
            />
          </CategoryWrapper>
        </StyledTitlesWrapper>
        <StyledButtonWrapper>
          <IconButton onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditRole()}>Edit</MenuItem>
            <MenuItem
              onClick={() => handleDeleteRole(roleDetails?.id as string)}
            >
              Delete
            </MenuItem>
          </Menu>
        </StyledButtonWrapper>
      </DetailsHeaderWrapper>
      <StyledWrapper>
        <SubTitle text={(roleDetails?.description as string) || ''} />
      </StyledWrapper>
    </>
  );
}
