import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import Button from '../../atoms/button/Button';
import { routes } from '../../routes/routes';
import CompaniesModal from '../modals/CompaniesModal';
import PageHeader from '../../atoms/header/PageHeader';
import ResetButton from '../../atoms/button/ResetButton';

interface IProps {
  qtyOfCompanies?: number;
  onResetButtonClick: () => void;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  marginBottom: '38px',
  gap: '15px',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const CompaniesHeader = ({ qtyOfCompanies, onResetButtonClick }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PageHeader>
        <StyledTitlesWrapper>
          <Title text={routes.companies.name} />
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${qtyOfCompanies ? qtyOfCompanies : '0'} total companies`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <StyledButtonWrapper>
          <Button
            btnType='PRIMARY'
            text={'Add new company'}
            onClick={() => setIsOpen(true)}
          />
        </StyledButtonWrapper>
      </PageHeader>
      <CompaniesModal
        open={isOpen}
        close={() => setIsOpen(false)}
      />
    </>
  );
};

export default CompaniesHeader;
