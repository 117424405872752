import { Box, Button, Toolbar } from '@mui/material';
import arrowLeft from '../../assets/icons/arrowLeft.svg';
import trash from '../../assets/icons/trash.svg';
import reportIcon from '../../assets/icons/reportIcon.svg';
import importicon from '../../assets/icons/Import.svg';
import exporticon from '../../assets/icons/export.svg';
import { styled } from '@mui/material/styles';
import { Restricted } from '../../rbac/Restricted';
import sendInviteIcon from '../../assets/icons/sendInvite.svg';

interface IProps {
  rowSelectionLabel?: string;
  rowsSelected?: number;
  FirstButtonLabel?: string;
  FirstButtonAction?: () => void;
  SecondButtonLabel?: string;
  SecondButtonAction?: () => void;
  iconType?: string;
  ThirdButtonLabel?: string;
  ThirdButtonAction?: () => void;
}

const StyledButton = styled(Button)({
  textDecoration: 'underline',
  border: 'px solid transparent',
  borderRadius: 0,
  margin: '11px 15px',
  padding: '2px',
  '&.Mui-selected': {},
  '&.Mui-focusVisible': {
    borderColor: 'transparent',
  },
  ':focus': {
    borderRadius: 'none !important',
    border: '1px solid',
    textDecoration: 'none',
  },
});

const TopToolbar = ({
  rowsSelected,
  rowSelectionLabel,
  SecondButtonAction,
  FirstButtonLabel,
  FirstButtonAction,
  SecondButtonLabel,
  ThirdButtonLabel,
  ThirdButtonAction,
  iconType,
}: IProps) => {
  return (
    <Box>
      <Toolbar
        sx={{
          backgroundColor: '#fff',
          borderRadius: '4px',
          border: '1px solid #000',
          display: 'flex',
          flexDirection: {
            xs: 'row',
            lg: 'row',
          },
          gap: '1rem',
          justifyContent: 'space-between',
          p: '5px 0',
        }} // unable to override Toolbar with styled, using sx for styling
      >
        <Box>
          <span>
            {rowsSelected} {rowSelectionLabel || 'users selected'}
          </span>
        </Box>
        <Box>
          {FirstButtonLabel && (
            <Restricted allowedTo='project.project_details.project_leaders.remove'>
              <StyledButton
                color='secondary'
                onClick={() => {
                  FirstButtonAction && FirstButtonAction();
                }}
                startIcon={
                  iconType && iconType === 'report' ? (
                    <img src={reportIcon} />
                  ) : (
                    <img src={trash} />
                  )
                }
                variant='text'
              >
                {FirstButtonLabel}
              </StyledButton>
            </Restricted>
          )}
          {SecondButtonLabel && (
            <StyledButton
              color='secondary'
              onClick={() => {
                SecondButtonAction && SecondButtonAction();
              }}
              startIcon={
                iconType && iconType === 'download' ? (
                  <img src={exporticon} />
                ) : (
                  <img src={arrowLeft} />
                )
              }
              variant='text'
            >
              {SecondButtonLabel}
            </StyledButton>
          )}
          {ThirdButtonLabel && (
            <Restricted allowedTo='project.project_details.project_leaders.send_invite'>
              <StyledButton
                color='secondary'
                onClick={() => {
                  ThirdButtonAction && ThirdButtonAction();
                }}
                startIcon={
                  iconType && iconType === 'upload' ? (
                    <img src={exporticon} />
                  ) : (
                    <img src={sendInviteIcon} />
                  )
                }
                variant='text'
              >
                {ThirdButtonLabel}
              </StyledButton>
            </Restricted>
          )}
        </Box>
      </Toolbar>
    </Box>
  );
};

export default TopToolbar;
