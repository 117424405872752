import React from 'react';
import { Box, Menu, MenuItem, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import DetailsHeaderWrapper from '../../atoms/header/DetailsHeaderWrapper';
import HDBreadCrumb from '../../hd-ui-kit/HDBreadcrumb';
import { useActions } from '../../hooks/actions';
import { useNavigate } from 'react-router';
import { routes } from '../../routes/routes';
import AddToRoleModal from '../modals/Statement/AddToRoleModal';
import { useAppSelector } from '../../hooks/redux';
import StatementLibraryWarningModal from '../modals/StatementLibraryWarningModal';
import {
  useDeleteClientStatementMutation,
  useGetClientStatementByIdQuery,
  useLazyValidateStatementByIdQuery,
} from '../../store/api/leadership';
import { IGetClientStatementData } from '../../store/api/types/leadership';

interface IProps {
  id: string;
  clientId?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const CategoryWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '40px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '20px',
  maxWidth: '1200px',
}));

export default function StatementDetailsHeader({ id, clientId }: IProps) {
  const navigate = useNavigate();

  const isOpen = useAppSelector((state) => state.addToRoleModal.isOpen);

  const { openStatementLibraryWarningModal } = useActions();

  const [deleteStatement] = useDeleteClientStatementMutation();
  const { data } = useGetClientStatementByIdQuery(id);

  const [validateStatement] = useLazyValidateStatementByIdQuery();

  const statementDetails = data?.data as IGetClientStatementData;

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    openAddToRoleModal,
    openEditLeadershipCapabilitySLModal,
    openEditLeadershipPotentialSLModal,
    openEditOrganizationalImpactSLModal,
    openEditFunctionalImpactSLModal,
    openEditCultureImpactSLModal,
  } = useActions();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Use this code when skillValidate api is ready
  const handleDelete = async () => {
    setAnchorEl(null);

    try {
      const payload = {
        clientId: clientId || 'Master',
        statementId: id,
      };
      const { data: validateData } = await validateStatement(payload).unwrap();
      if (validateData && validateData.length) {
        openStatementLibraryWarningModal(validateData);
      } else {
        await deleteStatement(id).unwrap();
        toggleSuccessSnackbar({ message: 'Statement Deleted Successfully' });
        if (clientId) {
          navigate(
            `${routes.company.url}${clientId}${routes.company.statements.url}`
          );
        } else {
          navigate(`${routes.statementLibrary.url}/`);
        }
      }
    } catch (e) {
      toggleErrorSnackbar({});
    }
  };

  const enabledSkillGroup = ['Functional Impact', 'Organizational Impact'];

  const handleApplyToRole = () => {
    openAddToRoleModal({
      skill: id,
      clientId,
      statementUUID: statementDetails?.statementLibraryUUID || '',
      skillGroup: statementDetails?.skillGroup || '',
    });
    setAnchorEl(null);
  };

  const handleEditStatement = () => {
    if (statementDetails.skillGroup === 'Functional Impact') {
      openEditFunctionalImpactSLModal(statementDetails);
    } else if (statementDetails.skillGroup === 'Organizational Impact') {
      openEditOrganizationalImpactSLModal(statementDetails);
    } else if (statementDetails.skillGroup === 'Capabilities') {
      openEditLeadershipCapabilitySLModal(statementDetails);
    } else if (statementDetails.skillGroup === 'Potential') {
      openEditLeadershipPotentialSLModal(statementDetails);
    } else if (statementDetails.skillGroup === 'Purpose') {
      openEditCultureImpactSLModal(statementDetails);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <DetailsHeaderWrapper>
        <StyledTitlesWrapper>
          <BreadCrumbWrapper>
            <HDBreadCrumb
              routes={[
                { text: 'Statement Library' },
                { text: statementDetails?.name || '' },
              ]}
            />
          </BreadCrumbWrapper>
          <Title text={statementDetails?.name || ''} />
          <StyledSubTitlesWrapper>
            <StyledSubTitlesWrapper>
              <SubTitle
                text='ID'
                bold
              />
              <SubTitle text={statementDetails?.statementCode || ''} />
            </StyledSubTitlesWrapper>
          </StyledSubTitlesWrapper>
          <CategoryWrapper>
            <SubTitle text={statementDetails?.skillCategoryName || ''} />
            <SubTitle text='|' />
            <SubTitle text={statementDetails?.impactModuleName || ''} />
          </CategoryWrapper>
          <StyledWrapper>
            <SubTitle text={statementDetails?.description || ''} />
          </StyledWrapper>
        </StyledTitlesWrapper>
        <StyledButtonWrapper>
          <IconButton onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEditStatement}>Edit</MenuItem>
            {enabledSkillGroup.includes(statementDetails?.skillGroup || '') && (
              <MenuItem onClick={handleApplyToRole}>Apply to role</MenuItem>
            )}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </StyledButtonWrapper>
      </DetailsHeaderWrapper>
      {isOpen && <AddToRoleModal />}
      <StatementLibraryWarningModal />
    </>
  );
}
