import React from 'react';
import { Box, styled } from '@mui/system';
import TabMenu from '../../../../atoms/tab/TabMenu';
import TabMenuItem from '../../../../atoms/tab/TabMenuItem';
import { useParams } from 'react-router';
import RoleDetailsHeader from '../../../../organisms/headers/Company/Roles/RoleDetails/RoleDetailsHeader';
import BusinessFunctionalImpact from './BusinessFunctionalImpact';
import BusinessOrganizationallImpact from './BusinessOrganizationalImpact';
import LeadershipCapability from './LeadershipCapability';
import LeadershipPotential from './LeadershipPotential';
import CultureImpact from './CultureImpact';
import { useGetRoleProfilesByIdQuery } from '../../../../store/api/leadership';
import RoleModal from '../../../../organisms/modals/RoleModal';

export default function RoleDetails() {
  const [tabValue, setTabValue] = React.useState(0);
  const { id, roleid } = useParams();
  const { data: roleData } = useGetRoleProfilesByIdQuery(roleid as string);

  const StyledContentWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    margin: 'auto',
    paddingLeft: '20px',
    maxWidth: '1200px',
    '.tabContent': {
      paddingTop: '40px',
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px - 66px)',
        overflowY: 'scroll',
      }}
    >
      {roleData?.data && (
        <>
          <RoleDetailsHeader roleDetails={roleData?.data} />
          <StyledContentWrapper>
            <TabMenu
              value={tabValue}
              onChange={handleTabChange}
            >
              <TabMenuItem
                index={0}
                label={'Business Impact: Functional'}
              />
              <TabMenuItem
                index={1}
                label={'Business Impact: Organizational'}
              />
              <TabMenuItem
                index={2}
                label={'Leadership Capability'}
              />
              <TabMenuItem
                index={3}
                label={'Leadership Potential'}
              />
              <TabMenuItem
                index={4}
                label={'Culture Impact'}
              />
            </TabMenu>
            <Box className='tabContent'>
              <BusinessFunctionalImpact
                index={0}
                value={tabValue}
                roleId={roleid}
                roleFunctionID={roleData?.data.functionName || ''}
                skillType={'Functional Impact'}
                client={id}
              />
              <BusinessOrganizationallImpact
                index={1}
                value={tabValue}
                roleId={roleid}
                skillType={'Organizational Impact'}
                roleFunctionID={roleData?.data.functionName || ''}
                client={id}
              />
              <LeadershipCapability
                index={2}
                value={tabValue}
                roleId={roleid}
                skillType={'Capabilities'}
                client={id}
              />
              <LeadershipPotential
                index={3}
                value={tabValue}
                roleId={roleid}
                skillType={'Potential'}
                client={id}
              />
              <CultureImpact
                index={4}
                value={tabValue}
                roleId={roleid}
                skillType={'Purpose'}
                client={id}
              />
            </Box>
          </StyledContentWrapper>
          <RoleModal />
        </>
      )}
    </Box>
  );
}
