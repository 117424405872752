import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import defaultLogo from '../../assets/img/hns-logo.png';
import { Box, styled, Avatar, Menu, MenuItem } from '@mui/material';
import { useAppSelector } from '../../hooks/redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

interface IProps {
  companyName?: string;
}

const stringAvatar = (name: string, bgColor = '#bcc4c6') => {
  return {
    sx: {
      bgcolor: bgColor,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

const StyledContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '68px',
  backgroundColor: '#f8f9fa',
  position: 'fixed',
  overflow: 'hidden',
});

const StyledLogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexBasis: '50%',
  alignItems: 'center',
  paddingLeft: '25px',
});

const StyledRightContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  flexBasis: '50%',
  alignItems: 'center',
  paddingRight: '25px',
  gap: '14px',
});

const StyledProfileContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  cursor: 'pointer',
});

const StyledFullName = styled(Box)({
  display: 'flex',
  color: 'rgba(74,94,93,.4)',
  fontWeight: 400,
});

const StyledCompanyName = styled(Box)({
  display: 'flex',
  fontWeight: 500,
  margin: '20px',
  fontSize: '25px',
  color: '#002136',
});

const StyledInfoBox = styled(Box)({
  display: 'flex',
  padding: '20px',
  paddingLeft: '10px',
  paddingBottom: '10px',
  gap: '20px',
  fontWeight: 400,
  '& .fullName': {
    color: '#000',
  },
});

const StyledLogoutBox = styled(Box)({
  display: 'flex',
  padding: '20px',
  paddingTop: '10px',
  paddingLeft: '10px',
  gap: '20px',
  fontWeight: 400,
  alignItems: 'center',
  color: '#000 !important',
});

const ClientUserHeader = ({ companyName }: IProps) => {
  const { oktaAuth } = useOktaAuth();

  const userInformation = useAppSelector((state) => state.userInformation);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <StyledContainer>
      <StyledLogoContainer>
        <img
          src={defaultLogo}
          style={{ height: '42px', width: '42px' }}
        />

        <StyledCompanyName>{companyName ? companyName : ''}</StyledCompanyName>
      </StyledLogoContainer>
      <StyledRightContainer>
        <StyledProfileContainer
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Avatar
            {...stringAvatar(
              `${userInformation.firstName} ${userInformation.lastName}`
            )}
          />
          <StyledFullName>{`${userInformation.firstName} ${userInformation.lastName}`}</StyledFullName>
          <KeyboardArrowDownIcon />
        </StyledProfileContainer>
      </StyledRightContainer>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 58 * 4.5,
            width: '280px',
          },
        }}
      >
        <MenuItem>
          <StyledInfoBox>
            <Box>
              <Avatar
                {...stringAvatar(
                  `${userInformation.firstName} ${userInformation.lastName}`,
                  'rgb(66, 102, 135)'
                )}
              />
            </Box>
            <Box>
              <StyledFullName className='fullName'>{`${userInformation.firstName} ${userInformation.lastName}`}</StyledFullName>
              <StyledFullName className='fullName'>{`${userInformation.emailAddress}`}</StyledFullName>
            </Box>
          </StyledInfoBox>
        </MenuItem>
        <MenuItem onClick={logout}>
          <StyledLogoutBox>
            <ExitToAppIcon fontSize='small' />
            Logout
          </StyledLogoutBox>
        </MenuItem>
      </Menu>
    </StyledContainer>
  );
};

export default ClientUserHeader;
