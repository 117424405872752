import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import {
  useAddFunctionMutation,
  useEditFunctionMutation,
  useGetFunctionsListQuery,
} from '../../../store/api/leadership';

interface IFunctionFormData {
  id: string;
  name: string;
  functionId: string;
  description?: string;
}

export default function FunctionVLModal() {
  const initialValues = useAppSelector(
    (state) => state.functionModal.initialValues
  );
  const isOpen = useAppSelector((state) => state.functionModal.isOpen);
  const modalType = useAppSelector((state) => state.functionModal.modalType);

  const { data: { data: functionData } = {} } =
    useGetFunctionsListQuery('Master');

  const [addFunction] = useAddFunctionMutation();
  const [editFunction] = useEditFunctionMutation();

  const methods = useForm<IFunctionFormData>();
  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const calculateFunctionId = React.useCallback(() => {
    if (modalType === 'add' && isOpen && functionData) {
      // This functionality will automatically set Sub-Function ID to first available
      if (modalType === 'add' && isOpen) {
        let i = 1;
        while (functionData!.find((el) => el.code === `F${i}`)) {
          i++;
        }
        setValue('functionId', `F${i}`);
      }
    }
  }, [functionData, isOpen, modalType, setValue]);

  const { toggleErrorSnackbar, toggleSuccessSnackbar, closeFunctionModal } =
    useActions();

  const handleClose = () => {
    closeFunctionModal();
  };

  const modalTypeMap = {
    edit: {
      fn: editFunction,
      messageSuccess: 'Function Updated Successfully',
      title: 'Edit function',
    },
    add: {
      fn: addFunction,
      messageSuccess: 'Function Added Successfully',
      title: 'Add new function',
    },
  };

  const onSubmit = async (formData: IFunctionFormData) => {
    const code = formData.functionId;
    const reg = new RegExp('^[0-9]*$');
    const checknumber = code.substring(1, code.length);
    const isnumber = reg.test(checknumber);

    if (code.substring(0, 1) !== 'F' || !isnumber || code.length <= 1) {
      setError('functionId', { message: 'Please provide valid function ID' });
      return;
    }

    const payload: any = {
      fieldValue: formData.name,
      code: formData.functionId,
      ...(formData.description && { description: formData.description }),
      ...(modalType === 'edit' && { id: formData.id }),
      ...(modalType === 'add' && { clientId: 'Master' }),
    };

    modalTypeMap[modalType]
      .fn(payload)
      .unwrap()
      .then((response) => {
        if (response.status === 'success') {
          toggleSuccessSnackbar({
            message: modalTypeMap[modalType].messageSuccess,
          });
          handleClose();
        }
      })
      .catch((error) => {
        if (error.data && error.data.detail) {
          toggleErrorSnackbar({ message: error.data.detail });
        }
      });
  };

  React.useEffect(() => {
    reset(initialValues);
    calculateFunctionId();
  }, [reset, initialValues, isOpen, calculateFunctionId]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalTypeMap[modalType].title}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-function'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='name'
            rules={{ required: 'Please provide function name' }}
            label='Function Name'
            defaultValue={initialValues.name}
          />
          <FormLabelInput
            name='functionId'
            tooltip={'Tooltip to be Added...'}
            rules={{ required: 'Please provide a function ID' }}
            label='Function ID'
            defaultValue={initialValues.functionId}
          />
          <FormLabelInput
            name='description'
            tooltip={'Tooltip to be Added...'}
            label='Description (optional)'
            defaultValue={initialValues.functionId}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='add-new-function'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
