import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../atoms/tab/TabContent';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import RoleTable from '../../organisms/tables/RoleTable';
import { MRT_TableInstance } from 'material-react-table';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import EditStatementRLModal from '../../organisms/modals/Role/EditStatementRLModal';
import { useLazyGetRoleSkillsByCategoryQuery } from '../../store/api/leadership';

interface IProps {
  roleId?: string;
  skillType: string;
  client?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export default function LeadershipCapability({
  index,
  value,
  roleId,
  client,
}: ITabContentProps & IProps) {
  const [getCapabilityStatements, { data: roleSkills, isLoading }] =
    useLazyGetRoleSkillsByCategoryQuery();

  React.useEffect(() => {
    if (value === 2) {
      getCapabilityStatements({
        client,
        roleId,
        skillType: 'Capabilities',
      });
    }
  }, [client, getCapabilityStatements, roleId, value]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Box sx={{ display: 'flex' }}>
              <Title
                text={'Leadership Capability'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <Box sx={{ marginTop: '15px' }}>
                <HpTooltip title={'Leadership Capability'} />
              </Box>
            </Box>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                roleSkills?.capabilitiesData?.length
                  ? roleSkills?.capabilitiesData?.length
                  : '0'
              } total statements`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        {value === 2 && <EditStatementRLModal />}
        <RoleTable
          statementType='leadershipCapability'
          isLoading={isLoading}
          data={roleSkills?.capabilitiesData}
          tableInstanceRef={tableInstanceRef}
        />
      </>
    </TabContent>
  );
}
