import React, { useMemo } from 'react';
import Table from '../../../atoms/table/Table';
import { Box, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { useActions } from '../../../hooks/actions';
import { SelectOptions } from '../../../commons/types';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { ILeaderProfileData } from '../../../store/api/types/leaderProfile';
import { useDeleteUserMutation } from '../../../store/api/v2/auth';
import { useDeleteLeaderProfileMutation } from '../../../store/api/v2/leaderProfile';
import { leaderRowData } from '../../../store/api/types/tazio';
import { useParams } from 'react-router';
import CompanySendToTazioModal from '../../modals/Company/CompanySendToTazioModal';
import TopToolbar from '../../../atoms/table/TopToolbar';
import { useGetClientByIdQuery } from '../../../store/api/clients';

interface IProps {
  data: ILeaderProfileData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  roleProfileFilterItems: SelectOptions[];
  userTypeFilterItems: SelectOptions[];
  totalCount: number;
  setRecord: React.Dispatch<React.SetStateAction<Array<ILeaderProfileData>>>;
  selectedUsersEmailList: string[];
}

const UsersTable = ({
  data,
  isLoading,
  tableInstanceRef,
  totalCount,
  setRecord,
  selectedUsersEmailList,
}: IProps) => {
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowSelection, setRowSelection] = React.useState({});
  const [rowsSelected, setRowsSelected] = React.useState(0);

  const navigate = useNavigate();

  const {
    openCompanySendToTazioModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
  } = useActions();

  const [deleteEmployee] = useDeleteLeaderProfileMutation();
  const [deleteUser] = useDeleteUserMutation();

  // Fetch client data to check if insights is enabled
  const pathName = window.location.pathname;
  const isSubNav = pathName.includes('/company');

  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    id as string,
    {
      skip: !isSubNav,
    }
  );
  const isInsights = clientData?.[0]?.applications?.insights?.isEnabled;

  // const multiSelectOnChangeHandler = (
  //   e: React.KeyboardEvent,
  //   newValue: SelectOptions[],
  //   reason: unknown,
  //   column: MRT_Column<ILeaderProfileData>
  // ) => {
  //   // This is an onChange event to update the filter value for this column
  //   if (
  //     e.type === 'keydown' &&
  //     (e as React.KeyboardEvent).key === 'Backspace' &&
  //     reason === 'removeOption'
  //   ) {
  //     return;
  //   }

  //   const selectedItems = newValue.map((value: { value: string }) => {
  //     return value.value;
  //   });

  //   if (selectedItems.length > 0) {
  //     column.setFilterValue(
  //       (selectedItems.length > 0 && selectedItems) || undefined
  //     );
  //   } else {
  //     column.setFilterValue(undefined);
  //   }
  // };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (userId: string) => {
    deleteUser(userId)
      .then(() => {
        deleteEmployee(userId)
          .unwrap()
          .then(() =>
            toggleSuccessSnackbar({ message: 'User Deleted Successfully' })
          )
          .catch((err: any) => toggleErrorSnackbar({ message: err.message }));
      })
      .catch((err) => toggleErrorSnackbar({ message: err.message }));

    setAnchorEl(null);
  };

  const handleEdit = (rowData: ILeaderProfileData) => {
    setAnchorEl(null);
    navigate(
      `${routes.company.url}${rowData.clientId}/user/edit/${rowData.id}`
    );
  };

  const columns = useMemo<MRT_ColumnDef<ILeaderProfileData>[]>(
    () => [
      {
        header: 'User Name',
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        filterFn: 'includesString',
      },
      {
        header: 'Employee ID',
        accessorKey: 'clientEmpId',
        filterFn: 'includesString',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        filterFn: 'includesString',
      },
      // {
      //   header: 'Role Profile',
      //   accessorKey: 'role',
      //   sortingFn: 'text',
      //   filterVariant: 'multi-select',
      //   Filter: ({ column }) => (
      //     <MultiSelectDropdown
      //       dropDownItem={roleProfileFilterItems}
      //       onChange={(e, newValue, reason) =>
      //         multiSelectOnChangeHandler(e, newValue, reason, column)
      //       }
      //       column={column}
      //     />
      //   ),
      // },
      // {
      //   header: 'User Type',
      //   accessorKey: 'ef_usertype',
      //   filterVariant: 'multi-select',
      //   Filter: ({ column }) => (
      //     <MultiSelectDropdown
      //       dropDownItem={userTypeFilterItems}
      //       onChange={(e, newValue, reason) =>
      //         multiSelectOnChangeHandler(e, newValue, reason, column)
      //       }
      //       column={column}
      //     />
      //   ),
      // },
      {
        header: 'Location',
        accessorKey: 'officeLocation',
        filterFn: 'includesString',
      },
    ],
    []
  );

  React.useEffect(() => {
    const rowSelection =
      tableInstanceRef.current?.getSelectedRowModel().flatRows;
    if (rowSelection && rowSelection.length) {
      setRowsSelected(Object.keys(rowSelection).length);
    } else {
      setRowsSelected(0);
    }
  }, [rowSelection]);

  React.useEffect(() => {
    // To Retrieve record at selected rows
    const selectedRecords: Array<ILeaderProfileData> = [];
    Object.keys(rowSelection).forEach((value: string) => {
      const idx = Number(value) as number;
      if (idx >= 0) {
        const record = data[idx];
        if (record) selectedRecords.push(record);
      }
    });
    setRecord(selectedRecords);
  }, [data, rowSelection, setRecord]);

  const onSendLeaderToTazioClick = (): void => {
    if (isInsights) {
      // create payload to pass to Modal selecting project from selected leaders row data
      const rowSelection =
        tableInstanceRef.current?.getSelectedRowModel().flatRows;
      const profileIdList: Array<string> = [];
      const profileEmailList: Array<string> = [];

      // Traverse RowModel data to fetch Original row values
      const rowData: Array<leaderRowData> = [];
      rowSelection &&
        Object.values(rowSelection).forEach((val) => {
          rowData.push(val.original);
        });

      // traverse selected row data to prepare ProfileIdList and ProfileEmailList
      for (let i = 0; i < rowData.length; i++) {
        rowData && profileIdList.push(rowData[i]['id']);
        rowData && profileEmailList.push(rowData[i]['email']);
      }

      const payload = {
        client_id: clientData && clientData[0]?.id,
        api_key: clientData && clientData[0]?.applications?.insights?.apiKey,
        domain: clientData && clientData[0]?.applications?.insights?.url,
        account_id:
          (clientData && clientData[0]?.applications?.insights?.accountId) ||
          '',
        profileIdList: profileIdList,
        emailList: profileEmailList,
      };
      openCompanySendToTazioModal(payload);
    } else {
      toggleErrorSnackbar({
        message:
          'Please enable Insights for this client to send leaders to Tazio.',
      });
    }
  };

  return (
    <Box>
      {/* Our Custom External Top Toolbar */}
      {rowsSelected && tableInstanceRef.current ? (
        <TopToolbar
          rowsSelected={rowsSelected}
          SecondButtonLabel={'Send To Tazio'}
          SecondButtonAction={onSendLeaderToTazioClick}
        />
      ) : (
        ''
      )}
      <Table
        tableInstanceRef={tableInstanceRef}
        data={data ?? []}
        columns={columns as MRT_ColumnDef<object>[]}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        renderRowActions={({ cell, row }) => (
          <>
            <IconButton
              id={cell.id}
              onClick={handleOpen}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl?.id === cell.id}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => handleEdit(row.original as ILeaderProfileData)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleDelete((row.original as ILeaderProfileData).id || '')
                }
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
        state={{
          showSkeletons: isLoading,
          rowSelection: rowSelection,
        }}
        rowCount={totalCount}
      />
      {<CompanySendToTazioModal />}
    </Box>
  );
};

export default UsersTable;
