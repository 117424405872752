import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import CompaniesHeader from '../organisms/headers/CompaniesHeader';
import CompaniesTable from '../organisms/tables/CompaniesTable';
import { MRT_TableInstance } from 'material-react-table';
import { useGetClientQuery } from '../store/api/clients';

const Companies = () => {
  const { data: { data } = {}, isLoading } = useGetClientQuery();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 99px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <CompaniesHeader
        qtyOfCompanies={data?.length}
        onResetButtonClick={onResetButtonClick}
      />
      <Container
        sx={{
          marginBottom: '50px',
        }}
      >
        <CompaniesTable
          isLoading={isLoading}
          data={data}
          tableInstanceRef={tableInstanceRef}
        />
      </Container>
    </Box>
  );
};

export default Companies;
