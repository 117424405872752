import { impactModuleVLClientModalReducer } from './Company/VariableLibrary/ImpactModuleVLClientModalSlice';
import { removeLeadersModalReducer } from './Company/Project/ProjectDetails/RemoveLeadersModalSlice';
import { configureStore } from '@reduxjs/toolkit';
import { coreApi } from './api/core';
import { snackbarReducer } from './snackbarSlice';
import { roleModalReducer } from './roleModalSlice';
import { functionalImpactSLModalReducer } from './Statement/FunctionalImpactSLModalSlice';
import { functionalImpactRLModalReducer } from './Role/FunctionalImpactRLModalSlice';
import { leadershipPotentialSLModalReducer } from './Statement/leadershipPotentialSLModalSlice';
import { adminsModalReducer } from './adminsModalSlice';
import { tazioApi } from './api/tazio';
import { cultureImpactSLModalReducer } from './Statement/CultureImpactSLModalSlice';
import { leadershipCapabilitySLModalReducer } from './Statement/LeadershipCapabilitySLModalSlice';
import { organizationalImpactSLModalReducer } from './Statement/OrganizationalImpactSLModalSlice';
import { AddToRoleModalReducer } from './addToRoleModalSlice';
import { importRoleModalReducer } from './importRoleModalSlice';
import { importCompanyUserModalReducer } from './Company/importCompanyUserModalSlice';
import { uploadFileApi } from './api/uploadFile';
import { importStatementModalReducer } from './importStatementModalSlice';
import { companyRolesModalReducer } from './Company/companyRolesModalSlice';
import { clientRoleStatementModalReducer } from './Company/Roles/clientRoleStatementModalSlice';
import { subFunctionVLModalReducer } from './VariableLibrary/subFunctionVLModalSlice';
import { functionVLModalReducer } from './VariableLibrary/functionVLModalSlice';
import { companyUsersModalReducer } from './Company/companyUsersModalSlice';
import { editStatementRLModalReducer } from './Role/EditStatementModalSlice';
import { organizationalImpactRLModalReducer } from './Role/OrganizationalImpactRLModalSlice';
import { assessmentApi } from './api/assessment';
import { clientRoleImpactStatementModalReducer } from './Company/Roles/RoleDetails/clientRoleImpactStatementSlice';
import { companyImpactGroupReducer } from './Company/CompanyImpactGroupSlice';
import { userInformationReducer } from './userInformationSlice';
import { expertiseCategoryVLModalReducer } from './VariableLibrary/expertiseCategoriesVLModalSlice';
import { levelVLModalReducer } from './VariableLibrary/levelVLModalSlice';
import { impactModuleVLModalReducer } from './VariableLibrary/impactModuleVLModalSlice';
import { skillCategoryVLModalReducer } from './VariableLibrary/skillCategoryVLModalSlice';
import { statementDetailsReducer } from './Statement/statementDetailsSlice';
import { statementLibraryWarningModalReducer } from './statementLibraryWarningModalSlice';
import { assessmentReducer } from './Company/Assessments/AssessmentSlice';
import { IGAddAssessmentIdReducer } from './Company/IGAddAssementIdSlice';
import { addRoleToImpactGroupModalReducer } from './Company/ImpactGroup/addRoleToImpactGroupModalSlice';
import { assignedRolesAnotherImpactGroupModalReducer } from './Company/ImpactGroup/assignedRolesAnotherImpactGroupModalslice';
import { authApi } from './api/v2/auth';
import { importCompanyEmployeeResumeModalReducer } from './Company/importCompanyEmployeeResumeModalSlice';
import { leaderProfileApi } from './api/v2/leaderProfile';
import { leadershipApi } from './api/leadership';
import { clientsApi } from './api/clients';
import { exportFileApi } from './api/exportFile';
import { projectsApi } from './api/projects';
import { companySendToTazioReducer } from './Company/CompanySendToTazioSlice';
import { reportGenerationModalReducer } from './Company/Project/ProjectDetails/reportGenerationModalSlice';
import { projectAssessmentsModalReducer } from './Company/Project/ProjectDetails/projectAssessmentsModalSlice';
import { errorsModalReducer } from './errorsModalSlice';
import { assessmentCohortExportModalReducer } from './Company/Project/ProjectDetails/AssessmentCohortExportModalSlice';
import { leadersReportGenerateModalReducer } from './Company/Project/ProjectDetails/leadersReportGenerateModalSlice';
import { downloadReportsModalReducer } from './Company/Project/ProjectDetails/downloadReportsModalSlice';
import { addLeadersModalReducer } from './Company/Project/ProjectDetails/AddLeadersModalSlice';
import { assessmentStructureApi } from './api/assessmentStructure';
import { assessmentReportApi } from './api/assessmentReports';
import { clientAssessmentModalReducer } from './Company/Assessments/ClientAssessments/ClientAssessmentModalSlice';
import { functionVLClientModalReducer } from './Company/VariableLibrary/FunctionVLClientModalSlice';
import { subFunctionVLClientModalReducer } from './Company/VariableLibrary/SubFunctionVLClientModalSlice';
import { projectSpinnerReducer } from './Company/Project/ProjectDetails/projectSpinnerSlice';
import { levelVLClientModalReducer } from './Company/VariableLibrary/LevelVLClientModalSlice';
import { reportsModalReducer } from './Company/Reports/reportsModalSlice';
import { reportStructureApi } from './api/reportStructure';
import { attachmentsApi } from './api/attachments';
import { calibrationModalReducer } from './Company/Project/ProjectDetails/AssessmentDetails/CalibrationModalSlice';
import { downloadReportReducer } from './Company/Project/ProjectDetails/AssessmentDetails/DownloadReportModalSlice';
import { addNewParticipantModalReducer } from './Company/Project/ProjectDetails/addNewParticipantModalSlice';
import { assignmentReducer } from './Company/Project/ProjectDetails/AssessmentDetails/AssignmentSlice';
import { importSubFunctionModalReducer } from './importSubFunctionModalSlice';

export const store = configureStore({
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [uploadFileApi.reducerPath]: uploadFileApi.reducer,
    [exportFileApi.reducerPath]: exportFileApi.reducer,
    [assessmentApi.reducerPath]: assessmentApi.reducer,
    [tazioApi.reducerPath]: tazioApi.reducer,
    [leaderProfileApi.reducerPath]: leaderProfileApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [leadershipApi.reducerPath]: leadershipApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [assessmentStructureApi.reducerPath]: assessmentStructureApi.reducer,
    [assessmentReportApi.reducerPath]: assessmentReportApi.reducer,
    [reportStructureApi.reducerPath]: reportStructureApi.reducer,
    [attachmentsApi.reducerPath]: attachmentsApi.reducer,
    adminsModal: adminsModalReducer,
    snackbar: snackbarReducer,
    roleModal: roleModalReducer,
    reportGenerationModal: reportGenerationModalReducer,
    functionalImpactSLModal: functionalImpactSLModalReducer,
    organizationalImpactSLModal: organizationalImpactSLModalReducer,
    cultureImpactSLModal: cultureImpactSLModalReducer,
    leadershipPotentialSLModal: leadershipPotentialSLModalReducer,
    leadershipCapabilitySLModal: leadershipCapabilitySLModalReducer,
    addToRoleModal: AddToRoleModalReducer,
    statementLibraryWarningModal: statementLibraryWarningModalReducer,
    errorsModal: errorsModalReducer,
    importRoleModal: importRoleModalReducer,
    importStatementModal: importStatementModalReducer,
    companyRolesModal: companyRolesModalReducer,
    importCompanyUserModal: importCompanyUserModalReducer,
    clientRoleStatementModal: clientRoleStatementModalReducer,
    subFunctionVLModal: subFunctionVLModalReducer,
    functionModal: functionVLModalReducer,
    skillCategoryVLModal: skillCategoryVLModalReducer,
    companyUsersModal: companyUsersModalReducer,
    clientRoleImpactStatementModal: clientRoleImpactStatementModalReducer,
    editStatementRLModal: editStatementRLModalReducer,
    organizationalImpactRLModal: organizationalImpactRLModalReducer,
    functionalImpactRLModal: functionalImpactRLModalReducer,
    companyImpactGroupModal: companyImpactGroupReducer,
    companySendToTazioModal: companySendToTazioReducer,
    userInformation: userInformationReducer,
    expertiseCategoryVLModal: expertiseCategoryVLModalReducer,
    levelVLModal: levelVLModalReducer,
    impactModuleVLModal: impactModuleVLModalReducer,
    statementDetails: statementDetailsReducer,
    assessmentModal: assessmentReducer,
    IGAddAssessmentID: IGAddAssessmentIdReducer,
    addRoleToImpactGroupModal: addRoleToImpactGroupModalReducer,
    assignedRolesAnotherImpactGroupModal:
      assignedRolesAnotherImpactGroupModalReducer,
    importCompanyEmployeeResumeModal: importCompanyEmployeeResumeModalReducer,
    projectAssessmentsModal: projectAssessmentsModalReducer,
    assessmentCohortExportModal: assessmentCohortExportModalReducer,
    leadersReportGenerateModal: leadersReportGenerateModalReducer,
    addLeadersModal: addLeadersModalReducer,
    removeLeadersModal: removeLeadersModalReducer,
    downloadReportsModal: downloadReportsModalReducer,
    clientAssessmentModal: clientAssessmentModalReducer,
    functionVLClientModal: functionVLClientModalReducer,
    subFunctionVLClientModal: subFunctionVLClientModalReducer,
    projectSpinner: projectSpinnerReducer,
    levelVLClientModal: levelVLClientModalReducer,
    reportsModal: reportsModalReducer,
    impactModuleVLClientModal: impactModuleVLClientModalReducer,
    calibrationModal: calibrationModalReducer,
    downloadReportModal: downloadReportReducer,
    addNewParticipantModal: addNewParticipantModalReducer,
    assignmentModal: assignmentReducer,
    importSubFunctionModal: importSubFunctionModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      coreApi.middleware,
      tazioApi.middleware,
      uploadFileApi.middleware,
      assessmentApi.middleware,
      leaderProfileApi.middleware,
      clientsApi.middleware,
      leadershipApi.middleware,
      clientsApi.middleware,
      exportFileApi.middleware,
      projectsApi.middleware,
      assessmentStructureApi.middleware,
      assessmentReportApi.middleware,
      reportStructureApi.middleware,
      attachmentsApi.middleware,
    ]),
});
export type RootState = ReturnType<typeof store.getState>;
