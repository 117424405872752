import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImportStatementModalProps {
  isOpen: boolean;
  progress: number;
}

const initialState: ImportStatementModalProps = {
  isOpen: false,
  progress: 0,
};

const importStatementModalSlice = createSlice({
  name: 'importStatmentModal',
  initialState,
  reducers: {
    closeImportStatementModal: (state) => {
      state.isOpen = false;
    },
    openImportStatementModal: (state) => {
      state.isOpen = true;
      state.progress = 0;
    },
    setImportStatementUploadProgress: (
      state,
      action: PayloadAction<number>
    ) => {
      state.progress = action.payload;
    },
  },
});

export const importStatementModalActions = importStatementModalSlice.actions;
export const importStatementModalReducer = importStatementModalSlice.reducer;
