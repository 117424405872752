import { Box, Link, Typography, styled } from '@mui/material';
import React from 'react';
import Footer from '../commons/Footer';
import ClientUserHeader from '../organisms/headers/ClientUserHeader';
import HpCard from '../atoms/card/HpCard';
import { IApplication } from '../commons/Applications';
import Loader from '../commons/Loader';
import { IMeData } from '../store/api/v2/types/auth';
import ProvisioningError from '../organisms/provisioning/ProvisioningError';

const StyledImageBox = styled(Box)({
  width: '300px',
  height: '150px',
  margin: '-24px',
  '& img': {
    width: '100%',
    height: '150px',
  },
});

const StyledLogoBox = styled(Box)({
  height: '44px',
  marginTop: '40px',
  '& img': {
    maxWidth: '200px',
    maxHeight: '44px',
    margin: 'auto',
    display: 'block',
  },
});

const StyledTitleBox = styled(Box)({
  '& h6': {
    color: '#000',
    fontSize: '16px!important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    margin: '16px 28px 28px',
  },
});

interface IProps {
  ppState: IApplication[];
  meData: IMeData;
}

export default function UserLanding({ ppState, meData }: IProps) {
  const appTiles = ppState.filter(
    (pp) => pp.checked && pp.enabled && pp.appCode !== 'SITE'
  );

  if (
    appTiles.length === 1 &&
    appTiles[0].appCode === 'NAVI' &&
    appTiles[0].link
  ) {
    window.location.href = appTiles[0].link;
    return <Loader />;
  }

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh)',
        }}
      >
        <ClientUserHeader />
        <Box
          sx={{
            height: 'calc(100vh - 66px)',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              marginLeft: '30px',
              marginTop: '100px',
            }}
          >
            {appTiles.length ? (
              appTiles.map((item, key) => {
                const { text, logo, picture, link } = item;
                return (
                  <Link
                    key={key}
                    href={link}
                    underline={'none'}
                  >
                    <HpCard sx={{ width: '300px', height: '300px' }}>
                      <StyledImageBox>{picture}</StyledImageBox>
                      <StyledLogoBox>{logo}</StyledLogoBox>
                      <StyledTitleBox>
                        <Typography variant='h6'>{text}</Typography>
                      </StyledTitleBox>
                    </HpCard>
                  </Link>
                );
              })
            ) : (
              <Box sx={{ margin: '50px' }}>
                <span style={{ fontSize: '30px', fontWeight: '500' }}>
                  You do not have access to any applications.
                </span>
              </Box>
            )}
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}
