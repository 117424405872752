import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Modal from '../../../../../atoms/modal/Modal';
import ModalTitle from '../../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../../atoms/modal/ModalActions';
import Button from '../../../../../atoms/button/Button';
import { useActions } from '../../../../../hooks/actions';
import { useAppSelector } from '../../../../../hooks/redux';
import { HDRadio } from '../../../../../hd-ui-kit/HDRadio';
import { useDownloadReportMutation } from '../../../../../store/api/assessmentReports';

const modalStyle = {
  '& .MuiDialog-paper': {
    minWidth: '768px',
    maxWidth: '1200px',
  },
};

export default function DownloadReportModal() {
  const [reportType, setReportType] = useState('');
  const [submitResponse, setSubmitResponse] = useState(false);

  const [downloadReport] = useDownloadReportMutation();

  const isOpen = useAppSelector((state) => state.downloadReportModal.isOpen);
  const assessmentId = useAppSelector(
    (state) => state.downloadReportModal.assessmentId
  );

  const { closeDownloadReportModal, toggleErrorSnackbar } = useActions();

  const handleClose = () => {
    closeDownloadReportModal();
  };

  const onExportReport = async () => {
    setSubmitResponse(true);
    try {
      const payload = {
        reportType: reportType,
        assessmentId: assessmentId || '',
      };

      const response = await downloadReport(payload).unwrap();
      if (response.status === 'error' && response.message)
        toggleErrorSnackbar({ message: response.message });

      handleClose();
    } catch (e: any) {
      toggleErrorSnackbar({ message: e.error });
    }
  };

  const onReportSelection = (e: any) => {
    setReportType(e.target.value);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>Export Report</ModalTitle>
      <Box>
        <ModalBody>
          <HDRadio
            label={
              <div>
                <Typography variant='body1'>Participant Version</Typography>
                <Typography
                  variant='body2'
                  color='textSecondary'
                >
                  Does not include recommendation status
                </Typography>
              </div>
            }
            checked={reportType === 'alr_leader'}
            name={'report_type'}
            value={'alr_leader'}
            onChange={(e: any) => onReportSelection(e)}
            hasError={false}
            classes={{}}
          />
          <HDRadio
            label={
              <div>
                <Typography variant='body1'>Client Version</Typography>
                <Typography
                  variant='body2'
                  color='textSecondary'
                >
                  Includes recommendation status
                </Typography>
              </div>
            }
            checked={reportType === 'alr_client'}
            name={'report_type'}
            value={'alr_client'}
            onChange={(e: any) => onReportSelection(e)}
            hasError={false}
            classes={{}}
          />
        </ModalBody>
        <ModalActions>
          <Button
            btnType={'PRIMARY'}
            text={'Export Report'}
            disabled={!reportType || submitResponse}
            onClick={onExportReport}
          />
          <Button
            btnType={'SECONDARY'}
            text={'Cancel'}
            onClick={handleClose}
          />
        </ModalActions>
      </Box>
    </Modal>
  );
}
