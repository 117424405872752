import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { ISubFunctionsData } from '../../../../store/api/types/leadership';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useActions } from '../../../../hooks/actions';

interface IProps {
  data?: ISubFunctionsData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const SubFunctionClientVLTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    openEditSubFunctionVLClientModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: ISubFunctionsData) => {
    openEditSubFunctionVLClientModal(row);
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<ISubFunctionsData>[]>(
    () => [
      {
        header: 'Sub-Function Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
      },
      {
        header: 'System Name',
        accessorKey: 'systemName',
        filterFn: 'includesString',
      },
      {
        header: 'Function',
        accessorKey: 'functionFieldValue',
        filterFn: 'includesString',
      },
      {
        header: 'ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
      {
        header: 'Sub-Function Abbreviation',
        accessorKey: 'abbreviation',
        filterFn: 'includesString',
      },
      {
        header: 'Sub-Function Family',
        accessorKey: 'subFunctionFamily',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleEdit(row.original as ISubFunctionsData)}
            >
              Edit
            </MenuItem>
          </Menu>
        </>
      )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default SubFunctionClientVLTable;
