import React, { Dispatch, SetStateAction, useMemo } from 'react';
import Table from '../../../../../atoms/table/Table';

import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { Box } from '@mui/material';
import { IProjectLeadersData } from '../../../../../store/api/types/leaderProfile';
interface IProps {
  data: IProjectLeadersData[];
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  setRowSelection: React.Dispatch<React.SetStateAction<Array<any>>>;
  setTabValue: Dispatch<SetStateAction<number>>;
  setTableFilters: Dispatch<
    SetStateAction<{
      assessment: string;
      participant: string;
    }>
  >;
  tabIndex: number;
  columns: MRT_ColumnDef<IProjectLeadersData>[];
}

const ProjectLeadersTable = ({
  data,
  tableInstanceRef,
  setRowSelection: setRecord,
  setTabValue,
  setTableFilters,
  tabIndex,
  columns,
}: IProps) => {
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    const selectedRecords: Array<IProjectLeadersData> = [];
    Object.keys(rowSelection).forEach((value: string) => {
      const idx = Number(value) as number;
      if (idx >= 0) {
        const record = data[idx];
        if (record) selectedRecords.push(record);
      }
    });
    setRecord(selectedRecords);
  }, [rowSelection, setRecord]);

  React.useEffect(() => {
    setRowSelection({});
  }, [data]);

  return (
    <Box>
      <Table
        enableRowSelection
        tableInstanceRef={tableInstanceRef}
        enableRowActions={false}
        data={data ?? []}
        columns={columns as MRT_ColumnDef<object>[]}
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection: rowSelection }}
        muiTableBodyCellProps={({ row }) => ({
          onClick: () => {
            setTabValue(tabIndex);
            setTableFilters({
              assessment: '',
              participant: (row.original as IProjectLeadersData).leader || '',
            });
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </Box>
  );
};

export default ProjectLeadersTable;
