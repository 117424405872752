import React, { useRef } from 'react';
import { IMetadataPayload, ITabContentProps } from '../../../commons/types';
import TabContent from '../../../atoms/tab/TabContent';
import ImpactGroupLeadersTable from '../../../organisms/tables/Company/ImpactGroups/ImpactGroupLeadersTable';
import { MRT_TableInstance } from 'material-react-table';
import { Box, styled } from '@mui/material';
import { ILeaderProfileData } from '../../../store/api/types/leaderProfile';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '6px 16px 10px',
});

const StyledLabel = styled('span')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '29px',
  color: '#0B0C10',
});

export default function ImpactGroupLeaders({
  index,
  value,
  impactGroupLeaders,
  metadata,
}: ITabContentProps & {
  impactGroupLeaders: Array<ILeaderProfileData>;
  metadata: IMetadataPayload | Record<string, never>;
}) {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledContentWrapper>
        <StyledLabel>{`${
          Object.keys(metadata).length ? metadata.pagination.totalCount : 0
        } total leaders`}</StyledLabel>
      </StyledContentWrapper>

      <ImpactGroupLeadersTable
        isLoading={false}
        roleProfileDropdown={
          impactGroupLeaders.map((item) => ({
            text: item?.roleProfile || '',
            value: item.roleProfile || '',
          })) || []
        }
        data={impactGroupLeaders}
        tableInstanceRef={tableInstanceRef}
      />
    </TabContent>
  );
}
