import React, { useMemo } from 'react';
import Table from '../../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { Box } from '@mui/material';
import { IReportsTypeData } from '../../../../../store/api/types/projects';

interface IProps {
  data?: Array<IReportsTypeData>;
  checkSave: (flag: any) => void;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  setRowSelection: React.Dispatch<
    React.SetStateAction<Array<IReportsTypeData>>
  >;
}

const ReportGenerationTable = ({
  data,
  checkSave,
  tableInstanceRef,
  setRowSelection: setRecord,
}: IProps) => {
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    const selection = tableInstanceRef?.current?.getSelectedRowModel().flatRows;
    if (selection) {
      checkSave(selection);
    } else {
      checkSave(selection);
    }
    if (data && data.length) {
      const selectedRecords: Array<IReportsTypeData> = [];
      Object.keys(rowSelection).forEach((value: string) => {
        const idx = Number(value) as number;
        if (idx >= 0) {
          const record = data[idx];
          if (record) selectedRecords.push(record);
        }
      });
      setRecord(selectedRecords);
    }
  }, [data, rowSelection, setRecord]);
  React.useEffect(() => {
    setRowSelection({});
  }, [data]);

  const columns = useMemo<MRT_ColumnDef<IReportsTypeData>[]>(
    () => [
      {
        header: 'Report Name',
        accessorKey: 'tableName',
        filterFn: 'includesString',
        size: 500,
      },
    ],
    []
  );

  return (
    <Box>
      <Table
        enableRowSelection
        tableInstanceRef={tableInstanceRef}
        enableRowActions={false}
        data={data ?? []}
        columns={columns as MRT_ColumnDef<object>[]}
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection: rowSelection }}
      />
    </Box>
  );
};

export default ReportGenerationTable;
