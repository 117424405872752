import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../../atoms/modal/Modal';
import { useActions } from '../../../../../hooks/actions';
import { useAppSelector } from '../../../../../hooks/redux';
import { IClientAssessmentModalData } from '../../../../../store/api/types/clientAssessment';
import ModalTitle from '../../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../../atoms/modal/ModalBody';
import FormLabelDropdown from '../../../../../molecules/form/FormLabelDropdown';
import FormLabelInput from '../../../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../../../molecules/form/FormWrapper';
import ModalActions from '../../../../../atoms/modal/ModalActions';
import Button from '../../../../../atoms/button/Button';
import {
  useAddAssessmentStructureMutation,
  useEditAssessmentStructureMutation,
  useLazyGetTemplatesQuery,
} from '../../../../../store/api/assessmentStructure';
import { useParams } from 'react-router';
import { useGetSkillCategoriesQuery } from '../../../../../store/api/leadership';
import { IAddAssessmentPayload } from '../../../../../store/api/types/assessmentStructure';
import _ from 'lodash';
import { useGetAllAvailableAssessmentsMutation } from '../../../../../store/api/exportFile';
import { useGetClientByIdQuery } from '../../../../../store/api/clients';

const categoryDropdown = [
  {
    keyName: 'CBI',
    keyValue: 'CBI',
  },
  {
    keyName: 'Saville Wave',
    keyValue: 'Saville Wave',
  },
  {
    keyName: 'Saville Swift',
    keyValue: 'Saville Swift',
  },
  {
    keyName: 'Hogan HDS',
    keyValue: 'Hogan HDS',
  },
  {
    keyName: 'Impact',
    keyValue: 'Impact',
  },
  {
    keyName: 'Capabilities',
    keyValue: 'Capabilities',
  },
  {
    keyName: 'Purpose',
    keyValue: 'Purpose',
  },
  {
    keyName: 'ALP',
    keyValue: 'ALP',
  },
  {
    keyName: 'Hogan HPI',
    keyValue: 'Hogan HPI',
  },
  {
    keyName: 'HCA',
    keyValue: 'HCA',
  },
  {
    keyName: 'Potential',
    keyValue: 'Potential',
  },
  {
    keyName: 'Hogan MVPI',
    keyValue: 'Hogan MVPI',
  },
  {
    keyName: 'Culture Impact',
    keyValue: 'Culture Impact',
  },
  {
    keyName: 'HCA Short Form',
    keyValue: 'HCA Short Form',
  },
];

type IInsightsType = {
  accountId: string;
  addedTs: string;
  apiKey: string;
  bulkImportAssessmentsEnabled: boolean;
  isEnabled: boolean;
  lastUpdatedTs: string;
  projectId: string;
  url: string;
};

export default function ClientAssessmentModal() {
  const { id: clientId } = useParams();

  const [tazioAssessment, setTazioAssessment] = useState(false);
  const [selectedCategory, setSelectdCategory] = useState('');

  const [addAssessment] = useAddAssessmentStructureMutation();
  const [editAssessment] = useEditAssessmentStructureMutation();

  const { data: skillCategoryData } = useGetSkillCategoriesQuery(
    clientId as string
  );

  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    clientId as string
  );

  const [getTazioAssessments, { data: availableAssessments }] =
    useGetAllAvailableAssessmentsMutation();

  const [getAssessmentType, { data: assessmentType }] =
    useLazyGetTemplatesQuery();

  const initialValues = useAppSelector(
    (state) => state.clientAssessmentModal.initialValues
  );

  const isOpen = useAppSelector((state) => state.clientAssessmentModal.isOpen);

  const modalType = useAppSelector(
    (state) => state.clientAssessmentModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeClientAssessmentModal,
  } = useActions();

  const methods = useForm<IClientAssessmentModalData>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = methods;

  const handleClose = () => {
    closeClientAssessmentModal();
  };

  const submitHandler = async (requestPayload: IAddAssessmentPayload) => {
    const modalTypeMap = {
      add: {
        fn: addAssessment,
        messageSuccess: 'Assessment added successfully',
        errorMessage: 'Error Adding Assessment',
      },
      edit: {
        fn: editAssessment,
        messageSuccess: 'Assessment updated successfully',
        errorMessage: 'Error Updating Assessment',
      },
    };

    try {
      const response = await modalTypeMap[modalType]
        .fn(requestPayload)
        .unwrap();
      if (response?.status === 'success' && response?.code === 200) {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
        if (modalType === 'add' && requestPayload.category === 'CBI') {
          window.location.href = response.data.redirectUri;
        }
      } else {
        toggleErrorSnackbar({ message: response.data });
      }
    } catch (e) {
      const error = e as {
        data?: { detail: string };
        status?: number;
        message?: string;
      };
      if (error.data?.detail)
        toggleErrorSnackbar({ message: error.data.detail });
      else if (error.message) toggleErrorSnackbar({ message: error.message });
      else
        toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
    }
  };

  const skillCategoryOnChangeHandler = (value: string) => {
    getAssessmentType({ clientId: clientId, skillCategory: value });
    setSelectdCategory(value);
  };

  useEffect(() => {
    reset(initialValues);
  }, [isOpen, reset, initialValues]);

  useEffect(() => {
    if (clientData?.length) {
      const insights = clientData[0]?.applications?.insights as IInsightsType;
      if (insights?.isEnabled) {
        getTazioAssessments({ accountID: insights.accountId || '' });
      }
    }
  }, [clientData, getTazioAssessments]);

  useEffect(() => {
    if (selectedCategory === 'Capabilities' && assessmentType?.dropdownList) {
      setValue('assessmentCategory', 'CBI');
    }
  }, [selectedCategory, assessmentType]);

  const onSubmit = (data: IClientAssessmentModalData) => {
    const requestPayload: IAddAssessmentPayload =
      modalType === 'add' && tazioAssessment === true
        ? {
            templateId: data.assessmentType,
            status: 'Draft',
            category:
              selectedCategory === 'Capabilities'
                ? 'CBI'
                : data.assessmentCategory,
            skillCategory: data.skillCategory,
            title: data.assessmentName,
            clientId: clientId || '',
            sourceAssessmentId: data.select_from_tazio?.split(' - ')[0],
            reason: 'development',
            version: '1.0',
            sections: '',
          }
        : modalType === 'add'
        ? {
            templateId: data.assessmentType,
            status: 'Draft',
            category:
              selectedCategory === 'Capabilities'
                ? 'CBI'
                : data.assessmentCategory,
            skillCategory: data.skillCategory,
            title: data.assessmentName,
            clientId: clientId || '',
          }
        : {
            id: initialValues.id,
            category: data.assessmentCategory,
            title: data.assessmentName,
          };

    if (!requestPayload.skillCategory) {
      delete requestPayload.skillCategory;
    }
    if (!requestPayload.templateId) {
      delete requestPayload.templateId;
    }
    submitHandler(requestPayload);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add a New Assessment' : 'Edit Assessment'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-client-assessment'
          onSubmit={handleSubmit(onSubmit)}
        >
          {modalType === 'add' && (
            <div
              onMouseDown={() => {
                if (clientData?.length) {
                  const insights = clientData[0]?.applications
                    ?.insights as IInsightsType;
                  if (!insights?.isEnabled) {
                    toggleErrorSnackbar({
                      message:
                        'Insights is not enabled and/or credentials are not correct',
                    });
                  }
                }
              }}
            >
              <FormLabelDropdown
                name='select_from_tazio'
                placeholder='Select from Tazio'
                label='Select from Tazio'
                onChange={(e) => {
                  clearErrors();
                  if (e.target.value) {
                    setTazioAssessment(true);
                    setValue(
                      'assessmentName',
                      `${e.target.value}`.split(' - ')[1]
                    );
                  } else {
                    setTazioAssessment(false);
                    setValue('assessmentName', '');
                  }
                }}
                dropDownItem={
                  _.uniqBy(
                    availableAssessments &&
                      availableAssessments?.availableAssessmentListDropdown,
                    'keyName'
                  ) ?? []
                }
                defaultValue={''}
              />
            </div>
          )}
          {modalType === 'add' && (
            <FormLabelDropdown
              name='skillCategory'
              placeholder='Select a skill category'
              rules={{
                required:
                  tazioAssessment === false
                    ? 'Please select a skill category'
                    : false,
              }}
              label='Skill Category'
              onChange={(e) => {
                setValue('assessmentType', '');
                setValue('assessmentCategory', '');
                skillCategoryOnChangeHandler(e.target.value as string);
              }}
              dropDownItem={
                _.uniqBy(
                  skillCategoryData?.skillCategoryListDropdownForName,
                  'keyName'
                ) ?? []
              }
              defaultValue={''}
            />
          )}
          {modalType === 'add' && (
            <FormLabelDropdown
              name='assessmentType'
              placeholder='Select an Assessment Type'
              rules={{
                required:
                  tazioAssessment === false
                    ? 'Please select an Assessment Type'
                    : false,
              }}
              label='Assessment Type'
              dropDownItem={assessmentType?.dropdownList ?? []}
              defaultValue={''}
            />
          )}
          <FormLabelDropdown
            name='assessmentCategory'
            placeholder='Select an Assessment Category'
            rules={{ required: 'Please select an Assessment Category' }}
            label='Assessment Category'
            dropDownItem={categoryDropdown.sort((a, b) =>
              a.keyValue > b.keyValue ? 1 : b.keyValue > a.keyValue ? -1 : 0
            )}
            defaultValue={''}
            disabled={selectedCategory === 'Capabilities' ? true : false}
          />
          <FormLabelInput
            name='assessmentName'
            rules={{ required: 'Please provide a Assessment Name' }}
            label='Assessment Name'
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={modalType === 'add' ? 'Create Assessment' : 'Save'}
          type='submit'
          form='add-new-client-assessment'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
