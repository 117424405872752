import React, { ReactNode } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import InputSlider from '../../atoms/slider/InputSlider';

interface IProps {
  label?: string;
  setValue: (...args: any) => void; // any type to avoid errors from React-Form
  tooltip?: string | ReactNode;
}

const FormInputSlider = ({
  name,
  defaultValue = 1,
  setValue,
  label,
  tooltip,
  ...extraProps
}: IProps & UseControllerProps) => {
  const { control } = useFormContext();

  const formControlHandler = (value: number) => {
    setValue(name, value);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...extraProps}
      render={({ field }) => (
        <InputSlider
          formControl={formControlHandler}
          value={field.value}
          label={label}
          tooltip={tooltip}
        />
      )}
    />
  );
};

export default FormInputSlider;
