import React from 'react';
import LabelInput from '../../atoms/input/LabelInput';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material';

interface IProps {
  label: string;
  extraLabelInputProps?: TextFieldProps;
  tooltip?: string;
}

const StyledIconButton = styled(IconButton)(() => ({
  display: 'inline-flex',
  flexDirection: 'row',
  margin: '-50px 5px 0px 0px',
  float: 'right',
  background: '#fff',
}));

/* --
Created this molecule to handle the toggle visibility of API key and Passsword fields.
Avoided making changes in FormLabelInput as it is used at a lot of places.
-- */

const FormMaskedInput = ({
  name,
  defaultValue = '',
  label,
  tooltip,
  extraLabelInputProps,
  ...extraProps
}: IProps & UseControllerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // State to manage visibility of passowrd and API key values on form input
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...extraProps}
      render={({ field }) => (
        <div>
          <LabelInput
            inputProps={{ ...field }}
            error={!!errors[name]}
            helperText={errors[name]?.message as unknown as string}
            label={label}
            tooltip={tooltip}
            type={showPassword ? 'text' : 'password'}
            disabled={extraLabelInputProps?.disabled}
            {...extraLabelInputProps}
          />
          <StyledIconButton
            aria-label='toggle password visibility'
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge='end'
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </StyledIconButton>
        </div>
      )}
    />
  );
};

export default FormMaskedInput;
