/* eslint-disable max-len */
import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import RoleLibraryHeader from '../organisms/headers/RoleLibraryHeader';
import RoleLibraryTable from '../organisms/tables/RoleLibraryTable';
import RoleModal from '../organisms/modals/RoleModal';
import ImportRoleModal from '../organisms/modals/ImportRoleModal';
import { MRT_TableInstance } from 'material-react-table';
import { useGetRoleProfilesQuery } from '../store/api/leadership';
import { useAppSelector } from '../hooks/redux';

const RoleLibrary = () => {
  const { data: { data } = {}, isLoading } = useGetRoleProfilesQuery('Master');

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const isImportModalOpen = useAppSelector(
    (state) => state.importRoleModal.isOpen
  );

  const [reset, setReset] = React.useState(0); // needed for reset function

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
    setReset((value) => value + 1);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 99px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <RoleModal />
      {isImportModalOpen && <ImportRoleModal />}
      <RoleLibraryHeader
        qtyOfCompanies={data?.length}
        onResetButtonClick={onResetButtonClick}
      />
      <Container
        sx={{
          marginBottom: '50px',
        }}
      >
        <RoleLibraryTable
          key={reset} // needed for rest function
          isLoading={isLoading}
          data={data}
          tableInstanceRef={tableInstanceRef}
        />
      </Container>
    </Box>
  );
};
export default RoleLibrary;
