import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import { useActions } from '../../../../hooks/actions';
import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import { SelectOptions } from '../../../../commons/types';
import { IGetClientStatementData } from '../../../../store/api/types/leadership';
import {
  useDeleteClientStatementMutation,
  useLazyValidateStatementByIdQuery,
} from '../../../../store/api/leadership';
import { routes } from '../../../../routes/routes';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';

interface IProps {
  data?: IGetClientStatementData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const CompanyFunctionalImpactTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [deleteStatement] = useDeleteClientStatementMutation();
  const [validateStatement] = useLazyValidateStatementByIdQuery();

  const impactModuleDropdown = data
    ?.map((item) => ({
      text: item?.impactModuleName || '',
      value: item?.impactModuleName || '',
    }))
    .filter((item) => Boolean(item?.value));

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    openEditFunctionalImpactSLModal,
    setStatementDetailsInfo,
    openAddToRoleModal,
    openStatementLibraryWarningModal,
  } = useActions();

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IGetClientStatementData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (statementId: string) => {
    try {
      const payload = {
        clientId: params.id ? params.id : '',
        statementId: statementId,
      };
      const { data: validateData } = await validateStatement(payload).unwrap();

      if (validateData && validateData.length) {
        openStatementLibraryWarningModal(validateData);
      } else {
        deleteStatement(statementId)
          .unwrap()
          .then(() =>
            toggleSuccessSnackbar({ message: 'Statement Deleted Successfully' })
          )
          .catch(() => toggleErrorSnackbar({}));
        setAnchorEl(null);
      }
    } catch (e) {
      toggleErrorSnackbar({});
    }
  };

  const handleApplyToRole = (skill: IGetClientStatementData) => {
    openAddToRoleModal({
      skill: skill.id || '',
      clientId: params.id,
      statementUUID: skill.statementLibraryUUID || '',
      skillGroup: skill.skillGroup || '',
    });
    setAnchorEl(null);
  };

  const handleEdit = (rowData: IGetClientStatementData) => {
    openEditFunctionalImpactSLModal({
      name: rowData.name,
      id: rowData.id,
      leadership_acumen: rowData.expertiseCategoryCode,
      impactModuleCode: rowData.impactModuleCode || '',
      accelerator: '',
      accelerator_name: '',
      custom_response_options: '',
      description: rowData.description,
      function: '',
      function_name: '',
      statement_id: '',
      leadership_acumen_name: '',
      module_number: '',
      scale: '',
      skill_category_id: '',
      skill_category_name: '',
      skill_group: '',
      skill_group_name: '',
      statement_in_module_number: '',
      sub_function: '',
      subgroupID: '',
      sub_function_name: '',
      status: '',
    });
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<IGetClientStatementData>[]>(
    () => [
      {
        header: 'Statement Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
      {
        header: 'Statement ID',
        accessorKey: 'statementCode',
        filterFn: 'includesString',
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
      },
      {
        header: 'Impact Module',
        accessorKey: 'impactModuleName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={_.uniqBy(impactModuleDropdown, 'value') ?? []}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
    ],
    [impactModuleDropdown]
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => {
        const rowData = row.original as IGetClientStatementData;
        return (
          <>
            <IconButton
              id={cell.id}
              onClick={handleOpen}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl?.id === cell.id}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEdit(rowData)}>Edit</MenuItem>
              {rowData.skillGroup !== 'Purpose' && (
                <MenuItem onClick={() => handleApplyToRole(rowData)}>
                  Apply To Role
                </MenuItem>
              )}
              <MenuItem onClick={() => handleDelete(rowData.id || '')}>
                Delete
              </MenuItem>
            </Menu>
          </>
        );
      }}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          setStatementDetailsInfo(row.original as IGetClientStatementData);
          const selection = window.getSelection()!.toString();
          if (!cell.id.includes('mrt-row-actions') && !selection) {
            navigate(
              `${routes.company.url}${params.id as string}${
                routes.company.statements.url
              }/${(row.original as IGetClientStatementData).id}`
            );
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default CompanyFunctionalImpactTable;
