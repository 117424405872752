import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { ReportStructureDataType } from '../../../../store/api/types/reportStructure';

interface IProps {
  data?: ReportStructureDataType[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const ReportsTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const columns = useMemo<MRT_ColumnDef<ReportStructureDataType>[]>(
    () => [
      {
        header: 'Report Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default ReportsTable;
