import { Dispatch, SetStateAction, useRef } from 'react';
import { IProjectData } from '../../../store/api/types/projects';
import { ITabContentProps } from '../../../commons/types';
import TabContent from '../../../atoms/tab/TabContent';
import { Box, styled } from '@mui/material';
import Title from '../../../atoms/title/Title';
import ResetButton from '../../../atoms/button/ResetButton';
import ClientProjectsTable from '../../../organisms/tables/Company/Projects/ClientProjectsTable';
import { MRT_TableInstance } from 'material-react-table';

interface IProps {
  data: IProjectData[] | [];
  isLoading: boolean;
  setTabValue: Dispatch<SetStateAction<number>>;
  filteredState: any[];
  setFilteredState: Dispatch<SetStateAction<Array<never>>>;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
}));

export const ProjectsTab = ({
  index,
  value,
  data,
  isLoading,
  setTabValue,
  filteredState,
  setFilteredState,
  tableInstanceRef,
}: ITabContentProps & IProps) => {
  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <Title
            text={'Projects'}
            sx={{
              fontSize: '28px',
            }}
          />
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <Title
            text={`${
              filteredState?.length ? filteredState?.length : '0'
            } total projects`}
            sx={{
              fontSize: '20px',
            }}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <ClientProjectsTable
        data={data}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
        filteredState={filteredState}
        setFilteredState={setFilteredState}
      />
    </TabContent>
  );
};
