import React, { useRef } from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import Button from '../../../../atoms/button/Button';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import AddRoleToImpactGroupTable from '../../../tables/Company/ImpactGroups/AddRoleToImpactGroupTable';
import { MRT_TableInstance } from 'material-react-table';
import { Box, styled } from '@mui/material';
import { IFilteredDataProps, SelectOptions } from '../../../../commons/types';
import {
  useGetFunctionsListQuery,
  useGetLevelsListQuery,
  useAssignImpactGroupToRoleMutation,
  useGetLeaderRolesQuery,
  useAssignRoleToImpactGroupMutation,
  useGetLeaderImpactGroupByIdQuery,
} from '../../../../store/api/leadership';
import { IGetRolesData } from '../../../../store/api/types/leadership';

const StyledLabel = styled('span')({
  font: 'Avenir',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
});

export default function AddRolesToImpactGroupModal() {
  const {
    openAssignedRolesAnotherImpactGroupModal,
    closeAddRoleToImpactGroupModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
  } = useActions();

  const { id: clientId, groupId } = useParams();

  const [rowSelection, setRowSelection] = React.useState<
    Array<IGetRolesData | null>
  >([]);

  const [filteredData, setFilteredData] = React.useState<IFilteredDataProps>({
    filteredFunctions: [],
    filteredLevels: [],
  });

  const [filteredRoles, setFilteredRoles] = React.useState<
    Array<IGetRolesData>
  >([]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const { data: functionData } = useGetFunctionsListQuery(clientId as string);
  const { data: levelData } = useGetLevelsListQuery(clientId as string);
  const { data: { data } = {}, isLoading } = useGetLeaderRolesQuery({
    clientId: clientId || '',
  });

  const { data: { data: impactGroupDetail } = {} } =
    useGetLeaderImpactGroupByIdQuery((groupId as string) || '');

  const { data: { data: impactGroupRoles } = {} } = useGetLeaderRolesQuery({
    impactGroupId: (groupId as string) || '',
    clientId: clientId || '',
  });

  const [assignedImpactGroupToRole] = useAssignImpactGroupToRoleMutation();
  const [assignRoleToImpactGroup] = useAssignRoleToImpactGroupMutation();

  React.useEffect(() => {
    if (data && impactGroupDetail) {
      const filteredRoles = [...data];
      if (impactGroupRoles)
        impactGroupRoles.forEach((item) => {
          const idx = filteredRoles.findIndex((role) => role.id === item.id);
          if (idx !== -1) filteredRoles.splice(idx, 1);
        });
      if (impactGroupDetail[0].roleProfileList)
        impactGroupDetail[0].roleProfileList.forEach((value) => {
          const idx = filteredRoles.findIndex((role) => role.id === value);
          if (idx !== -1) filteredRoles.splice(idx, 1);
        });
      setFilteredRoles(filteredRoles);
    }
  }, [data, impactGroupDetail, impactGroupRoles]);

  const isOpen = useAppSelector(
    (state) => state.addRoleToImpactGroupModal.isOpen
  );

  const handleSubmit = () => {
    if (rowSelection.find((item) => item?.impactGroupAssigned)) {
      handleClose();
      openAssignedRolesAnotherImpactGroupModal(rowSelection);
    } else {
      Promise.all(
        rowSelection.map(async (role) => {
          const response = await assignedImpactGroupToRole({
            id: role?.id || '',
            payload: {
              impactGroupAssigned: groupId || '',
            },
          });
          return response;
        })
      )
        .then(() => {
          assignRoleToImpactGroup({
            id: groupId || '',
            payload: {
              roleProfileList: rowSelection.map((role) => role?.id || ''),
            },
          }).then(() => {
            toggleSuccessSnackbar({ message: 'Roles Added Successfully' });
            handleClose();
          });
        })
        .catch(() => toggleErrorSnackbar({ message: 'Error Adding Roles' }));
    }
  };

  const handleClose = () => {
    closeAddRoleToImpactGroupModal();
  };

  React.useEffect(() => {
    if (
      data &&
      data.length &&
      functionData?.dropdownData.length &&
      levelData?.dropdownData.length
    ) {
      const filteredFunctions: Array<SelectOptions | null> =
        functionData.dropdownData.filter(
          (item) => !!data.find((role) => role.functionName === item.value)
        ) || [];
      const filteredLevels: Array<SelectOptions | null> =
        levelData.dropdownData.filter(
          (item) => !!data.find((role) => role.functionName === item.value)
        ) || [];
      setFilteredData({ filteredFunctions, filteredLevels });
    }
  }, [data, functionData, levelData]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            minWidth: '80%',
            overflowX: 'auto',
          },
        },
      }}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        <Box>{'Add Roles to Impact Group'}</Box>
        <StyledLabel>{`${rowSelection.length} roles selected`}</StyledLabel>
      </ModalTitle>
      <ModalBody>
        <AddRoleToImpactGroupTable
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
          data={filteredRoles}
          recordSelection={setRowSelection}
          levelList={filteredData.filteredLevels as SelectOptions[]}
          functionList={filteredData.filteredFunctions as SelectOptions[]}
        />
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          onClick={handleSubmit}
          form='add-new-role'
          disabled={Boolean(!rowSelection.length)}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
