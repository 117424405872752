import { createSlice } from '@reduxjs/toolkit';

interface IAddRoleToImpactGroupModalProps {
  isOpen: boolean;
  modalType: 'add';
}

const initialState: IAddRoleToImpactGroupModalProps = {
  isOpen: false,
  modalType: 'add',
};

const addRoleToImpactGroupModalSlice = createSlice({
  name: 'addRoleToImpactGroupModal',
  initialState,
  reducers: {
    openAddRoleToImpactGroupModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    closeAddRoleToImpactGroupModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const addRoleToImpactGroupModalActions =
  addRoleToImpactGroupModalSlice.actions;
export const addRoleToImpactGroupModalReducer =
  addRoleToImpactGroupModalSlice.reducer;
