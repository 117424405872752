import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import { routes } from '../../../../routes/routes';
import PageHeader from '../../../../atoms/header/PageHeader';
import ResetButton from '../../../../atoms/button/ResetButton';
import Button from '../../../../atoms/button/Button';
import { useActions } from '../../../../hooks/actions';
import { useSyncNavigatorRoleMutation } from '../../../../store/api/core';
import { useParams } from 'react-router';
import ImportIcon from '../../../../assets/icons/Import.svg';

interface IProps {
  qtyOfUsers?: number;
  onResetButtonClick: () => void;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  justifyContent: 'end',
  alignItems: 'center',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

const RolesHeader = ({ qtyOfUsers, onResetButtonClick }: IProps) => {
  const { id } = useParams();
  const {
    openImportRoleModal,
    openDownloadCompanyRolesModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
    toggleInfoSnackbar,
  } = useActions();
  const [syncNavigatorRole, { isLoading }] = useSyncNavigatorRoleMutation();

  const { openAddRoleModal } = useActions();

  const handleSyncNavigator = async () => {
    try {
      const { status } = await syncNavigatorRole(id as string).unwrap();
      if (status === 'success') {
        toggleSuccessSnackbar({
          message: 'Successfully updated the role information',
        });
      } else {
        toggleErrorSnackbar({
          message: 'EightFold API credentials are blank or invalid',
        });
      }
    } catch (error) {
      toggleErrorSnackbar({
        message: 'EightFold API credentials are blank or invalid',
      });
    }
  };

  if (isLoading) {
    toggleInfoSnackbar({
      message: 'Updating the system with new role information',
    });
  }

  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text={routes.company.roles.name} />
        <StyledSubHeaderWrapper>
          <SubTitle text={`${qtyOfUsers ? qtyOfUsers : '0'} total roles`} />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <StyledHeaderActionWrapper>
        <Button
          btnType={'PRIMARY'}
          text={'Sync Navigator'}
          onClick={handleSyncNavigator}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Import data'}
          onClick={() => openImportRoleModal()}
          startIcon={
            <ButtonIcon
              src={ImportIcon}
              alt='Import CSV'
            />
          }
        />
        <Button
          btnType={'SECONDARY'}
          text={'Download data'}
          onClick={() => openDownloadCompanyRolesModal()}
          startIcon={
            <ButtonIcon
              src={ImportIcon}
              alt='Import CSV'
            />
          }
        />
        <Button
          btnType={'PRIMARY'}
          text={'Add new role'}
          onClick={() => openAddRoleModal()}
        />
      </StyledHeaderActionWrapper>
    </PageHeader>
  );
};

export default RolesHeader;
