import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { COMPANY_USERS } from './constants';
import { downloadFile, saveFileNameAsCSV } from './helpers';
import {
  IGetAccountIdPayload,
  IGetAccountIdResponse,
  IGetProjectsResponse,
  IGetProjectsPayload,
} from './types';
import { IGetAssessmentsResponse, sendTazioInfoResponse } from './types/tazio';
import { IExcelResponse } from './types/roleskills';

export const tazioApi = createApi({
  reducerPath: 'tazio/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_KEY}tazio/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Assessment'],
  endpoints: (builder) => ({
    getAvailableAssessments: builder.query<IGetAssessmentsResponse, void>({
      query: () => ({
        url: 'GetAvailableAssessments/',
        method: 'POST',
      }),
      transformResponse: (response: IGetAssessmentsResponse) => ({
        status: response.status,
        data: response.data,
        assessmentsDropdown: response.data?.map((el) => ({
          text: `${el.assessmentID}-${el.reference} - ${el.title}` || '',
          value: el.assessmentID || '',
        })),
        assessmentsList: response.data?.map((el) => ({
          keyName: `${el.assessmentID} - ${el.reference} - ${el.title}` || '',
          keyValue: el.assessmentID || '',
        })),
      }),
    }),

    // downloads
    downloadCompanyUsersCustomFields: builder.query<IExcelResponse, string>({
      query: (id) => {
        return {
          url: `DownloadCustomFields/${id}`,
          responseHandler: async (response: any) => {
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsCSV(
                  COMPANY_USERS.Heidrick_ATSNAME_EMPLOYEE_CUSTOMFIELD_IMPORT
                )
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const { useGetAvailableAssessmentsQuery } = tazioApi;

// downloads
export const { useLazyDownloadCompanyUsersCustomFieldsQuery } = tazioApi;
