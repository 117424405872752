import {
  Drawer,
  IconButton,
  styled,
  Box,
  Collapse,
  MenuList,
  MenuItem,
  Menu,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import { ExpandLess, ExpandMore, Close, Dehaze } from '@mui/icons-material';
import accountLogo from '../../assets/icons/account-light.svg';
import chevronDown from '../../assets/icons/chevron-down.svg';
import { IMeData } from '../../store/api/v2/types/auth';
import { userTypeHandler } from '../../utils/helpers';
import { restrictedHandler } from '../../rbac/Restricted';

export interface HDNavigationTypes {
  darkLogo?: string | undefined;
  lightLogo?: string | undefined;
  navItems: any[];
  myAccount: any[];
  userData?: IMeData;
  permissionsArr?: string[];
  onNavigationClick: (arg0: string) => void;
}

const NEW_APPLICATION_URL = process.env.REACT_APP_NEW_ADMINPORTAL_DOMAIN || '';

const HDNavigation = (props: HDNavigationTypes) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkTheme = useTheme().palette.mode === 'dark';

  // Access to new App handler
  const newAppActionHandler = () => {
    window.open(NEW_APPLICATION_URL, '_blank');
  };

  const SCAccountIcon = styled('img')(() => ({
    width: '20px',
    height: '20px',
  }));

  const SCDownIcon = styled('img')(() => ({
    width: '20px',
    height: '20px',
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));

  const DeskNavigationWrap = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  }));

  const DeskMenuList = styled(MenuList)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: '24px',
  }));

  const DeskMenuItemBar = styled(MenuItem)(() => ({
    borderLeft: '1px solid',
    height: '38px',
    pointerEvents: 'none',
    borderLeftColor: 'rgba(100, 106, 124, 0.2)',
    paddingLeft: 0,
    paddingRight: 0,
  }));

  const DeskMenuItem = styled(MenuItem)(() => ({
    marginLeft: '32px',
    height: '38px',
    padding: 0,
    borderBottom: '3px solid transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }));

  const StyledMenuItem = styled(MenuItem)((theme) => ({
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
    padding: 0,
  }));

  const StyledAccountMenuItem = styled(MenuItem)(() => ({
    padding: '15px 20px 15px 20px',
  }));

  const StyledAccountIcon = styled(IconButton)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #CCCCCC',
    borderRadius: '40px',
    padding: '11px',
    gap: '6px',
  }));

  const StyledUserName = styled(Box)(() => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    fontSize: '16px',
    fontFamily: 'Aventa',
  }));

  const activeOpt = {
    borderColor: isDarkTheme ? '#2cdada' : '#18768c',
  };

  const DrawerComponent = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    const StyledNavBarWrapBox = styled(Box)((theme) => ({
      padding: '0 20px',
    }));

    const StyledMenuListCollapsed = styled(MenuList)(() => ({
      width: '100%',
      padding: '0 25px',
    }));

    const StyledIconButtonMobile = styled(IconButton)(() => ({
      padding: '5%',
    }));

    const MobileLogoWrap = styled(Box)(() => ({
      margin: '15px 0',
      cursor: 'pointer',
    }));

    const MobileMenuIconWrap = styled(Box)(() => ({
      position: 'absolute',
      right: '20px',
    }));

    return (
      <>
        <MobileMenuIconWrap>
          <Drawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            anchor={'left'}
          >
            <DrawerHeader>
              <MobileLogoWrap>
                <img src={isDarkTheme ? props?.darkLogo : props?.lightLogo} />
              </MobileLogoWrap>
              <Box>
                <IconButton
                  color='inherit'
                  onClick={() => setOpenDrawer(false)}
                >
                  <Close fontSize='large' />
                </IconButton>
              </Box>
            </DrawerHeader>
            <Divider />

            <StyledNavBarWrapBox>
              <MenuList>
                {props.navItems.map((element: any, index: number) => {
                  return (
                    element.itemName && (
                      <>
                        <StyledMenuItem
                          key={index}
                          onClick={() => onNavClick(element.url)}
                        >
                          {element.itemName}
                        </StyledMenuItem>
                        <Divider />
                      </>
                    )
                  );
                })}
                <StyledMenuItem onClick={() => handleClick()}>
                  <p style={{ width: '90%' }}>My Account</p>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </StyledMenuItem>

                <Collapse
                  in={open}
                  timeout='auto'
                  unmountOnExit
                >
                  <StyledMenuListCollapsed disablePadding>
                    {props.myAccount.map((element: any, index: number) => {
                      return (
                        element.itemName && (
                          <>
                            <StyledAccountMenuItem
                              key={index}
                              onClick={() => onNavClick(element.url)}
                            >
                              {element.itemName}
                            </StyledAccountMenuItem>
                            <Divider />
                          </>
                        )
                      );
                    })}
                    {/* check if user can access new app link */}
                    {restrictedHandler(
                      'new_app_access.read',
                      props?.permissionsArr
                    ) && (
                      <StyledAccountMenuItem onClick={newAppActionHandler}>
                        {'Access new application'}
                      </StyledAccountMenuItem>
                    )}
                  </StyledMenuListCollapsed>
                </Collapse>
                <Divider />
              </MenuList>
            </StyledNavBarWrapBox>
          </Drawer>
          <StyledIconButtonMobile
            color='inherit'
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <Dehaze fontSize='large' />
          </StyledIconButtonMobile>
        </MobileMenuIconWrap>
      </>
    );
  };

  const AccountMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <StyledAccountIcon
          aria-controls='account-menu'
          aria-haspopup='true'
          color='inherit'
          onClick={handleClick}
        >
          <SCAccountIcon
            src={accountLogo}
            alt='account logo'
          />
          <StyledUserName>{`${props.userData?.firstName} ${props.userData?.lastName}`}</StyledUserName>
          <SCDownIcon
            src={chevronDown}
            alt={'open'}
          />
        </StyledAccountIcon>

        <Menu
          id='account-menu'
          anchorEl={anchorEl}
          keepMounted={false}
          open={open}
          onClose={handleClose}
        >
          {props.myAccount.map((element: any, index: number) => {
            return (
              element.itemName && (
                <StyledAccountMenuItem
                  key={index}
                  onClick={() => onNavClick(element.url)}
                >
                  {element.itemName}
                </StyledAccountMenuItem>
              )
            );
          })}

          {/* check if user can access new app link */}
          {restrictedHandler('new_app_access.read', props?.permissionsArr) && (
            <StyledAccountMenuItem onClick={newAppActionHandler}>
              {'Access new application'}
            </StyledAccountMenuItem>
          )}
        </Menu>
      </>
    );
  };

  function onNavClick(url: string) {
    props.onNavigationClick(url);
  }

  return (
    <>
      {isMobile ? (
        <DrawerComponent />
      ) : (
        <DeskNavigationWrap>
          <DeskMenuList>
            <DeskMenuItemBar></DeskMenuItemBar>
            {props.navItems.map((element: any, index: number) => {
              return (
                element.itemName && (
                  <DeskMenuItem
                    key={index}
                    sx={element.className && activeOpt}
                    onClick={() => onNavClick(element.url)}
                  >
                    {element.itemName}
                  </DeskMenuItem>
                )
              );
            })}
          </DeskMenuList>
          <AccountMenu />
        </DeskNavigationWrap>
      )}
    </>
  );
};

export default HDNavigation;
