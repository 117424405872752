import HDButton from '../../hd-ui-kit/HDButton';
import { HDButtonTypes } from '../../hd-ui-kit/HDButton/HDButton';
import { styled } from '@mui/material/styles';

const StyledHyperLinkButton = styled(HDButton)({
  '&.MuiButton-root': {
    display: 'flex',
    justifyContent: 'start',
    border: 'none',
    fontWeight: 400,
    fontSize: '16px',
  },
  '&.Mui-focusVisible': {
    borderColor: 'transparent',
  },
  ':focus': {
    borderColor: 'transparent',
  },
  padding: '0',
  textDecoration: 'underline !important',
});

const HyperLinkButton = ({ text, ...extraProps }: HDButtonTypes) => {
  return (
    <StyledHyperLinkButton
      size='small'
      variant='text'
      disableRipple={true}
      text={text}
      {...extraProps}
    />
  );
};

export default HyperLinkButton;
