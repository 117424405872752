import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/icons/close.svg';
import { styled } from '@mui/material';

type IProps = Omit<TooltipProps, 'children'>;

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  marginLeft: '6px',
}));

export default function HpTooltip({ ...extraProps }: IProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 320,
      padding: '16px',
      font: 'Aventa',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20.09px',
      letterSpacing: '2%',
    },
  });

  const StyledImg = styled('img')(() => ({
    position: 'relative',
    zIndex: 1600,
    height: '17px',
    marginLeft: '1px',
    marginRight: '5px',
  }));

  return (
    <div>
      <CustomWidthTooltip
        placement='bottom'
        onClose={handleTooltipOpen}
        disableHoverListener
        open={open}
        {...extraProps}
      >
        <StyledIconButton onClick={handleTooltipOpen}>
          {open ? (
            <StyledImg src={closeIcon} />
          ) : (
            <HelpIcon fontSize={'small'} />
          )}
        </StyledIconButton>
      </CustomWidthTooltip>
    </div>
  );
}
