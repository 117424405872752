import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ICurrentPPVersionsResponse,
  IGetUserResponse,
  IMeResponse,
  IUserData,
  IUserErrorResponse,
  IUserResponse,
  IUsersResponse,
} from './types/auth';
import { IClientConfigResponse } from '../types';

export const authApi = createApi({
  reducerPath: 'auth/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}auth/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        ?.accessToken?.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'users',
    'client users',
    'identityGroups',
    'identityApps',
    'GetMe',
    'GetMeJwt',
  ],
  endpoints: (builder) => ({
    // me
    getMe: builder.query<IMeResponse, void>({
      query: () => 'me',
      transformResponse: (response: IMeResponse) => {
        return {
          ...response,
          permissionsArr: response.data.permissions.map(
            (el) => el.resourceName + '/' + el.actionType
          ),
        };
      },
      providesTags: ['GetMe'],
    }),
    getMeJwtToken: builder.query<IMeResponse, void>({
      query: () => 'me?pac-applications=PLATFORM_API&generateAuthZ=true',
      transformResponse: (response: IMeResponse) => {
        if (response?.data?.jwt?.length) {
          localStorage.setItem('authzToken', response.data.jwt);
        }
        return {
          ...response,
          permissionsArr: response.data.permissions.map(
            (el) => el.resourceName + '/' + el.actionType
          ),
        };
      },
      providesTags: ['GetMeJwt'],
    }),
    setMe: builder.mutation<IMeResponse, unknown>({
      query: (payload) => ({
        url: 'me',
        method: 'POST',
        body: payload,
      }),
    }),
    putMe: builder.mutation<unknown, unknown>({
      query: (payload) => ({
        url: 'me',
        method: 'PUT',
        body: payload,
      }),
    }),

    // users
    createUser: builder.mutation<IUserResponse, IUserData>({
      query: (statement) => ({
        url: 'users',
        method: 'POST',
        body: statement,
      }),
      transformErrorResponse: (response: IUserErrorResponse) => {
        if (response.data.code === 406)
          return Object.values(response.data.message)[0][0];
        return response;
      },
      invalidatesTags: ['users'],
    }),
    getAdminUsers: builder.query<IUsersResponse, void>({
      query: () => 'users?roles=Heidrick Admins',
      transformResponse: (response: IUsersResponse) => {
        return {
          status: response.status,
          data: response.data,
          usersDropdown:
            response.data &&
            response.data.map((el) => ({
              keyName: el.firstName + ' ' + el.lastName || '',
              keyValue: el.id || '',
            })),
        };
      },
      providesTags: ['users'],
    }),
    getClientUser: builder.query<unknown, unknown>({
      query: () => 'users?roles=Client Users',
      providesTags: ['client users'],
    }),
    editUser: builder.mutation<IUserResponse, IUserData>({
      query: (payload) => ({
        url: `users/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      transformErrorResponse: (response: IUserErrorResponse) => {
        return response.data.message;
      },
      invalidatesTags: ['users'],
    }),
    deleteUser: builder.mutation<IUserResponse, IUserData | string>({
      query: (payload) => {
        if (typeof payload === 'string')
          return {
            url: `users/${payload}`,
            method: 'DELETE',
          };

        return {
          url: `users/${payload.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['users'],
    }),
    // auth user service to get user details by email ID
    getUsers: builder.query<IGetUserResponse, string | undefined>({
      query: (email) => `users?userName=${email}`,
    }),
    // privacy policy
    getPrivacyPolicyVersions: builder.query<ICurrentPPVersionsResponse, void>({
      query: () => 'privacy-policy-versions',
    }),
    // Get Identity Groups
    getClientIdentityGroupNames: builder.query<IClientConfigResponse, string>({
      query: (id) => `identity-groups?clientId=${id}&excludeGroups=Everyone`,
      providesTags: ['identityGroups'],
      transformResponse: (response: IClientConfigResponse) => {
        return {
          status: response.status,
          data: response.data,
          clientGroupNameDropdown: response?.data?.map((el) => ({
            keyName: el.label || '',
            keyValue: el.id || '',
          })),
        };
      },
    }),
    // Identity Apps
    getClientIdentityAppIDForAPIs: builder.query<IClientConfigResponse, string>(
      {
        query: (id) => `identity-apps?/?clientId=${id}`,
        providesTags: ['identityApps'],
        transformResponse: (response: IClientConfigResponse) => {
          return {
            status: response.status,
            data: response.data,
            oktaAppIDForAPIsDropdown: response?.data?.map((el) => ({
              keyName: el.label || '',
              keyValue: el.id || '',
            })),
          };
        },
      }
    ),
    // Get Assessors dropdown users
    getAssessorsUsers: builder.query<IUsersResponse, string>({
      query: (id) =>
        `users?clientId=${id}&resourceName=WORKFLOW_UI/CONDUCT_CBI_INTERVIEW&actionType=ALLOW`,
      transformResponse: (response: IUsersResponse) => {
        return {
          status: response.status,
          data: response.data,
          usersDropdown:
            response.data &&
            response.data.map((el) => ({
              keyName: el.firstName + ' ' + el.lastName || '',
              keyValue: el.id || '',
            })),
        };
      },
      providesTags: ['users'],
    }),
  }),
});

// me
export const {
  useGetMeQuery,
  useLazyGetMeQuery,
  useLazyGetMeJwtTokenQuery,
  useSetMeMutation,
  usePutMeMutation,
} = authApi;

export const useGetMeQueryState = authApi.endpoints?.getMe.useQueryState;

// users
export const {
  useCreateUserMutation,
  useGetAdminUsersQuery,
  useEditUserMutation,
  useDeleteUserMutation,
  useLazyGetUsersQuery,
  useGetAssessorsUsersQuery,
} = authApi;

// privacy policy
export const { useLazyGetPrivacyPolicyVersionsQuery } = authApi;

// Identity Apps and Groups
export const {
  useGetClientIdentityGroupNamesQuery,
  useGetClientIdentityAppIDForAPIsQuery,
} = authApi;

export const authApiInvalidateTags = authApi.util.invalidateTags;
