import { useGetMeQuery } from '../store/api/v2/auth';
import { userTypeHandler } from '../utils/helpers';

type IProps = {
  children: JSX.Element;
};

const ProtectRoute = ({ children }: IProps) => {
  const { data: userData } = useGetMeQuery();

  const myPersonas = userData?.data?.myPersonas; // Get current users usertype

  if (userTypeHandler(myPersonas) === 'Global') {
    return children;
  } else {
    return null;
  }
};

export default ProtectRoute;
