import React, { useState, useEffect } from 'react';
import Modal from '../../atoms/modal/Modal';
import ModalTitle from '../../atoms/modal/ModalTitle';
import ModalBody from '../../atoms/modal/ModalBody';
import ModalActions from '../../atoms/modal/ModalActions';
import Button from '../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import FormLabelInput from '../../molecules/form/FormLabelInput';
import FormWrapper from '../../molecules/form/FormWrapper';
import { useActions } from '../../hooks/actions';
import { useSetClientMutation } from '../../store/api/clients';

interface IProps {
  open: boolean;
  close: () => void;
}

interface IFormData {
  name: string;
  website_url: string;
}

const CompaniesModal = ({ open, close }: IProps) => {
  const methods = useForm<IFormData>();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  const [loading, setLoading] = useState(false);

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const [addClient] = useSetClientMutation();

  const onSubmit = async (formData: IFormData) => {
    setLoading(true);
    addClient(formData)
      .unwrap()
      .then(() => {
        toggleSuccessSnackbar({ message: 'Company Added Successfully' });
        close();
        setLoading(false);
      })
      .catch((e) => {
        const error = e as { status: number; data: { detail: string } };
        toggleErrorSnackbar({ message: error.data.detail });
        close();
        setLoading(false);
      });
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset, close]);

  return (
    <Modal
      open={open}
      onClose={close}
    >
      <ModalTitle>{'Add a new company'}</ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-company'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='name'
            rules={{ required: 'The company name is required' }}
            label='Company Name'
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='add-new-company'
          disabled={loading || !!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={close}
        />
      </ModalActions>
    </Modal>
  );
};

export default CompaniesModal;
