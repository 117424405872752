import React from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';

interface IProps {
  text: string;
}

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '41px',
  lineHeight: '54px',
}));

const Title = ({ text, ...extraProps }: IProps & TypographyProps) => {
  return (
    <StyledTypography
      variant={'h1'}
      {...extraProps}
    >
      {text}
    </StyledTypography>
  );
};

export default Title;
