import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IEditRoleSkillData,
  IRoleImpactStatementProps,
} from '../../commons/types';
import { IRoleSkillData } from '../api/types/leadership';

interface IFunctionalImpactRLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: IRoleImpactStatementProps;
  filteredSubFunctionList: [];
}

const initialState: IFunctionalImpactRLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    impact_area: '',
    function: '',
    leadership_acumen: '',
    description: '',
    subfunction: '',
    proficiency: 1,
    statementName: null,
  },
  filteredSubFunctionList: [],
};

const FunctionalImpactRLModalSlice = createSlice({
  name: 'FunctionalImpactRLModal',
  initialState,
  reducers: {
    closeFunctionalImpactRLModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openFunctionalImpactRLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    setFunctionalImpactRLSubFunctionList: (state, action) => {
      const { list, value } = action.payload;
      state.filteredSubFunctionList = list?.filter(
        (el: IRoleImpactStatementProps) => el.function === value
      );
    },
    openEditFunctionalImpactRLModal: (
      state,
      action: PayloadAction<IRoleSkillData>
    ) => {
      (state.isOpen = true),
        (state.modalType = 'edit'),
        (state.initialValues = {
          id: action.payload.id || '',
          name: action.payload.name || '',
          impact_area: action.payload.impact_area || '',
          function: action.payload.function || '',
          leadership_acumen: action.payload.leadership_acumen || '',
          description: action.payload.description || '',
          subfunction: action.payload.subfunction || '',
          proficiency: action.payload.proficiency || 1,
          statementName: null,
        });
    },
  },
});

export const functionalImpactRLModalActions =
  FunctionalImpactRLModalSlice.actions;
export const functionalImpactRLModalReducer =
  FunctionalImpactRLModalSlice.reducer;
