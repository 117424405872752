import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { Box, BoxProps } from '@mui/system';
import { styled } from '@mui/material/styles';

interface IProps extends CheckboxProps {
  label?: string;
  error?: boolean;
}
interface IStyledBoxProps extends BoxProps {
  error?: boolean;
}

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'error',
})<IProps>(({ error, disabled, label }) => ({
  '&:hover': {
    backgroundColor: label ? '#F8F8F8' : '#FFFFFF',
    color: '#F8F8F8',
    '& .HpCheckBox-root': {
      border: '2px solid #18768C',
    },
  },
  '&:active': {
    backgroundColor: '#F8F8F8',
    '& .HpCheckBox-root': {
      border: '0px solid',
      boxShadow: '0 0 0 1px #646A7C , 0 0 0 3px white,0 0 0 5px #18768C',
      boxSizing: 'border-box',
      borderRadius: '1px',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
    paddingLeft: '0px',
  },
  '& .HpCheckBox-root': {
    width: '24px',
    height: '24px',
    border: `${error ? '2px' : '1px'} solid ${error ? '#C02195' : '#646A7C'}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${disabled ? '#CCCCCC' : '#ffffff'}`,
    ...(disabled && { borderColor: '#CCCCCC' }),
  },
}));

const StyledCheckboxLabelBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<IStyledBoxProps>(({ error }) => ({
  display: 'flex',
  justifyContent: 'start',
  border: `${error ? '2px' : '1px'} solid ${error ? '#C02195' : '#F8F8F8'}`,
  width: '100%',
  marginTop: '2px',
  marginBottom: '2px',
  borderRadius: '8px',
  backgroundColor: '#F8F8F8',
  height: '64px',
  alignItems: 'center',
  paddingLeft: '8px',
  '&:hover': {
    borderColor: '#18768C',
    '& .HpCheckBox-root': {
      border: '0px solid',
      boxShadow: '0 0 0 1px #18768C',
      boxSizing: 'border-box',
      borderRadius: '3px',
    },
  },
  '&:active': {
    border: '0px solid',
    boxShadow: '0 0 0 2px #18768C , 0 0 0 3px #F8F8F8,0 0 0 4px #18768C',
    boxSizing: 'border-box',
    borderRadius: '2px',
    '& .HpCheckBox-root': {
      border: '2px solid #18768C',
      borderRadius: '2px',
    },
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    fontSize: 20,
    fontWeight: 500,
    color: 'rgba(0, 33, 54, 1)',
    margin: '0px',
  },
});

const UnCheckedBoxIcon = () => {
  return <div className='HpCheckBox-root'></div>;
};

const CheckedBoxIcon = () => {
  return (
    <div className='HpCheckBox-root'>
      <svg
        width='15'
        height='11'
        viewBox='0 0 15 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.59 0L6 7.58L1.41 3L0 4.41L6 10.41L15 1.41L13.59 0Z'
          fill='#002136'
        />
      </svg>
    </div>
  );
};

export default function HpCheckbox({ label, ...extraProps }: IProps) {
  if (!label) {
    return (
      <StyledCheckbox
        icon={<UnCheckedBoxIcon />}
        checkedIcon={<CheckedBoxIcon />}
        label={label}
        {...extraProps}
      />
    );
  }
  return (
    <StyledCheckboxLabelBox error={extraProps.error}>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            name={label}
            icon={<UnCheckedBoxIcon />}
            checkedIcon={<CheckedBoxIcon />}
            label={label}
            {...extraProps}
          />
        }
        label={label}
      />
    </StyledCheckboxLabelBox>
  );
}
