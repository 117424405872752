import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFunctionsData } from '../../api/types/leadership';

interface IFunctionModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: {
    id: string;
    name: string;
    functionId: string;
    description?: string;
    fieldValue: string;
    systemName?: string;
  };
}

const initialState: IFunctionModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    functionId: '',
    fieldValue: '',
    systemName: '',
    description: '',
  },
};

const functionVLClientModalSlice = createSlice({
  name: 'functionVLClientModal',
  initialState,
  reducers: {
    closeFunctionVLClientModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddFunctionVLClientModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditFunctionVLClientModal: (
      state,
      action: PayloadAction<IFunctionsData>
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.fieldValue || '',
        functionId: action.payload.code || '',
        description: action.payload.description || '',
        fieldValue: action.payload.fieldValue || '',
        systemName: action.payload.systemName || '',
      };
    },
  },
});

export const functionVLClientModalActions = functionVLClientModalSlice.actions;
export const functionVLClientModalReducer = functionVLClientModalSlice.reducer;
