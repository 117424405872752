import { createSlice } from '@reduxjs/toolkit';

interface IProjectSpinnerI {
  isLoading: boolean;
}

const initialState: IProjectSpinnerI = {
  isLoading: false,
};

const projectSpinnerSlice = createSlice({
  name: 'projectSpinner',
  initialState,
  reducers: {
    openProjectSpinner: () => ({
      isLoading: true,
    }),
    closeProjectSpinner: () => ({
      isLoading: false,
    }),
  },
});

export const projectSpinnerActions = projectSpinnerSlice.actions;
export const projectSpinnerReducer = projectSpinnerSlice.reducer;
