import PageHeader from '../../../../../atoms/header/PageHeader';
import Title from '../../../../../atoms/title/Title';
import { routes } from '../../../../../routes/routes';
import { Box, styled } from '@mui/material';
import Button from '../../../../../atoms/button/Button';
import plusIcon from '../../../../../assets/icons/Plus.svg';
import { useActions } from '../../../../../hooks/actions';
import { useAppSelector } from '../../../../../hooks/redux';
import ClientAssessmentModal from '../../../../modals/Company/Assessments/ClientAssessments/ClientAssessmentModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const ClientAssessmentHeader = () => {
  const { openClientAssessmentModal } = useActions();

  const isOpen = useAppSelector((state) => state.clientAssessmentModal.isOpen);

  return (
    <>
      <PageHeader type='full'>
        <StyledTitlesWrapper>
          <Title text={routes.company.newassessment.name} />
        </StyledTitlesWrapper>
        <StyledButtonWrapper>
          <Button
            btnType='PRIMARY'
            text={'Add assessment'}
            endIcon={<img src={plusIcon} />}
            onClick={() => openClientAssessmentModal()}
          />
        </StyledButtonWrapper>
      </PageHeader>
      {isOpen && <ClientAssessmentModal />}
    </>
  );
};

export default ClientAssessmentHeader;
