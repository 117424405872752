import React from 'react';
import { Box, styled } from '@mui/material';
import Title from '../../atoms/title/Title';
import PageHeader from '../../atoms/header/PageHeader';

const StyledTitlesWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export default function AssessmentHeader() {
  return (
    <PageHeader>
      <StyledTitlesWrapper>
        <Title text='Report Generation and Export' />
      </StyledTitlesWrapper>
    </PageHeader>
  );
}
