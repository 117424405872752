import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { projectApiInvalidateTags } from './projects';
import {
  IAssessmentGenerateResponse,
  IAssessmentGenerateTransformResponse,
} from './types/assessment';
export const assessmentReportApi = createApi({
  reducerPath: 'assessment-report/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}assessment-reports/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Assessment'],
  endpoints: (builder) => ({
    generateHeidrickReport: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'generator/',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        // Added timeout to fetch latest Project data after report generated and project data updated in backend
        setTimeout(() => {
          dispatch(projectApiInvalidateTags(['projects', 'project']));
        }, 10000);
      },
      invalidatesTags: ['Assessment'],
    }),

    downloadReport: builder.mutation<IAssessmentGenerateTransformResponse, any>(
      {
        query: (payload) => ({
          url: 'generator',
          method: 'POST',
          body: payload,
        }),
        transformResponse: (response: IAssessmentGenerateResponse) => {
          if (response.status === 200 && response.state === 'completed') {
            window.open(response.download_link, '_blank');
            return { status: 'success' };
          }
          if (response.error) {
            return { status: 'error', message: response.error };
          }
          return {
            status: 'error',
            message: 'Unable to generate assessment report',
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          // Added timeout to fetch latest Project data after report generated and project data updated in backend
          setTimeout(() => {
            dispatch(projectApiInvalidateTags(['projects', 'project']));
          }, 10000);
        },
        invalidatesTags: ['Assessment'],
      }
    ),
  }),
});

export const { useGenerateHeidrickReportMutation, useDownloadReportMutation } =
  assessmentReportApi;
