import { Navigate, Route } from 'react-router-dom';
import { routes } from './routes';
import CompanyOutlet from '../pages/Company/CompanyOutlet';
import AssessmentValidation from '../pages/Company/Assessments/AssessmentValidation';
import { Restricted } from '../rbac/Restricted';
import ClientProjects from '../pages/Company/Projects/ClientProjects';
import AddNewClientProject from '../pages/Company/Projects/AddNewClientProject';
import ClientProjectDetails from '../pages/Company/Projects/ProjectDetails/ClientProjectDetails';
import ProjectAssessmentsDetails from '../pages/Company/Projects/ProjectDetails/ProjectAssessmentsDetails';
import ClientAssessment from '../pages/Company/Assessments/ClientAssessments/ClientAssessment';

export const ClientRoutes = (clientId: string) => {
  const homePage = `${routes.company.url}${clientId}${routes.company.projects.url}`;

  return (
    <>
      <Route
        index
        element={<Navigate to={homePage} />}
      />
      <Route element={<CompanyOutlet />}>
        <Route
          path={`${routes.company.url}:id${routes.company.projects.url}`}
          element={
            <Restricted allowedTo={'project.page'}>
              <ClientProjects />
            </Restricted>
          }
        />
        <Route
          path={`${routes.company.url}:id${routes.company.projects.url}${routes.company.projects.add.url}`}
          element={
            <Restricted allowedTo={'project.project_list.add'}>
              <AddNewClientProject />
            </Restricted>
          }
        />
        <Route
          path={`${routes.company.url}:id${routes.company.projects.url}/:projectId${routes.company.projects.edit.url}`}
          element={
            <Restricted
              allowedTo={
                'project.project_list.edit' || 'project.project_details.edit'
              }
            >
              <AddNewClientProject />
            </Restricted>
          }
        />
        <Route
          path={`${routes.company.url}:id${routes.company.projects.url}/:projectId`}
          element={
            <Restricted allowedTo={'project.page'}>
              <ClientProjectDetails />
            </Restricted>
          }
        />
        <Route
          path={`${routes.company.url}:id${routes.company.projects.url}/:projectId/assessments/:assessmentsId`}
          element={
            <Restricted allowedTo={'project.page'}>
              <ProjectAssessmentsDetails />
            </Restricted>
          }
        />
        <Route
          path={`${routes.company.url}:id${routes.company.newassessment.url}`}
          element={
            <Restricted allowedTo={'client.assessments.read'}>
              <ClientAssessment />
            </Restricted>
          }
        />
        <Route
          path={`${routes.company.url}:id${routes.company.assessmentstatus.url}`}
          element={<AssessmentValidation />}
        />
      </Route>
    </>
  );
};
