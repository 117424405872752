import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGetRolesData } from '../../api/types/leadership';

interface IAddRoleToImpactGroupModalProps {
  isOpen: boolean;
  modalType: 'add';
  data: Array<IGetRolesData | null>;
}

const initialState: IAddRoleToImpactGroupModalProps = {
  isOpen: false,
  modalType: 'add',
  data: [],
};

const assignedRolesAnotherImpactGroupModalslice = createSlice({
  name: 'assignedRolesAnotherImpactGroupModal',
  initialState,
  reducers: {
    openAssignedRolesAnotherImpactGroupModal: (
      state,
      action: PayloadAction<Array<IGetRolesData | null>>
    ) => {
      state.isOpen = true;
      state.data = action.payload;
    },
    closeAssignedRolesAnotherImpactGroupModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const assignedRolesAnotherImpactGroupModalActions =
  assignedRolesAnotherImpactGroupModalslice.actions;
export const assignedRolesAnotherImpactGroupModalReducer =
  assignedRolesAnotherImpactGroupModalslice.reducer;
