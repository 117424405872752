import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import { useGetAvailableAssessmentsQuery } from '../../../store/api/tazio';
import {
  useEditSkillCategoriesMutation,
  useGetSkillCategoriesQuery,
  useSetSkillCategoriesMutation,
} from '../../../store/api/leadership';
import { ISkillCategoriesEditRequest } from '../../../store/api/types/leadership';

type IFormData = ISkillCategoriesEditRequest;

export const SkillCategoryVLModal = () => {
  const { data: { assessmentsList } = {} } = useGetAvailableAssessmentsQuery();
  const { data: { data: skillCategoriesData } = {} } =
    useGetSkillCategoriesQuery('Master');

  const [addSkillCategory] = useSetSkillCategoriesMutation();
  const [editSkillCategory] = useEditSkillCategoriesMutation();

  const initialValues = useAppSelector(
    (state) => state.skillCategoryVLModal.initialValues
  );
  const isOpen = useAppSelector((state) => state.skillCategoryVLModal.isOpen);
  const modalType = useAppSelector(
    (state) => state.skillCategoryVLModal.modalType
  );

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeSkillCategoryVLModal,
  } = useActions();

  const methods = useForm<IFormData>();
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    closeSkillCategoryVLModal();
  };

  const skillCategoryCodeHandler = () => {
    // This functionality will automatically set skill category ID to first available
    if (modalType === 'add' && isOpen) {
      let i = 1;
      while (skillCategoriesData!.find((el) => el.code === `SC${i}`)) {
        i++;
      }
      setValue('code', `SC${i}`);
    }
  };

  const assessmentIdHandler = () => {
    // This functionality prevent from crashing for condition when assessment id does not exist in endpoint response
    // It will add unknown assessment id to the dropdown list
    if (modalType === 'edit' && isOpen) {
      if (!initialValues.sourceAssessmentId) return assessmentsList;

      const isValueExist = assessmentsList?.some(
        (el) => el.keyValue === initialValues.sourceAssessmentId
      );
      return isValueExist
        ? assessmentsList
        : [
            ...(assessmentsList as []),
            {
              keyValue: initialValues.sourceAssessmentId,
              keyName: initialValues.sourceAssessmentId,
            },
          ];
    }

    return assessmentsList;
  };

  React.useEffect(() => {
    reset(initialValues);
    skillCategoryCodeHandler();
  }, [isOpen, reset, initialValues]);

  const onSubmit = async (formData: IFormData) => {
    // checking if assessment id is empty string (not selected) than replace it
    // to null, which is acceptable value for backend, otherwise it will create duplicate
    if (formData.sourceAssessmentId === '') formData.sourceAssessmentId = null;

    if (modalType === 'edit') {
      delete formData.code;
    }

    const modalTypeMap = {
      add: {
        fn: addSkillCategory,
        messageSuccess: 'Skill Category Added Successfully',
        errorMessage: 'Error Adding Skill Category',
      },
      edit: {
        fn: editSkillCategory,
        messageSuccess: 'Skill Category Updated Successfully',
        errorMessage: 'Error Updating Skill Category',
      },
    };

    try {
      const response = await modalTypeMap[modalType].fn(formData).unwrap();

      if (response.status === 'success') {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
      }
    } catch (e) {
      const error = e as {
        data?: { detail: string };
        status?: number;
        message?: string;
      };

      toggleErrorSnackbar({ message: error?.data?.detail });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add new skill category' : 'Edit skill category'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='skill-category-modal'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='name'
            rules={{ required: 'Please provide skill category name' }}
            label='Skill Category Name'
          />
          {assessmentsList && (
            <FormLabelDropdown
              name='sourceAssessmentId'
              placeholder='Select assessment ID'
              label='Tazio Assessment ID (optional)'
              dropDownItem={assessmentIdHandler() ?? []}
            />
          )}
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='skill-category-modal'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};
