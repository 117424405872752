import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssignmentData } from '../../../../api/types/projects';

interface IAssignmentModalProps {
  isOpen: boolean;
  modalType: 'view';
  initialValues: IAssignmentData;
}

const initialState: IAssignmentModalProps = {
  isOpen: false,
  modalType: 'view',
  initialValues: {
    participant: '',
    assessment: '',
    project: '',
    projectStartDate: '',
    projectEndDate: '',
    type: '',
    status: '',
    id: '',
    raters: [],
    completedDate: '',
    completedPercentage: '',
    lastReminderSent: '',
    reminderCount: '',
    raterTypes: [],
  },
};

const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    openAssignmentModal: (state, action: PayloadAction<IAssignmentData>) => ({
      ...initialState,
      isOpen: true,
      initialValues: {
        id: action.payload.id || '',
        status: action.payload.status || '',
        type: action.payload.type || '',
        project: action.payload.project || '',
        projectStartDate: action.payload.projectStartDate || '',
        projectEndDate: action.payload.projectEndDate || '',
        assessment: action.payload.assessment || '',
        participant: action.payload.participant || '',
        raters: action.payload.raters,
        completedDate: action.payload.completedDate || '',
        completedPercentage: action.payload.completedPercentage || '',
        lastReminderSent: action.payload.lastReminderSent || '',
        reminderCount: action.payload.reminderCount || '',
        raterTypes: action.payload.raterTypes,
      },
    }),
    closeAssessmentModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const assignmentActions = assignmentSlice.actions;
export const assignmentReducer = assignmentSlice.reducer;
