import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const CompanyOutlet = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 99px - 66px)',
        overflowY: 'auto',
      }}
    >
      <Outlet />
    </Box>
  );
};

export default CompanyOutlet;
