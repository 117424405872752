import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAssessmentData } from '../../../api/types/assessmentStatusDashboard';

interface IReportGenerateModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  generateReportsTableData: [];
  reportData: Array<any>;
  tazio360Assessments: { participantName: string; status: boolean }[];
}

const initialState: IReportGenerateModalProps = {
  isOpen: false,
  modalType: 'add',
  generateReportsTableData: [],
  reportData: [],
  tazio360Assessments: [],
};

const reportGenerationModalSlice = createSlice({
  name: 'reportGenerationModal',
  initialState,
  reducers: {
    openProjectModal: (state, action) => ({
      ...state,
      isOpen: true,
      reportData: action.payload,
    }),
    closeProjectModal: (state) => ({
      ...initialState,
      isOpen: false,
      generateReportsTableData: state.generateReportsTableData,
      tazio360Assessments: state.tazio360Assessments,
    }),
    addAvailableReports: (state, action) => ({
      ...state,
      generateReportsTableData: action.payload,
    }),
    setReportTazio360Assessment: (
      state,
      action: PayloadAction<{ participantName: string; status: boolean }[]>
    ) => {
      state.tazio360Assessments = action.payload;
    },
  },
});

export const reportGenerationModalActions = reportGenerationModalSlice.actions;
export const reportGenerationModalReducer = reportGenerationModalSlice.reducer;
