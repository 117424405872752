import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import { SelectOptions } from '../../../../commons/types';
import SelectDropdown from '../../../../atoms/select/SelectDropdown';
import { ILeaderProfileData } from '../../../../store/api/types/leaderProfile';

interface IProps {
  data: ILeaderProfileData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  roleProfileDropdown: SelectOptions[];
}

const ImpactGroupLeadersTable = ({
  data,
  isLoading,
  tableInstanceRef,
  roleProfileDropdown,
}: IProps) => {
  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<ILeaderProfileData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<ILeaderProfileData>[]>(
    () => [
      {
        header: 'Name',
        filterFn: 'includesString',
        accessorFn: ({ firstName, lastName }) => `${firstName} ${lastName}`,
      },
      {
        header: 'Employee ID',
        accessorKey: 'clientEmpId',
        filterFn: 'includesString',
        size: 100,
        minSize: 100,
        maxSize: 100,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        filterFn: 'includesString',
        size: 200,
        minSize: 200,
        maxSize: 560,
      },
      {
        header: 'Role Profile',
        accessorKey: 'roleProfile',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={roleProfileDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        size: 200,
        minSize: 200,
        maxSize: 560,
      },
      {
        header: 'Manually Assigned',
        accessorKey: 'manuallyAssigned',
        accessorFn: (item) => {
          return item.manuallyAssigned ? 'Yes' : 'No';
        },
        filterVariant: 'select',
        filterFn: 'contains',
        size: 50,
        minSize: 50,
        maxSize: 100,
        Filter: ({ header }) => {
          return (
            <div
              className='row'
              style={{ maxWidth: '15rem' }}
            >
              <SelectDropdown
                value={header.column.getFilterValue() ?? ''}
                dropDownItem={[
                  {
                    keyValue: 'Yes',
                    keyName: 'Yes',
                  },
                  {
                    keyValue: 'No',
                    keyName: 'No',
                  },
                ]}
                tooltip={'Tooltip to be Added...'}
                clearfunction={() => header.column.setFilterValue(undefined)}
                onChange={(e) =>
                  header.column.setFilterValue(e.target.value || undefined)
                }
              ></SelectDropdown>
            </div>
          );
        },
        filterSelectOptions: [
          { text: 'Yes', value: 'Yes' },
          { text: 'No', value: 'No' },
        ],
      },
    ],
    [roleProfileDropdown]
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      muiSelectCheckboxProps={{ color: 'default' }}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default ImpactGroupLeadersTable;
