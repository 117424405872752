import { Box, styled } from '@mui/material';
import React, { useRef, useMemo } from 'react';
import TabContent from '../../atoms/tab/TabContent';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps, SelectOptions } from '../../commons/types';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';
import FunctionalImpactSLModal from '../../organisms/modals/Statement/FunctionalImpactSLModal';
import ImportIcon from '../../assets/icons/Import.svg';
import { useGetClientStatementsQuery } from '../../store/api/leadership';
import MultiSelectDropdown from '../../atoms/multiselect/MultiSelectDropdown';
import { IGetClientStatementData } from '../../store/api/types/leadership';
import StatementLibraryTable from '../../organisms/tables/StatementLibraryTable';
import _ from 'lodash';

interface IProps {
  skillType: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

export default function FunctionalImpact({
  index,
  value,
}: ITabContentProps & IProps) {
  const { data: { data, metadata } = {}, isLoading } =
    useGetClientStatementsQuery({
      skillGroup: 'Functional Impact',
      clientId: 'Master',
    });

  const impactModuleDropdown = data
    ?.map((item) => ({
      text: item?.impactModuleName || '',
      value: item?.impactModuleName || '',
    }))
    .filter((item) => Boolean(item?.value));

  const { openAddFunctionalImpactSLModal, openImportStatementModal } =
    useActions();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IGetClientStatementData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IGetClientStatementData>[]>(
    () => [
      {
        header: 'Statement Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
      {
        header: 'Statement ID',
        accessorKey: 'statementCode',
        filterFn: 'includesString',
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
      },
      {
        header: 'Impact Module',
        accessorKey: 'impactModuleName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={_.uniqBy(impactModuleDropdown, 'value') ?? []}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        filterFn: 'includesString',
      },
    ],
    [impactModuleDropdown]
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      <FunctionalImpactSLModal />
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <StyledHeaderWrapper>
              <Title
                text={'Business Impact: Functional Impact'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <Box>
                <HpTooltip
                  title={
                    'Proven track record of professional accomplishments and ability ' +
                    'to drive business impact in specific functions and roles (role-specific)'
                  }
                />
              </Box>
            </StyledHeaderWrapper>
            <StyledHeaderActionWrapper>
              <Button
                btnType='SECONDARY'
                text={'Import data'}
                onClick={() => openImportStatementModal()}
                startIcon={
                  <ButtonIcon
                    src={ImportIcon}
                    alt='Import CSV'
                  />
                }
              />
              <Button
                btnType={'PRIMARY'}
                text={'Add new statement'}
                onClick={() => openAddFunctionalImpactSLModal()}
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                metadata ? metadata.pagination.totalCount : '0'
              } total statements`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <StatementLibraryTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          tableInstanceRef={tableInstanceRef}
        />
      </>
    </TabContent>
  );
}
