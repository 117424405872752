import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, styled, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { userTypeHandler } from '../../utils/helpers';
import { myPersonas } from '../../store/api/v2/types/auth';

export interface HDHeaderTypes {
  logo?: string;
  logoLink?: string | undefined;
  logoClick: () => void;
  subNav?: boolean;
  subNavName?: string;
  alt?: string | undefined;
  logoHeight?: string | number | undefined;
  logoWidth?: string | number | undefined;
  children?:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  myPersonas?: myPersonas[];
}

const StyledLogoWrap = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'subNav',
})<Pick<HDHeaderTypes, 'subNav'>>(({ subNav }) => ({
  margin: '20px 0 20px 0',
  cursor: subNav ? 'default' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
}));

const StyledBackWrap = styled(Box)(() => ({
  background: '#197E95',
  color: '#FFFFFF',
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '83px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
  zIndex: 1,
}));

const StyledAppBarWrap = styled(AppBar)(() => ({
  position: 'relative',
  background: '#ffffff',
  borderBottom: '1px solid rgba(100, 106, 124, 0.2)',
  boxShadow: '0px 0px 0px 0px #fff',
}));

const HDHeader = (props: HDHeaderTypes) => {
  return (
    <StyledAppBarWrap>
      {props.subNav && userTypeHandler(props.myPersonas) === 'Global' && (
        <StyledBackWrap onClick={() => props.logoClick()}>
          <ArrowBackIcon fontSize='medium' />
          <span>HOME</span>
        </StyledBackWrap>
      )}
      <Container>
        <Toolbar disableGutters>
          <StyledLogoWrap subNav={props.subNav}>
            <img
              src={props.logo}
              alt={props.alt}
              height={props.logoHeight}
              width={props.logoWidth}
              onClick={() => {
                if (!props.subNav) props.logoClick();
              }}
            />
            {props.subNav && <span>{props.subNavName}</span>}
          </StyledLogoWrap>
          {props.children}
        </Toolbar>
      </Container>
    </StyledAppBarWrap>
  );
};
export default HDHeader;
