import React, { useState } from 'react';
import { Container } from '@mui/material';
import CompanyHeader from '../../organisms/headers/CompanyHeader';
import TabMenu from '../../atoms/tab/TabMenu';
import TabMenuItem from '../../atoms/tab/TabMenuItem';
import TabContent from '../../atoms/tab/TabContent';
import Configuration from './settings/Configuration';
import ApplicationAccess from './settings/ApplicationAccess';

const Settings = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <CompanyHeader />
      <Container sx={{ marginBottom: '50px' }}>
        <TabMenu
          value={value}
          onChange={handleChange}
        >
          <TabMenuItem
            label='Configuration'
            index={0}
          />
          <TabMenuItem
            label='Application Access'
            index={1}
          />
        </TabMenu>
        <TabContent
          index={0}
          value={value}
        >
          <Configuration />
        </TabContent>
        <TabContent
          index={1}
          value={value}
        >
          <ApplicationAccess />
        </TabContent>
      </Container>
    </>
  );
};

export default Settings;
