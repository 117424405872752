import { createSlice } from '@reduxjs/toolkit';

interface IAddLeadersModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  projectLeaders: Array<string>;
}

const initialState: IAddLeadersModalProps = {
  isOpen: false,
  modalType: 'add',
  projectLeaders: [],
};

const addLeadersModalSlice = createSlice({
  name: 'addLeadersModal',
  initialState,
  reducers: {
    openAddLeadersModal: (state, action) => ({
      ...initialState,
      isOpen: true,
      projectLeaders: action.payload,
    }),
    closeAddLeadersModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const addLeadersModalActions = addLeadersModalSlice.actions;
export const addLeadersModalReducer = addLeadersModalSlice.reducer;
