import React from 'react';
import { Box, styled } from '@mui/system';
import TabMenu from '../../atoms/tab/TabMenu';
import TabMenuItem from '../../atoms/tab/TabMenuItem';
import RoleModal from '../../organisms/modals/RoleModal';
import BusinessOrganizationalImpact from './BusinessOrganizationalImpact';
import BusinessFunctionalImpact from './BusinessFunctionalImpact';
import LeadershipCapability from './LeadershipCapability';
import LeadershipPotential from './LeadershipPotential';
import CultureImpact from './CultureImpact';
import RoleDetailsHeader from '../../organisms/headers/RoleDetailsHeader';
import { useParams } from 'react-router';
import { useGetRoleProfilesByIdQuery } from '../../store/api/leadership';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: 'auto',
  paddingLeft: '20px',
  maxWidth: '1200px',
  '.tabContent': {
    paddingTop: '40px',
  },
});

export default function RoleView() {
  const [tabValue, setTabValue] = React.useState(0);
  const { id } = useParams();

  const { data: roleData } = useGetRoleProfilesByIdQuery(id as string);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px - 66px)',
        overflowY: 'scroll',
      }}
    >
      {!!roleData && (
        <>
          <RoleDetailsHeader roleDetails={roleData?.data} />
          <StyledContentWrapper>
            <TabMenu
              value={tabValue}
              onChange={handleTabChange}
            >
              <TabMenuItem
                index={0}
                label={'Business Impact: Functional Impact'}
              />
              <TabMenuItem
                index={1}
                label={'Business Impact: Organizational Impact'}
              />
              <TabMenuItem
                index={2}
                label={'Leadership Capability'}
              />
              <TabMenuItem
                index={3}
                label={'Leadership Potential'}
              />
              <TabMenuItem
                index={4}
                label={'Culture Impact'}
              />
            </TabMenu>
            <Box className='tabContent'>
              <BusinessFunctionalImpact
                index={0}
                value={tabValue}
                roleId={id}
                skillType={'Functional Impact'}
                roleFunctionID={roleData?.data?.functionName} // Passing role details to this tab
                client={'Master'}
              />
              <BusinessOrganizationalImpact
                index={1}
                value={tabValue}
                roleId={id}
                roleFunctionID={roleData?.data?.functionName}
                skillType={'Organizational Impact'}
                client={'Master'}
              />
              <LeadershipCapability
                index={2}
                value={tabValue}
                roleId={id}
                skillType={'Capabilities'}
                client={'Master'}
              />
              <LeadershipPotential
                index={3}
                value={tabValue}
                roleId={id}
                skillType={'Potential'}
                client={'Master'}
              />
              <CultureImpact
                index={4}
                value={tabValue}
                roleId={id}
                skillType={'Purpose'}
                client={'Master'}
              />
            </Box>
          </StyledContentWrapper>
          <RoleModal />
        </>
      )}
    </Box>
  );
}
