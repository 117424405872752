import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, FormHelperText, styled } from '@mui/material';
import { SelectOptions } from '../../commons/types';
import HpTooltip from '../tooltip/HpTooltip';
import SearchIcon from '@mui/icons-material/Search';
import AlertIcon from '../../assets/icons/Alert.svg';

interface IProps {
  tooltip?: string;
  label?: string;
  dropDownItem: Array<SelectOptions | null>;
  placeholder?: string;
  defaultValue: string;
  value: SelectOptions | null;
  error: string;
  onChange: (value: SelectOptions | null) => void;
  disabled?: boolean;
}

type StyledSelectLabelType = {
  error?: boolean;
};

const StyledAutocomplete = styled(Autocomplete)(() => ({
  backgroundColor: '#fff',
  '& .MuiAutocomplete-popupIndicatorOpen': {
    display: 'none',
  },
  marginTop: '-15px',
}));

const StyledSelectLabel = styled('span')<StyledSelectLabelType>(
  ({ error }) => ({
    fontFamily: 'Avenir',
    color: error ? '#C02195 !important' : '#646a7c',
    display: 'flex',
    marginBottom: '6px',
  })
);

const StyledFormHelperText = styled(FormHelperText)(() => ({
  color: '#C02195 !important',
  marginTop: '-15px',
}));

const StyledAutocompleteLi = styled('li')(() => ({
  paddingLeft: '5px !important',
  margin: '5px !important',
  paddingBottom: '0px',
  borderBottom: '1px solid #CCC',
  width: '100%',
  '&:hover': {
    color: '#fff !important',
    background: '#18768C !important',
  },
  '&.MuiAutocomplete-option[aria-selected=true]': {
    backgroundColor: '#18768C',
  },
}));

const StyledAutocompleteLibox = styled(Box)(() => ({
  '&:hover': {
    color: '#fff',
    background: '#18768C',
  },
  '&:focus': {
    color: '#fff',
    background: '#18768C',
  },
  backgroundColor: 'transparent',
  width: '100%',
}));

const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginTop: '4px',
  height: '12px',
  marginRight: '2px',
}));

export default function LabelSearchDropdown({
  tooltip,
  dropDownItem,
  label,
  placeholder,
  defaultValue,
  value,
  onChange,
  error,
  disabled,
}: IProps) {
  return (
    <>
      {!!label && (
        <StyledSelectLabel error={!!error.length}>
          {label}
          {tooltip && <HpTooltip title={tooltip} />}
        </StyledSelectLabel>
      )}
      <StyledAutocomplete
        disablePortal
        id='search-dropdown'
        options={dropDownItem}
        getOptionLabel={(option: any) => option.text || ''}
        renderOption={(props, option: any) => (
          <StyledAutocompleteLi {...props}>
            <StyledAutocompleteLibox>
              {option.text || ''}
            </StyledAutocompleteLibox>
          </StyledAutocompleteLi>
        )}
        popupIcon={<SearchIcon />}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder ? placeholder : ''}
          />
        )}
        noOptionsText={'Search'}
        defaultValue={defaultValue.length ? defaultValue : null}
        value={value}
        onChange={(event, value: any) =>
          onChange(value as SelectOptions | null)
        }
        disabled={disabled}
      />

      {error && (
        <StyledFormHelperText error={!!error.length}>
          <StyledImg src={AlertIcon} /> {error}
        </StyledFormHelperText>
      )}
    </>
  );
}
