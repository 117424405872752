import React from 'react';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

interface IProps extends TabProps {
  index: number;
}

const StyledTab = styled(Tab)({
  textTransform: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  color: '#334255',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  '&.Mui-selected': {
    color: '#0B0C10',
  },
});

const a11yProps = (index: number) => {
  return {
    id: `hp-tab-${index}`,
    'aria-controls': `hp-tabpanel-${index}`,
  };
};

const TabMenuItem = ({ index, ...extraProps }: IProps) => {
  return (
    <StyledTab
      {...extraProps}
      {...a11yProps(index)}
    />
  );
};

export default TabMenuItem;
