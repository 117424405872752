import React, { useMemo } from 'react';
import Table, { checkboxProps } from '../../../../../../atoms/table/Table';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';
import { AssessmentLeadersTable } from '../../../../../../store/api/types/projects';

interface IProps {
  data: Array<AssessmentLeadersTable>;
  isLoading?: boolean;
  tableInstanceRef?: React.RefObject<MRT_TableInstance>;
  checkSave: React.Dispatch<
    React.SetStateAction<Array<AssessmentLeadersTable>>
  >;
}

const CalibrationTable = ({
  data,
  isLoading,
  tableInstanceRef,
  checkSave,
}: IProps) => {
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    const selection = tableInstanceRef?.current
      ?.getSelectedRowModel()
      .flatRows.map((item) => item.original) as Array<AssessmentLeadersTable>;
    if (selection) {
      checkSave(selection);
    }
  }, [checkSave, rowSelection, tableInstanceRef]);

  const columns = useMemo<MRT_ColumnDef<AssessmentLeadersTable>[]>(
    () => [
      {
        header: 'Leader',
        accessorKey: 'leaderName',
        filterFn: 'includesString',
      },
      {
        header: 'Role',
        accessorKey: 'roleName',
        filterFn: 'includesString',
      },
      {
        header: 'Primary Assessor',
        accessorKey: 'pAssessor',
        filterFn: 'includesString',
      },
      {
        header: 'Co-Assessors',
        accessorKey: 'cAssessor',
        filterFn: 'includesString',
      },
      {
        header: 'Calibration Status',
        accessorKey: 'status',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      data={data}
      // enable row selection for false flag only
      enableRowSelection={(row: MRT_Row) => {
        const rowData = row.original as AssessmentLeadersTable;
        return rowData.status === 'Ready';
      }}
      muiSelectCheckboxProps={({ row }) => {
        if ((row.original as AssessmentLeadersTable)?.status === 'Not Ready') {
          return {
            checked: false,
          };
        } else {
          return checkboxProps;
        }
      }}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      onRowSelectionChange={setRowSelection}
      state={{ showSkeletons: isLoading, rowSelection }}
    />
  );
};

export default CalibrationTable;
