import React from 'react';
import StatementDetailsHeader from '../../../organisms/headers/StatementDetailsHeader';
import StatementDetailsTable from '../../../organisms/tables/Statement/StatementDetailsTable';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import CultureImpactSLModal from '../../../organisms/modals/Statement/CultureImpactSLModal';
import FunctionalImpactSLModal from '../../../organisms/modals/Statement/FunctionalImpactSLModal';
import LeadershipCapabilitySLModal from '../../../organisms/modals/Statement/LeadershipCapabilitySLModal';
import LeadershipPotentialSLModal from '../../../organisms/modals/Statement/LeadershipPotentialSLModal';
import OrganizationalImpactSLModal from '../../../organisms/modals/Statement/OrganizationalImpactSLModal';
import HpContainer from '../../../atoms/container/HpContainer';
import { useGetRolesAssignedToStatementQuery } from '../../../store/api/leadership';

const ClientStatementDetails = () => {
  const params = useParams();

  const { data: { data } = {}, isLoading } =
    useGetRolesAssignedToStatementQuery(
      params?.statementId as unknown as string
    );

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 98px - 66px)',
          overflowY: 'scroll',
        }}
      >
        <StatementDetailsHeader
          id={params.statementId as string}
          clientId={params.id}
        />
        <HpContainer
          sx={{
            marginBottom: '50px',
          }}
        >
          <StatementDetailsTable
            isLoading={isLoading}
            data={data?.roles || []}
          />
        </HpContainer>
      </Box>
      <CultureImpactSLModal clientId={params.id} />
      <FunctionalImpactSLModal clientId={params.id} />
      <LeadershipCapabilitySLModal />
      <LeadershipPotentialSLModal />
      <OrganizationalImpactSLModal clientId={params.id} />
    </>
  );
};

export default ClientStatementDetails;
