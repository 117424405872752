import React from 'react';
import Table from '../../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';

interface IProps {
  data?: any[];
  isLoading: boolean;
  columns: MRT_ColumnDef<any>[];
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const RatersTable = ({
  data,
  isLoading,
  tableInstanceRef,
  columns,
}: IProps) => {
  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      muiTableBodyCellProps={({ cell }) => ({
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default RatersTable;
