import React from 'react';
import LabelInput from '../../atoms/input/LabelInput';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField';
interface IProps {
  label: string;
  extraLabelInputProps?: TextFieldProps;
  tooltip?: string;
  disabled?: boolean;
}

const FormLabelInput = ({
  name,
  defaultValue = '',
  label,
  tooltip,
  disabled,
  extraLabelInputProps,
  ...extraProps
}: IProps & UseControllerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...extraProps}
      render={({ field }) => (
        <LabelInput
          inputProps={{ ...field }}
          error={!!errors[name]}
          helperText={errors[name]?.message as unknown as string}
          label={label}
          tooltip={tooltip}
          InputProps={{
            readOnly: disabled ? true : false,
          }}
          {...extraLabelInputProps}
        />
      )}
    />
  );
};

export default FormLabelInput;
