import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISkillCategoriesData } from '../api/types/leadership';

interface ISkillCategoryVLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: Pick<
    ISkillCategoriesData,
    'name' | 'sourceAssessmentId' | 'code' | 'id' | 'clientId'
  >;
}

const initialState: ISkillCategoryVLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    name: '',
    sourceAssessmentId: '',
    code: '',
    id: '',
    clientId: 'Master',
  },
};

const skillCategoryVLModalSlice = createSlice({
  name: 'skillCategoryVLModal',
  initialState,
  reducers: {
    closeSkillCategoryVLModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    openAddSkillCategoryVLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditSkillCategoryVLModal: (
      state,
      action: PayloadAction<
        Pick<
          ISkillCategoriesData,
          'name' | 'sourceAssessmentId' | 'code' | 'id' | 'clientId'
        >
      >
    ) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        name: action.payload.name || '',
        sourceAssessmentId: action.payload.sourceAssessmentId || '',
        code: action.payload.code || '',
        id: action.payload.id || '',
        clientId: action.payload.clientId || 'Master',
      };
    },
  },
});

export const skillCategoryVLModalActions = skillCategoryVLModalSlice.actions;
export const skillCategoryVLModalReducer = skillCategoryVLModalSlice.reducer;
