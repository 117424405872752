import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material';
import HpTooltip from '../tooltip/HpTooltip';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

type IProps = {
  error?: boolean;
  tooltip?: string;
  label?: string;
  inputFieldProps?: TextFieldProps;
  datePickerProps?: object;
  onChange: (event: any) => void;
};

const StyledTextFieldLabel = styled('span')<Pick<IProps, 'error'>>(
  ({ error }) => ({
    fontFamily: 'Avenir',
    color: error ? '#C02195' : '#646a7c',
  })
);

const StyledTextField = styled(TextField)(() => ({
  '& .Mui-error': {
    color: '#C02195',
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C02195',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#C02195',
  },
  '& .MuiInputBase-root': {
    color: '#002136',
    background: 'rgb(255, 255, 255)',
    borderRadius: '12px',
  },
  '& .MuiInputBase-input': {
    padding: '11.5px 14px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    background: '#F1F1F1',
    borderColor: '#646A7C',
    pointerEvents: 'none',
  },
}));

const LabelDatePicker = ({
  error,
  label,
  tooltip,
  onChange,
  inputFieldProps,
  datePickerProps,
}: IProps) => {
  return (
    <div>
      <StyledTextFieldLabel error={error}>
        {label}
        {tooltip && <HpTooltip title={tooltip} />}
      </StyledTextFieldLabel>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          onChange={onChange}
          value={''}
          inputFormat={'MM / dd / yyyy'}
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon,
          }}
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              size='small'
              variant='outlined'
              {...params}
              {...inputFieldProps}
            />
          )}
          {...datePickerProps}
        />
      </LocalizationProvider>
    </div>
  );
};

export default LabelDatePicker;
