import { createSlice } from '@reduxjs/toolkit';
import { IReportsTypeData } from '../../../api/types/projects';

interface ILeadersReportGenerateModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  leaderData: Array<IReportsTypeData>;
}

const initialState: ILeadersReportGenerateModalProps = {
  isOpen: false,
  modalType: 'add',
  leaderData: [],
};

const leadersReportGenerateModalSlice = createSlice({
  name: 'leadersReportGenerateModal',
  initialState,
  reducers: {
    openLeadersReportGenerateModal: (state, action) => ({
      ...initialState,
      isOpen: true,
      leaderData: action.payload,
    }),
    closeLeadersReportGenerateModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const leadersReportGenerateModalActions =
  leadersReportGenerateModalSlice.actions;
export const leadersReportGenerateModalReducer =
  leadersReportGenerateModalSlice.reducer;
