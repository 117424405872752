import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../../atoms/button/Button';
import HpCheckbox from '../../../atoms/checkbox/HpCheckbox';
import Modal from '../../../atoms/modal/Modal';
import ModalActions from '../../../atoms/modal/ModalActions';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import SubTitle from '../../../atoms/title/SubTitle';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import {
  useLazyDownloadCompanyUsersEducationQuery,
  useLazyDownloadCompanyUsersPerformanceQuery,
  useLazyDownloadCompanyUsersResumesQuery,
} from '../../../store/api/core';
import {
  useDownloadCompanyUsersAssessmentResultMutation,
  useDownloadCompanyNavigatorCustomFieldMutation,
} from '../../../store/api/exportFile';
import {
  useLazyDownloadCompanyNavigatorAccessQuery,
  useLazyDownloadCompanyUsersEmployeeQuery,
} from '../../../store/api/v2/leaderProfile';

interface IProps {
  qtyOfUsers?: number;
  selectedUsersEmailList: string[];
}

interface IStateData {
  name: string;
  label: string;
  checked: boolean;
  fn: any; // LazyQueryTrigger type varies according to the API call. Hence preventing type mismatches with any.
}

const CompanyUsersModal = ({
  qtyOfUsers = 0,
  selectedUsersEmailList,
}: IProps) => {
  const { id = '' } = useParams();

  const [downloadCompanyUsersResumes] =
    useLazyDownloadCompanyUsersResumesQuery();
  const [downloadCompanyUsersEducation] =
    useLazyDownloadCompanyUsersEducationQuery();
  const [downloadCompanyUsersEmployee] =
    useLazyDownloadCompanyUsersEmployeeQuery();
  const [downloadCompanyUsersPerformance] =
    useLazyDownloadCompanyUsersPerformanceQuery();
  const [downloadCompanyUsersAssessment] =
    useDownloadCompanyUsersAssessmentResultMutation();
  const [downloadCompanyUsersNavigator] =
    useLazyDownloadCompanyNavigatorAccessQuery();
  const [downloadCompanyNavigatorCustomField] =
    useDownloadCompanyNavigatorCustomFieldMutation();

  const isOpen = useAppSelector((state) => state.companyUsersModal.isOpen);

  const defaultState = [
    {
      name: 'employee',
      label: 'Employee',
      checked: false,
      fn: downloadCompanyUsersEmployee,
    },
    {
      name: 'assessmentResult',
      label: 'Assessment Result',
      checked: false,
      fn: downloadCompanyUsersAssessment,
    },
    {
      name: 'resumes',
      label: 'Resumes',
      checked: false,
      fn: downloadCompanyUsersResumes,
    },
    {
      name: 'education',
      label: 'Education',
      checked: false,
      fn: downloadCompanyUsersEducation,
    },
    {
      name: 'performanceRating',
      label: 'Performance Rating',
      checked: false,
      fn: downloadCompanyUsersPerformance,
    },
    {
      name: 'navigatorCustomFields',
      label: 'Navigator Custom Fields',
      checked: false,
      fn: downloadCompanyNavigatorCustomField,
    },
    {
      name: 'navigatorAccess',
      label: 'Navigator Access',
      checked: false,
      fn: downloadCompanyUsersNavigator,
    },
  ];

  // TODO: placing the useState after defaultState declaration as the triggers for functions are required to define default state.
  const [checkboxState, setCheckboxState] =
    useState<IStateData[]>(defaultState);

  const { toggleErrorSnackbar, toggleSuccessSnackbar, closeCompanyUsersModal } =
    useActions();

  const handleClose = () => {
    setCheckboxState(defaultState);
    closeCompanyUsersModal();
  };

  const onSubmit = () => {
    const fileDownloads = checkboxState.map(async (cb: IStateData) => {
      if (cb.checked) {
        try {
          const response = await cb
            .fn({
              id,
              ...(selectedUsersEmailList.length && {
                selectedUsersEmailList: selectedUsersEmailList.toString(),
              }),
            })
            .unwrap();
          if (response.status === 'success') {
            toggleSuccessSnackbar({
              message: 'The file has been downloaded successfully',
            });
          } else {
            if (
              response.errors &&
              response.errors[Object.keys(response.errors)[0]].length
            ) {
              toggleErrorSnackbar({
                message: response.errors[Object.keys(response.errors)[0]][0],
              });
            }
          }
        } catch (e) {
          toggleErrorSnackbar({ message: 'The file could not be downloaded' });
        }
      }
    });

    Promise.all(fileDownloads).then(() => {
      handleClose();
    });
  };

  const isDisabled = !checkboxState.some((x: IStateData) => x?.checked);

  const handleCheckbox = (
    cbState: IStateData[],
    cu: IStateData
  ): IStateData[] => {
    const filteredState = cbState.filter(
      (cb: IStateData) => cb.name !== cu.name
    );
    return [...filteredState, cu];
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        Select file types to download.
      </ModalTitle>
      <ModalBody>
        <Box sx={{ paddingLeft: '5px' }}>
          <SubTitle text={`Download for all leaders (${qtyOfUsers})`} />
        </Box>
        {defaultState.map((cu) => (
          <Box
            sx={{ padding: '5px' }}
            key={cu.name}
          >
            <HpCheckbox
              label={cu.label}
              onChange={(e) =>
                setCheckboxState((prevState: IStateData[]) =>
                  handleCheckbox(prevState, {
                    ...cu,
                    checked: e.target.checked,
                  })
                )
              }
            />
          </Box>
        ))}
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Download'}
          disabled={isDisabled}
          onClick={onSubmit}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default CompanyUsersModal;
