import React from 'react';
import { Dialog, styled } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '764px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
}));

const Modal = ({ children, ...extraProps }: DialogProps) => {
  return <StyledDialog {...extraProps}>{children}</StyledDialog>;
};

export default Modal;
