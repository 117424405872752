import Select, { SelectProps } from '@mui/material/Select';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MenuItem from '@mui/material/MenuItem';
import HpTooltip from '../tooltip/HpTooltip';
import { DropdownTypes } from '../../commons/types';
import { FormHelperText, styled } from '@mui/material';
import AlertIcon from '../../assets/icons/Alert.svg';

export type IRules = {
  required?: string | boolean;
};

export type IProps = Omit<SelectProps, 'variant'> & {
  error?: boolean;
  rules?: IRules;
  tooltip?: string | JSX.Element;
  label: string;
  dropDownItem: Array<DropdownTypes | null>;
  placeholder?: string;
};

type StyledSelectLabelType = {
  error?: boolean;
};

const StyledSelectLabel = styled('span')<StyledSelectLabelType>(
  ({ error }) => ({
    fontFamily: 'Avenir',
    color: error ? '#C02195 !important' : '#646a7c',
    display: 'flex',
    marginBottom: '6px',
  })
);

const StyledSelect = styled(Select)(() => ({
  '& .MuiSelect-select': {
    padding: '12px 14px',
    background: '#fff',
  },
  '& .MuiSelect-select.Mui-disabled': {
    background: '#F1F1F1',
    borderColor: '#646A7C',
    pointerEvents: 'none',
  },
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  color: '#C02195 !important',
  marginLeft: '0px',
  marginTop: '8px',
  marginRight: '14px',
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': {
    color: '#fff',
    background: '#18768C',
  },
  '&:focus': {
    color: '#fff',
    background: '#18768C',
  },
  '&.Mui-selected:hover': {
    color: '#fff',
    backgroundColor: '#646a7c',
  },
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#646a7c',
  },
  '&.Mui-selected span': {
    color: '#fff',
  },
  padding: '0 20px 0 20px',
  borderBottom: '1px solid #CCC',
  lineHeight: 2.5,
}));

const StyledPlaceholder = styled('span')(() => ({
  color: '#646A7C',
}));

const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginTop: '4px',
  height: '12px',
  marginRight: '2px',
}));

const LabelDropdown = ({
  error,
  tooltip,
  dropDownItem,
  label,
  placeholder,
  defaultValue,
  ...extraProps
}: IProps) => {
  return (
    <div style={{ width: '100%' }}>
      <StyledSelectLabel error={error}>
        {label}
        {tooltip && <HpTooltip title={tooltip} />}
      </StyledSelectLabel>
      <StyledSelect
        fullWidth
        displayEmpty={true}
        size={'small'}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: '10px',
              border: '1px solid #646A7C',
            },
          },
        }}
        defaultValue={(dropDownItem.length > 0 && defaultValue) || ''}
        IconComponent={KeyboardArrowDownOutlinedIcon}
        {...extraProps}
        error={error}
      >
        {placeholder && (
          <StyledMenuItem
            key='1'
            value=''
          >
            <StyledPlaceholder>{placeholder}</StyledPlaceholder>
          </StyledMenuItem>
        )}
        {dropDownItem &&
          dropDownItem.length > 0 &&
          dropDownItem.map((el) => (
            <StyledMenuItem
              key={el?.keyValue}
              value={el?.keyValue}
            >
              {el?.keyName}
            </StyledMenuItem>
          ))}
        ;
      </StyledSelect>
      {error && (
        <div>
          <StyledFormHelperText error={error}>
            <StyledImg src={AlertIcon} />
            {extraProps?.rules?.required}
          </StyledFormHelperText>
        </div>
      )}
    </div>
  );
};

export default LabelDropdown;
