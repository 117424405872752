import { createSlice } from '@reduxjs/toolkit';
import { IProjectLeadersData } from '../../../api/types/leaderProfile';
import { AssessmentsTable } from '../../../api/types/projects';

interface ProjectAssessmentModalProps {
  isOpen: boolean;
  isRemoveAssessmentsModal: boolean;
  modalType: 'add';
  projectLeaderData: IProjectLeadersData[];
  removeAssessmentsData: AssessmentsTable[];
}

const initialState: ProjectAssessmentModalProps = {
  isOpen: false,
  modalType: 'add',
  projectLeaderData: [],
  isRemoveAssessmentsModal: false,
  removeAssessmentsData: [],
};

const projectAssessmentsModalSlice = createSlice({
  name: 'addAssessmentModal',
  initialState,
  reducers: {
    closeProjectAssessmentModal: (state) => {
      state.isOpen = false;
    },
    openProjectAssessmentModal: (state, action) => ({
      ...initialState,
      isOpen: true,
      projectLeaderData: action.payload,
    }),
    openProjectAssessmentsRemoveModal: (state, action) => {
      state.isRemoveAssessmentsModal = true;
      state.removeAssessmentsData = action.payload;
    },
    closeProjectAssessmentsRemoveModal: (state) => {
      state.isRemoveAssessmentsModal = false;
    },
  },
});

export const projectAssessmentsModalActions =
  projectAssessmentsModalSlice.actions;
export const projectAssessmentsModalReducer =
  projectAssessmentsModalSlice.reducer;
