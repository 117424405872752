import { Box, styled } from '@mui/material';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import TabContent from '../../../atoms/tab/TabContent';
import { ITabContentProps, SelectOptions } from '../../../commons/types';
import Title from '../../../atoms/title/Title';
import { MRT_Column, MRT_ColumnDef } from 'material-react-table';
import AssessmentsTable from '../../../organisms/tables/Company/Assessments/AssessmentsTable';
import { IProjectData } from '../../../store/api/types/assessmentStatusDashboard';
import MultiSelectDropdown from '../../../atoms/multiselect/MultiSelectDropdown';
import {
  dateSortingFunction,
  showCreatedDate,
} from '../../../store/api/helpers';
import { filterForMultipleValues } from '../../../utils/helpers';

interface IProps {
  projectData?: IProjectData[];
  isLoading: boolean;
  setTabValue: Dispatch<SetStateAction<number>>;
  setTableFilters: Dispatch<
    SetStateAction<{
      project?: string | undefined;
      participant?: string | undefined;
    }>
  >;
}
const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const statusDropdown = [
  {
    text: 'Completed',
    value: 'Completed',
  },
  {
    text: 'Not Started',
    value: 'Not Started',
  },
  {
    text: 'Incomplete',
    value: 'Incomplete',
  },
];

export default function Projects({
  index,
  value,
  projectData,
  isLoading,
  setTabValue,
  setTableFilters,
}: ITabContentProps & IProps) {
  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IProjectData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IProjectData>[]>(
    () => [
      {
        header: 'Project Reference',
        accessorKey: 'project',
        filterFn: (row, id, filterValue) =>
          filterForMultipleValues(row, id, filterValue),
        minSize: 200,
        maxSize: 560,
      },
      {
        header: 'Start Date',
        accessorFn: (row) => showCreatedDate(row['startDate'] ?? ''),
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) => dateSortingFunction(rowA, rowB, 'startDate'),
      },
      {
        header: 'Close Date',
        accessorFn: (row) => showCreatedDate(row['endDate'] ?? ''),
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) => dateSortingFunction(rowA, rowB, 'endDate'),
      },
      {
        header: 'Participants Invited',
        accessorKey: 'participantsQty',
        minSize: 200,
        filterFn: 'equals',
      },
      {
        header: 'Completed',
        accessorKey: 'completed',
        filterFn: 'equals',
      },
      {
        header: 'Incomplete',
        accessorKey: 'inProgress',
        filterFn: 'equals',
      },
      {
        header: 'Not Started',
        accessorKey: 'notStarted',
        filterFn: 'equals',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='15rem !important'
            dropDownItem={statusDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
    ],
    []
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <StyledHeaderWrapper>
              <Title
                text={`${
                  projectData?.length ? projectData?.length : '0'
                } total projects`}
                sx={{
                  fontSize: '20px',
                }}
              />
            </StyledHeaderWrapper>
          </StyledTitleWrapper>
        </StyledTitlesWrapper>
        <AssessmentsTable
          state={{ showSkeletons: isLoading }}
          initialState={{
            sorting: [{ id: 'project', desc: false }],
            showColumnFilters: true,
          }}
          data={projectData ?? []}
          columns={columns}
          muiTableBodyCellProps={({ cell, row }) => ({
            onClick: () => {
              const selection = window.getSelection()!.toString();
              if (!cell.id.includes('mrt-row-actions') && !selection) {
                setTabValue(1);
                setTableFilters({
                  project: row.original.project,
                });
              }
            },
            sx: {
              cursor: !cell.id.includes('mrt-row-actions')
                ? 'pointer'
                : 'default',
            },
          })}
        />
      </>
    </TabContent>
  );
}
