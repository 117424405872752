import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import HpCard from '../atoms/card/HpCard';
import HpCardHeader from '../atoms/card/HpCardHeader';
import HpCardContent from '../atoms/card/HpCardContent';
import LabelInput from '../atoms/input/LabelInput';
import LabelDropdown from '../atoms/dropdown/LabelDropdown';
import SelectDropdown from '../atoms/select/SelectDropdown';
import Toggle from '../atoms/toggle/Toggle';
import HpCheckbox from '../atoms/checkbox/HpCheckbox';
import { useActions } from '../hooks/actions';
import Button from '@mui/material/Button';
import InputSlider from '../atoms/slider/InputSlider';
import MultiSelectDropdown from '../atoms/multiselect/MultiSelectDropdown';

const ComponentTest = () => {
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [selected, SetSelected] = useState<string | EventTarget>('');
  const [selectedMulti, setSelectedMulti] = useState<Array<string>>([]);

  const handleOptionClick = (value: EventTarget) => {
    SetSelected(value);
  };

  const { toggleErrorSnackbar, toggleSuccessSnackbar, toggleInfoSnackbar } =
    useActions();

  const successalert = () => {
    toggleSuccessSnackbar({ message: 'Company Added Successfully' });
  };

  const erroralert = () => {
    toggleErrorSnackbar({ message: 'There is some Error, please try again!' });
  };

  const warningalert = () => {
    toggleInfoSnackbar({ message: 'This is some info!' });
  };

  const dropdowndata = [
    {
      keyValue: 'Marketing',
      keyName: 'Marketing',
    },
    {
      keyValue: 'IT',
      keyName: 'IT',
    },
    {
      keyValue: 'Research',
      keyName: 'Research',
    },
  ];

  const multiselectdropdowndata = [
    {
      text: 'Marketing',
      value: 'Marketing',
    },
    {
      text: 'IT',
      value: 'IT',
    },
    {
      text: 'Research',
      value: 'Research',
    },
    {
      text: 'Finance',
      value: 'Finance',
    },
    {
      text: 'Sales',
      value: 'Sales',
    },
  ];
  return (
    <Box
      sx={{
        height: 'calc(100vh - 106px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <Container sx={{ marginBottom: '50px' }}>
        <HpCard sx={{ marginTop: '50px' }}>
          <HpCardHeader title='Hello Card Test' />
          <HpCardContent>
            <Box>
              <HpCheckbox
                checked={checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
              />
              <HpCheckbox
                checked={!checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
              />
            </Box>
            <Box>
              <HpCheckbox
                checked={checkbox}
                disabled={true}
                onChange={() => setCheckbox((value: boolean) => !value)}
              />
              <HpCheckbox
                checked={!checkbox}
                disabled={true}
                onChange={() => setCheckbox((value: boolean) => !value)}
              />
            </Box>
            <Box>
              <HpCheckbox
                error={true}
                checked={checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
              />
              <HpCheckbox
                error={true}
                checked={!checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
              />
            </Box>
            <Box>
              <HpCheckbox
                checked={checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'Default'}
              />
              <HpCheckbox
                checked={!checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'Default'}
              />
            </Box>
            <Box>
              <HpCheckbox
                checked={checkbox}
                disabled={true}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'disabled'}
              />
              <HpCheckbox
                checked={!checkbox}
                disabled={true}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'disabled'}
              />
            </Box>
            <Box>
              <HpCheckbox
                checked={checkbox}
                error={true}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'Error'}
              />
              <HpCheckbox
                checked={!checkbox}
                error={true}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'Error'}
              />
            </Box>
            <Box sx={{ marginTop: '5px' }}>
              <HpCheckbox
                checked={checkbox}
                onChange={() => setCheckbox((value: boolean) => !value)}
                label={'Focus'}
              />
            </Box>
            <Box>
              <InputSlider
                value={3}
                disabled
              />
              <InputSlider value={2} />
              <InputSlider
                value={4}
                label={'Label'}
              />
            </Box>
            <Typography>This card Design Test</Typography>
          </HpCardContent>
        </HpCard>

        <Typography
          sx={{ marginTop: '50px' }}
          variant='h3'
        >
          Card With Header Action
        </Typography>

        <HpCard sx={{ marginTop: '50px' }}>
          <HpCardHeader
            title='Hello Card WIth Action'
            action={<Toggle />}
          />
          <HpCardContent>
            <LabelInput
              label='Outlined secondary'
              tooltip='Tooltip Test'
            />
            <LabelInput label='Filled success' />
            <LabelInput label='Standard warning' />
            <LabelDropdown
              label='Standard warning'
              value={'IT'}
              dropDownItem={dropdowndata}
              tooltip={'Tooltip to be Added...'}
              onChange={(e) => handleOptionClick(e.target.value as EventTarget)}
            />
          </HpCardContent>
        </HpCard>

        <HpCard sx={{ marginTop: '50px' }}>
          <h2>Notification Alerts</h2>
          <Button onClick={(e) => successalert()}>Show Success Alert</Button>
          <Button onClick={(e) => erroralert()}>Show Error Alert</Button>
          <Button onClick={(e) => warningalert()}>Show Warning Alert</Button>
        </HpCard>

        <HpCard sx={{ marginTop: '50px' }}>
          <HpCardHeader
            title='Select Dropdown with label'
            action={<Toggle />}
          />
          <HpCardContent>
            <SelectDropdown
              label='Select with Label'
              value={selected}
              dropDownItem={dropdowndata}
              tooltip={'Tooltip to be Added...'}
              onChange={(e) => handleOptionClick(e.target.value as EventTarget)}
            />
          </HpCardContent>
        </HpCard>
        <HpCard sx={{ marginTop: '50px' }}>
          <HpCardHeader
            title='Select Dropdown without label'
            action={<Toggle />}
          />
          <HpCardContent>
            <SelectDropdown
              value={selected}
              dropDownItem={dropdowndata}
              tooltip={'Tooltip to be Added...'}
              onChange={(e) => handleOptionClick(e.target.value as EventTarget)}
            />
          </HpCardContent>
        </HpCard>

        <HpCard sx={{ marginTop: '50px' }}>
          <HpCardHeader
            title='MultiSelect Dropdown with checkbox'
            action={<Toggle />}
          />
          <HpCardContent>
            <MultiSelectDropdown
              dropDownItem={multiselectdropdowndata}
              // This is an onChange event to update the filter value for this column
              onChange={(e, newValue, reason) => {
                if (
                  e.type === 'keydown' &&
                  (e as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                const selecteditems = newValue.map(
                  (value: { value: string }) => {
                    return value.value;
                  }
                );
                setSelectedMulti(selecteditems);
              }}
            />
          </HpCardContent>
        </HpCard>
      </Container>
    </Box>
  );
};

export default ComponentTest;
