import { Box, styled } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import TabContent from '../../../atoms/tab/TabContent';
import { ITabContentProps, SelectOptions } from '../../../commons/types';
import Title from '../../../atoms/title/Title';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import MultiSelectDropdown from '../../../atoms/multiselect/MultiSelectDropdown';
import ViewRatersButton from '../../../atoms/button/ViewRatersButton';
import { IAssessmentData } from '../../../store/api/types/assessmentStatusDashboard';
import { useActions } from '../../../hooks/actions';
import AssessmentDetailsModal from '../../../organisms/modals/Company/Assessments/AssessmentDetailsModal';
import {
  dateFiltering,
  dateSortingFunction,
  showCreatedDate,
} from '../../../store/api/helpers';
import { filterForMultipleValues } from '../../../utils/helpers';
import ResetButton from '../../../atoms/button/ResetButton';
import { useNavigate, useParams } from 'react-router';
import { routes } from '../../../routes/routes';
import useFooterColumn from '../../../hooks/useFooterColumn';
import ClientAssessmentsTable from '../../../organisms/tables/Company/Assessments/ClientAssessmentsTable';
import { useLazyGetAllAssignmentsQuery } from '../../../store/api/projects';
import TableDatePicker from '../../../atoms/datepicker/TableDatePicker';
import RatersModal from '../../../organisms/modals/Project/ProjectDetails/AssessmentDetails/RatersModal';

interface IProps {
  statusDropdown: { text: string; value: string }[];
  assessmentData: IAssessmentData[];
  isLoading: boolean;
  filteredAssignmentState: any[];
  setFilteredAssignmentState: React.Dispatch<
    React.SetStateAction<Array<never>>
  >;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
}));

const typeDropdownList = [
  { text: 'Self Assessment', value: 'Self Assessment' },
  { text: '360', value: '360' },
  { text: 'CBI', value: 'CBI' },
];

export default function HeidrickAssignmentTab({
  index,
  value,
  statusDropdown,
  assessmentData,
  isLoading,
  filteredAssignmentState,
  setFilteredAssignmentState,
  tableInstanceRef,
}: ITabContentProps & IProps) {
  const navigate = useNavigate();
  const { id: companyId } = useParams();

  const { openAssignmentModal, toggleErrorSnackbar } = useActions();

  const onRatersButtonClick = (assessmentData: IAssessmentData) => {
    openAssignmentModal(assessmentData);
  };

  const [getAllAssignmentsData, { data: assignmentData }] =
    useLazyGetAllAssignmentsQuery();

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IAssessmentData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
    setFilteredAssignmentState([]);
  };

  const columns = useMemo<MRT_ColumnDef<IAssessmentData>[]>(
    () => [
      {
        header: 'Participant',
        accessorKey: 'participant',
        filterFn: 'includesString',
        Footer: ({ table }) => {
          const filteredData = table
            .getFilteredRowModel()
            .rows.map((row) => row.original);

          useFooterColumn(
            filteredData,
            filteredAssignmentState,
            setFilteredAssignmentState
          );

          return null;
        },
      },
      {
        header: 'Assessment',
        accessorKey: 'assessment',
        filterFn: 'includesString',
      },
      {
        header: 'Project Name',
        accessorKey: 'project',
        filterFn: (row, id, filterValue) =>
          filterForMultipleValues(row, id, filterValue),
      },
      {
        header: 'Type',
        accessorKey: 'type',
        filterVariant: 'multi-select',
        size: 200,
        minSize: 200,
        maxSize: 560,
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={typeDropdownList}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
      {
        header: 'Assigned',
        accessorFn: (row) => showCreatedDate(row['projectStartDate'] ?? ''),
        Filter: ({ column }) => (
          <TableDatePicker
            onChange={(event) => {
              if (!event) {
                column.setFilterValue('');
              } else {
                column.setFilterValue(
                  `${event.c.month}/${event.c.day}/${event.c.year}`
                );
              }
            }}
            datePickerProps={{
              value: column.getFilterValue() || null,
            }}
          />
        ),
        id: 'projectStartDate',
        filterFn: (row, columnId, filterValue) =>
          dateFiltering(row, columnId, filterValue, 'Start'),
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'projectStartDate'),
      },
      {
        header: 'Closed',
        accessorFn: (row) => showCreatedDate(row['projectEndDate'] ?? ''),
        Filter: ({ column }) => (
          <TableDatePicker
            onChange={(event) => {
              if (!event) {
                column.setFilterValue('');
              } else {
                column.setFilterValue(
                  `${event.c.month}/${event.c.day}/${event.c.year}`
                );
              }
            }}
            datePickerProps={{
              value: column.getFilterValue() || null,
            }}
          />
        ),
        id: 'projectEndDate',
        filterFn: (row, columnId, filterValue) =>
          dateFiltering(row, columnId, filterValue, 'Close'),
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'projectEndDate'),
      },
      {
        header: 'Completed',
        accessorFn: (row) => showCreatedDate(row['completedDate'] ?? ''),
        Filter: ({ column }) => (
          <TableDatePicker
            onChange={(event) => {
              if (!event) {
                column.setFilterValue('');
              } else {
                column.setFilterValue(
                  `${event.c.month}/${event.c.day}/${event.c.year}`
                );
              }
            }}
            datePickerProps={{
              value: column.getFilterValue() || null,
            }}
          />
        ),
        id: 'completedDate',
        filterFn: (row, columnId, filterValue) =>
          dateFiltering(row, columnId, filterValue, 'Close'),
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'completedDate'),
      },
      {
        header: 'Completed %',
        accessorKey: 'completedPercentage',
        filterFn: 'includesString',
      },
      {
        header: 'Last Reminder Sent',
        accessorFn: (row) => showCreatedDate(row['lastReminderSent'] ?? ''),
        Filter: ({ column }) => (
          <TableDatePicker
            onChange={(event) => {
              if (!event) {
                column.setFilterValue('');
              } else {
                column.setFilterValue(
                  `${event.c.month}/${event.c.day}/${event.c.year}`
                );
              }
            }}
            datePickerProps={{
              value: column.getFilterValue() || null,
            }}
          />
        ),
        id: 'lastReminderSent',
        filterFn: (row, columnId, filterValue) =>
          dateFiltering(row, columnId, filterValue, 'Close'),
        sortingFn: (rowA, rowB) =>
          dateSortingFunction(rowA, rowB, 'lastReminderSent'),
      },
      {
        header: 'Total number of reminders sent',
        accessorKey: 'reminderCount',
        filterFn: 'includesString',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        filterVariant: 'multi-select',
        size: 250,
        minSize: 200,
        maxSize: 560,
        Filter: ({ column }) => (
          <MultiSelectDropdown
            width='100% !important'
            dropDownItem={statusDropdown}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              {row.original.type === '360' ? (
                <div>
                  {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                  <span style={{ marginRight: '8px' }}>
                    {renderedCellValue}
                  </span>
                  <span style={{ color: '#CCCCCC' }}>|</span>
                  <ViewRatersButton
                    onClick={() =>
                      onRatersButtonClick(row.original as IAssessmentData)
                    }
                    text='view raters'
                  />
                </div>
              ) : (
                <span>{renderedCellValue}</span>
              )}
            </>
          );
        },
      },
    ],
    [filteredAssignmentState]
  );

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        {' '}
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Title
              text={'Assignments'}
              sx={{
                fontSize: '28px',
              }}
            />
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <Title
              text={`${
                filteredAssignmentState?.length
                  ? filteredAssignmentState?.length
                  : '0'
              } total assignments`}
              sx={{
                fontSize: '20px',
              }}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        <ClientAssessmentsTable
          data={assessmentData ?? []}
          columns={columns}
          tableInstanceRef={tableInstanceRef}
          state={{ showSkeletons: isLoading }}
          initialState={{
            sorting: [{ id: 'participant', desc: false }],
            showColumnFilters: true,
          }}
          muiTableBodyCellProps={({ cell, row }) => ({
            onClick: () => {
              const selection = window.getSelection()!.toString();
              if (
                !cell.id.includes('mrt-row-actions') &&
                !cell.id.includes('status') &&
                !selection
              ) {
                if (row.original.projectId) {
                  navigate(
                    `${routes.company.url}${companyId}${
                      routes.company.projects.url
                    }/${(row.original as IAssessmentData).projectId}`
                  );
                } else {
                  toggleErrorSnackbar({
                    message:
                      'Project does not exist, please reach out to Managed Services',
                  });
                }
              }
            },
            sx: {
              cursor: !cell.id.includes('mrt-row-actions')
                ? 'pointer'
                : 'default',
            },
          })}
        />
        <RatersModal />
      </>
    </TabContent>
  );
}
