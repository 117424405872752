import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IAssessmentGenerateRequest,
  IAssessmentGenerateResponse,
  IAssessmentGenerateTransformResponse,
} from './types/assessment';

export const assessmentApi = createApi({
  reducerPath: 'assessment/api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ASSESSMENT_API_KEY,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      if (token) headers.set('authorization', `Bearer ${token}`);
      headers.set('Accept', 'application.json');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Assessment'],
  endpoints: (builder) => ({
    generateAssessmentReport: builder.mutation<
      IAssessmentGenerateTransformResponse,
      IAssessmentGenerateRequest
    >({
      query: (payload) => ({
        url: 'generator/',
        method: 'POST',
        body: JSON.stringify(payload),
      }),
      transformResponse: (response: IAssessmentGenerateResponse) => {
        if (response.status === 200 && response.state === 'completed') {
          window.open(response.download_link, '_blank');
          return { status: 'success' };
        }
        if (response.error) {
          return { status: 'error', message: response.error };
        }
        return {
          status: 'error',
          message: 'Unable to generate assessment report',
        };
      },
      invalidatesTags: ['Assessment'],
    }),
  }),
});

export const { useGenerateAssessmentReportMutation } = assessmentApi;
