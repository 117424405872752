import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import Loader from '../commons/Loader';
import ProvisioningError from '../organisms/provisioning/ProvisioningError';
import UserPrivacyAndPolicy from '../organisms/provisioning/UserPrivacyAndPolicy';
import { IMeData, IMeResponse } from '../store/api/v2/types/auth';
import UserLanding from '../pages/UserLanding';
import {
  useLazyGetMeJwtTokenQuery,
  useLazyGetMeQuery,
  useLazyGetPrivacyPolicyVersionsQuery,
  useSetMeMutation,
} from '../store/api/v2/auth';
import Applications, { IApplication } from '../commons/Applications';
import { populatePPState } from '../store/api/v2/helpers';
import { useLazyGetClientByIdQuery } from '../store/api/clients';
import { useActions } from '../hooks/actions';
import { userTypeHandler } from '../utils/helpers';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [getPrivacyPolicyVersions] = useLazyGetPrivacyPolicyVersionsQuery();
  const [getClientById] = useLazyGetClientByIdQuery();
  const [getMe] = useLazyGetMeQuery();
  const [getMeJwtToken] = useLazyGetMeJwtTokenQuery();
  const [setMe] = useSetMeMutation();

  const [provisioningError, setProvisioningError] = useState(false);
  const [provisioningSuccess, setProvisioningSuccess] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const [ppState, setPPState] = useState<IApplication[]>(Applications);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [meData, setMeData] = useState<IMeData | null>(null);

  const { addUserInformation } = useActions();

  useEffect(() => {
    if (!meData) {
      getMeJwtToken();
      getMe()
        .unwrap()
        .catch(async (e) => {
          if (e?.data?.detail === 'User Account missing') {
            const oktaProfile = {
              userName: authState?.idToken?.claims.preferred_username || '',
              firstName: authState?.idToken?.claims.firstName || '',
              lastName: authState?.idToken?.claims.lastName || '',
              oktaGroups: authState?.idToken?.claims.groups || '',
            };

            const createdUser = await setMe(oktaProfile)
              .unwrap()
              .then((res) => {
                setMeData(res.data);
                return res;
              })
              .catch(() => {
                setProvisioningError(true);
              });
            return createdUser;
          } else {
            setProvisioningError(true);
          }
        })
        .then(async (meResponse: IMeResponse | void) => {
          const { data: me } = meResponse as IMeResponse;
          const { data: ppv } = await getPrivacyPolicyVersions().unwrap();
          setMeData(me as IMeData);

          const { roles, clientId, firstName, lastName, userName } = me;

          addUserInformation({
            firstName,
            lastName,
            emailAddress: userName,
          });

          if (roles[0].name! === 'Client Users' && clientId) {
            const cpp = await getClientById(clientId).unwrap();
            return { me, ppv, clientApps: cpp?.data[0].applications };
          }

          return { me, ppv };
        })
        .then(({ me, ppv, clientApps }) => {
          const refinedPrivacyPolicies = populatePPState(
            me,
            ppv,
            Applications,
            clientApps
          );
          setIsPrivacyAccepted(
            refinedPrivacyPolicies.some((rpp) => rpp.enabled && !rpp.checked)
              ? false
              : true
          );
          setPPState(refinedPrivacyPolicies);
          setProvisioningSuccess(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (!authState || !authState?.isAuthenticated) {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin
    );
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
    return <Loader />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (provisioningError) {
    return <ProvisioningError />;
  }

  const userType = meData?.roles[0]?.name;

  const myPersonas = meData?.myPersonas;

  if (!isLoading && provisioningSuccess && meData) {
    // Check if user is not of type 'Client User' to load respective screen
    if (
      userTypeHandler(myPersonas) === 'Client' &&
      userType !== 'Client Users'
    ) {
      return <Outlet />;
    } else if (!isPrivacyAccepted) {
      return (
        <UserPrivacyAndPolicy
          ppState={ppState}
          setPPState={setPPState}
          setIsPrivacyAccepted={setIsPrivacyAccepted}
          meData={meData}
        />
      );
    } else if (userTypeHandler(myPersonas) === 'Global') {
      return <Outlet />;
    } else if (userType === 'Client Users') {
      return (
        <UserLanding
          ppState={ppState}
          meData={meData}
        />
      );
    }
  }
  return <Loader />;
};
