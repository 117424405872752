import React, { useMemo, useState } from 'react';
import Table from '../../../../atoms/table/Table';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import MultiSelectDropdown from '../../../../atoms/multiselect/MultiSelectDropdown';
import { SelectOptions } from '../../../../commons/types';
import { IGetRolesData } from '../../../../store/api/types/leadership';

interface IProps {
  data: IGetRolesData[] | [];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
  functionList: SelectOptions[];
  levelList: SelectOptions[];
  recordSelection: (records: Array<IGetRolesData | null>) => void;
}

const AddRoleToImpactGroupTable = ({
  data,
  isLoading,
  tableInstanceRef,
  functionList,
  levelList,
  recordSelection,
}: IProps) => {
  const [rowSelection, setRowSelection] = useState({});

  React.useEffect(() => {
    const selectedRecords: Array<IGetRolesData | null> = [];
    Object.keys(rowSelection).forEach((value: string) => {
      const idx = Number(value) as number;
      if (idx >= 0) {
        const record = data[idx];
        if (record) selectedRecords.push(record);
      }
    });
    recordSelection(selectedRecords);
  }, [data, recordSelection, rowSelection]);

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IGetRolesData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IGetRolesData>[]>(
    () => [
      {
        header: 'Roles',
        filterFn: 'includesString',
        accessorKey: 'name',
      },
      {
        header: 'Role ID',
        accessorKey: 'clientRoleCode',
        filterFn: 'includesString',
      },
      {
        header: 'Level',
        accessorKey: 'levelName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={levelList}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
      {
        header: 'Function',
        accessorKey: 'functionName',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={functionList}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
      },
      {
        header: '# of Impact Groups',
        accessorFn: () => '',
      },
      {
        header: '# of Leaders',
        accessorFn: () => '',
      },
    ],
    [functionList, levelList]
  );

  return (
    functionList &&
    levelList && (
      <Table
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        enableSelectAll={true}
        tableInstanceRef={tableInstanceRef}
        data={data ?? []}
        columns={columns as MRT_ColumnDef<object>[]}
        muiSelectCheckboxProps={{ color: 'default' }}
        state={{ rowSelection, showSkeletons: isLoading }}
      />
    )
  );
};

export default AddRoleToImpactGroupTable;
