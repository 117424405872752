import React, { useMemo } from 'react';
import Table from '../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { useActions } from '../../../hooks/actions';
import { ISkillCategoriesData } from '../../../store/api/types/leadership';

interface IProps {
  data?: ISkillCategoriesData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const SkillCategoryVLTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { openEditSkillCategoryVLModal } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: ISkillCategoriesData) => {
    openEditSkillCategoryVLModal(row);
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<ISkillCategoriesData>[]>(
    () => [
      {
        header: 'Category Name',
        accessorKey: 'name',
        filterFn: 'includesString',
      },
      {
        header: 'Category ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      // need to hide edit and delete action as per the requirement
      // renderRowActions={({ cell, row }) => (
      //   <>
      //     <IconButton
      //       id={cell.id}
      //       onClick={handleOpen}
      //     >
      //       <MoreVert />
      //     </IconButton>
      //     <Menu
      //       anchorEl={anchorEl}
      //       open={anchorEl?.id === cell.id}
      //       onClose={handleClose}
      //     >
      //       <MenuItem
      //         onClick={() => handleEdit(row.original as ISkillCategoriesData)}
      //       >
      //         Edit
      //       </MenuItem>
      //     </Menu>
      //   </>
      // )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default SkillCategoryVLTable;
