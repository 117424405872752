import { createSlice } from '@reduxjs/toolkit';

interface IReportsModalProps {
  isOpen: boolean;
  modalType: 'add';
  initialValues: { report: string };
}

const initialState: IReportsModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    report: '',
  },
};

const reportsModalSlice = createSlice({
  name: 'reportsModal',
  initialState,
  reducers: {
    closeReportsModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddReportsModal: () => ({
      ...initialState,
      isOpen: true,
    }),
  },
});

export const reportsModalActions = reportsModalSlice.actions;
export const reportsModalReducer = reportsModalSlice.reducer;
