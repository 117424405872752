import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

type IProps = {
  error?: boolean;
  tooltip?: string;
  label?: string;
  inputFieldProps?: TextFieldProps;
  datePickerProps?: object;
  onChange: (event: any) => void;
};

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    fontFamily: 'Avenir',
    fontWeight: '500 !important',
    fontSize: '16px',
    color: '#002136',
    background: 'rgb(255, 255, 255)',
    marginTop: '6px',
  },
  '& .MuiInputBase-input': {
    padding: '13px 0 13px 16px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    pointerEvents: 'none',
  },
}));

const TableDatePicker = ({
  onChange,
  inputFieldProps,
  datePickerProps,
}: IProps) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          onChange={onChange}
          value={''}
          inputFormat={'MMM dd, yyyy'}
          disableMaskedInput={true}
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon,
          }}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          renderInput={(params) => {
            return (
              <StyledTextField
                fullWidth
                size='small'
                variant='outlined'
                disabled={true}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Search',
                  disabled: true,
                }}
                error={false}
                {...inputFieldProps}
              />
            );
          }}
          {...datePickerProps}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TableDatePicker;
