import React from 'react';
import { Box, styled } from '@mui/material';
import StatementLibraryHeader from '../organisms/headers/StatementLibraryHeader';
import TabMenu from '../atoms/tab/TabMenu';
import TabMenuItem from '../atoms/tab/TabMenuItem';
import FunctionalImpact from './Statement/FunctionalImpact';
import OrganizationalImpact from './Statement/OrganizationalImpact';
import LeadershipCapability from './Statement/LeadershipCapability';
import LeadershipPotential from './Statement/LeadershipPotential';
import CultureImpact from './Statement/CultureImpact';
import ImportStatementModal from '../organisms/modals/ImportStatementModal';
import StatementLibraryWarningModal from '../organisms/modals/StatementLibraryWarningModal';
import { useAppSelector } from '../hooks/redux';
import ErrorsModal from '../organisms/modals/ErrorsModal';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: 'auto',
  paddingLeft: '20px',
  maxWidth: '1200px',
  '.tabContent': {
    paddingTop: '40px',
  },
});

const StatementLibrary = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const isImportModalOpen = useAppSelector(
    (state) => state.importStatementModal.isOpen
  );
  const isErrorModalOpen = useAppSelector((state) => state.errorsModal.isOpen);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <StatementLibraryHeader />
      <StyledContentWrapper>
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={'Business Impact: Functional Impact'}
          />
          <TabMenuItem
            index={1}
            label={'Business Impact: Organizational Impact'}
          />
          <TabMenuItem
            index={2}
            label={'Leadership Capability'}
          />
          <TabMenuItem
            index={3}
            label={'Leadership Potential'}
          />
          <TabMenuItem
            index={4}
            label={'Culture Impact'}
          />
        </TabMenu>
        <Box className='tabContent'>
          <FunctionalImpact
            index={0}
            value={tabValue}
            skillType={'Functional Impact'}
          />
          <OrganizationalImpact
            index={1}
            value={tabValue}
            skillType={'Organizational Impact'}
          />
          <LeadershipCapability
            index={2}
            value={tabValue}
            skillType={'Capabilities'}
          />
          <LeadershipPotential
            index={3}
            value={tabValue}
            skillType={'Potential'}
          />
          <CultureImpact
            index={4}
            value={tabValue}
            skillType={'Purpose'}
          />
        </Box>
      </StyledContentWrapper>
      {isImportModalOpen && <ImportStatementModal />}
      {isErrorModalOpen && <ErrorsModal />}
      <StatementLibraryWarningModal />
    </Box>
  );
};

export default StatementLibrary;
