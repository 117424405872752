import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IGetProjectsResponse, IGetProjectsPayload } from '../types';

export const tazioApi = createApi({
  reducerPath: 'tazio/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}clients/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Assessment'],
  endpoints: (builder) => ({
    getProjects: builder.mutation<IGetProjectsResponse, IGetProjectsPayload>({
      query: (payload) => ({
        url: 'get-projects/',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: IGetProjectsResponse) => ({
        status: response.status,
        data: response.data,
        projectsDropdown: response.data?.map((el) => ({
          keyName: `${el.projectID} - ${el.reference}` || '',
          keyValue: `${el.projectID}` || '',
        })),
      }),
    }),
  }),
});

export const { useGetProjectsMutation } = tazioApi;
