import React, { useMemo } from 'react';
import Table from '../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { IClientImpactModuleData } from '../../../store/api/types/leadership';
import { useActions } from '../../../hooks/actions';
import { useDeleteImpactModuleMutation } from '../../../store/api/leadership';

interface IProps {
  data?: IClientImpactModuleData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const ImpactModulesVLTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    openEditImpactModuleVLModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const [deleteImpactModule] = useDeleteImpactModuleMutation();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: IClientImpactModuleData) => {
    openEditImpactModuleVLModal(row);
    setAnchorEl(null);
  };

  const handleDelete = (impactModuleId: string) => {
    if (impactModuleId) {
      deleteImpactModule(impactModuleId)
        .unwrap()
        .then(() =>
          toggleSuccessSnackbar({
            message: 'Impact Module Deleted Successfully',
          })
        )
        .catch(({ data }) => toggleErrorSnackbar({ message: data.detail }));
    }
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<IClientImpactModuleData>[]>(
    () => [
      {
        header: 'Module Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
        size: 400,
      },
      {
        header: 'ID',
        accessorKey: 'code',
        filterFn: 'includesString',
        size: 400,
      },
      {
        header: 'Impact Module Abbreviation',
        accessorKey: 'abbreviation',
        filterFn: 'includesString',
        Cell: ({ row }) => row.original.abbreviation,
      },
      {
        header: 'Statements in Module',
        accessorFn: () => '',
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      state={{ showSkeletons: isLoading }}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                handleEdit(row.original as IClientImpactModuleData)
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleDelete((row.original as IClientImpactModuleData).id || '')
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    />
  );
};

export default ImpactModulesVLTable;
