import Select, { SelectProps } from '@mui/material/Select';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Box, styled } from '@mui/material';
import { Clear } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import HpTooltip from '../tooltip/HpTooltip';
import { DropdownTypes } from '../../commons/types';

interface IProps {
  tooltip?: string;
  label?: string;
  dropDownItem: Array<DropdownTypes | null>;
  clearfunction?: () => void;
}

const StyledWrapper = styled(Box)(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '8px',
}));

const StyledSpan = styled('span')(() => ({
  width: '10%',
  display: 'flex',
  position: 'relative',
  margin: '11px auto',
  left: '-24px',
  color: 'rgba(0, 0, 0, 0.54)',
}));

const StyledSelectLabel = styled('span')(() => ({
  fontFamily: 'Avenir',
  color: 'rgba(0, 0, 0, 0.87)',
  display: 'flex',
}));

const StyledSelect = styled(Select)(() => ({
  '& .MuiSelect-select': {
    padding: '11px 16px',
    width: '90%',
    background: '#fff',
    height: '1.4375em !important',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0, 0, 0, 0.87)',
  },
  '& .MuiSvgIcon-root': {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '10px',
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  padding: '0 20px 0 20px',
  borderBottom: '1px solid #CCC',
  lineHeight: 2.5,
  '&:hover': {
    color: '#fff',
    background: '#18768C',
  },
  '&:focus': {
    color: '#fff',
    background: '#18768C',
  },
  '&.Mui-selected:hover': {
    color: '#fff',
    backgroundColor: '#646a7c',
  },
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#646a7c',
  },
}));

const SelectDropdown = ({
  tooltip,
  dropDownItem,
  label,
  clearfunction,
  ...extraProps
}: IProps & Omit<SelectProps, 'variant'>) => {
  return (
    <StyledWrapper>
      <>
        {label ? (
          <StyledSelectLabel>
            {label}
            {tooltip && <HpTooltip title={tooltip} />}
          </StyledSelectLabel>
        ) : (
          ''
        )}
        <StyledSelect
          fullWidth
          displayEmpty={true}
          size={'small'}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '10px',
                border: '1px solid #646A7C',
              },
            },
          }}
          IconComponent={KeyboardArrowDownOutlinedIcon}
          {...extraProps}
        >
          {dropDownItem &&
            dropDownItem.length > 0 &&
            dropDownItem.map((el) => (
              <StyledMenuItem
                key={el?.keyValue}
                value={el?.keyValue}
              >
                {el?.keyName}
              </StyledMenuItem>
            ))}
          ;
        </StyledSelect>
        {extraProps.value && (
          <StyledSpan onClick={clearfunction}>
            <Clear
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: '#0B0C10',
                },
              }}
            />
          </StyledSpan>
        )}
      </>
    </StyledWrapper>
  );
};

export default SelectDropdown;
