import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import Button from '../../../atoms/button/Button';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import { useExportCohortMutation } from '../../../store/api/assessmentStructure';

interface IProps {
  open: boolean;
  close: () => void;
}

const StyledUl = styled('ul')(() => ({
  padding: '0px 3px',
}));

const StyledLi = styled('li')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AssessmentCohortExportSummaryModal = ({ open, close }: IProps) => {
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const [exportCohort] = useExportCohortMutation();

  const leadersData = useAppSelector(
    (state) => state.assessmentCohortExportModal.rowData
  );
  const selectedleadersCount = leadersData.length;

  // Array to store leaders with export summary
  const leadersWithExportSummary: any[] = [];

  // Array to store leaders without export summary
  const leadersWithoutExportSummary: any[] = [];

  const exportCohortSummaryButton = () => {
    const assessmentIdList = leadersWithExportSummary.map(
      (item) => item.assessmentId
    );
    exportCohort({ assessment_ids: assessmentIdList })
      .unwrap()
      .then(() => {
        toggleSuccessSnackbar({
          message: 'Exported successfully',
        });
        close();
      })
      .catch(() => {
        toggleErrorSnackbar({
          message: 'Error exporting cohort summary',
        });
      });
  };

  for (let i = 0; i < leadersData.length; i++) {
    if (
      leadersData[i].cbiStatus === 'Summary ready for cohort review' ||
      leadersData[i].cbiStatus === 'In cohort review' ||
      leadersData[i].cbiStatus === 'Final summary exported'
    ) {
      leadersWithExportSummary.push(leadersData[i]);
    } else {
      leadersWithoutExportSummary.push(leadersData[i]);
    }
  }

  return (
    <Modal
      open={open}
      onClose={close}
    >
      <ModalTitle
        onClose={close}
      >{`${leadersWithExportSummary.length} of ${selectedleadersCount} selected leaders can be included in the cohort summary export.`}</ModalTitle>
      <ModalBody>
        <span>The following leaders are not ready to be included.</span>
        <StyledUl>
          {leadersWithoutExportSummary.map((el, i) => (
            <StyledLi key={i}>{`${el.leaderName}`}</StyledLi>
          ))}
        </StyledUl>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Continue with export'}
          type='button'
          disabled={!leadersWithExportSummary.length}
          onClick={exportCohortSummaryButton}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={close}
        />
      </ModalActions>
    </Modal>
  );
};

export default AssessmentCohortExportSummaryModal;
