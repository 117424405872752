import React from 'react';
import { Box, styled } from '@mui/material';
import TabMenu from '../../atoms/tab/TabMenu';
import TabMenuItem from '../../atoms/tab/TabMenuItem';
import StatementHeader from '../../organisms/headers/Company/Statements/StatementHeader';
import FunctionalImpact from './Statements/FunctionalImpact';
import OrganizationalImpact from './Statements/OrganizationalImpact';
import LeadershipCapability from './Statements/LeadershipCapability';
import LeadershipPotential from './Statements/LeadershipPotential';
import CultureImpact from './Statements/CultureImpact';
import ImportStatementModal from '../../organisms/modals/ImportStatementModal';
import ErrorsModal from '../../organisms/modals/ErrorsModal';
import { useAppSelector } from '../../hooks/redux';
import StatementLibraryWarningModal from '../../organisms/modals/StatementLibraryWarningModal';

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: 'auto',
  paddingLeft: '20px',
  maxWidth: '1200px',
  '.tabContent': {
    paddingTop: '40px',
  },
});

const Statements = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const isImportModalOpen = useAppSelector(
    (state) => state.importStatementModal.isOpen
  );
  const isErrorModalOpen = useAppSelector((state) => state.errorsModal.isOpen);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px - 66px)',
        overflowY: 'scroll',
      }}
    >
      <StatementHeader />
      <StyledContentWrapper>
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={'Business Impact: Functional Impact'}
          />
          <TabMenuItem
            index={1}
            label={'Business Impact: Organizational Impact'}
          />
          {/* <TabMenuItem
            index={2}
            label={'Leadership Capability'}
          />
          <TabMenuItem
            index={3}
            label={'Leadership Potential'}
          />
          <TabMenuItem
            index={4}
            label={'Culture Impact'}
          /> */}
        </TabMenu>
        <Box className='tabContent'>
          <FunctionalImpact
            index={0}
            value={tabValue}
          />
          <OrganizationalImpact
            index={1}
            value={tabValue}
          />
          {/* <LeadershipCapability
            index={2}
            value={tabValue}
          />
          <LeadershipPotential
            index={3}
            value={tabValue}
          />
          <CultureImpact
            index={4}
            value={tabValue}
          /> */}
        </Box>
      </StyledContentWrapper>
      {isImportModalOpen && <ImportStatementModal />}
      {isErrorModalOpen && <ErrorsModal />}
      <StatementLibraryWarningModal />
    </Box>
  );
};

export default Statements;
