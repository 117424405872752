const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || '';
const ISSUER = process.env.REACT_APP_OKTA_DOMAIN || '';
const CALLBACK_PATH = '/login/callback';
const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_PATH}`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: `https://${ISSUER}/oauth2/default`,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
  },
};
