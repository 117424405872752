import { createSlice } from '@reduxjs/toolkit';

interface IRemoveLeadersModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  removeLeadersData: Array<any>;
}

const initialState: IRemoveLeadersModalProps = {
  isOpen: false,
  modalType: 'add',
  removeLeadersData: [],
};

const removeLeadersModalSlice = createSlice({
  name: 'removeLeadersModal',
  initialState,
  reducers: {
    openRemoveLeadersModal: (state, action) => ({
      ...initialState,
      isOpen: true,
      removeLeadersData: action.payload,
    }),
    closeRemoveLeadersModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const removeLeadersModalActions = removeLeadersModalSlice.actions;
export const removeLeadersModalReducer = removeLeadersModalSlice.reducer;
