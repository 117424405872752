import React from 'react';
import { Snackbar } from '@mui/material';
import AlertError from '../../atoms/alert/AlertError';
import AlertSuccess from '../../atoms/alert/AlertSuccess';
import AlertInfo from '../../atoms/alert/AlterInfo';
import DismissButton from '../../atoms/button/DismissButton';
import { useAppSelector } from '../../hooks/redux';
import { useActions } from '../../hooks/actions';

const HpSnackbar = () => {
  const snackbarState = useAppSelector((state) => state.snackbar);
  const { toggleCloseSnackbar } = useActions();

  const alterMap = {
    error: AlertError,
    success: AlertSuccess,
    info: AlertInfo,
  };

  const AlertComponent = alterMap[snackbarState.status!];

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    toggleCloseSnackbar();
  };

  return (
    <Snackbar
      open={snackbarState.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <AlertComponent
        onClose={handleClose}
        elevation={20}
        message={snackbarState.message!}
        action={<DismissButton onClick={handleClose} />}
      />
    </Snackbar>
  );
};

export default HpSnackbar;
