import * as _ from 'lodash';
import { IApplicationAccessData, IConfigurationData } from './types';
import {
  IAssessmentData,
  ILeadersData,
  ISelfAssessmentResponse,
  IType360AssessmentResponse,
} from './types/assessmentStatusDashboard';
import { IParticipantTypes, IProjectsData } from '../../commons/types';
import { IProjectData } from './types/projects';

export const transformApplicationAccessPayload = (
  data: IApplicationAccessData,
  clientData: any // need to use any type to avoid type mismatch from response and adding them to payload
) => {
  return {
    name: clientData[0].name || '',
    oktaAPIClientId: clientData[0].oktaAPIClientId || '',
    oktaGroup: clientData[0].oktaGroup || '',
    website_url: clientData[0].websiteUrl || '',
    isStarred: true,
    useMasterRole: clientData[0].use_master_role || false,
    clientId: clientData[0].id,
    applications: {
      navigator: {
        isEnabled: data?.navigator ?? false,
        apiKey: data?.navigatorApiKey ?? '',
        apiUsername: data?.navigatorApiUsername ?? '',
        apiPassword: data?.navigatorApiPassword ?? '',
        url: data?.navigatorUrl ?? '',
      },
      insights: {
        isEnabled: data?.insight ?? false,
        url: data?.tazioApiDomain ?? '',
        apiKey: data?.tazioApiKey ?? '',
        accountId: data?.tazioAccountId ?? '',
        projectId: data?.tazioProjectId ?? '',
        bulkImportAssessmentsEnabled:
          clientData[0]?.applications?.insights?.bulkImportAssessmentsEnabled ??
          false,
      },
      journey: {
        isEnabled: data?.journey ?? false,
        branchId: data?.branchId ?? '',
        branchName: data?.branchName ?? '',
        logoAttachmentId: data?.logoAttachmentId || '',
        s3UrlCache: data?.s3UrlCache ?? '',
        s3UrlExpiresTs: new Date() ?? '',
        logoId: data?.id ?? '',
      },
    },
  };
};

export const transformConfigurationPayload = (
  data: IConfigurationData,
  clientData: any // need to use any type to avoid type mismatch from response and adding them to payload
) => {
  return {
    name: data.companyName || '',
    oktaAPIClientId: data.oktaAppIDForAPIs || '',
    oktaGroup: data.clientGroupName || '',
    website_url: clientData[0].websiteUrl || '',
    isStarred: true,
    useMasterRole: clientData[0].use_master_role || false,
    clientId: clientData.id,
    applications: {
      navigator: {
        isEnabled: clientData[0]?.applications?.navigator.isEnabled ?? false,
        apiKey: clientData[0]?.applications?.navigator?.apiKey ?? '',
        apiUsername: clientData[0]?.applications?.navigator?.apiUsername ?? '',
        apiPassword: clientData[0]?.applications?.navigator.apiPassword ?? '',
        url: clientData[0]?.applications?.navigator.url ?? '',
      },
      insights: {
        isEnabled: clientData[0]?.applications?.insights.isEnabled ?? false,
        url: clientData[0]?.applications?.insights.url ?? '',
        apiKey: clientData[0]?.applications?.insights.apiKey ?? '',
        accountId: clientData[0]?.applications?.insights.accountId ?? '',
        projectId: clientData[0]?.applications?.insights.projectId ?? '',
        bulkImportAssessmentsEnabled:
          clientData[0]?.applications?.insights?.bulkImportAssessmentsEnabled ??
          false,
      },
      journey: {
        isEnabled: clientData[0]?.applications?.journey?.isEnabled ?? false,
        branchName: clientData[0]?.applications?.journey?.branch_name ?? '',
        branchId: clientData[0]?.applications?.journey?.branchId ?? '',
      },
    },
    accountName: data.accountName,
    numberOfEmployees: Number(data.numberOfEmployees),
    annualRevenue: data.annualRevenue,
    primaryCompanyOwnership: data.primaryCompanyOwnership,
    primaryHandSIndustry: data.primaryHandSIndustry,
    NAICSIndustry: data.NAICSIndustry,
    closedWonHdCount: Number(data.closedWonHdCount),
  };
};

// Downloads
export const downloadFile = (blob: Blob, filename: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const getDatedFileName = (fileName: string) => {
  const currentDate = new Date();
  return `${fileName}_${currentDate
    .toISOString()
    .substring(0, 10)
    .replace(/\D/g, '')}.xlsx`;
};

export const saveFileNameAsCSV = (fileName: string) => {
  const currentDate = new Date();
  return `${fileName}_${currentDate
    .toISOString()
    .substring(0, 10)
    .replace(/\D/g, '')}.csv`;
};

export const saveFileNameAsJSON = (fileName: string) => {
  const currentDate = new Date();
  return `${fileName}_${currentDate
    .toISOString()
    .substring(0, 10)
    .replace(/\D/g, '')}.json`;
};

export const getSelfAssessmentTableData = (
  selfAssessment: ISelfAssessmentResponse
) => {
  // Getting required fields from self assessment API
  const typeSelfProjectResultsData = _.map(
    selfAssessment['results']['Projects'][0],
    (project) => _.omit(project, 'ProjectID')
  );
  const finalTypeSelfProjectResultsData1 = typeSelfProjectResultsData.map(
    (obj) => {
      return obj['Project Results'].map((element) => ({
        ...element,
        'Project Ref': obj['Project Ref'],
        'Project Start Date': obj['Project Start Date'],
        'Project End Date': obj['Project End Date'],
      }));
    }
  );
  const flatTypeSelfProjectResults = _.flatten(
    finalTypeSelfProjectResultsData1
  );

  // Structuring the above Self Assessment data from above to table fields format
  const typeSelfAssessmentsData: IAssessmentData[] =
    flatTypeSelfProjectResults.map((obj) => {
      return {
        participant:
          obj['Participant First Name'] + ' ' + obj['Participant Last Name'],
        assessment: obj['Assessment Name'],
        project: obj['Project Ref'],
        projectStartDate: obj['Project Start Date'],
        projectEndDate: obj['Project End Date'],
        email: obj['Participant Email'],
        type: 'Self Assessment',
        status: obj['Assessment Status'],
        id: obj['Assessment ID'],
        raters: [],
        completedDate: obj['Application Completed Date'],
        completedPercentage: obj['Assessment Completion'],
        lastReminderSent: obj['Latest Reminder'],
        reminderCount: obj['Reminder Count'],
        raterTypes: [],
      };
    });

  return typeSelfAssessmentsData;
};

export const getType360AssessmentTableData = (
  Type360Assessment: IType360AssessmentResponse
) => {
  // Getting required fields from 360 assessment API
  const type360ProjectResultsData = _.map(
    Type360Assessment['results']['Projects'][0],
    (project) => _.omit(project, 'ProjectID')
  );

  const finalType360ProjectResultsData1 = type360ProjectResultsData
    .map((obj) => {
      if (obj['Project Results'] !== undefined) {
        return obj['Project Results'].map((element) => {
          const keys = Object.keys(element);
          return {
            ...element[keys[0]],
            'Project Ref': obj['Project Ref'],
            'Project Start Date': obj['Project Start Date'],
            'Project End Date': obj['Project End Date'],
          };
        });
      }
    })
    .filter(function (element) {
      return element !== undefined;
    });

  const flatType360ProjectResults = _.flatten(finalType360ProjectResultsData1);

  // Structuring the above 360 Assessment data from above to table fields format
  const type360AssessmentsData: IAssessmentData[] =
    flatType360ProjectResults.map((obj) => {
      const raterThreshold: any = {}; // stores key value pair of required threshold of rater types
      const completedRaters: any = {}; // store key value pair of number completed assessments of rater types

      let noMatch = 0;
      let match = 0;
      let status;

      // storing key value pair of required threshold of rater types in raterThreshold
      obj!['Raters Required']?.forEach(
        (raterType) =>
          (raterThreshold[raterType['Rater Type']] =
            raterType['Required Raters'])
      );

      // storing key value pair of number completed assessments of rater types in completedRaters
      obj!.Raters.forEach((rater) => {
        if (rater['Rater Status'] == 'Completed') {
          if (!completedRaters[rater['Rater Type']]) {
            completedRaters[rater['Rater Type']] = 1;
          } else {
            completedRaters[rater['Rater Type']] =
              completedRaters[rater['Rater Type']] + 1;
          }
        }
      });

      // special case for checking if Focus Status is completed
      if (raterThreshold['Focus']) {
        if (obj?.['Focus Status'] == 'Completed') {
          completedRaters['Focus'] = 1;
        }
      }

      // Checking how many rater types crossed the threshold
      for (const key in raterThreshold) {
        if (completedRaters[key] < raterThreshold[key]) {
          noMatch = noMatch + 1;
        } else if (completedRaters[key] >= raterThreshold[key]) {
          match = match + 1;
        } else if (!completedRaters[key]) {
          noMatch++;
        }
      }

      // setting the status value based on 'match' and 'noMatch'
      if (noMatch > 0 && match > 0) {
        status = 'Incomplete';
      } else if (noMatch === 0 && match > 0) {
        status = 'Completed';
      } else {
        status = 'Not Started';
      }

      return {
        participant: obj!['Focus First Name'] + ' ' + obj!['Focus Last Name'],
        email: obj!['Focus Email'],
        assessment: obj!['Appraise Title'],
        project: obj!['Project Ref'],
        projectStartDate: obj!['Project Start Date'],
        projectEndDate: obj!['Project End Date'],
        type: '360',
        status: status,
        id: obj!['Appraise ID'],
        raters: obj!['Raters'],
        completedDate: obj!['Focus Application Completed Date'],
        completedPercentage: obj!['Focus Completion'],
        lastReminderSent: obj!['Focus Latest Reminder'],
        reminderCount: obj!['Focus Reminder Count'],
        raterTypes: obj!['Raters Required'],
      };
    });

  return type360AssessmentsData;
};

export const getAssessmentTableData = (
  selfAssessment: IAssessmentData[],
  Type360Assessment: IAssessmentData[]
) => {
  // Combining both assessment types
  return [...selfAssessment, ...Type360Assessment];
};

export const getProjectsTableData = (
  selfAssessment?: ISelfAssessmentResponse,
  type360Assessment?: IType360AssessmentResponse
) => {
  const statusCounter = (results: [], emailType: string) => {
    const statusResults = {
      participantsQty: 0,
      completed: 0,
      inProgress: 0,
      notStarted: 0,
      status: 'Not Started',
    };

    if (!results.length) return statusResults;

    const uniqParticipantsArr = _.values(_.groupBy(results, emailType));

    const arrOfStatusesPerParticipant = uniqParticipantsArr.map(
      (arrOfDuplicates) => {
        const resultsForDup = {
          completed: 0,
          inProgress: 0,
          notStarted: 0,
        };

        let statusCount = 0;

        if (emailType == 'Focus Email') {
          arrOfDuplicates.map((el: any) => {
            statusCount++;
            const raterThreshold: any = {}; // stores key value pair of required threshold of rater types
            const completedRaters: any = {}; // store key value pair of number completed assessments of rater types

            let noMatch = 0;
            let match = 0;
            let status;

            // storing key value pair of required threshold of rater types in raterThreshold
            el!['Raters Required']?.forEach(
              (raterType: any) =>
                (raterThreshold[raterType['Rater Type']] =
                  raterType['Required Raters'])
            );

            // storing key value pair of number completed assessments of rater types in completedRaters
            el!.Raters.forEach((rater: any) => {
              if (rater['Rater Status'] == 'Completed') {
                if (!completedRaters[rater['Rater Type']]) {
                  completedRaters[rater['Rater Type']] = 1;
                } else {
                  completedRaters[rater['Rater Type']] =
                    completedRaters[rater['Rater Type']] + 1;
                }
              }
            });

            // special case for checking if Focus Status is completed
            if (raterThreshold['Focus']) {
              if (el?.['Focus Status'] == 'Completed') {
                completedRaters['Focus'] = 1;
              }
            }

            // Checking how many rater types crossed the threshold
            for (const key in raterThreshold) {
              if (completedRaters[key] < raterThreshold[key]) {
                noMatch = noMatch + 1;
              } else if (completedRaters[key] >= raterThreshold[key]) {
                match = match + 1;
              } else if (!completedRaters[key]) {
                noMatch++;
              }
            }

            // setting the status value based on 'match' and 'noMatch'
            if (noMatch > 0 && match > 0) {
              status = 'Incomplete';
            } else if (noMatch === 0 && match > 0) {
              status = 'Completed';
            } else {
              status = 'Not Started';
            }

            if (status === 'Completed')
              resultsForDup.completed = resultsForDup.completed + 1;
            if (status === 'Not Started')
              resultsForDup.notStarted = resultsForDup.notStarted + 1;
            if (status === 'Incomplete')
              resultsForDup.inProgress = resultsForDup.inProgress + 1;
          });
        } else {
          arrOfDuplicates.map((el: any) => {
            statusCount++;
            if (el['Assessment Status'] === 'Completed')
              resultsForDup.completed = resultsForDup.completed + 1;
            if (el['Assessment Status'] === 'Not Started')
              resultsForDup.notStarted = resultsForDup.notStarted + 1;
            if (el['Assessment Status']?.includes('In Progress'))
              resultsForDup.inProgress = resultsForDup.inProgress + 1;
          });
        }

        if (statusCount === resultsForDup.completed) return 'Completed';
        if (resultsForDup.inProgress > 0) return 'In Progress';
        if (
          resultsForDup.completed > 0 &&
          resultsForDup.completed !== statusCount
        )
          return 'In Progress';

        return 'Not Started';
      }
    );

    arrOfStatusesPerParticipant?.forEach((el) => {
      if (el === 'Completed')
        statusResults.completed = statusResults.completed + 1;
      if (el === 'Not Started')
        statusResults.notStarted = statusResults.notStarted + 1;
      if (el === 'In Progress')
        statusResults.inProgress = statusResults.inProgress + 1;
    });

    statusResults.participantsQty = arrOfStatusesPerParticipant.length;

    if (statusResults.participantsQty === statusResults.completed)
      statusResults.status = 'Completed';
    if (statusResults.inProgress > 0) statusResults.status = 'Incomplete';
    if (
      statusResults.completed > 0 &&
      statusResults.completed !== statusResults.participantsQty
    )
      statusResults.status = 'Incomplete';

    return statusResults;
  };

  const selfAssessmentData = _.flatten(selfAssessment?.results?.Projects).map(
    (el) => {
      const results = statusCounter(
        el['Project Results'] as [],
        'Participant Email'
      );

      return {
        project: el['Project Ref'],
        projectID: el['ProjectID'],
        startDate: el['Project Start Date'],
        endDate: el['Project End Date'],
        ...results,
      };
    }
  );

  const type360AssessmentData = _.flatten(
    type360Assessment?.results?.Projects
  ).map((el) => {
    const flatResults = _.flatten(
      _.map(el['Project Results'], (el) => _.values(el))
    );

    const results = statusCounter(flatResults as [], 'Focus Email');

    return {
      project: el['Project Ref'],
      projectID: el['ProjectID'],
      startDate: el['Project Start Date'],
      endDate: el['Project End Date'],
      ...results,
    };
  });

  return [...selfAssessmentData, ...type360AssessmentData];
};

export const getLeadersTableData = (assessmentData: IAssessmentData[]) => {
  const result: ILeadersData[] = [];

  // Fetch all the statuses of a participant
  assessmentData.forEach(function (item) {
    const existing = result.filter(function (v: ILeadersData) {
      return v.email === item.email && v.project === item.project;
    });
    if (existing.length) {
      const existingIndex = result.indexOf(existing[0]);
      result[existingIndex].value = [
        ...result[existingIndex].value,
        item.status,
      ];
    } else {
      if (typeof item.status == 'string')
        result.push({
          participant: item.participant,
          project: item.project,
          email: item?.email,
          value: [item.status],
        });
    }
  });

  // Count of Complete, Incomplete, Not Started
  const leadersData = result.map((obj) => {
    const total = obj.value.length;
    let complete = 0;
    let incomplete = 0;
    let notStarted = 0;
    if (total > 0) {
      for (let i = 0; i < total; i++) {
        if (obj.value[i] === 'Completed') {
          complete++;
        } else if (obj.value[i] === 'Not Started') {
          notStarted++;
        } else {
          incomplete++;
        }
      }
    }
    return { ...obj, total, complete, notStarted, incomplete };
  });

  return leadersData;
};

export const isValidDate = (dateObject: Date) =>
  new Date(dateObject).toString() !== 'Invalid Date';

export const showCreatedDate = (timeStamp: string) => {
  if (isValidDate(new Date(timeStamp)) && timeStamp !== null) {
    const date = new Date(timeStamp);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const day = date.getDate();
    const fullYear = date.getFullYear();
    const month = date.getMonth();
    const year = fullYear.toString();

    return `${months[month]} ${day}, ${year}`;
  } else {
    return '-';
  }
};

export const showCreatedDateViaSlash = (timeStamp: string) => {
  if (timeStamp) {
    const date = new Date(timeStamp);

    const day = date.getDate();
    const fullYear = date.getFullYear();
    const month = date.getMonth() + 1;
    const year = fullYear.toString();

    return `${month < 10 ? '0' + month : month}/${
      day < 10 ? '0' + day : day
    }/${year.slice(2)}`;
  } else {
    return '-';
  }
};

export const dateSortingFunction = (
  rowA: any,
  rowB: any,
  fieldName: string
) => {
  const a = new Date(
    rowA.original[fieldName] !== '0000-00-00 00:00:00'
      ? rowA.original[fieldName]
      : '2020-12-12 22:59:59'
  ).getTime();
  const b = new Date(
    rowB.original[fieldName] !== '0000-00-00 00:00:00'
      ? rowB.original[fieldName]
      : '2020-12-12 22:59:59'
  ).getTime();
  return b > a ? -1 : 1;
};

export const dateFiltering = (
  row: any,
  columnId: any,
  filterValue: any,
  type: 'Start' | 'Close'
) => {
  const rowValue = row.original[columnId] ?? row.original.startDate;
  const x = new Date(rowValue);
  const y = new Date(filterValue);

  if (type === 'Start') {
    return x >= y;
  }
  if (type === 'Close') {
    return x <= y;
  }
  return true;
};

export const projectAssessmentDataHelper = (
  project: Array<any>
): Array<IProjectsData> => {
  return project.map((item) => {
    const projectObject = {
      projectId: '',
      projectName: '',
      projectResults: [],
    } as IProjectsData;
    projectObject.projectId = item['ProjectID'] || '';
    projectObject.projectName = item['Project Name'] || '';
    if (Array.isArray(item['Project Results'])) {
      projectObject.projectResults = item['Project Results'].map(
        (participant) => {
          const participantObject = {
            participantName: '',
            participantEmail: '',
          } as IParticipantTypes;

          participantObject.participantName =
            participant['Participant First Name'] || '';
          participantObject.participantName = `${participantObject.participantName} ${participant['Participant Last Name']}`;
          participantObject.participantEmail =
            participant['Participant Email'] || '';
          return participantObject;
        }
      );
    }
    return projectObject;
  });
};

export const serializeObjectToParams = (obj: {
  [key: string]: string | number;
}) => {
  const searchParams = new URLSearchParams();
  Object.keys(obj).forEach((key) =>
    searchParams.append(key, obj[key] as string)
  );
  return searchParams.toString();
};

// helper function to add project id in tazio assignment data
export const addProjectIdToTazio = (
  assessmentsData: IAssessmentData[],
  projectData: IProjectData[]
) => {
  const newData = assessmentsData.map((obj) => {
    const requiredProject = projectData?.find(
      (project) => obj.project.toLowerCase() === project.name.toLowerCase()
    );
    if (requiredProject) {
      return { ...obj, projectId: requiredProject.id };
    }
    return { ...obj, projectId: '' };
  });
  return newData;
};
export const set360TableData = (assessment: any) =>
  assessment.map((obj: any) => ({
    participant: obj['leaderName'],
    assessment: obj['assessment'],
    project: obj['project'],
    projectStartDate: obj['startDate'],
    projectEndDate: obj['closeDate'],
    email: obj['leaderEmail'],
    type:
      obj['ratersRequired'] || obj['appraisalStatus']
        ? '360'
        : 'Self Assessment',
    status: obj['assignmentStatus'],
    id: obj['id'],
    raters: obj['raters'] || [],
    completedDate: obj['applicationCompletedTs'],
    completedPercentage: obj['completion'],
    lastReminderSent: obj['latestReminderTs'],
    reminderCount: obj['reminderCount'],
    raterTypes: obj['ratersRequired'] || [],
  }));
