import { Button, styled } from '@mui/material';
import * as React from 'react';

export interface HDButtonTypes {
  onClick?: (arg0: any) => void;
  text:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  variant?: string | any;
  size?: string | any;
  startIcon?: any;
  endIcon?: any;
  disabled?: boolean;
  disableRipple?: boolean;
  sx?: object;
}

const HDButton = (props: HDButtonTypes) => {
  return <Button {...props}>{props.text}</Button>;
};

export default HDButton;
