import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useRemoveLeaderMutation } from '../../../../store/api/projects';
import RemoveLeadersErrorModal from './RemoveLeadersErrorModal';

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

const RemoveLeadersModal = () => {
  const params = useParams();
  const isOpen = useAppSelector((state) => state.removeLeadersModal.isOpen);

  const removeLeadersData = useAppSelector(
    (state) => state.removeLeadersModal.removeLeadersData
  );
  const { closeRemoveLeadersModal } = useActions();
  const { toggleSuccessSnackbar, toggleErrorSnackbar } = useActions();

  const [removeLeaderFromProject] = useRemoveLeaderMutation();

  const handleClose = () => {
    closeRemoveLeadersModal();
  };

  const onSubmit = async () => {
    const payload: {
      assignmentId: string;
      projectId: string | undefined;
      leaderProfileId: string;
    }[] = [];
    removeLeadersData.forEach((item) => {
      const leaderData = {
        assignmentId: item.assignmentId,
        projectId: params.projectId,
        leaderProfileId: item.leaderProfileId,
      };
      payload.push(leaderData);
    });
    payload.forEach((leaderRrecord) => {
      removeLeaderFromProject(leaderRrecord)
        .unwrap()
        .then(() => {
          toggleSuccessSnackbar({ message: 'User Removed Successfully' });
        })
        .catch((e) => {
          const error = e as { status: number; data: { detail: string } };
          toggleErrorSnackbar({ message: error.data.detail });
        });
    });
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      {removeLeadersData.some(
        (leader) =>
          leader.isManualAssigned &&
          removeLeadersData.some(
            (leader) => leader.staged || leader.staged === 0
          ) &&
          removeLeadersData.every(
            (leader) =>
              leader.assigned === 0 &&
              leader.complete === 0 &&
              leader.inProgress === 0
          )
      ) ? (
        <>
          <ModalTitle onClose={handleClose}>
            Removing {removeLeadersData.length} Leaders
          </ModalTitle>
          <ModalBody>
            Are you sure you want remove {removeLeadersData.length} leaders?
          </ModalBody>
          <ModalActions>
            <Button
              btnType={'PRIMARY'}
              text={'Remove Leaders'}
              onClick={onSubmit}
            />
            <Button
              btnType={'SECONDARY'}
              text={'Cancel'}
              onClick={handleClose}
            />
          </ModalActions>
        </>
      ) : (
        <RemoveLeadersErrorModal />
      )}
    </Modal>
  );
};

export default RemoveLeadersModal;
