import React, { useEffect } from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import Button from '../../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import FormWrapper from '../../../../molecules/form/FormWrapper';
import { useActions } from '../../../../hooks/actions';
import FormLabelDropdown from '../../../../molecules/form/FormLabelDropdown';
import { useGenerateAssessmentReportMutation } from '../../../../store/api/assessment';
import HDSpinner from '../../../../hd-ui-kit/HDSpinner';
import { useParams } from 'react-router';

interface IProps {
  open: boolean;
  close: () => void;
  csvReports: {
    accountId?: string;
    projectId?: string;
    reportsAvailable: [];
    status: boolean;
  };
}

interface IFormData {
  reportType: string;
  accountID: number;
  projectID: number;
  clientId?: string;
}

const ExtractDataToCSVModal = ({ open, close, csvReports }: IProps) => {
  const [generateReport, { isLoading }] = useGenerateAssessmentReportMutation();

  const params = useParams();

  const methods = useForm<IFormData>();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useActions();

  const onSubmit = async (formData: IFormData) => {
    formData.accountID = Number(csvReports.accountId);
    formData.projectID = Number(csvReports.projectId);
    formData.clientId = params.id;

    generateReport(formData)
      .unwrap()
      .then((res) => {
        if (res.status === 'error') {
          toggleErrorSnackbar({ message: res.message });
          close();
          return;
        }

        toggleSuccessSnackbar({ message: 'CSV Generated Successfully' });
        close();
      })
      .catch((e) => {
        const error = e as { status: number; data: { detail: string } };
        toggleErrorSnackbar({ message: error.data.detail });
        close();
      });
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset, close]);

  return (
    <Modal
      open={open}
      onClose={isLoading ? () => null : close}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={isLoading ? () => null : close}>
        {'Extract Data to CSV'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='download_csv'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelDropdown
            name='reportType'
            label='Data Type'
            dropDownItem={csvReports?.reportsAvailable || []}
            disabled={isLoading}
            placeholder='Select'
            rules={{ required: 'Please select a report' }}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        {isLoading ? (
          <Button
            btnType={'PRIMARY'}
            text={'Download Data'}
            type='submit'
            form='download_csv'
            disabled={true}
            endIcon={
              <HDSpinner
                size=''
                buttonColor={false}
              />
            }
          />
        ) : (
          <Button
            btnType={'PRIMARY'}
            text={'Download Data'}
            type='submit'
            form='download_csv'
            disabled={!!Object.values(errors).length}
          />
        )}
      </ModalActions>
    </Modal>
  );
};

export default ExtractDataToCSVModal;
