import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { useNavigate, useParams } from 'react-router';
import { routes } from '../../../../routes/routes';
import { IImpactGroupData } from '../../../../store/api/types/leadership';

interface IProps {
  data?: IImpactGroupData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const ImpactGroupsTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();

  const columns = useMemo<MRT_ColumnDef<IImpactGroupData>[]>(
    () => [
      {
        header: 'Impact Group',
        accessorKey: 'name',
        filterFn: 'includesString',
        size: 200, // added this width as per figma design
      },
      {
        header: 'ID',
        accessorKey: 'id',
        filterFn: 'includesString',
        size: 200, // added this width as per figma design
      },
      {
        header: 'Description',
        accessorKey: 'description',
        filterFn: 'includesString',
        size: 255, // added this width as per figma design
      },
      // For later API enhancement fields
      // {
      //   header: '# of roles',
      //   accessorFn: ({ roleProfileList }) =>
      //     roleProfileList ? roleProfileList.length : 0,
      //   filterFn: 'equals',
      // },
      // {
      //   header: '# of leaders assigned',
      //   accessorFn: ({ numberLeaders }) => numberLeaders || 0,
      //   filterFn: 'equals',
      //   size: 180, // added this width as per figma design
      // },
    ],
    []
  );

  return (
    <>
      <Table
        data={data ?? []}
        tableInstanceRef={tableInstanceRef}
        enableRowActions={false}
        columns={columns as MRT_ColumnDef<object>[]}
        muiTableBodyCellProps={({ cell, row }) => ({
          onClick: () => {
            const selection = window.getSelection()!.toString();
            if (!cell.id.includes('mrt-row-actions') && !selection) {
              navigate(
                `${routes.company.url}${companyId}${
                  routes.company.impactgroups.url
                }${(row.original as IImpactGroupData).id}`
              );
            }
          },
          sx: {
            cursor: !cell.id.includes('mrt-row-actions')
              ? 'pointer'
              : 'default',
          },
        })}
        state={{ showSkeletons: isLoading }}
      />
    </>
  );
};

export default ImpactGroupsTable;
