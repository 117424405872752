import React from 'react';
import LabelSearchDropdown from '../../atoms/dropdown/LabelSearchDropdown';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import { SelectOptions } from '../../commons/types';

interface IProps {
  label: string;
  tooltip?: string;
  onChange?: (value: SelectOptions | null) => void;
  placeholder?: string;
  disabled?: boolean;
  dropDownItem: Array<SelectOptions | null>;
}

export default function FormLabelDropdown({
  name,
  label,
  tooltip,
  dropDownItem,
  defaultValue = '',
  placeholder = '',
  onChange,
  disabled,
  ...extraProps
}: IProps & UseControllerProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...extraProps}
      render={({ field }) => (
        <LabelSearchDropdown
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            onChange && onChange(value);
          }}
          label={label}
          tooltip={tooltip}
          dropDownItem={dropDownItem}
          placeholder={placeholder}
          defaultValue={defaultValue}
          error={(!!errors[name] && (errors[name]?.message as string)) || ''}
          disabled={disabled}
        />
      )}
    />
  );
}
