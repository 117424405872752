import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../atoms/tab/TabContent';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import { MRT_TableInstance } from 'material-react-table';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import RoleTable from '../../organisms/tables/RoleTable';
import EditStatementRLModal from '../../organisms/modals/Role/EditStatementRLModal';
import { useLazyGetRolePurposeSkillsByCategoryQuery } from '../../store/api/leadership';

interface IProps {
  roleId?: string;
  skillType: string;
  client?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export default function CultureImpact({
  index,
  value,
}: ITabContentProps & IProps) {
  const [getPurposeStatements, { data: roleSkills }] =
    useLazyGetRolePurposeSkillsByCategoryQuery();

  React.useEffect(() => {
    if (value === 4) {
      getPurposeStatements({
        client: 'Global',
        skillType: 'Purpose',
      });
    }
  }, [getPurposeStatements, value]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Box sx={{ display: 'flex' }}>
              <Title
                text={'Culture Impact'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <Box>
                <HpTooltip title={'Culture Impact'} />
              </Box>
            </Box>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                roleSkills?.data?.length ? roleSkills?.data?.length : '0'
              }  total statements`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        {value === 4 && <EditStatementRLModal />}
        <RoleTable
          statementType='cultureImpact'
          isLoading={false}
          data={roleSkills?.data}
          tableInstanceRef={tableInstanceRef}
        />
      </>
    </TabContent>
  );
}
