import { Box, styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import checked from '../../../../assets/icons/checked.svg';
import close from '../../../../assets/icons/close.svg';
import { IReportLeaderType } from '../../../../store/api/types/projects';

const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginRight: '8px',
  marginBottom: '2.5px',
  width: '20px',
}));

const StyledText = styled('p')(({ type }: { type: 'success' | 'error' }) => ({
  fontFamily: 'Avenir',
  fontSize: '16px',
  fontWeight: '600',
  color: type === 'success' ? '#334255' : '#C02195',
}));

const StyledBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& .info-container': {
    display: 'flex',
  },
}));

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background: 'white',
  },
};

interface IProps {
  generateReports: () => void;
  isLoading: boolean;
  selectedReports: IReportLeaderType;
}

const LeadersReportGenerateModal = ({
  generateReports,
  isLoading,
  selectedReports,
}: IProps) => {
  const isOpen = useAppSelector(
    (state) => state.leadersReportGenerateModal.isOpen
  );

  const { closeLeadersReportGenerateModal } = useActions();

  const handleClose = () => {
    closeLeadersReportGenerateModal();
  };

  const onSubmit = async () => {
    generateReports();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>Generate Reports</ModalTitle>
      <ModalBody sx={{ backgroundColor: 'white' }}>
        <StyledBox>
          {!!selectedReports.generateReports.length && (
            <Box className='info-container'>
              <StyledImg
                src={checked}
                alt={'checked'}
              />
              <StyledText type='success'>
                {`Generate Reports for: ${selectedReports.generateReports.map(
                  (item) => item.name
                )}  `}
              </StyledText>
            </Box>
          )}
          {!!selectedReports.notGenerateReports.length && (
            <Box className='info-container'>
              <StyledImg
                src={close}
                alt={'close'}
              />
              <StyledText type='error'>
                {`Cannot generate report for: ${selectedReports.notGenerateReports}  `}
              </StyledText>
            </Box>
          )}
        </StyledBox>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={`${isLoading ? 'Generating...' : 'Generate Reports'}`}
          disabled={isLoading || !selectedReports.generateReports.length}
          onClick={onSubmit}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default LeadersReportGenerateModal;
