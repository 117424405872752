import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import AssessmentsHeader from '../../../organisms/headers/Company/Assessments/AssessmentsHeader';
import TabMenu from '../../../atoms/tab/TabMenu';
import TabMenuItem from '../../../atoms/tab/TabMenuItem';
import AssessmentsTab from './AssessmentsTab';
import Projects from './Projects';
import {
  useGet360AssessmentStatusMutation,
  useGetSelfAssessmentStatusMutation,
} from '../../../store/api/exportFile';
import { useParams } from 'react-router';
import { useActions } from '../../../hooks/actions';
import Leaders from './Leaders';
import {
  getAssessmentTableData,
  getLeadersTableData,
  getProjectsTableData,
  getSelfAssessmentTableData,
  getType360AssessmentTableData,
} from '../../../store/api/helpers';
import {
  IAssessmentData,
  ILeadersData,
  IProjectData,
} from '../../../store/api/types/assessmentStatusDashboard';
import { useLazyGetClientByIdQuery } from '../../../store/api/clients';
import { SummaryMetricsData } from '../../../atoms/header/SummaryMetrics';
import HpContainer from '../../../atoms/container/HpContainer';
import { userTypeHandler } from '../../../utils/helpers';
import { myPersonas } from '../../../store/api/v2/types/auth';
import { statusDropdown } from '../../../commons/projectPurposeDropdownData';

interface IProps {
  myPersonas?: myPersonas[];
}

const Assessments = ({ myPersonas }: IProps) => {
  const { id } = useParams();

  const [tabValue, setTabValue] = React.useState(0);
  const [isLoading, setISLoading] = React.useState(true);
  const [metricsData, setMetricsData] = React.useState<SummaryMetricsData[]>(
    []
  );
  const [tableFilters, setTableFilters] = React.useState<{
    project?: string;
    participant?: string;
  }>({});

  const [assessmentData, setAssessmentData] =
    React.useState<IAssessmentData[]>();
  const [projectData, setProjectData] = React.useState<IProjectData[]>([]);
  const [leaderData, setLeaderData] = React.useState<ILeadersData[]>([]);

  const [selfAssessmentError, setSelfAssessmentError] = React.useState(false);
  const [type360AssessmentError, setType360AssessmentError] =
    React.useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setTableFilters({});
  };

  const { toggleErrorSnackbar } = useActions();

  const [fetchClientTazioData, { data: clientTazioData }] =
    useLazyGetClientByIdQuery();

  const [getSelfAssessmentStatus, { data: selfAssessmentData }] =
    useGetSelfAssessmentStatusMutation();
  const [get360AssessmentStatus, { data: type360AssessmentData }] =
    useGet360AssessmentStatusMutation();

  useEffect(() => {
    if (userTypeHandler(myPersonas) === 'Global') {
      fetchClientTazioData(id as string);
    } else if (userTypeHandler(myPersonas) === 'Client') {
      const getAssessmentData = async () => {
        try {
          await getSelfAssessmentStatus({
            assessmentType: 'assessment',
          }).unwrap();
        } catch (err) {
          setSelfAssessmentError(true);
        }
        try {
          await get360AssessmentStatus({
            assessmentType: 'appraise',
          }).unwrap();
        } catch (err) {
          setType360AssessmentError(true);
        }
      };
      getAssessmentData();
    }
  }, [id]);

  useEffect(() => {
    if (
      clientTazioData &&
      clientTazioData?.data[0]?.applications?.insights?.accountId
    ) {
      const getAssessmentData = async () => {
        try {
          await getSelfAssessmentStatus({
            accountID:
              clientTazioData?.data[0]?.applications?.insights?.accountId,
            assessmentType: 'assessment',
          }).unwrap();
        } catch (err) {
          setSelfAssessmentError(true);
        }
        try {
          await get360AssessmentStatus({
            accountID:
              clientTazioData?.data[0]?.applications?.insights?.accountId,
            assessmentType: 'appraise',
          }).unwrap();
        } catch (err) {
          setType360AssessmentError(true);
        }
      };
      getAssessmentData();
    } else if (
      clientTazioData &&
      !clientTazioData?.data[0]?.applications?.insights?.accountId
    ) {
      setISLoading(false);
      toggleErrorSnackbar({
        message: 'Insights API credentials are blank or invalid',
      });
    }
  }, [clientTazioData]);

  useEffect(() => {
    if (
      selfAssessmentData?.status == 'Ok' &&
      type360AssessmentData?.status === 'Ok'
    ) {
      // fetch assessment data
      const selfAssessmentsData =
        getSelfAssessmentTableData(selfAssessmentData);
      const Type360AssessmentsData = getType360AssessmentTableData(
        type360AssessmentData
      );
      const assessmentsData = getAssessmentTableData(
        selfAssessmentsData,
        Type360AssessmentsData
      );

      // fetch project data
      const projectsData = getProjectsTableData(
        selfAssessmentData,
        type360AssessmentData
      );

      // fetch leaders data
      const leadersData = getLeadersTableData(assessmentsData);

      setAssessmentData(assessmentsData);
      setProjectData(projectsData);
      setLeaderData(leadersData);

      setISLoading(false);
    } else if (
      selfAssessmentData?.status == 'Ok' &&
      (type360AssessmentData?.status.toLowerCase() === 'error' ||
        type360AssessmentError)
    ) {
      // fetch assessment data
      const selfAssessmentsData =
        getSelfAssessmentTableData(selfAssessmentData);

      // fetch project data
      const projectsData = getProjectsTableData(
        selfAssessmentData,
        type360AssessmentData
      );

      // fetch leaders data
      const leadersData = getLeadersTableData(selfAssessmentsData);

      setAssessmentData(selfAssessmentsData);
      setProjectData(projectsData);
      setLeaderData(leadersData);

      setISLoading(false);

      toggleErrorSnackbar({
        message:
          'The system encountered an issue while loading the 360-assessment ' +
          'information. Please contact the managed service team for assistance',
      });
    } else if (
      type360AssessmentData?.status == 'Ok' &&
      (selfAssessmentData?.status.toLowerCase() === 'error' ||
        selfAssessmentError)
    ) {
      // fetch assessment data
      const Type360AssessmentsData = getType360AssessmentTableData(
        type360AssessmentData
      );

      // fetch project data
      const projectsData = getProjectsTableData(
        selfAssessmentData,
        type360AssessmentData
      );

      // fetch leaders data
      const leadersData = getLeadersTableData(Type360AssessmentsData);

      setAssessmentData(Type360AssessmentsData);
      setProjectData(projectsData);
      setLeaderData(leadersData);

      setISLoading(false);

      toggleErrorSnackbar({
        message:
          'The system encountered an issue while loading the self-assessment information. ' +
          'Please contact the managed service team for assistance',
      });
    } else if (
      (selfAssessmentData?.status.toLowerCase() === 'error' &&
        type360AssessmentData?.status.toLowerCase() === 'error') ||
      (selfAssessmentError && type360AssessmentError)
    ) {
      setISLoading(false);
      toggleErrorSnackbar({
        message:
          'The system encountered an issue while loading the self-assessment and 360-assessment information. ' +
          'Please contact the managed service team for assistance',
      });
    }
  }, [
    selfAssessmentData,
    type360AssessmentData,
    selfAssessmentError,
    type360AssessmentError,
  ]);

  useEffect(() => {
    const metricsArray = [
      {
        itemName: 'Total number of participants',
        itemQty: leaderData?.length || 0,
      },
      {
        itemName: 'Total number of assessments provisioned',
        itemQty: assessmentData?.length || 0,
      },
      {
        itemName: 'Total number of assessments completed',
        itemQty:
          assessmentData?.reduce((acc, el) => {
            if (el.status === 'Completed') {
              return acc + 1;
            }
            return acc;
          }, 0) || 0,
      },
    ];

    setMetricsData(metricsArray);
  }, [assessmentData, leaderData, projectData]);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 106px - 66px)',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <AssessmentsHeader metricsData={metricsData} />
      <HpContainer>
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={'Projects'}
          />
          <TabMenuItem
            index={1}
            label={'Participants'}
          />
          <TabMenuItem
            index={2}
            label={'Assessments'}
          />
        </TabMenu>
        <Box className='tabContent'>
          <Projects
            index={0}
            value={tabValue}
            projectData={projectData}
            isLoading={isLoading}
            setTabValue={setTabValue}
            setTableFilters={setTableFilters}
          />
          <Leaders
            index={1}
            value={tabValue}
            leaderData={leaderData}
            isLoading={isLoading}
            setTabValue={setTabValue}
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
          />
          <AssessmentsTab
            index={2}
            value={tabValue}
            statusDropdown={statusDropdown}
            assessmentData={assessmentData || []}
            isLoading={isLoading}
            tableFilters={tableFilters}
          />
        </Box>
      </HpContainer>
    </Box>
  );
};
export default Assessments;
