import React, { useRef } from 'react';
import { Box, styled } from '@mui/material';
import TabContent from '../../../atoms/tab/TabContent';
import Title from '../../../atoms/title/Title';
import { ITabContentProps } from '../../../commons/types';
import SubTitle from '../../../atoms/title/SubTitle';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { useGetSkillCategoriesQuery } from '../../../store/api/leadership';
import SkillCategoriesVLClientTable from '../../../organisms/tables/Company/VariableLibrary/SkillCategoriesVLClientTable';
import _ from 'lodash';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ClientSkillCategories = ({ index, value }: ITabContentProps) => {
  const { id } = useParams();

  const { data: { data: skillCategoryData } = {}, isLoading } =
    useGetSkillCategoriesQuery(id as string);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Skill Categories'}
              sx={{ fontSize: '28px' }}
            />
          </StyledHeaderWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${
              _.uniqBy(skillCategoryData, 'code')?.length
                ? _.uniqBy(skillCategoryData, 'code')?.length
                : '0'
            } total unique skill categories`}
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <SkillCategoriesVLClientTable
        data={_.uniqBy(skillCategoryData, 'code') ?? []}
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
      />
    </TabContent>
  );
};

export default ClientSkillCategories;
