import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { ILevelData } from '../../../../store/api/types/leadership';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useActions } from '../../../../hooks/actions';

interface IProps {
  data?: ILevelData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const LevelVLClientTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    openEditLevelVLClientModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row: ILevelData) => {
    openEditLevelVLClientModal(row);
    setAnchorEl(null);
  };

  const columns = useMemo<MRT_ColumnDef<ILevelData>[]>(
    () => [
      {
        header: 'Level Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
      },
      {
        header: 'System Name',
        accessorKey: 'systemName',
        filterFn: 'includesString',
      },
      {
        header: 'ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
      // It will be uncommented once it is done and returned in API response as per AC
      //   {
      //     header: '# of Roles with Level',
      //     accessorFn: () => '',
      //   },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEdit(row.original as ILevelData)}>
              Edit
            </MenuItem>
          </Menu>
        </>
      )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default LevelVLClientTable;
