import React from 'react';
import { Box, styled } from '@mui/material';
import HpContainer from '../container/HpContainer';

interface IProps {
  children: React.ReactNode;
}

const StyledWrapper = styled(Box)(() => ({
  background:
    'radial-gradient(89.54% 90.64% at 6.02% 9.36%, #E3ECEF 0%,' +
    ' rgba(241, 241, 241, 0) 100%)',
  display: 'flex',
  justifyContent: 'space-between',
}));

const DetailsHeaderWrapper = ({ children }: IProps) => {
  return (
    <StyledWrapper>
      <HpContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </HpContainer>
    </StyledWrapper>
  );
};

export default DetailsHeaderWrapper;
