import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ImportSubFunctionModalProps {
  isOpen: boolean;
  progress: number;
}

const initialState: ImportSubFunctionModalProps = {
  isOpen: false,
  progress: 0,
};

const importSubFunctionModalSlice = createSlice({
  name: 'importSubFunctionModal',
  initialState,
  reducers: {
    closeImportSubFunctionModal: (state) => {
      state.isOpen = false;
    },
    openImportSubFunctionModal: (state) => {
      state.isOpen = true;
      state.progress = 0;
    },
    setImportSubFunctionUploadProgress: (
      state,
      action: PayloadAction<number>
    ) => {
      state.progress = action.payload;
    },
  },
});

export const importSubFunctionModalActions =
  importSubFunctionModalSlice.actions;
export const importSubFunctionModalReducer =
  importSubFunctionModalSlice.reducer;
