import React from 'react';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import Button from '../../../../atoms/button/Button';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import HpTooltip from '../../../../atoms/tooltip/HpTooltip';
import { Box, styled } from '@mui/material';
import LabelDropdown from '../../../../atoms/dropdown/LabelDropdown';
import {
  useAssignImpactGroupToRoleMutation,
  useAssignRoleToImpactGroupMutation,
  useGetLeaderImpactGroupQuery,
} from '../../../../store/api/leadership';
import { useParams } from 'react-router-dom';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '10px',
  paddingTop: '20px',
  paddingBottom: '25px',
  boxShadow: '3px 0px 1px 0px #646A7C',
}));

const StyledLabel = styled('span')(() => ({
  fontFamily: 'Avenir',
  color: '#334255',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 300,
}));

const StyledRoleNameLabel = styled('span')(() => ({
  fontFamily: 'Aventa',
  color: '#334255',
  fontSize: '16px',
  lineHeight: '22.96px',
  fontWeight: 500,
}));

type SelectChangeEvent<T = string | unknown> =
  | (Event & { target: { value: T; name: string } })
  | React.ChangeEvent<HTMLInputElement>;

export default function AssignedRolesAnotherImpactGroupModal() {
  const isOpen = useAppSelector(
    (state) => state.assignedRolesAnotherImpactGroupModal.isOpen
  );
  const assignedRoles = useAppSelector(
    (state) => state.assignedRolesAnotherImpactGroupModal.data
  );

  const { id: clientId, groupId } = useParams();

  const {
    closeAssignedRolesAnotherImpactGroupModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
  } = useActions();

  const [assignedImpactGroupToRole] = useAssignImpactGroupToRoleMutation();
  const [assignRoleToImpactGroup] = useAssignRoleToImpactGroupMutation();

  const [selectedRoles, setSelectedRoles] = React.useState<
    Array<{ roleId: string; impactGroupId: string }>
  >([]);

  const handleClose = () => {
    closeAssignedRolesAnotherImpactGroupModal();
  };

  const { data: { data: impactGroupsData } = {} } =
    useGetLeaderImpactGroupQuery({
      id: clientId || '',
      offset: 0,
      limit: 1000,
    });

  const dropdownData = impactGroupsData
    ? impactGroupsData.map((item) => ({
        keyName: item.name || '',
        keyValue: item.id || '',
      }))
    : [];

  const filteredRoles =
    assignedRoles.filter((item) => item?.impactGroupAssigned) || [];
  const impactGroupRoles =
    assignedRoles.filter((item) => !item?.impactGroupAssigned) || [];

  const handleSubmit = () => {
    Promise.all([
      ...selectedRoles.map(async (role) => {
        const response = await assignedImpactGroupToRole({
          id: role.roleId,
          payload: {
            impactGroupAssigned: role.impactGroupId,
          },
        });
        return response;
      }),
      ...impactGroupRoles.map(async (role) => {
        const response = await assignedImpactGroupToRole({
          id: role?.id || '',
          payload: {
            impactGroupAssigned: groupId || '',
          },
        });
        return response;
      }),
    ])
      .then(() => {
        assignRoleToImpactGroup({
          id: groupId || '',
          payload: {
            roleProfileList: [...assignedRoles.map((role) => role?.id || '')],
          },
        }).then(() => {
          toggleSuccessSnackbar({ message: 'Roles Added Successfully' });
          handleClose();
        });
        toggleSuccessSnackbar({ message: 'Roles Added Successfully' });
        handleClose();
      })
      .catch(() => toggleErrorSnackbar({ message: 'Error Adding Roles' }));
  };

  const onDropdownChange = (event: SelectChangeEvent, roleId: string) => {
    setSelectedRoles((value) => {
      const index: number = value.findIndex((item) => item.roleId === roleId);
      if (index === -1)
        return [
          ...value,
          { roleId, impactGroupId: (event.target.value as string) || '' },
        ];
      value[index].impactGroupId = (event.target.value as string) || '';
      return [...value];
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        <Box>{`${filteredRoles.length} roles are in another Impact Group`}</Box>
        <StyledLabel>
          {'Please define the assigned Impact group for each  role'}
          <HpTooltip
            title={
              'Below are roles that are already part of another impact group'
            }
          />
        </StyledLabel>
      </ModalTitle>
      <ModalBody>
        <Box
          sx={{
            boxShadow: '0px -15px 1px -15px #646A7C',
            marginBottom: '10px',
            marginTop: '1px',
          }}
        >
          {filteredRoles.map((item, key) => (
            <StyledBox key={key}>
              <Box sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <StyledRoleNameLabel>{item?.name || ''}</StyledRoleNameLabel>
              </Box>
              <Box sx={{ flexBasis: '50%' }}>
                <StyledLabel sx={{ color: '#646A7C' }}>
                  {'Primary Impact Group'}
                </StyledLabel>
                <LabelDropdown
                  sx={{ marginTop: '5px' }}
                  label={''}
                  onChange={(e) => onDropdownChange(e, item?.id || '')}
                  dropDownItem={dropdownData}
                  defaultValue={
                    dropdownData && item?.impactGroupAssigned
                      ? item.impactGroupAssigned
                      : ''
                  }
                />
              </Box>
            </StyledBox>
          ))}
        </Box>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Done'}
          type='submit'
          onClick={handleSubmit}
          disabled={false}
        />
      </ModalActions>
    </Modal>
  );
}
