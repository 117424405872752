import React from 'react';
import { Typography } from '@mui/material';

interface IProps {
  text: string;
}

const TabContainerTitle = ({ text }: IProps) => {
  return (
    <Typography
      variant={'h2'}
      sx={{
        fontFamily: 'Aventa',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '28px',
        lineHeight: '40px',
      }}
    >
      {text}
    </Typography>
  );
};

export default TabContainerTitle;
