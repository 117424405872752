import React, { useRef } from 'react';
import { Box, styled } from '@mui/material';
import Modal from '../../../../atoms/modal/Modal';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalActions from '../../../../atoms/modal/ModalActions';
import Button from '../../../../atoms/button/Button';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import AddAssessmentsTable from '../../../tables/Company/Projects/ProjectDetails/AddAssessmentsTable';
import plusIcon from '../../../../assets/icons/Plus.svg';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { useGetAssessmentsStructureQuery } from '../../../../store/api/assessmentStructure';
import {
  useGetProjectByIdQuery,
  useUpdateAssignmentStatusMutation,
  useUpdateProjectMutation,
} from '../../../../store/api/projects';
import { IProjectAssessmentAddTableType } from '../../../../store/api/types/assessmentStructure';
import { assessmentStatusData } from '../../../../commons/types';
import HDSpinner from '../../../../hd-ui-kit/HDSpinner';

const SpinnerWrapper = styled(Box)(() => ({
  '& .la-ball-spin.la-2x': {
    margin: '0 auto',
    top: '40%',
  },
  width: '100%',
  height: '100%',
  minHeight: '200px',
  zIndex: 99,
  textAlign: 'center',
}));

export default function ProjectAssessmentModal() {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const { id } = useParams();

  const [assessmentSelected, setAssessmentSelected] = React.useState<
    Array<IProjectAssessmentAddTableType>
  >([]);
  const [modalData, setModalData] = React.useState<
    Array<IProjectAssessmentAddTableType>
  >([]);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const isOpen = useAppSelector(
    (state) => state.projectAssessmentsModal.isOpen
  );
  const projectLeaderData = useAppSelector(
    (state) => state.projectAssessmentsModal.projectLeaderData
  );

  const { openProjectSpinner, closeProjectSpinner } = useActions();
  const params = useParams();

  const { data: { data: assessmentData } = {}, isLoading } =
    useGetAssessmentsStructureQuery({
      clientId: id,
      status: 'Published',
      limit: undefined,
      offset: undefined,
    });

  const { data: response, isSuccess } = useGetProjectByIdQuery(
    params.projectId as string
  );

  const [addAssessmentToProject] = useUpdateProjectMutation();
  const [updateAssignment] = useUpdateAssignmentStatusMutation();
  const [editProjectStatus] = useUpdateProjectMutation();

  React.useEffect(() => {
    if (assessmentData && isSuccess) {
      const projectData = response?.data || [];

      const updatedData = assessmentData?.map((item) => ({
        id: item.id,
        assessment: item.title,
        skillCategory: item.category,
        type: item.type,
        addedToProject: true,
      })) as Array<IProjectAssessmentAddTableType>;

      if (projectData[0]?.assessmentStructures) {
        updatedData.map((item) => {
          const idx = projectData[0]?.assessmentStructures.findIndex(
            (assessment) => assessment.assessmentStructureId === item.id
          );

          if (idx !== -1) {
            item.addedToProject = false;
          }
          return item;
        });
      }

      if (updatedData.length)
        setModalData(updatedData as Array<IProjectAssessmentAddTableType>);
    }
  }, [assessmentData, isSuccess, response]);

  const {
    closeProjectAssessmentModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
  } = useActions();

  const handleClose = () => {
    closeProjectAssessmentModal();
  };

  const addAssessments = async () => {
    setShowLoader(true);
    const projectData = response?.data || [];
    const payload = {
      projectId: params.projectId,
      assessmentStructures: [
        ...(projectData[0]?.assessmentStructures
          ? projectData[0].assessmentStructures
          : []),
        ...assessmentSelected.map((item) => ({
          assessmentStructureId: item.id,
        })),
      ],
    };

    try {
      setIsDisabled(true);
      const response = await addAssessmentToProject(payload).unwrap();
      if (response.status === 'success') {
        toggleSuccessSnackbar({ message: 'Assessment Added Successfully' });
        openProjectSpinner();
        Promise.all(
          projectLeaderData.map((leader) => {
            const payload = {
              projectId: params.projectId,
              leaderProfileId: leader.leaderProfileId,
              assignmentId: leader.assignmentId,
              assessments: assessmentSelected.map((item) => ({
                assessmentStructureId: item.id,
                assignmentStatus:
                  item.skillCategory === 'CBI' ? 'Not Started' : 'Not Invited',
              })),
            } as assessmentStatusData;
            return updateAssignment(payload);
          })
        )
          .then(() => {
            closeProjectSpinner();
            // Check if any CBI assessment is added to project
            const is_CBI =
              assessmentSelected.filter(
                (item) => item?.skillCategory === 'CBI'
              ) || [];
            // Update Project Status if project in Draft state and there are leaders in project and after adding a CBI assessment, the leaders are assigned to the assessment in above promise
            if (
              is_CBI.length > 0 &&
              projectLeaderData.length &&
              projectData[0].status === 'Draft'
            ) {
              const formStatusData = {
                projectId: String(params.projectId),
                status: 'In Progress',
              };
              editProjectStatus(formStatusData);
              setShowLoader(false);
              handleClose();
              return;
            } else {
              setShowLoader(false);
              handleClose();
            }
          })
          .catch(() => {
            closeProjectSpinner();
            setShowLoader(false);
            handleClose();
          });
      } else {
        toggleErrorSnackbar({ message: 'Error Adding Assessment' });
        setShowLoader(false);
        handleClose();
      }
    } catch (e) {
      setIsDisabled(false);
      toggleErrorSnackbar({ message: 'Error Adding Assessment' });
      setShowLoader(false);
      handleClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={{ width: '100%' }}
    >
      <ModalTitle onClose={handleClose}>Add Assessments</ModalTitle>
      {showLoader ? (
        <SpinnerWrapper>
          <HDSpinner
            size={'la-2x'}
            buttonColor={false}
          />
        </SpinnerWrapper>
      ) : (
        <Box>
          <ModalBody>
            <AddAssessmentsTable
              isLoading={isLoading}
              data={modalData}
              tableInstanceRef={tableInstanceRef}
              checkSave={setAssessmentSelected}
            />
          </ModalBody>
          <ModalActions>
            <Button
              btnType={'PRIMARY'}
              text={'Add Assessment'}
              endIcon={<img src={plusIcon} />}
              onClick={() => addAssessments()}
              disabled={!assessmentSelected.length || isDisabled}
            />
            <Button
              btnType={'SECONDARY'}
              text={'Cancel'}
              onClick={handleClose}
            />
          </ModalActions>
        </Box>
      )}
    </Modal>
  );
}
