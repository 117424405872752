import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import {
  Box,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  styled,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectOptions } from '../../commons/types';
import HpTooltip from '../tooltip/HpTooltip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UncheckedCheckboxIcon from '../../assets/icons/checkbox/default/unchecked-checkbox.svg';
import CheckedCheckboxIcon from '../../assets/icons/checkbox/default/checked-checkbox.svg';

interface IProps {
  tooltip?: string;
  label?: string;
  dropDownItem: Array<SelectOptions>;
  clearfunction?: () => void;
  /* Here , I was gettign some issues for other event types , so kept any as we are passing
  library event to our component */
  onChange: (e: any, value: Array<SelectOptions>, reason: unknown) => void;
  column?: any; // can't just pass MRT_Columns because it also has generic def for data of table
  width?: string;
  selectValue?: number;
}

const StyledCheckboxIcon = styled('img')(() => ({
  width: '24px',
  height: '24px',
}));

export interface multitype {
  title: string;
  year: number;
}

const StyledSelectLabel = styled('span')(() => ({
  fontFamily: 'Avenir',
  color: 'rgba(0, 0, 0, 0.87)',
  display: 'flex',
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  marginTop: '16px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  '& .MuiAutocomplete-listbox': {
    padding: '0 !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
  },
  '& .MuiInputBase-input': {
    height: '30px !important',
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: 'calc(100% - min(47.5% ,62px))',
  },
  '& .MuiChip-root': {
    height: 'auto',
    padding: '0.5px',
    margin: '1px 3px 1px 3px !important',
  },
}));

const StyledAutocompleteLi = styled('li')(() => ({
  width: '100%',
  '&:hover': {
    color: '#fff !important',
    background: '#18768C !important',
  },
  '&.MuiAutocomplete-option[aria-selected=true]': {
    backgroundColor: '#FFFFFF',
  },
  '&.MuiAutocomplete-option': {
    padding: '12px 16px 13px 16px',
    borderBottom: '1px solid #CCC',
  },
}));

const StyledAutocompleteLibox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: '#fff',
    background: '#18768C',
  },
  '&:focus': {
    color: '#fff',
    background: '#18768C',
  },
  '& .MuiCheckbox-root.Mui-checked': {
    color: '#646A7C',
    backgroundColor: 'transparent',
  },
  backgroundColor: 'transparent',
  width: '100%',
}));

const StyledPopperComponent = styled(Popper)(() => ({
  backgroundColor: 'transparent',
  borderRadius: '8px',
  '& .MuiPaper-root': {
    borderRadius: '8px !important',
  },
  '&.MuiAutocomplete-listbox': {
    padding: '0 !important',
  },
}));

const StyledTextField = styled(TextField)(() => ({
  '& .MuiAutocomplete-input': {
    display: 'flex',
    padding: '0 !important',
    marginBottom: '0px',
    alignContent: 'center',
    fontSize: '16px',
    fontWeight: '300',
    fontFamily: 'Avenir',
    color: '#646A7C',
  },
}));

const StyledPaperComponent = styled(Paper)(() => ({
  '& .MuiAutocomplete-listbox': {
    padding: 0,
    borderRadius: '8px',
  },
}));

const StyledOption = styled(Box)(() => ({
  padding: 0,
  margin: 0,
  fontSize: '16px',
  fontWeight: '500',
}));

const CustomPaper = ({ children, ...paperProps }: PaperProps) => (
  <StyledPaperComponent {...paperProps}>{children}</StyledPaperComponent>
);

const CustomPopper = ({ children, ...popperProps }: PopperProps) => (
  <StyledPopperComponent {...popperProps}>{children}</StyledPopperComponent>
);

export default function MultiSelectDropdown({
  tooltip,
  dropDownItem,
  label,
  onChange,
  column,
  width,
  selectValue,
  ...extraProps
}: IProps) {
  // --
  // This functionality allow to remove multi-selected items by clicking on reset button on header
  // It could be improved during refactoring
  const [values, setValue] = React.useState<SelectOptions[]>([]);

  const filteredValue = column?.getFilterValue();

  React.useEffect(() => {
    if (selectValue && selectValue >= 0) {
      setValue([dropDownItem[selectValue]]);
    }
  }, [filteredValue, selectValue]);

  React.useEffect(() => {
    if (!filteredValue) {
      setValue([]);
    }
  }, [filteredValue]);
  // --

  return (
    <>
      {label ? (
        <StyledSelectLabel>
          {label}
          {tooltip && <HpTooltip title={tooltip} />}
        </StyledSelectLabel>
      ) : (
        ''
      )}
      <StyledAutocomplete
        ListboxProps={{
          style: {
            maxHeight: '294px',
          },
        }}
        limitTags={1}
        PaperComponent={CustomPaper}
        PopperComponent={CustomPopper}
        popupIcon={<KeyboardArrowDownIcon />}
        sx={{
          width: width ? width : '320px !important',
          marginTop: '8px',
        }}
        multiple
        value={values || []}
        id='checkboxes-tags-demo'
        options={dropDownItem}
        disableCloseOnSelect
        disableClearable
        onChange={(event, newValue, reason) => {
          onChange(event, newValue as Array<SelectOptions>, reason);
          setValue(newValue as Array<SelectOptions>);
        }}
        /* We needed to use Type any here , as any other type used over here,
          was resulting in linting erros and showing those erros on browser when component loaded */
        getOptionLabel={(option: any) => option.value}
        renderOption={(props, option: any, { selected }) => (
          <StyledAutocompleteLi {...props}>
            <StyledAutocompleteLibox>
              <Checkbox
                style={{
                  marginRight: 8,
                  height: '24px',
                  width: '24px',
                  color: '#646A7C',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
                checked={selected}
                icon={
                  <StyledCheckboxIcon
                    src={UncheckedCheckboxIcon}
                    alt={'unchecked'}
                  />
                }
                checkedIcon={
                  <StyledCheckboxIcon
                    src={CheckedCheckboxIcon}
                    alt={'checked'}
                  />
                }
              />
              <StyledOption>{option.value || ''}</StyledOption>
            </StyledAutocompleteLibox>
          </StyledAutocompleteLi>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder={
              params.InputProps.startAdornment === undefined ? 'Select' : ''
            }
          />
        )}
        {...extraProps}
      />
    </>
  );
}
