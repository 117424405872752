import * as React from 'react';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { styled, Box } from '@mui/material';

export interface HpProgressProps extends LinearProgressProps {
  progress: number;
}

const StyledProgress = styled(LinearProgress)(() => ({
  backgroundColor: 'rgba(0, 33, 54, 0.2)',
  height: '8px',
  borderRadius: '40px',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#18768C',
  },
}));

export default function HpProgressBar({
  progress,
  ...extraProps
}: HpProgressProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <StyledProgress
        {...extraProps}
        variant='determinate'
        value={progress}
      />
    </Box>
  );
}
