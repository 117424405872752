import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../atoms/modal/Modal';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import ModalBody from '../../../../atoms/modal/ModalBody';
import FormLabelDropdown from '../../../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../../../molecules/form/FormWrapper';
import ModalActions from '../../../../atoms/modal/ModalActions';
import Button from '../../../../atoms/button/Button';
import { useParams } from 'react-router';
import {
  useAddReportStructureMutation,
  useGetReportStructureQuery,
} from '../../../../store/api/reportStructure';
import { ReportStructureDataType } from '../../../../store/api/types/reportStructure';
import { DropdownTypes } from '../../../../commons/types';

interface IProps {
  tableData?: ReportStructureDataType[];
}

export default function ReportsModal({ tableData }: IProps) {
  const [dropdown, setDropdown] = useState<DropdownTypes[]>([]);

  const { id: clientId } = useParams();

  const initialValues = useAppSelector(
    (state) => state.reportsModal.initialValues
  );

  const isOpen = useAppSelector((state) => state.reportsModal.isOpen);

  const modalType = useAppSelector((state) => state.reportsModal.modalType);

  const { data: { data = [], dropdownList = [] } = {} } =
    useGetReportStructureQuery({ clientId: 'Master' as string });

  const [addReport] = useAddReportStructureMutation();

  const { toggleErrorSnackbar, toggleSuccessSnackbar, closeReportsModal } =
    useActions();

  const methods = useForm<{ report: string }>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    closeReportsModal();
  };

  useEffect(() => {
    reset(initialValues);
  }, [isOpen, reset, initialValues]);

  useEffect(() => {
    if (dropdownList.length > 0) {
      const reportsAvailable = [];
      for (let i = 0; i < dropdownList.length; i++) {
        if (tableData === undefined || tableData?.length == 0) {
          break;
        }
        const value = tableData?.findIndex(
          (idx) => idx.name == dropdownList[i]?.keyName
        );
        if (value === -1) {
          reportsAvailable.push(dropdownList[i]);
        }
      }
      if (tableData === undefined || tableData?.length == 0) {
        setDropdown(dropdownList);
      } else {
        setDropdown(reportsAvailable);
      }
    }
  }, [dropdownList]);

  const submitHandler = async (requestPayload: ReportStructureDataType) => {
    const modalTypeMap = {
      add: {
        fn: addReport,
        messageSuccess: 'Report added successfully',
        errorMessage: 'Error Adding Report',
      },
    };

    try {
      const response = await modalTypeMap[modalType]
        .fn(requestPayload)
        .unwrap();
      if (response?.status === 'success' && response?.code === 200) {
        toggleSuccessSnackbar({
          message: modalTypeMap[modalType].messageSuccess,
        });
        handleClose();
      } else {
        toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
      }
    } catch (e) {
      const error = e as {
        data?: { detail: string };
        status?: number;
        message?: string;
      };
      if (error.data?.detail)
        toggleErrorSnackbar({ message: error.data.detail });
      else if (error.message) toggleErrorSnackbar({ message: error.message });
      else
        toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
    }
  };

  const onSubmit = (submitData: { report: string }) => {
    const obj = data.filter(
      (element: ReportStructureDataType) => element._id === submitData.report
    )[0];

    const requestPayload = { ...obj, clientId: clientId! };

    submitHandler(requestPayload);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>Add New Client Report</ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-client-report'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelDropdown
            name='report'
            placeholder='Select Report'
            rules={{ required: 'Please select report' }}
            label='Select Report'
            dropDownItem={dropdown || []}
            defaultValue={initialValues.report || ''}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Done'}
          type='submit'
          form='add-new-client-report'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
