import { Box, styled } from '@mui/material';
import TabContent from '../../atoms/tab/TabContent';
import Title from '../../atoms/title/Title';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import ImpactModulesVLTable from '../../organisms/tables/VariableLibrary/ImpactModulesVLTable';
import { useRef } from 'react';
import { MRT_TableInstance } from 'material-react-table';
import { useGetImpactModuleQuery } from '../../store/api/leadership';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';
import { ImpactModuleVLModal } from '../../organisms/modals/VariableLibrary/ImpactModuleVLModal';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ImpactModules = ({ index, value }: ITabContentProps) => {
  const { data: { data } = {}, isLoading } = useGetImpactModuleQuery('Master');

  const { openAddImpactModuleVLModal } = useActions();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };
  return (
    <TabContent
      index={index}
      value={value}
    >
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <StyledHeaderWrapper>
            <Title
              text={'Impact Modules'}
              sx={{
                fontSize: '28px',
              }}
            />
            <Box>
              <HpTooltip title={' '} />
            </Box>
          </StyledHeaderWrapper>
          <StyledHeaderActionWrapper>
            <Button
              btnType={'PRIMARY'}
              text={'Add new impact module'}
              onClick={() => openAddImpactModuleVLModal()}
            />
          </StyledHeaderActionWrapper>
        </StyledTitleWrapper>
        <StyledSubHeaderWrapper>
          <SubTitle
            text={`${data?.length ? data?.length : '0'} total impact modules`}
          />
          <ResetButton
            onClick={onResetButtonClick}
            text='Reset'
          />
        </StyledSubHeaderWrapper>
      </StyledTitlesWrapper>
      <ImpactModulesVLTable
        isLoading={isLoading}
        tableInstanceRef={tableInstanceRef}
        data={data}
      />
      <ImpactModuleVLModal />
    </TabContent>
  );
};

export default ImpactModules;
