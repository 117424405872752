import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { sendLeadersToTazioRequest } from '../api/types/tazio';

interface SendToTazioPayload {
  client_id?: string;
  profileIdList?: Array<string>;
  emailList?: Array<string>;
  account_id?: string;
  project_id?: string;
}
interface ICompanySendToTazioModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: SendToTazioPayload;
}

const initialState: ICompanySendToTazioModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    client_id: '',
    profileIdList: [],
    emailList: [],
    account_id: '',
    project_id: '',
  },
};

const companySendToTazioSlice = createSlice({
  name: 'companySendToTazio',
  initialState,
  reducers: {
    openCompanySendToTazioModal: (
      state,
      action: PayloadAction<sendLeadersToTazioRequest>
    ) => {
      state.isOpen = true;
      state.modalType = 'add';
      state.initialValues = {
        client_id: action.payload.client_id || '',
        profileIdList: action.payload.profileIdList,
        emailList: action.payload.emailList,
        account_id: action.payload.account_id,
        project_id: action.payload.project_id,
      };
    },
    closeCompanySendToTazioModal: () => ({
      ...initialState,
      isOpen: false,
    }),
  },
});

export const companySendToTazioActions = companySendToTazioSlice.actions;
export const companySendToTazioReducer = companySendToTazioSlice.reducer;
