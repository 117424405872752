import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import HDButton from '../../hd-ui-kit/HDButton';
import { HDButtonTypes } from '../../hd-ui-kit/HDButton/HDButton';

interface IProps {
  btnType: 'PRIMARY' | 'SECONDARY';
  type?: string;
  form?: string;
  startIcon?: JSX.Element;
}

const Button = ({
  btnType,
  text,
  disabled,
  startIcon,
  ...extraProps
}: HDButtonTypes & IProps) => {
  const themeMode = btnType === 'SECONDARY';

  const btnStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '22px',
    background: themeMode ? 'transparent' : '#334255',
    color: themeMode ? ' #0B0C10' : '#fff',
    boxShadow: 'none',
    height: '44px',
    alignContent: 'center',
    justifyContent: 'center',
    '&:focus': {
      boxShadow: 'none',
      ...(btnType === 'PRIMARY' && {
        // adding outline on primary as all primary buttons need outline on focus
        outline: '2px solid #334255 !important',
        outlineOffset: '2px !important',
      }),
      ...(btnType === 'SECONDARY' && {
        // adding animation for secondary buttons on focus
        outline: '2px solid #002136',
        outlineOffset: '-2px',
        transition: 'transform 0.3s ease-out',
      }),
      ...(!themeMode
        ? {
            border: 'none',
          }
        : {
            border: '1px solid #646A7C',
          }),
      background: themeMode ? 'none' : '#334255',
      ...(themeMode
        ? {
            '&:before': {
              border: 'none',
            },
          }
        : {}),
    },
    '&:hover': {
      boxShadow: 'none',
      ...(btnType === 'SECONDARY' && {
        // adding animation for secondary butto on hover
        outline: '2px solid #002136',
        outlineOffset: '-2px',
        transition: 'transform 0.3s ease-out',
      }),
      ...(btnType === 'PRIMARY' && {
        // adding animation condition on button type as all primary buttons need animation
        '& .MuiButton-endIcon': {
          webkitTransform: 'translateX(6px)',
          mozTransform: 'translateX(6px)',
          transform: 'translateX(6px)',
          transition: 'transform 0.3s ease-out',
        },
        outline: '2px solid transparent',
      }),
    },
  };

  const arrowStyle = {
    color: disabled ? '#A9A9A9' : '#66fcfc',
  };

  return (
    <HDButton
      variant={themeMode ? 'outlined' : 'contained'}
      startIcon={startIcon}
      endIcon={!themeMode ? <ArrowForwardIcon sx={arrowStyle} /> : null}
      text={text}
      sx={btnStyle}
      disabled={disabled}
      {...extraProps}
    />
  );
};

export default Button;
