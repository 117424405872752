import { DropdownTypes } from './types';

export const projectPurpose: DropdownTypes[] = [
  {
    keyName: 'Selection',
    keyValue: 'Selection',
  },
  {
    keyName: 'Development',
    keyValue: 'Development',
  },
  {
    keyName: 'Future Leaders',
    keyValue: 'Future Leaders',
  },
];

export const statusDropdown = [
  {
    text: 'Completed',
    value: 'Completed',
  },
  {
    text: 'Not Started',
    value: 'Not Started',
  },
  {
    text: 'In Progress',
    value: 'In Progress',
  },
  {
    text: 'Incomplete',
    value: 'Incomplete',
  },
];
