import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFunctionsData } from '../api/types/leadership';

interface IFunctionModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: {
    id: string;
    name: string;
    functionId: string;
    description?: string;
  };
}

const initialState: IFunctionModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    name: '',
    functionId: '',
  },
};

const functionVLModalSlice = createSlice({
  name: 'functionVLModal',
  initialState,
  reducers: {
    closeFunctionModal: (state) => {
      state.isOpen = false;
      state.initialValues = initialState.initialValues;
    },
    openAddFunctionModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditFunctionModal: (state, action: PayloadAction<IFunctionsData>) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        id: action.payload.id || '',
        name: action.payload.fieldValue || '',
        functionId: action.payload.code || '',
        description: action.payload.description || '',
      };
    },
  },
});

export const functionVLModalActions = functionVLModalSlice.actions;
export const functionVLModalReducer = functionVLModalSlice.reducer;
