import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../../atoms/tab/TabContent';
import HpTooltip from '../../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../../commons/types';
import Title from '../../../atoms/title/Title';
import SubTitle from '../../../atoms/title/SubTitle';
import ResetButton from '../../../atoms/button/ResetButton';
import { MRT_TableInstance } from 'material-react-table';
import Button from '../../../atoms/button/Button';
import ImportIcon from '../../../assets/icons/Import.svg';
import FunctionalImpactSLModal from '../../../organisms/modals/Statement/FunctionalImpactSLModal';
import { useActions } from '../../../hooks/actions';
import { useParams } from 'react-router-dom';
import { useGetClientStatementsQuery } from '../../../store/api/leadership';
import CompanyFunctionalImpactTable from '../../../organisms/tables/Company/Statements/CompanyFunctionalImpactTable';

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

export default function FunctionalImpact({ index, value }: ITabContentProps) {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const { id } = useParams();

  const { data: { data: functionalImpact, metadata } = {}, isLoading } =
    useGetClientStatementsQuery({
      skillGroup: 'Functional Impact',
      clientId: id || '',
    });

  const { openAddFunctionalImpactSLModal, openImportStatementModal } =
    useActions();

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <div>
      <TabContent
        index={index}
        value={value}
      >
        <>
          <FunctionalImpactSLModal clientId={id} />
          <StyledTitlesWrapper>
            <StyledTitleWrapper>
              <StyledHeaderWrapper>
                <Title
                  text={'Business Impact: Functional Impact'}
                  sx={{
                    fontSize: '28px',
                  }}
                />
                <Box>
                  <HpTooltip
                    title={
                      'Proven track record of professional accomplishments and ability to drive ' +
                      'business impact in specific functions and roles (role-specific)'
                    }
                  />
                </Box>
              </StyledHeaderWrapper>
              <StyledHeaderActionWrapper>
                <Button
                  btnType='SECONDARY'
                  text={'Import data'}
                  onClick={() => openImportStatementModal()}
                  startIcon={
                    <ButtonIcon
                      src={ImportIcon}
                      alt='Import CSV'
                    />
                  }
                />
                <Button
                  btnType={'PRIMARY'}
                  text={'Add new statement'}
                  onClick={() => openAddFunctionalImpactSLModal()}
                />
              </StyledHeaderActionWrapper>
            </StyledTitleWrapper>
            <StyledSubHeaderWrapper>
              <SubTitle
                text={`${
                  metadata ? metadata.pagination.totalCount : '0'
                } total statements`}
              />
              <ResetButton
                onClick={onResetButtonClick}
                text='Reset'
              />
            </StyledSubHeaderWrapper>
          </StyledTitlesWrapper>
          <CompanyFunctionalImpactTable
            data={functionalImpact}
            isLoading={isLoading}
            tableInstanceRef={tableInstanceRef}
          />
        </>
      </TabContent>
    </div>
  );
}
