import React, { useMemo } from 'react';
import Table from '../../../../atoms/table/Table';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { IFunctionsData } from '../../../../store/api/types/leadership';
import { useActions } from '../../../../hooks/actions';

interface IProps {
  data?: Array<IFunctionsData>;
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const FunctionClientVLTable = ({
  data,
  isLoading,
  tableInstanceRef,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    openEditFunctionVLClientModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = (row: IFunctionsData) => {
    openEditFunctionVLClientModal(row);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns: MRT_ColumnDef<IFunctionsData>[] = useMemo(
    () => [
      {
        header: 'Function Name',
        accessorKey: 'fieldValue',
        filterFn: 'includesString',
        minSize: 255,
      },
      {
        header: 'System Name',
        accessorKey: 'systemName',
        filterFn: 'includesString',
        minSize: 200,
      },
      {
        header: 'ID',
        accessorKey: 'code',
        filterFn: 'includesString',
      },
    ],
    []
  );

  return (
    <Table
      data={data ?? []}
      tableInstanceRef={tableInstanceRef}
      columns={columns as MRT_ColumnDef<object>[]}
      renderRowActions={({ cell, row }) => (
        <>
          <IconButton
            id={cell.id}
            onClick={handleOpen}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl?.id === cell.id}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleEdit(row.original as IFunctionsData)}
            >
              Edit
            </MenuItem>
          </Menu>
        </>
      )}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default FunctionClientVLTable;
