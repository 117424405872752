import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import Button from '../../../atoms/button/Button';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormWrapper from '../../../molecules/form/FormWrapper';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import { useForm } from 'react-hook-form';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import {
  useAddLeaderImpactGroupMutation,
  useEditLeaderImpactGroupMutation,
} from '../../../store/api/leadership';
import { useParams } from 'react-router-dom';

interface IAddImpactGroupFormProps {
  id: string;
  name: string;
  description: string;
}

const CompanyImpactGroupModal = () => {
  const isOpen = useAppSelector(
    (state) => state.companyImpactGroupModal.isOpen
  );
  const modalType = useAppSelector(
    (state) => state.companyImpactGroupModal.modalType
  );
  const initialValues = useAppSelector(
    (state) => state.companyImpactGroupModal.initialValues
  );

  const { id } = useParams();

  const methods = useForm<IAddImpactGroupFormProps>();
  const { handleSubmit, reset } = methods;

  const {
    closeCompanyImpactGroupModal,
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
  } = useActions();

  const [addImpactGroup] = useAddLeaderImpactGroupMutation();
  const [editImpactGroup] = useEditLeaderImpactGroupMutation();

  const handleClose = () => {
    closeCompanyImpactGroupModal();
  };

  const onSubmit = async (formData: IAddImpactGroupFormProps) => {
    if (formData.id) {
      const payload = {
        name: formData.name,
        description: formData.description,
      };

      editImpactGroup({ id: formData.id, payload })
        .unwrap()
        .then((response) => {
          if (response.status === 'error' && response?.message) {
            toggleErrorSnackbar({ message: response.message });
            return;
          }
          toggleSuccessSnackbar({
            message: 'Impact Group Updated Successfully',
          });
          handleClose();
          return;
        })
        .catch(() => {
          toggleErrorSnackbar({ message: 'Error Updating Impact Group' });
        });
    } else {
      const payload = {
        name: formData.name,
        clientId: id || '',
        description: formData.description || '',
        status: 'Draft',
      };
      addImpactGroup(payload)
        .unwrap()
        .then((response) => {
          if (response.data.status === 'error' && response.data?.message) {
            toggleErrorSnackbar({ message: response.data.message });
            return;
          }
          toggleSuccessSnackbar({ message: 'Impact Group Added Successfully' });
          handleClose();
          return;
        })
        .catch(() => {
          toggleErrorSnackbar({ message: 'Error Adding Impact Group' });
        });
    }
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [isOpen, initialValues, reset]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add' ? 'Add a new impact group' : 'Edit a impact group'}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-impact-group'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='name'
            label='Impact Group Name'
            rules={{ required: 'Please provide an impact group name' }}
            tooltip=' '
            defaultValue={initialValues.name}
          />
          <FormLabelInput
            name='description'
            label='Description'
            rules={{ required: 'Please provide an impact group description' }}
            extraLabelInputProps={{
              minRows: 2,
              maxRows: 2,
              multiline: true,
            }}
            defaultValue={initialValues.description}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          form='add-impact-group'
          type='submit'
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default CompanyImpactGroupModal;
