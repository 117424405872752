import HDButton from '../../hd-ui-kit/HDButton';
import { HDButtonTypes } from '../../hd-ui-kit/HDButton/HDButton';

const ViewRatersButton = ({ text, ...extraProps }: HDButtonTypes) => {
  const btnStyle = {
    '&.Mui-selected': {},
    '&.Mui-focusVisible': {
      borderColor: 'transparent',
    },
    ':focus': {
      borderColor: 'transparent',
    },
    padding: '8px',
    textDecoration: 'underline !important',
  };

  return (
    <HDButton
      size='small'
      variant='text'
      disableRipple={true}
      text={text}
      sx={btnStyle}
      {...extraProps}
    />
  );
};

export default ViewRatersButton;
