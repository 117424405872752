import React from 'react';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import {
  useGetExpertiseCategoryQuery,
  useSetExpertiseCategoryMutation,
  useEditExpertiseCategoryMutation,
} from '../../../store/api/leadership';

interface IExpertiseCategoryFormData {
  id?: string;
  clientId?: string;
  fieldValue: string;
  code: string;
}

export default function ExpertiseCategoriesVLModal() {
  const initialValues = useAppSelector(
    (state) => state.expertiseCategoryVLModal.initialValues
  );
  const isOpen = useAppSelector(
    (state) => state.expertiseCategoryVLModal.isOpen
  );
  const modalType = useAppSelector(
    (state) => state.expertiseCategoryVLModal.modalType
  );

  const { data: { data: categoryData } = {} } =
    useGetExpertiseCategoryQuery('Master');

  const [addExpertiseCategory] = useSetExpertiseCategoryMutation();
  const [editExpertiseCategory] = useEditExpertiseCategoryMutation();

  const methods = useForm<IExpertiseCategoryFormData>();
  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = methods;

  const calculateExpertiseCategoryId = () => {
    // This functionality will automatically set Expertise Category ID to first available
    if (modalType === 'add' && isOpen) {
      let i = 1;
      while (categoryData!.find((el) => el.code === `EC${i}`)) {
        i++;
      }
      setValue('code', `EC${i}`);
    }
  };

  const {
    toggleErrorSnackbar,
    toggleSuccessSnackbar,
    closeExpertiseCategoryVLModal,
  } = useActions();

  const handleClose = () => {
    closeExpertiseCategoryVLModal();
  };

  const modalTypeMap = {
    edit: {
      fn: editExpertiseCategory,
      messageSuccess: 'Expertise Category Updated Successfully',
      errorMessage: 'Error Updating Expertise Category',
      title: 'Edit expertise category',
    },
    add: {
      fn: addExpertiseCategory,
      messageSuccess: 'Expertise Category Added Successfully',
      errorMessage: 'Error Adding Expertise Category',
      title: 'Add new expertise category',
    },
  };

  const onSubmit = async (formData: IExpertiseCategoryFormData) => {
    // Check the ExpertiseCategory ID for after two character it should be followed by Number Only
    const code = formData.code;
    const reg = new RegExp('^[0-9]*$');
    const checkNumber = code.substring(2, code.length);
    const isnumber = reg.test(checkNumber);

    if (code.substring(0, 2) !== 'EC' || !isnumber || code.length <= 2) {
      setError('code', {
        message: 'Please provide valid expertise category ID',
      });
      return;
    }

    if (modalType === 'add') {
      formData.clientId = 'Master';
    }

    modalTypeMap[modalType]
      .fn(formData)
      .unwrap()
      .then((response) => {
        if (response.status === 'success') {
          toggleSuccessSnackbar({
            message: modalTypeMap[modalType].messageSuccess,
          });
          handleClose();
        }
      })
      .catch((e) => {
        if (e.data.detail.includes('with code')) {
          setError('code', {
            message: e.data.detail,
          });
        } else {
          toggleErrorSnackbar({ message: e.data.detail });
        }
      });
  };

  React.useEffect(() => {
    reset(initialValues);
    calculateExpertiseCategoryId();
  }, [isOpen, reset, initialValues]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalTypeMap[modalType].title}
      </ModalTitle>
      <ModalBody>
        <FormWrapper
          methods={methods}
          id='add-new-expertise-category'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabelInput
            name='fieldValue'
            rules={{ required: 'Please provide expertise category name' }}
            label='Expertise Category Name'
            defaultValue={initialValues.fieldValue}
          />
          <FormLabelInput
            name='code'
            tooltip={'Tooltip to be Added...'}
            rules={{ required: 'Please provide expertise category ID' }}
            label='Expertise Category ID'
            defaultValue={initialValues.code}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='add-new-expertise-category'
          disabled={!isDirty || !!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
