import React, { useMemo } from 'react';
import Table from '../../atoms/table/Table';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_TableInstance,
} from 'material-react-table';
import { IClientApplications, IClientData } from '../../store/api/types/client';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/routes';
import MultiSelectDropdown from '../../atoms/multiselect/MultiSelectDropdown';
import { SelectOptions } from '../../commons/types';
import { dateSortingFunction } from '../../store/api/helpers';
import { restrictedHandler } from '../../rbac/Restricted';
import { useGetMeQuery } from '../../store/api/v2/auth';

interface IProps {
  data?: IClientData[];
  isLoading: boolean;
  tableInstanceRef: React.RefObject<MRT_TableInstance>;
}

const CompaniesTable = ({ data, isLoading, tableInstanceRef }: IProps) => {
  const navigate = useNavigate();

  const { data: userData } = useGetMeQuery();

  const appColumnHandler = (applications: IClientApplications) => {
    if (!Object.keys(applications).length) {
      return '-';
    }
    let applicationOptions: string[] = [];

    if (applications?.insights?.isEnabled) {
      applicationOptions = [...applicationOptions, 'Insights'];
    }
    if (applications?.journey?.isEnabled) {
      applicationOptions = [...applicationOptions, 'Journey'];
    }
    if (applications?.navigator?.isEnabled) {
      applicationOptions = [...applicationOptions, 'Navigator'];
    }

    return applicationOptions.length ? applicationOptions.join(', ') : '-';
  };

  const showCreatedDate = (timeStamp: string) => {
    const date = new Date(timeStamp);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth();

    return `${months[month]} ${day < 10 ? '0' + day : day}, ${year}`;
  };

  const applicationsdata = [
    {
      text: 'Insights',
      value: 'Insights',
    },
    {
      text: 'Navigator',
      value: 'Navigator',
    },
    {
      text: 'Journey',
      value: 'Journey',
    },
  ];

  // multiSelect Onchange Event handler
  const multiSelectOnChangeHandler = (
    e: React.KeyboardEvent,
    newValue: SelectOptions[],
    reason: unknown,
    column: MRT_Column<IClientData>
  ) => {
    // This is an onChange event to update the filter value for this column
    if (
      e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const selectedItems = newValue.map((value: { value: string }) => {
      return value.value;
    });

    if (selectedItems.length > 0) {
      column.setFilterValue(
        (selectedItems.length > 0 && selectedItems) || undefined
      );
    } else {
      column.setFilterValue(undefined);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IClientData>[]>(
    () => [
      {
        header: 'Company Name',
        accessorKey: 'name',
        filterFn: 'includesString',
        size: 320,
      },
      {
        header: 'Date Added',
        accessorFn: (row) => showCreatedDate(row['addedTs'] ?? ''),
        filterFn: 'includesString',
        sortingFn: (rowA, rowB) => dateSortingFunction(rowA, rowB, 'addedTs'),
        // using cell value as accessorFn sorts the date values by string and not by timestamp
        Cell: ({ cell }) => (
          <>
            {cell.getValue() ? showCreatedDate(cell.getValue() as string) : '-'}
          </>
        ),
        size: 320,
      },
      {
        header: 'Applications',
        accessorFn: (originalRow) =>
          appColumnHandler(originalRow.applications || {}),
        id: 'applications',
        filterVariant: 'multi-select',
        Filter: ({ column }) => (
          <MultiSelectDropdown
            dropDownItem={applicationsdata ?? []}
            onChange={(e, newValue, reason) =>
              multiSelectOnChangeHandler(e, newValue, reason, column)
            }
            column={column}
          />
        ),
        maxSize: 200,
        minSize: 180,
        size: 180,
      },
      // {
      //   header: 'Number of Users',
      //   accessorKey: 'leaders_count',
      //   filterFn: 'equals',
      // },
    ],
    []
  );

  return (
    <Table
      tableInstanceRef={tableInstanceRef}
      data={data ?? []}
      columns={columns as MRT_ColumnDef<object>[]}
      muiTableBodyCellProps={({ cell, row }) => ({
        onClick: () => {
          const selection = window.getSelection()!.toString();
          if (!cell.id.includes('mrt-row-actions') && !selection) {
            if (restrictedHandler('project.page', userData?.permissionsArr)) {
              navigate(
                `${routes.company.url}${(row.original as IClientData).id}${
                  routes.company.projects.url
                }`
              );
            } else {
              navigate(
                `${routes.company.url}${(row.original as IClientData).id}${
                  routes.company.settings.url
                }`
              );
            }
          }
        },
        sx: {
          cursor: !cell.id.includes('mrt-row-actions') ? 'pointer' : 'default',
        },
      })}
      state={{ showSkeletons: isLoading }}
    />
  );
};

export default CompaniesTable;
