import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILevelFormData } from '../api/types/leadership';

interface ILevelVLModalProps {
  isOpen: boolean;
  modalType: 'add' | 'edit';
  initialValues: ILevelFormData;
}

const initialState: ILevelVLModalProps = {
  isOpen: false,
  modalType: 'add',
  initialValues: {
    id: '',
    fieldValue: '',
    code: '',
  },
};

const levelVLModalSlice = createSlice({
  name: 'levelVLModal',
  initialState,
  reducers: {
    closeLevelVLModal: () => ({
      ...initialState,
      isOpen: false,
    }),
    openAddLevelVLModal: () => ({
      ...initialState,
      isOpen: true,
    }),
    openEditLevelVLModal: (state, action: PayloadAction<ILevelFormData>) => {
      state.isOpen = true;
      state.modalType = 'edit';
      state.initialValues = {
        fieldValue: action.payload.fieldValue || '',
        code: action.payload.code || '',
        id: action.payload.id || '',
      };
    },
  },
});

export const levelVLModalActions = levelVLModalSlice.actions;
export const levelVLModalReducer = levelVLModalSlice.reducer;
